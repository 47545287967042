import { parse } from 'query-string';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { ExpandMore as ExpandMoreIcon, MenuOpen as MenuOpenIcon } from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SvgIcon,
  Toolbar,
  Typography,
  useMediaQuery,
  useScrollTrigger,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactComponent as EditIcon } from '@geo/media/visual/editInMenuIcon.svg';
import { ReactComponent as HelloIcon } from '@geo/media/visual/helloIcon.svg';
import { ReactComponent as InfoIcon } from '@geo/media/visual/infoIcon.svg';
import { ReactComponent as MilkyIcon } from '@geo/media/visual/milkyOfFranceIcon.svg';
import { ReactComponent as SearchIcon } from '@geo/media/visual/searchIcon.svg';
import { AppRouteProps, buildUrl } from '@geo/utils/dist/AppProps';
import { isStellanaut, isStelladmin, isStellaguru } from '@geo/utils/dist/AwsUtils';
import {
  LangDataAction,
  LangDataBodyCube,
  LangDataBodyHello,
  LangDataBodyHexagon,
  LangDataBodyMilky,
  LangDataBodyStellanaut,
} from '@geo/utils/dist/LangData';
import { getLangOption, LangOption, LangOptions } from '@geo/utils/dist/LangOption';
import { StaticApplication } from '@geo/utils/dist/StaticApplication';
import { StaticAuth } from '@geo/utils/dist/StaticAuth';
import { PaletteEnum, sx, sxColoredStylesType, sxLayout } from '@geo/utils/dist/Styles';
import { getDesignationSvg } from '@geo/utils/dist/SvgDic';
import { MainUserGroupIcon } from '@geo/utils/dist/SvgIconUtils';
import theme from '@geo/utils/dist/Theme';
import { AppEnum, AppPathEnum, MenuPageArray } from '@geo/utils/dist/TypeUtils';

interface ElevationScrollProps {
  children: React.ReactElement;
}
export const ElevationScroll: FC<ElevationScrollProps> = (props: ElevationScrollProps) => {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

type ActivePageAndKey = {
  page: AppEnum;
  key: string;
};

enum SubMenuEnum {
  none = 'none',
  userMenu = 'userMenu',
  langMenu = 'langMenu',
}

const StaticMenu: FC<AppRouteProps> = (props: AppRouteProps) => {
  const isWideScreen = useMediaQuery('(min-width:900px)');
  const { lang } = props;
  const userPower = props.authenticationData.userPower;
  const pointer: string | null = props.authenticationData.stellaInterface
    ? props.authenticationData.stellaInterface.inputStella.pointer
    : null;
  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);
  const [langAnchorEl, setLangAnchorEl] = useState<null | HTMLElement>(null);
  const [openSubMenu, setOpenSubMenu] = useState<SubMenuEnum>(SubMenuEnum.none);

  const langOptions: LangOptions = StaticApplication.supportedLanguage.map((supportedLang: string) => getLangOption(supportedLang));
  const designationId: string | null = StaticApplication.getDesignation();

  const menuPageArray: MenuPageArray = {
    root: '',
    cube: LangDataBodyCube.cubeEditorPage(),
    hello: 'Hello',
    milky: LangDataBodyMilky.milkyFrance(),
    stella: '',
    stellanaut: 'Stellanaut',
    hexagon: LangDataBodyHexagon.hexagonEditorPage(),
  };

  const getActivePageAndPageKey = (path: string): ActivePageAndKey => {
    const result: ActivePageAndKey = {
      page: path === AppPathEnum.root.path ? AppEnum.milky : (path.substring(1).split(AppPathEnum.root.path).shift() as AppEnum),
      key: '',
    };
    if (result.page === AppEnum.stella) result.key = `${path.substring(1).split(AppPathEnum.root.path)[1]}`;
    return result;
  };
  const activePageAndKey: ActivePageAndKey = getActivePageAndPageKey(props.location.pathname);

  const getSx = (): sxColoredStylesType => {
    return activePageAndKey.page === AppEnum.cube ? sx(PaletteEnum.tertiary) : sx(PaletteEnum.primary);
  };

  // Used for Milky Icon, Cube Icon, and Signin Icon, the idea is to show the active page icon in dark
  const getIconStyle = (iconPath: AppEnum | undefined, isWide = false): SxProps => {
    return activePageAndKey.page === iconPath
      ? isWide
        ? getSx().activeWideIconStyle
        : getSx().activeIconStyle
      : isWide
      ? getSx().hoverWideIconStyle
      : getSx().hoverIconStyle;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserAnchorEl(event.currentTarget);
    setOpenSubMenu(SubMenuEnum.userMenu);
  };

  const handleLangClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLangAnchorEl(event.currentTarget);
    setOpenSubMenu(SubMenuEnum.langMenu);
  };

  const handleLangClose = (): void => {
    setOpenSubMenu(SubMenuEnum.none);
    setLangAnchorEl(null);
  };

  const handleClose = (): void => {
    setOpenSubMenu(SubMenuEnum.none);
    setUserAnchorEl(null);
  };

  const handleUserListKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenSubMenu(SubMenuEnum.none);
    }
  };

  const handleLangListKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenSubMenu(SubMenuEnum.none);
    }
  };

  const SigninMenu: FC = () => {
    return !isStellanaut(userPower) ? (
      <MenuItem
        sx={{ transformOrigin: 'center bottom', ...getSx().hoverStyle }}
        {...{ component: Link, to: buildUrl(props, { path: AppPathEnum.hello.path }) }}
        key="signin"
        onClick={() => {
          handleClose();
        }}
      >
        {LangDataBodyHello.signIn()}
      </MenuItem>
    ) : null;
  };

  const StellanautMenu: FC = () => {
    return isStellanaut(userPower) ? (
      <MenuItem
        sx={getSx().hoverStyle}
        key="stellanaut"
        selected={activePageAndKey.page === AppEnum.stellanaut}
        onClick={() => {
          handleClose();
        }}
        {...(activePageAndKey.page !== AppEnum.stellanaut
          ? { component: Link, to: buildUrl(props, { path: AppPathEnum.stellanaut.path }) }
          : {})}
      >
        {LangDataBodyStellanaut.myProfile()}
      </MenuItem>
    ) : null;
  };

  const HexagonMenu: FC = () => {
    return isStellaguru(userPower) ? (
      <MenuItem
        sx={getSx().hoverStyle}
        key="hexagon"
        selected={activePageAndKey.page === AppEnum.hexagon}
        onClick={() => {
          handleClose();
        }}
        {...{ component: Link, to: buildUrl(props, { path: AppPathEnum.hexagon.path }) }}
      >
        {'Hexagon'}
      </MenuItem>
    ) : null;
  };

  const CubeMenu: FC = () => {
    return isStelladmin(userPower) && pointer ? (
      <Button
        component={Link}
        to={buildUrl(props, { path: AppPathEnum.cube.element + pointer })}
        sx={{ color: theme.palette.primary.contrastText }}
      >
        <>
          <SvgIcon sx={getIconStyle(AppEnum.cube)}>
            <EditIcon />
          </SvgIcon>
          {pointer.slice(0, -4)}
        </>
      </Button>
    ) : (
      <Button
        component={Link}
        to={buildUrl(props, {
          path: isStellanaut(userPower) ? AppPathEnum.cube.path : AppPathEnum.hello.path,
        })}
        sx={getSx().hoverStyle}
      >
        {`${LangDataAction.create()} ${LangDataBodyMilky.myStella()}`}
      </Button>
    );
  };

  const SignoutMenu: FC = () => {
    return isStellanaut(userPower) ? (
      <MenuItem
        sx={getSx().hoverStyle}
        key="signout"
        component={Link}
        to={buildUrl(props, { path: AppPathEnum.root.path })}
        onClick={() => {
          handleClose();
          StaticAuth.signout();
        }}
      >
        {LangDataBodyHello.signOut()}
      </MenuItem>
    ) : null;
  };

  const UserMenu: FC = () => {
    return (
      <Popper anchorEl={userAnchorEl} open={openSubMenu === SubMenuEnum.userMenu} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  sx={getSx().bgColorStyle}
                  autoFocusItem={openSubMenu === SubMenuEnum.userMenu}
                  id="user-menu-list-grow"
                  onKeyDown={handleUserListKeyDown}
                >
                  <SigninMenu />
                  <StellanautMenu />
                  <HexagonMenu />
                  <SignoutMenu />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  return (
    <>
      <ElevationScroll>
        <AppBar
          position="fixed"
          sx={{
            height: theme.mixins.toolbar.height,
            flexGrow: 1,
            color: 'primary.contrastText',
            backgroundColor: activePageAndKey.page === AppEnum.cube ? 'tertiary.main' : 'primary.main',
          }}
        >
          <Toolbar disableGutters>
            <Grid container alignItems="center" direction="row">
              {!isWideScreen && (activePageAndKey.page === AppEnum.cube || activePageAndKey.page === AppEnum.hexagon) ? (
                <Grid item xs={1}>
                  <Button
                    sx={{ pl: 0 }}
                    component="span"
                    onClick={() =>
                      props.setAuthenticationData({ ...props.authenticationData, cubeDrawer: !props.authenticationData.cubeDrawer })
                    }
                  >
                    <SvgIcon sx={{ ...sxLayout.iconNoBgStyle, color: 'primary.contrastText' }}>
                      <MenuOpenIcon />
                    </SvgIcon>
                  </Button>
                </Grid>
              ) : null}
              <Grid item xs={activePageAndKey.page === AppEnum.cube || activePageAndKey.page === AppEnum.hexagon ? 2 : 3} md={1}>
                <Button component={Link} to={buildUrl(props, { path: AppPathEnum.root.path })}>
                  <SvgIcon sx={getIconStyle(AppEnum.milky)}>
                    <MilkyIcon />
                  </SvgIcon>
                </Button>
              </Grid>
              {isWideScreen ? (
                <Grid item md={4}>
                  <Typography variant="h6" noWrap>
                    {activePageAndKey.page === AppEnum.stella && designationId !== null ? (
                      <SvgIcon viewBox="0 0 512 512">
                        <svg
                          {...{
                            width: '512px',
                            height: '512px',
                            viewBox: '0 0 512 512',
                            style: {
                              ...{
                                cursor: 'pointer',
                                backgroundSize: '32px 32px',
                                width: '32px',
                                height: '32px',
                              },
                            },
                          }}
                        >
                          <path id="whitePath" fill={theme.palette.primary.contrastText} d={getDesignationSvg(designationId)} />
                        </svg>
                      </SvgIcon>
                    ) : (
                      ''
                    )}
                    {menuPageArray[activePageAndKey.page] + activePageAndKey.key}
                  </Typography>
                </Grid>
              ) : null}
              <Grid item xs={5} md={3} container alignItems="center" direction="row">
                <Grid item xs={2} md={4} container justifyContent="center">
                  <SvgIcon sx={getIconStyle(undefined)}>
                    <SearchIcon />
                  </SvgIcon>
                </Grid>
                <Grid item xs={10} md={8} container justifyContent="flex-start">
                  <CubeMenu />
                </Grid>
              </Grid>
              <Grid item xs={4} sm={4} container direction="row" alignItems="center">
                <Grid item xs={3} sm={4} md={3} container justifyContent="flex-end">
                  <SvgIcon sx={getIconStyle(undefined)}>
                    <InfoIcon />
                  </SvgIcon>
                </Grid>
                <Grid item xs={4} md={3}>
                  <Button
                    aria-controls={openSubMenu === SubMenuEnum.userMenu ? 'lang-menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleLangClick}
                    sx={{ px: 0, justifyContent: 'flex-start' }}
                  >
                    <Avatar sx={getSx().textInBannerStyle}>{lang}</Avatar>
                    {isWideScreen ? <ExpandMoreIcon sx={getSx().expandStyle} /> : null}
                  </Button>
                </Grid>
                <Grid item xs={5} sm={4} md={6}>
                  {isStellanaut(userPower) ? (
                    <Button
                      aria-controls={openSubMenu === SubMenuEnum.userMenu ? 'user-menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                      sx={{ color: theme.palette.primary.contrastText, px: 0, justifyContent: 'flex-start' }}
                    >
                      <SvgIcon sx={{ ...getIconStyle(AppEnum.stellanaut, true), mr: '0.5em' }}>
                        <MainUserGroupIcon userPower={userPower} />
                      </SvgIcon>
                      {props.authenticationData.inputUser && isWideScreen ? props.authenticationData.inputUser.name.nickname : null}
                    </Button>
                  ) : (
                    <Button component={Link} to={buildUrl(props, { path: AppPathEnum.hello.path })}>
                      <SvgIcon sx={getIconStyle(AppEnum.hello)}>
                        <HelloIcon />
                      </SvgIcon>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
          <UserMenu />
          <Popper anchorEl={langAnchorEl} open={openSubMenu === SubMenuEnum.langMenu} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleLangClose}>
                    <MenuList
                      sx={getSx().bgColorStyle}
                      autoFocusItem={openSubMenu === SubMenuEnum.langMenu}
                      id="lang-menu-list-grow"
                      onKeyDown={handleLangListKeyDown}
                    >
                      {langOptions
                        .filter((langOption) => langOption.value !== lang)
                        .map((langOption: LangOption) => (
                          <MenuItem
                            sx={getSx().hoverStyle}
                            key={langOption.key}
                            onClick={() => {
                              setOpenSubMenu(SubMenuEnum.none);
                              props.setLang(langOption.value);
                            }}
                            {...{
                              component: Link,
                              to: buildUrl(props, { lang: langOption.value, search: parse(location.search), hash: location.hash.slice(1) }),
                            }}
                          >
                            {langOption.languageName}
                          </MenuItem>
                        ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </AppBar>
      </ElevationScroll>
      <Box sx={(theme) => theme.mixins.toolbar} />
    </>
  );
};

export default StaticMenu;
