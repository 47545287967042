/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_appsync_graphqlEndpoint": "https://kgpmho5etrbhbj7ifbmxjskz7u.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "eu-west-3:de06714f-0af9-48a2-b14c-e13aedd95bc2",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_cKkEZ4EOm",
    "aws_user_pools_web_client_id": "4p133jq90au0eb7p8gugqvf815",
    "oauth": {
        "domain": "geostellawebapp011bed37-011bed37-milky.auth.eu-west-3.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://geostella.com/signin/",
        "redirectSignOut": "https://geostella.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "mybucket05052-milky",
    "aws_user_files_s3_bucket_region": "eu-west-3"
};


export default awsmobile;
