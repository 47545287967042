import { getLngData, TextLabelsEnum } from './LangUtils';
import { LangEnum, DialogContent, DialogEnum, StatusContent, StatusEnum, SuffixEnum } from './TypeUtils';

export const StelladminCreateLabelsDic: Record<TextLabelsEnum, { (lang?: LangEnum): string }> = {
  nameLabel: getLngData({
    en: "What's your name?",
    fr: 'Comment vous appelez-vous?',
  }),
  pageLabel: getLngData({
    en: 'About you :)',
    fr: 'Quelques détail sur vous',
  }),
  phoneNumberLabel: getLngData({
    en: 'Your phone',
    fr: 'Votre téléphone',
  }),
  reachableByEmaiQuestion: getLngData({
    en: 'Would you be reachable by email?',
    fr: 'Êtes-vous joignable par email?',
  }),
  reachableByPhoneQuestion: getLngData({
    en: 'Would you be reachable by phone?',
    fr: 'Êtes-vous joignable par téléphone?',
  }),
  userTitleLabel: getLngData({
    en: 'Your position within the business',
    fr: 'Votre rôle au sein de la structure',
  }),
};
export const StelladukeCreateLabelsDic: Record<TextLabelsEnum, { (lang?: LangEnum): string }> = {
  nameLabel: getLngData({
    en: 'Who is the Stelladmin?',
    fr: "Comment s'appele le Stellamdin?",
  }),
  pageLabel: getLngData({
    en: 'About the Stelladmin',
    fr: 'Au sujet du Stelladmin',
  }),
  phoneNumberLabel: getLngData({
    en: 'His/Her phone',
    fr: 'Son téléphone',
  }),
  reachableByEmaiQuestion: getLngData({
    en: 'Would the Stellanaut be reachable by email?',
    fr: 'Est-il joignable par email?',
  }),
  reachableByPhoneQuestion: getLngData({
    en: 'Would the Stellanaut be reachable by phone?',
    fr: 'Est-il joignable par téléphone?',
  }),
  userTitleLabel: getLngData({
    en: 'His/Her position within the business',
    fr: 'Son rôle au sein de la structure',
  }),
};

export const StelladminDic = {
  associatedEmail: getLngData({
    en: 'associated email to my Stellanaut account',
    fr: 'email associé à mon compte Stellanaut',
  }),
  contactPhone: getLngData({
    en: 'Contact phone number',
    fr: 'Téléphone de contact',
  }),
  madam: getLngData({
    en: 'Mrs',
    fr: 'Mme',
  }),
  mister: getLngData({
    en: 'Mr',
    fr: 'M.',
  }),
  notReachable: getLngData({
    en: 'In the Stella, I am not available neither by email nor by phone',
    fr: 'Dans la Stella, je ne suis pas joignable ni par email ni par téléphone',
  }),
  notSpecified: getLngData({
    en: 'Not specified',
    fr: 'Non renseigné',
  }),
  notSpecifiedPhone: getLngData({
    en: 'Phone number not specified',
    fr: 'Téléphone non renseigné',
  }),
  reachableByPhone: getLngData({
    en: 'In the Stella, I am reachable by phone',
    fr: 'Dans la Stella, je suis joignable par téléphone',
  }),
  reachableByEmail: getLngData({
    en: 'In the Stella, I am reachable by email',
    fr: 'Dans la Stella, je suis joignable par email',
  }),
  reachableByPhoneAndEmail: getLngData({
    en: 'In the Stella, I am reachable by both phone and email',
    fr: 'Dans la Stella, je suis joignable par téléphone et par email',
  }),
};

export const LinkingWordDic = {
  a_f: getLngData({
    en: 'a',
    fr: 'une',
  }),
  a_m: getLngData({
    en: 'a',
    fr: 'un',
  }),
  in: getLngData({
    en: 'in',
    fr: 'dans',
  }),
  of: getLngData({
    en: 'of',
    fr: 'de',
  }),
  or: getLngData({
    en: 'or',
    fr: 'ou',
  }),
  the_f: getLngData({
    en: 'the',
    fr: 'une',
  }),
  the_m: getLngData({
    en: 'the',
    fr: 'un',
  }),
};

const date = new Date();

export const LangDataAction = {
  cancel: getLngData({
    en: 'Cancel',
    fr: 'Annuler',
  }),
  choose: getLngData({
    en: 'Choose',
    fr: 'Choisir',
  }),
  confirm: getLngData({
    en: 'Confirm',
    fr: 'Valider',
  }),
  create: getLngData({
    en: 'Create',
    fr: 'Créer',
  }),
  delete: getLngData({
    en: 'Delete',
    fr: 'Supprimer',
  }),
  display: getLngData({
    en: 'Display',
    fr: 'Afficher',
  }),
  edit: getLngData({
    en: 'Edit',
    fr: 'Éditer',
  }),
  editing: getLngData({
    en: 'Editing',
    fr: 'Édition',
  }),
  finish: getLngData({
    en: 'Finish',
    fr: 'Terminer',
  }),
  modify: getLngData({
    en: 'Modify',
    fr: 'Modifier',
  }),
  next: getLngData({
    en: 'Next',
    fr: 'Suivant',
  }),
  preview: getLngData({
    en: 'Preview',
    fr: 'Aperçu',
  }),
  previous: getLngData({
    en: 'Previous',
    fr: 'Précédent',
  }),
  quit: getLngData({
    en: 'Quit',
    fr: 'Quitter',
  }),
  search: getLngData({
    en: 'Search...',
    fr: 'Rechercher...',
  }),
  submit: getLngData({
    en: 'Submit',
    fr: 'Soumettre',
  }),
  update: getLngData({
    en: 'Update',
    fr: 'Mettre à jour',
  }),
  upload: getLngData({
    en: 'Upload',
    fr: 'Télécharger',
  }),
  zoomIn: getLngData({
    en: 'Zoom in',
    fr: 'Zoomer',
  }),
  zoomOut: getLngData({
    en: 'Zoom out',
    fr: 'Dézoomer',
  }),
};

export const LangDataBodyCube = {
  addressRegionPaca: getLngData({
    en: "Address (Provence-Alpes-Côte d'Azur)",
    fr: 'Adresse (Région PACA)',
  }),
  addStella: getLngData({
    en: 'Add Stella',
    fr: 'Ajouter une Stella',
  }),
  catchphrase: getLngData({
    en: 'Catchphrase',
    fr: 'Slogan',
  }),
  confirmation: getLngData({
    en: 'Confirmation',
    fr: 'Confirmation',
  }),
  contentInEnglish: getLngData({
    en: 'English content',
    fr: 'Version anglaise',
  }),
  contentInFrench: getLngData({
    en: 'French content',
    fr: 'Version française',
  }),
  cubeEditorPage: getLngData({
    en: 'Cube - Editor',
    fr: 'Cube - Éditeur',
  }),
  designationSelection: getLngData({
    en: 'Choose the designation',
    fr: 'Choisir la désignation',
  }),
  details: getLngData({
    en: 'Details',
    fr: 'Détails',
  }),
  domainUrl: getLngData({
    en: 'Domain name',
    fr: 'Nom de domaine',
  }),
  domainUrlTitle: getLngData({
    en: 'Enter your domain name',
    fr: 'Entrez votre nom de domaine',
  }),
  domainUrlDescription: getLngData({
    en: 'Please enter your domain like www.domain.com or domain.fr',
    fr: 'Le format attendu est www.domain.com or domain.fr',
  }),
  doYouHaveWebsite: getLngData({
    en: 'Do you have a domain website (url)?',
    fr: 'Avez-vous un domaine de site web (url) ?',
  }),
  editingStella: getLngData({
    en: 'Editing Stella',
    fr: 'Mise à jour de la Stella',
  }),
  facebookPage: getLngData({
    en: 'Facebook page',
    fr: 'Page Facebook',
  }),
  facebookPageDescription: getLngData({
    en: 'Do you have a Facebook page?',
    fr: 'Avez-vous une page Facebook ?',
  }),
  fillDetails: getLngData({
    en: 'Thanks to fill below info',
    fr: 'Merci de donner quelques infos',
  }),
  fillSpecialtyMainTitle: getLngData({
    en: 'What is your main Specialty?',
    fr: 'Quelle est votre spécialité principale ?',
  }),
  firstname: getLngData({
    en: 'firstname',
    fr: 'prénom',
  }),
  gender: getLngData({
    en: 'Gender (Mr/Mrs)',
    fr: 'Genre (M/Mme)',
  }),
  indexPageContentMainTitle: getLngData({
    en: 'Index Page Content',
    fr: 'Contenu de la page d’index',
  }),
  inMilkyView: getLngData({
    en: 'In Milky view',
    fr: 'Dans vue Milky',
  }),
  instagramPage: getLngData({
    en: 'Instagram page',
    fr: 'Page Instagram',
  }),
  instagramPageDescription: getLngData({
    en: 'Do you have an Instagram page?',
    fr: 'Avez-vous une page Instagram ?',
  }),
  lastname: getLngData({
    en: 'family name',
    fr: 'nom de famille',
  }),
  locationAddress: getLngData({
    en: 'Address',
    fr: 'Adresse',
  }),
  locationAddressDescription: getLngData({
    en: 'What is the exact address?',
    fr: 'Quelle est l’adresse exacte ?',
  }),
  locationCity: getLngData({
    en: 'City',
    fr: 'Ville',
  }),
  locationPostalCode: getLngData({
    en: 'Postal Code',
    fr: 'Code postal',
  }),
  locationStreet: getLngData({
    en: 'Num, Street',
    fr: 'No, Rue',
  }),
  logo: getLngData({
    en: 'your logo',
    fr: 'votre logo',
  }),
  mainTitleGeneralStellaInfo: getLngData({
    en: 'What is your Stella’s name and where is it?',
    fr: 'Comment s’appelle votre Stella et où se trouve-t-elle ?',
  }),
  mainTitleUploadLogo: getLngData({
    en: 'The logo allows you to stand out',
    fr: 'Le logo vous permet de vous distinguer !',
  }),
  milkyDesignation: getLngData({
    en: 'Milky Designation',
    fr: 'Désignation Milky',
  }),
  milkyView: getLngData({
    en: 'Milky View',
    fr: 'Vue Milky',
  }),
  myLogo: getLngData({
    en: 'my logo',
    fr: 'mon logo',
  }),
  myOptions: getLngData({
    en: 'My Options',
    fr: 'Mes Options',
  }),
  mySales: getLngData({
    en: 'My Sales',
    fr: 'Mes Ventes',
  }),
  name: getLngData({
    en: 'Name',
    fr: 'Nom',
  }),
  newStella: getLngData({
    en: 'New Stella!',
    fr: 'Nouvelle Stella !',
  }),
  paragraph: getLngData({
    en: 'Paragraph',
    fr: 'Paragraphe',
  }),
  phone: getLngData({
    en: 'phone',
    fr: 'téléphone',
  }),
  pointer: getLngData({
    en: 'Pointer',
    fr: 'Pointeur',
  }),
  privateStella: getLngData({
    en: "It's a private Stella, not expected to be visited",
    fr: "C'est une Stella privée, qui ne se visite pas (Seulement la commune est visible dans la Stella)",
  }),
  publicStella: getLngData({
    en: 'It is a public Stella, which can be visited (address visible in the Stella)',
    fr: 'Stella localisée et publique, qui peut se visiter (adresse visible dans la Stella)',
  }),
  published: getLngData({
    en: 'Published',
    fr: 'Publié',
  }),
  role: getLngData({
    en: 'Role in the structure',
    fr: 'Rôle dans la structure',
  }),
  secondLngMainTitle: getLngData({
    en: 'Content in French',
    fr: 'Version en anglais',
  }),
  secondLngDescription: getLngData({
    en: 'Thanks to fill the below information in French',
    fr: 'Merci de remplir les infos ci-dessous en anglais',
  }),
  sectionTitle: getLngData({
    en: 'Section title',
    fr: 'Titre de section',
  }),
  selectDomainMainTitle: getLngData({
    en: 'What is your Stella about?',
    fr: 'Que voulez-vous exposer dans votre Stella ?',
  }),
  selectDomainSubTitle: getLngData({
    en: 'Choose your domain',
    fr: 'Sélection du domaine',
  }),
  selectPublicStella: getLngData({
    en: 'Public Stella',
    fr: 'Stella publique',
  }),
  selectPublicStellaSubTitle: getLngData({
    en: 'Do you want your address to be a visit or sale point, or to be private?\nA private Stella is not expected to be visited',
    fr: 'Voulez-vous que votre Stella soit un point de visite ou une stella privée ?\nUne Stella privée ne se visite pas',
  }),
  selectSpecialtyDesignation: getLngData({
    en: 'Please select the most accurate Specialty designation',
    fr: 'Choisissez la désignation la plus adaptée à votre Spécialité',
  }),
  specialty: getLngData({
    en: 'Specialty',
    fr: 'Spécialité',
  }),
  specialtyCatchphrase: getLngData({
    en: 'Your Specialty catchphrase or motto',
    fr: "Le slogan ou phrase d'accroche de votre Spécialité",
  }),
  specialtyCatchphraseDescription: getLngData({
    en: 'In a nutshell how would you promote this Specialty?',
    fr: 'En deux mots quel est votre message de promotion ?',
  }),
  stella: getLngData({
    en: 'Stella',
    fr: 'Stella',
  }),
  stelladminDefaultTitle: getLngData({
    en: 'Managing the business',
    fr: "Gérant de l'activité",
  }),
  stellaNameDescription: getLngData({
    en: 'What is your Stella’s name?',
    fr: 'Comment s’appelle votre Stella ?',
  }),
  stellactSite: getLngData({
    en: 'Stellact Site',
    fr: 'Site Stellact',
  }),
  stellactSiteChoice: getLngData({
    en: 'Get your generated Stellact website',
    fr: 'Choisissez la génération de site Stellact',
  }),
  stellactSuggestion: getLngData({
    en: 'Activate your Stellactweb site for 5€/month',
    fr: 'Activez cette option pour avoir un site Stellact à 5€/mois',
  }),
  subtitleGeneralStellaInfo: getLngData({
    en: 'What is your Stella name and location?',
    fr: 'Comment s’appelle votre Stella, et où se trouve-t-elle ?',
  }),
  subtitleUploadLogo: getLngData({
    en: 'Choose your logo',
    fr: 'Choix de votre logo',
  }),
  thanksToConfirm: getLngData({
    en: 'Thanks to confirm Stella creation',
    fr: 'Merci de confirmer la création de Stella',
  }),
  title: getLngData({
    en: 'Title',
    fr: 'Titre',
  }),
  uploadOfStellactFiles: getLngData({
    en: 'Upload of website files',
    fr: 'Téléchargement des fichiers pour le site web',
  }),
  user: getLngData({
    en: 'User',
    fr: 'Utilisateur',
  }),
  verifyLocationOnMap: getLngData({
    en: 'Verify your location',
    fr: 'Vérifiez la localisation',
  }),
  viewInMilky: getLngData({
    en: 'View in Milky',
    fr: 'Voir dans Milky',
  }),
  visualizeOnMap: getLngData({
    en: 'Visualize on map',
    fr: 'Visualisation sur carte',
  }),
  websiteDescription: getLngData({
    en: 'You may use your domain else for existing website or for our web solution',
    fr: 'Vous pouvez utiliser votre domaine soit pour votre site web, soit pour notre solution Stellact',
  }),
  webTraffic: getLngData({
    en: 'Web Traffic',
    fr: 'Trafic Web',
  }),
};

export const LangDataBodyHello = {
  bravoYouAreStelladmin: getLngData({
    en: 'BRAVO! You are now Admin of the Stella',
    fr: 'BRAVO ! Vous êtes à présent gérant de la Stella',
  }),
  email: getLngData({
    en: 'Email',
    fr: 'Email',
  }),
  forgotPasswordDescription: getLngData({
    en: 'You forgot your password? No worries, you will receive an email:',
    fr: "Vous avez oublié votre mot de passe ? Ce n'est pas grave, vous allez recevoir un email :",
  }),
  forgottenPassword: getLngData({
    en: 'Forgotten Password',
    fr: 'Passe oublié',
  }),
  newPasswordDescription: getLngData({
    en: 'Please enter and confirm your new password',
    fr: 'Veuillez entrer et confirmer votre nouveau mot de passe',
  }),
  nickname: getLngData({
    en: 'Username',
    fr: "Nom d'utilisateur",
  }),
  noAccountRegister: getLngData({
    en: 'No account? Register',
    fr: "Pas de compte? S'inscrire",
  }),
  password: getLngData({
    en: 'Password',
    fr: 'Mot de passe',
  }),
  passwordConfirmation: getLngData({
    en: 'Password confirmation',
    fr: 'Confirmez le passe',
  }),
  reconnect: getLngData({
    en: 'Please reconnect',
    fr: 'Reconnectez-vous',
  }),
  signIn: getLngData({
    en: 'Sign in',
    fr: 'Se Connecter',
  }),
  signOut: getLngData({
    en: 'Sign out',
    fr: 'Se Déconnecter',
  }),
  signUp: getLngData({
    en: 'Sign up',
    fr: 'S’inscrire',
  }),
  welcomeInGeostella: getLngData({
    en: 'Welcome to Geostella',
    fr: 'Bienvenue dans Geostella',
  }),
};
export const LangDataBodyHexagon = {
  addDesignation: getLngData({
    en: 'Add a designation',
    fr: 'Ajouter une désignation',
  }),
  addDomain: getLngData({
    en: 'Add domain',
    fr: 'Ajouter un domaine',
  }),
  addEntity: getLngData({
    en: 'Add Entity',
    fr: 'Ajouter une entité',
  }),
  addLevel: getLngData({
    en: 'Add Level',
    fr: 'Ajouter un niveau',
  }),
  addSubdomain: getLngData({
    en: 'Add subdomain',
    fr: 'Ajouter un sous-domaine',
  }),
  cloudDataPage: getLngData({
    en: 'Cloud Data',
    fr: 'Cloud Data',
  }),
  color: getLngData({
    en: 'Color',
    fr: 'Couleur',
  }),
  comet: getLngData({
    en: 'Comet',
    fr: 'Comète',
  }),
  creatingOrUpdatingAStella: getLngData({
    en: 'Creating or updating a Stella:',
    fr: "Création ou mise à jour d'une Stella :",
  }),
  designationCreation: getLngData({
    en: 'Designation creation',
    fr: 'La création de désignation',
  }),
  designationDeletion: getLngData({
    en: 'Designation deletion',
    fr: 'La suppression de la désignation',
  }),
  designations: getLngData({
    en: 'Designations',
    fr: 'Désignations',
  }),
  designationUpdate: getLngData({
    en: 'Designation update',
    fr: 'La mise à jour de la désignation',
  }),
  domain: getLngData({
    en: 'Domain',
    fr: 'Domaine',
  }),
  domainCreation: getLngData({
    en: 'Domain creation',
    fr: 'La création de domaine',
  }),
  domainDeletion: getLngData({
    en: 'Domain deletion',
    fr: 'La suppression du domaine',
  }),
  domains: getLngData({
    en: 'Domains',
    fr: 'Domaines',
  }),
  domainUpdate: getLngData({
    en: 'Domain update',
    fr: 'La mise à jour du domaine',
  }),
  englishDescription: getLngData({
    en: 'English description',
    fr: 'Description en anglais',
  }),
  englishName: getLngData({
    en: 'English name',
    fr: 'Nom anglais',
  }),
  entities: getLngData({
    en: 'Entities',
    fr: 'Entités',
  }),
  entityCreation: getLngData({
    en: 'Entity creation',
    fr: 'La création de l’entité',
  }),
  entityDeletion: getLngData({
    en: 'Entity deletion',
    fr: 'La suppression de l’entité',
  }),
  entityUpdate: getLngData({
    en: 'Entity update',
    fr: 'La mise à jour de l’entité',
  }),
  frenchDescription: getLngData({
    en: 'French description',
    fr: 'Description en Français',
  }),
  frenchName: getLngData({
    en: 'French name',
    fr: 'Nom français',
  }),
  hexagonEditorPage: getLngData({
    en: 'Hexagon - Super Editor',
    fr: 'Hexagon - Super Éditeur',
  }),
  levelCreation: getLngData({
    en: 'Level creation',
    fr: 'La création de niveau',
  }),
  levelDeletion: getLngData({
    en: 'Level deletion',
    fr: 'La suppression du niveau',
  }),
  levels: getLngData({
    en: 'Levels',
    fr: 'Niveaux',
  }),
  levelUpdate: getLngData({
    en: 'Level update',
    fr: 'La mise à jour du niveau',
  }),
  municipality: getLngData({
    en: 'Municipality',
    fr: 'Municipalité',
  }),
  staticDataConfiguration: getLngData({
    en: 'Static data configuration: Add/Modify/Delete common data.',
    fr: 'Configuration des données statiques : Ajouter/Modifier/Supprimer les données communes.',
  }),
  subdomain: getLngData({
    en: 'Subdomain',
    fr: 'Sous-domaine',
  }),
  subdomainCreation: getLngData({
    en: 'Subdomain creation',
    fr: 'La création de sous-domaine',
  }),
  subdomainDeletion: getLngData({
    en: 'Subdomain deletion',
    fr: 'La suppression du sous-domaine',
  }),
  subdomains: getLngData({
    en: 'Subdomains',
    fr: 'Sous-domaines',
  }),
  subdomainUpdate: getLngData({
    en: 'Subdomain update',
    fr: 'La mise à jour du sous-domaine',
  }),
};
export const LangDataBodyMilky = {
  activity: getLngData({
    en: 'Activity',
    fr: 'Activité',
  }),
  copyrightLine1: getLngData({
    en: `Copyright Geostella ${date.getFullYear()} ©`,
    fr: `Copyright Geostella ${date.getFullYear()} ©`,
  }),
  copyrightLine2: getLngData({
    en: `All rights reserved. Version`,
    fr: `Tous droits réservés. Version`,
  }),
  creation: getLngData({
    en: 'Creation',
    fr: 'Création',
  }),
  deactivatedOption: getLngData({
    en: 'Deactivated option',
    fr: 'Option désactivée',
  }),
  enLanguageName: getLngData({
    en: 'English',
    fr: 'Anglais',
  }),
  frLanguageName: getLngData({
    en: 'French',
    fr: 'Français',
  }),
  generatedStellactSite: getLngData({
    en: 'Generated Stellact Site',
    fr: 'Site Stellact généré',
  }),
  inFewWords: getLngData({
    en: 'Geostella in a few words',
    fr: 'Geostella en 2 mots',
  }),
  manyPossibleTypesOfStellas: getLngData({
    en: 'Many possible types of Stellas are possible:',
    fr: 'De nombreux types de Stellas sont possibles :',
  }),
  milkyFrance: getLngData({
    en: 'Milky - France',
    fr: 'Milky - France',
  }),
  myStella: getLngData({
    en: 'My Stella',
    fr: 'Ma Stella',
  }),
  onlinePresence: getLngData({
    en: 'Online presence',
    fr: 'Présence en ligne',
  }),
  ownerOfStella: getLngData({
    en: 'Owner of the Stella',
    fr: 'Gérant de la Stella',
  }),
  privateStellaIn: getLngData({
    en: "It's a private Stella, not expected to be visited, in",
    fr: "C'est une Stella privée, qui ne se visite pas, de la commune de",
  }),
  resetNorth: getLngData({
    en: 'Reset north',
    fr: 'Recentrer',
  }),
  stellasOfMunicipality: getLngData({
    en: 'Stellas of the municipality',
    fr: 'Stellas de la commune',
  }),
  stellasToday: getLngData({
    en: 'Stellas today',
    fr: 'Stellas aujourd’hui',
  }),
  tourism: getLngData({
    en: 'Tourism',
    fr: 'Tourisme',
  }),
  website: getLngData({
    en: 'Website',
    fr: 'Site Web',
  }),
  welcomeTo: getLngData({
    en: 'Welcome to ',
    fr: 'Bienvenue à ',
  }),
};
export const LangDataBodyStella = {
  additional: getLngData({
    en: 'Additional',
    fr: 'Additionnel',
  }),
  callMe: getLngData({
    en: 'Please call me ',
    fr: 'Contactez-moi au ',
  }),
  contact: getLngData({
    en: 'Contact',
    fr: 'Contact',
  }),
  gallery: getLngData({
    en: 'Gallery',
    fr: 'Galerie',
  }),
  geonews: getLngData({
    en: 'Geonews',
    fr: 'Geonews',
  }),
  indexPage: getLngData({
    en: 'Index Page',
    fr: 'Page d’index',
  }),
  items: getLngData({
    en: 'Items',
    fr: 'Articles',
  }),
  pageTitleAdditional: getLngData({
    en: 'Additional pages',
    fr: 'Pages additionnelles',
  }),
  pageTitleContact: getLngData({
    en: 'Contact Page',
    fr: 'Page de contact',
  }),
  pageTitleGallery: getLngData({
    en: 'Gallery Page',
    fr: 'Page de galerie',
  }),
  pageTitleGeonews: getLngData({
    en: 'Geonews Page',
    fr: 'Page de Geonews',
  }),
  pageTitleItems: getLngData({
    en: 'Items Page',
    fr: 'Page des articles',
  }),
  writeMe: getLngData({
    en: 'Write me an email at',
    fr: 'Écrivez-moi un mail sur',
  }),
};
export const LangDataBodyStellanaut = {
  actions: getLngData({
    en: 'Actions',
    fr: 'Actions',
  }),
  changeMyPassword: getLngData({
    en: 'Change my password',
    fr: 'Changer mon mot de passe',
  }),
  currentPassword: getLngData({
    en: 'Current password',
    fr: 'Mot de passe actuel',
  }),
  iAmA: getLngData({
    en: 'I am a',
    fr: 'Je suis un',
  }),
  myProfile: getLngData({
    en: 'My Stellanaut Profile',
    fr: 'Mon profil Stellanaut',
  }),
  newPassword: getLngData({
    en: 'New password',
    fr: 'Nouveau mot de passe',
  }),
  welcome: getLngData({
    en: 'Welcome ',
    fr: 'Bienvenue ',
  }),
};
export const LangDataMessageCube = {
  addressError: getLngData({
    en: 'Address not found, try again',
    fr: 'Adresse non trouvée, réessayer',
  }),
  and: getLngData({
    en: 'and',
    fr: 'et',
  }),
  between: getLngData({
    en: 'Between',
    fr: 'Entre',
  }),
  chars: getLngData({
    en: 'chars',
    fr: 'caractères',
  }),
  charsNoSpace: getLngData({
    en: 'chars without space',
    fr: 'caractères sans espace',
  }),
  chooseShortIdentifier: getLngData({
    en: 'Choose a short identifer',
    fr: 'Choisir un identifiant court',
  }),
  deletionConfirmationPrompt: getLngData({
    en: 'Are you sure you want to delete this element?',
    fr: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
  }),
  deletionConfirmationTitle: getLngData({
    en: 'Deletion Confirmation',
    fr: 'Confirmation de suppression',
  }),
  digits: getLngData({
    en: 'digits',
    fr: 'chiffres',
  }),
  failed: getLngData({
    en: 'failed',
    fr: 'a échoué',
  }),
  fillAllTheSteps: getLngData({
    en: 'Please have all the steps as passed',
    fr: 'Valider toutes les étapes',
  }),
  fillMandatoryFieldMessage: getLngData({
    en: 'Please fill mandatory field, missing:',
    fr: 'Veuillez remplir tous les champs obligatoires, manquant :',
  }),
  invalidDomainNameError: getLngData({
    en: 'Domain name is invalid',
    fr: 'Le nom de Domaine n’est pas valide',
  }),
  invalidFormat: getLngData({
    en: 'The text must contain a word of',
    fr: 'Le texte doit contenir un mot de plus de',
  }),
  invalidPostCode: getLngData({
    en: 'Invalid Post Code',
    fr: 'Code postal invalide',
  }),
  letters: getLngData({
    en: 'letters',
    fr: 'lettres',
  }),
  municipalityNotFound: getLngData({
    en: 'Post code okay but municipality name not found. Please correct',
    fr: "Post code reconnu mais municipalité non trouvée. Veuillez vérifier l'orthographe",
  }),
  municipalityRestriction: getLngData({
    en: 'Only municipalities in PACA region are supported',
    fr: 'Seulement les municipalités de la région PACA sont supportées',
  }),
  noSpecialChar: getLngData({
    en: 'No special char allowed',
    fr: 'Les charactères spéciaux ne sont pas autorisés',
  }),
  postCodeRestriction: getLngData({
    en: 'Only Post Codes in PACA region are supported',
    fr: 'Seulement les codes postaux de la région PACA sont supportés',
  }),
  selectDomainMoreDetails: getLngData({
    en: 'A Stella allows you to exhibit your assets which can be one of the following domains',
    fr: 'Une Stella vous permet d’exposer des articles de type des domaines listés ci-dessous',
  }),
  stellaCreationCongrats: getLngData({
    en: 'Congrats, job done! You made it :) You are now a Stelladmin',
    fr: 'Bravo ! Vous avez réussi à créer votre Stella :) Vous êtes Stelladmin',
  }),
  stellaCreationError: getLngData({
    en: 'Error while creating the Stella',
    fr: 'Erreur lors de la création de la Stella',
  }),
  stellaNameFieldHepper: getLngData({
    en: 'Stella name is invalid',
    fr: 'Le nom de la Stella n’est pas valide',
  }),
  succeeded: getLngData({
    en: 'succeeded',
    fr: 'a réussi',
  }),
  supportedFormats: getLngData({
    en: 'Supported formats are ',
    fr: 'Les formats supportés sont ',
  }),
  textFormatError: getLngData({
    en: 'Text has invalid format',
    fr: 'Format invalide du texte',
  }),
  uploadFailed: getLngData({
    en: 'Upload Failed',
    fr: 'Téléchargement a échoué',
  }),
  uploadSuccessful: getLngData({
    en: 'Successful upload',
    fr: 'Téléchargement réussi',
  }),
  validatedAddress: getLngData({
    en: 'Address is validated',
    fr: 'Adresse vérifiée avec succès',
  }),
  validFormat: getLngData({
    en: 'Valid format',
    fr: 'Format valide',
  }),
};
export const LangDataMessageHello = {
  bravoYouNowSignin: getLngData({
    en: 'Bravo! You can now sign in',
    fr: 'Bravo ! Vous pouvez maintenant vous connecter',
  }),
  connection: getLngData({
    en: 'The connection',
    fr: 'La connexion',
  }),
  emailSentForgotPassword: getLngData({
    en: 'You should shortty receive an email. Please click on the specified link.',
    fr: 'Dans quelques instants un email sera dans votre boîte de reception. Cliquer sur le lien spécifié.',
  }),
  invalidEmailException: getLngData({
    en: 'Invalid Email',
    fr: "L'email' n’est pas valide",
  }),
  invalidForgotPasswordLinkError: getLngData({
    en: 'Invalid link. Redo the forgot password request',
    fr: "Lien invalide. Refaire la requête d'oubli de mot de passe",
  }),
  invalidPasswordException: getLngData({
    en: 'Invalid Password',
    fr: 'Le mot de passe n’est pas valide',
  }),
  limitExceededException: getLngData({
    en: 'Attempt limit exceeded, please try after some time',
    fr: 'Nombre maximum de tentatives atteint, réessayer plus tard',
  }),
  lowercase: getLngData({
    en: 'Contains 1 lowercase char',
    fr: 'Contient 1 lettre minuscule',
  }),
  mailError: getLngData({
    en: 'Email format is invalid',
    fr: 'Le format de l’email est invalide',
  }),
  minLength: getLngData({
    en: '8-chars long or more',
    fr: 'Contient 8 caractères ou plus',
  }),
  notAuthorizedException: getLngData({
    en: 'Incorrect email or password',
    fr: 'Email ou mot de passe incorrect',
  }),
  passwordConfirmationError: getLngData({
    en: 'Passwords do not match',
    fr: 'Les mots de passe ne correspondent pas',
  }),
  passwordMustMatch: getLngData({
    en: 'Must match the password',
    fr: 'Doit correspondre au mot de passe',
  }),
  passwordPolicy: getLngData({
    en: 'A password must be of 8 chars minimum, it must have a lower-case letter, an upper-case letter, a number, and a special character',
    fr: 'Un mot de passe a une longueur minimale de 8, avec une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial',
  }),
  passwordValidityError: getLngData({
    en: 'The password is not valid',
    fr: "Le mot de passe n'est pas valide",
  }),
  requireNumber: getLngData({
    en: 'Contains 1 number',
    fr: 'Contient 1 chiffre',
  }),
  requireSymbol: getLngData({
    en: 'Contains 1 special char',
    fr: 'Contient 1 caractère spécial',
  }),
  signUpNotConfirmed: getLngData({
    en: 'Email sent! Your account is created but not confirmed, please confirm by clicking the confirmation link in the received mail',
    fr: 'Vous allez recevoir un email. Veillez confirmer votre compte en cliquant sur le lien de confirmation dans l’email reçu',
  }),
  stellaAssociationTitle: getLngData({
    en: 'Do you accept to administrate following Stella?',
    fr: 'Acceptez-vous de gérer la Stella suivante ?',
  }),
  stellaAssociationPrompt: getLngData({
    en: 'This Stella was created for you:',
    fr: 'Cette Stella a été créé pour vous :',
  }),
  successfulPasswordChange: getLngData({
    en: 'Your password has been updated successfully',
    fr: 'Votre mot de passe a été changé avec succès',
  }),
  uppercase: getLngData({
    en: 'Contains 1 uppercase char',
    fr: 'Contient 1 lettre majuscule',
  }),
  usernameExistsException: getLngData({
    en: 'An account is already registered to this mail',
    fr: 'Cet email est déjà utilisé par un autre compte',
  }),
  userNotConfirmedException: getLngData({
    en: 'User is not confirmed, please verify your email box (and spam)',
    fr: 'Cet utilisateur n’est pas confirmé, veuillez vérifier votre boîte mail (et les spams)',
  }),
  userNotFoundException: getLngData({
    en: 'User does not exist',
    fr: 'Cet utilisateur n’existe pas',
  }),
};
export const LangDataMessageHexagon = {
  designationLoadingError: getLngData({
    en: 'An error occurred while loading the designations, please refresh',
    fr: 'Une erreur est survenue lors du chargement des désignations, veuillez rafraîchir la page',
  }),
  domainLoadingError: getLngData({
    en: 'An error occurred while loading the domains, please refresh',
    fr: 'Une erreur est survenue lors du chargement des domaines, veuillez rafraîchir la page',
  }),
  entityLoadingError: getLngData({
    en: 'An error occurred while loading the entities, please refresh',
    fr: 'Une erreur est survenue lors du chargement des entités, veuillez rafraîchir la page',
  }),
  invalidSectionId: getLngData({
    en: 'Invalid SectionId',
    fr: 'SectionId invalide',
  }),
  levelLoadingError: getLngData({
    en: 'An error occurred while loading the levels, please refresh',
    fr: 'Une erreur est survenue lors du chargement les niveaux, veuillez rafraîchir la page',
  }),
  stellaPointerError: getLngData({
    en: 'Stella pointer does not exist',
    fr: "Ce pointeur de Stella n'existe pas",
  }),
  subdomainLoadingError: getLngData({
    en: 'An error occurred while loading the subdomains, please refresh',
    fr: 'Une erreur est survenue lors du chargement des sous-domaines, veuillez rafraîchir la page',
  }),
};
export const LangDataMessageMilky = {
  errorContactSupport: getLngData({
    en: 'Sorry, an error occurred, try to refresh the page. If the problem persists, please contact support:',
    fr: 'Désolé, une erreur est survenue, essayez de rafraîchir la page. Si le problème persiste veuillez contacter le support :',
  }),
  internalErrorException: getLngData({
    en: 'Internal Error',
    fr: 'Erreur Interne',
  }),
  loadingData: getLngData({
    en: 'Loading data...',
    fr: 'Chargement de données...',
  }),
  urlNotFound: getLngData({
    en: 'URL not found...',
    fr: 'Url introuvable...',
  }),
};
export const LangDataMessageStellanaut = {
  wrongCurrentPassword: getLngData({
    en: 'The current password entered does not match. Please try again',
    fr: 'Le mot de passe actuel ne correspond pas. Veuillez réessayer',
  }),
};

export const statusArray: Record<StatusEnum, StatusContent> = {
  [StatusEnum.editTextPart1]: { message: '', error: false, suffix: SuffixEnum.none },
  [StatusEnum.editImagePart2]: { message: '', error: false, suffix: SuffixEnum.none },
  [StatusEnum.noStatus]: { message: '', error: false, suffix: SuffixEnum.none },
  [StatusEnum.successfulConnection]: { message: LangDataMessageHello.connection(), error: false, suffix: SuffixEnum.status },
  [StatusEnum.successfulPasswordChange]: {
    message: LangDataMessageHello.successfulPasswordChange(),
    error: false,
    suffix: SuffixEnum.none,
  },
  [StatusEnum.successfulSignUp]: { message: LangDataMessageHello.signUpNotConfirmed(), error: true, suffix: SuffixEnum.none },
  [StatusEnum.successfulStellaCreation]: { message: LangDataMessageCube.stellaCreationCongrats(), error: false, suffix: SuffixEnum.none },
  [StatusEnum.welcome]: { message: LangDataBodyStellanaut.welcome(), error: false, suffix: SuffixEnum.nickname },
};

export const dialogArray: Record<DialogEnum, DialogContent> = {
  [DialogEnum.deletionConfirmation]: {
    title: LangDataMessageCube.deletionConfirmationTitle(),
    prompt: LangDataMessageCube.deletionConfirmationPrompt(),
    action: LangDataAction.delete(),
  },
  [DialogEnum.stellaAssociation]: {
    title: LangDataMessageHello.stellaAssociationTitle(),
    prompt: LangDataMessageHello.stellaAssociationPrompt(),
    action: LangDataAction.confirm(),
  },
};
