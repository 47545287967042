import React, { FC } from 'react';
import { USERPOWER } from '@geo/gql/dist/schema';
import { ReactComponent as StelladminIcon } from '@geo/media/visual/stelladminIcon.svg';
import { ReactComponent as StelladukeIcon } from '@geo/media/visual/stelladukeIcon.svg';
import { ReactComponent as StellaguruIcon } from '@geo/media/visual/stellaguruIcon.svg';
import { ReactComponent as StellanautIcon } from '@geo/media/visual/stellanautIcon.svg';
import { getMainUserGroup } from './AwsUtils';
import { UserGroup } from './TypeUtils';

export const MainUserGroupIcon: FC<{ userPower: USERPOWER }> = (props: { userPower: USERPOWER }) => {
  let component = null;
  switch (getMainUserGroup(props.userPower)) {
    case UserGroup.stellaguru:
      component = <StellaguruIcon />;
      break;
    case UserGroup.stelladuke:
      component = <StelladukeIcon />;
      break;
    case UserGroup.stelladmin:
      component = <StelladminIcon />;
      break;
    default:
      component = <StellanautIcon />;
      break;
  }
  return component;
};
