import React, { FC, useState, useEffect } from 'react';
import { UserAuthenticationProps } from '@geo/utils/dist/AppProps';
import { StaticAuth, AuthListener } from '@geo/utils/dist/StaticAuth';
import { LangEnum } from '@geo/utils/dist/TypeUtils';
import App from './App';

export interface AppAuthProps {
  country: string;
  lang: LangEnum;
  authenticationData: UserAuthenticationProps;
}

const AppAuth: FC<AppAuthProps> = (props: AppAuthProps) => {
  const [lang, setLang] = useState<LangEnum>(props.lang);
  const [country, setCountry] = useState<string>(props.country);
  const [authenticationData, setAuthenticationData] = useState<UserAuthenticationProps>(props.authenticationData);

  const authListener: AuthListener = (authenticationData: UserAuthenticationProps) => {
    setAuthenticationData(authenticationData);
  };

  useEffect(() => {
    StaticAuth.addAuthRefreshListener(authListener);
    StaticAuth.refreshAuthenticationDataFromAmplify();
    return () => {
      StaticAuth.removeAuthRefreshListener(authListener);
    };
  }, []);

  return (
    <App
      {...props}
      lang={lang}
      setLang={setLang}
      country={country}
      setCountry={setCountry}
      authenticationData={authenticationData}
      setAuthenticationData={setAuthenticationData}
    ></App>
  );
};

export default AppAuth;
