import React, { FC } from 'react';
import { Divider, Typography } from '@mui/material';
import { LangDataBodyMilky } from '@geo/utils/dist/LangData';
import Package from '../../package.json';

export const Footer: FC = () => {
  return (
    <>
      <Divider />
      <Typography sx={{ textAlign: 'center' }} variant="body2">
        {LangDataBodyMilky.copyrightLine1()}
      </Typography>
      <Typography sx={{ textAlign: 'center' }} variant="body2">
        {`${LangDataBodyMilky.copyrightLine2()} ${Package.version}`}
      </Typography>
    </>
  );
};

export default Footer;
