import { ISignUpResult } from 'amazon-cognito-identity-js';
import { Auth, Storage } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { SignUpParams } from '@aws-amplify/auth/lib-esm/types/Auth';
import Amplify from '@aws-amplify/core';
import { PutResult } from '@aws-amplify/storage/lib/types/AWSS3Provider';
import { DeleteObjectCommandOutput } from '@aws-sdk/client-s3';
import { USERPOWER } from '@geo/gql/dist/schema';
import { AmplifyConfig } from './AmplifyConfig';
import { geostellaProdAPI, geostellaProdAuthAPI } from './AppSyncConfig';
import { isMockServer } from './Config';
import { LangDataMessageHello, LangDataMessageMilky } from './LangData';
import { AuthenticationDataFromCognito } from './SchemaUtils';
import { traceDebug } from './Trace';
import { UserGroup } from './TypeUtils';
import awsconfig from './aws-exports';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace React {
    interface DOMAttributes<T> {
      onResize?: ReactEventHandler<T> | undefined;
      onResizeCapture?: ReactEventHandler<T> | undefined;
      nonce?: string | undefined;
    }
  }
}

export enum CognitoApiReturnCode {
  Success = 'Success',
  AliasExistsException = 'AliasExistsException',
  CodeMismatchException = 'CodeMismatchException',
  ExpiredCodeException = 'ExpiredCodeException',
  ForbiddenException = 'ForbiddenException',
  InternalErrorException = 'InternalErrorException',
  InvalidLambdaResponseException = 'InvalidLambdaResponseException',
  InvalidParameterException = 'InvalidParameterException',
  InvalidPasswordException = 'InvalidPasswordException',
  LimitExceededException = 'LimitExceededException',
  NotAuthorizedException = 'NotAuthorizedException',
  ResourceNotFoundException = 'ResourceNotFoundException',
  TooManyFailedAttemptsException = 'TooManyFailedAttemptsException',
  TooManyRequestsException = 'TooManyRequestsException',
  UnexpectedLambdaException = 'UnexpectedLambdaException',
  UserLambdaValidationException = 'UserLambdaValidationException',
  UserNotFoundException = 'UserNotFoundException',
}

export const getCognitoApiReturnCode = (errorcode: string): CognitoApiReturnCode => {
  const result: CognitoApiReturnCode | undefined = CognitoApiReturnCode[errorcode as keyof typeof CognitoApiReturnCode];
  return result === undefined ? CognitoApiReturnCode.InternalErrorException : result;
};

export const getAmplifyErrorMessage = (errorcode: string): string => {
  let errorMessage = '';
  switch (errorcode) {
    case 'UserNotFoundException':
      errorMessage = LangDataMessageHello.userNotFoundException();
      break;
    case 'InternalErrorException':
      errorMessage = LangDataMessageMilky.internalErrorException();
      break;
    case 'InvalidPasswordException':
      errorMessage = LangDataMessageHello.invalidPasswordException();
      break;
    case 'LimitExceededException':
      errorMessage = LangDataMessageHello.limitExceededException();
      break;
    case 'UsernameExistsException':
      errorMessage = LangDataMessageHello.usernameExistsException();
      break;
    case 'NotAuthorizedException':
      errorMessage = LangDataMessageHello.notAuthorizedException();
      break;
    case 'UserNotConfirmedException':
      errorMessage = LangDataMessageHello.userNotConfirmedException();
      break;
    default:
      errorMessage = LangDataMessageMilky.internalErrorException();
  }
  return errorMessage;
};

export const isStellanaut = (userPower: USERPOWER): boolean => {
  return userPower !== USERPOWER.Y;
};

export const isStelladmin = (userPower: USERPOWER): boolean => {
  return USERPOWER[userPower].includes('M');
};

export const isStelladuke = (userPower: USERPOWER): boolean => {
  return USERPOWER[userPower].includes('K');
};

export const isStellaguru = (userPower: USERPOWER): boolean => {
  return USERPOWER[userPower].includes('G');
};

export const getUserPower = (userGroups: string[]): USERPOWER => {
  return userGroups
    .map((group) => UserGroup[group as keyof typeof UserGroup])
    .sort()
    .join('') as USERPOWER;
};

export const getMainUserGroup = (userPower: USERPOWER): UserGroup => {
  if (isStellaguru(userPower)) return UserGroup.stellaguru;
  else if (isStelladuke(userPower)) return UserGroup.stelladuke;
  else if (isStelladmin(userPower)) return UserGroup.stelladmin;
  else if (isStellanaut(userPower)) return UserGroup.stellanaut;
  return UserGroup.cybernaut;
};

export const displayMainUserGroup = (userPower: USERPOWER): string => {
  const userGroupValues: UserGroup[] = Object.values(UserGroup);
  const findKey = userGroupValues.findIndex((_key, index) => userGroupValues[index] === getMainUserGroup(userPower));
  return Object.keys(UserGroup)[findKey];
};

export const upgradeUserPower = (userPower: USERPOWER, newUserGroup: UserGroup): USERPOWER => {
  if (newUserGroup === UserGroup.stellaguru) {
    if (isStellaguru(userPower)) return userPower;
    else return ('G' + userPower.valueOf()) as USERPOWER;
  } else if (newUserGroup === UserGroup.stelladuke) {
    if (isStelladuke(userPower)) return userPower;
    else return ('K' + userPower.valueOf()).split('').sort().join('') as USERPOWER;
  } else if (newUserGroup === UserGroup.stelladmin) {
    if (isStelladmin(userPower)) return userPower;
    else return ('M' + userPower.valueOf()).split('').sort().join('') as USERPOWER;
  }
  return USERPOWER.N;
};

export const getAuthenticationDataFromCognito = (userGroups: string[], userRef: string, email: string): AuthenticationDataFromCognito => {
  const userRefElements: string[] = userRef.split(':');
  const result: AuthenticationDataFromCognito = {
    userPower: getUserPower(userGroups),
    userId: null,
    stellaId: null,
    email,
  };
  if (userRefElements.length === 1) {
    result.userId = userRefElements[0];
  } else if (userRefElements.length === 2) {
    result.userId = userRefElements[0];
    result.stellaId = userRefElements[1];
  }
  return result;
};

export const checkAmplifyAuth = async (): Promise<AuthenticationDataFromCognito> => {
  return Auth.currentAuthenticatedUser()
    .then((data: CognitoUser) => {
      traceDebug('Current user session', data);
      const payload = data.getSignInUserSession()?.getIdToken().decodePayload();
      traceDebug('Current  user session payload', payload);
      const userGroups: Array<string> | null = payload ? (payload['cognito:groups'] as Array<string>) : null;
      const userRef: string | null = payload ? payload['given_name'] : null;
      const email: string | null = payload ? payload['email'] : null;
      if (userGroups && userRef && email) {
        return getAuthenticationDataFromCognito(userGroups, userRef, email);
      } else
        return {
          userPower: USERPOWER.Y,
          userId: null,
          stellaId: null,
          email: null,
        };
    })
    .then((data) => data);
};

export const gqlUnauthApi = {
  ...geostellaProdAPI,
  // Override graphqlEndpoint from aws-exports
  graphqlEndpoint: awsconfig.aws_appsync_graphqlEndpoint,
};

export const gqlAuthApi = {
  ...geostellaProdAuthAPI,
  // Override graphqlEndpoint from aws-exports
  graphqlEndpoint: awsconfig.aws_appsync_graphqlEndpoint,
};

interface AwsAuthenticationData {
  Auth: {
    identityPoolId: string;
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
  };
}

export const getAwsAuthenticationData = (): AwsAuthenticationData => {
  return {
    Auth: {
      ...AmplifyConfig,
      // Override with data from aws-exports
      identityPoolId: awsconfig.aws_cognito_identity_pool_id,
      userPoolId: awsconfig.aws_user_pools_id,
      userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,
    },
  };
};

export const amplifyConfigure = (): void => {
  Amplify.configure(getAwsAuthenticationData());
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const amplifySignIn = async (username: string, password: string): Promise<any> => {
  return Auth.signIn(username, password).then((res) => res);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const amplifySignOut = (): Promise<any> => {
  return Auth.signOut().then((data) => data);
};

export const amplifySignUp = (email: string, password: string, userId: string): Promise<ISignUpResult> => {
  const signUpParams: SignUpParams = {
    username: email,
    password,
    attributes: isMockServer()
      ? {
          email,
          given_name: userId,
          family_name: 'MockServer',
        }
      : {
          email,
          given_name: userId,
        },
  };
  return Auth.signUp(signUpParams)
    .then((res) => res)
    .catch((err) => err.errorMessage);
};

export const amplifyConfirmSignUp = async (email: string, code = '000000'): Promise<CognitoApiReturnCode> => {
  return Auth.confirmSignUp(email, code, { forceAliasCreation: false })
    .then(() => CognitoApiReturnCode.AliasExistsException)
    .catch((err: { code: CognitoApiReturnCode; message: string }) => {
      // An HTTP 400 exception is expected as a valid behavior
      const errorContent: string = err.code + ': ' + err.message;
      traceDebug('ConfirmSignUp API result: ', errorContent);
      return err.code;
    });
};

export const amplifyForgotPassword = (email: string): Promise<unknown> => {
  return Auth.forgotPassword(email)
    .then((res) => res)
    .catch((err) => err.errorMessage);
};

export const amplifyForgotPasswordSubmit = (
  email: string,
  verificationCode: string,
  newPassword: string,
): Promise<CognitoApiReturnCode> => {
  return Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
    .then(() => CognitoApiReturnCode.Success)
    .catch((err: { code: CognitoApiReturnCode; message: string }) => {
      // An HTTP 400 exception is expected as a valid behavior
      const errorContent: string = err.code + ': ' + err.message;
      traceDebug('ForgotPasswordSubmit API result: ', errorContent);
      return err.code;
    });
};

export const amplifyUpdateUserRef = async (value: string): Promise<string> => {
  const user = await Auth.currentAuthenticatedUser();
  return Auth.updateUserAttributes(user, { given_name: value })
    .then((res) => res)
    .catch((err) => err.errorMessage);
};

export const getUserId = async (): Promise<string> => {
  return await Auth.currentSession()
    .then((data) => data.getIdToken().payload.sub)
    .catch((err) => err.errorMessage);
};

export const getEmail = async (): Promise<string> => {
  return await Auth.currentSession()
    .then((data) => data.getIdToken().payload.email)
    .catch((err) => err.errorMessage);
};

export const getSessionToken = async (): Promise<string> => {
  const token = async (): Promise<string> => {
    const tokenGet = await Auth.currentSession()
      .then((data) => data.getIdToken().getJwtToken())
      .catch((err) => err);

    return tokenGet;
  };
  const tokenValue = token()
    .then((data) => data)
    .catch((error) => error);
  return tokenValue;
};

export const isUserAdmin = (userTypeArray: Array<string>): boolean => {
  return userTypeArray.indexOf('admin') > -1;
};

export const configureS3Storage = (bucketName: string, rootPublicPath = ''): void => {
  Storage.configure({
    bucket: bucketName,
    region: awsconfig.aws_project_region,
    identityPoolId: awsconfig.aws_cognito_identity_pool_id,
    customPrefix: {
      public: rootPublicPath,
    },
  });
};

export const uploadFileToS3Bucket = async (srcFile: File, fullPathToDestFile: string): Promise<PutResult> => {
  return Storage.put(fullPathToDestFile, srcFile, { level: 'public', contentType: srcFile.type });
};

const uploadFileFromStringToS3Bucket = async (srcString: string, fullPathToDestFile: string, mimeType: string): Promise<PutResult> => {
  return Storage.put(fullPathToDestFile, srcString, { level: 'public', contentType: mimeType });
};

export const getSignedUrl = async (bucketName: string, fullPathToFile: string): Promise<string> => {
  configureS3Storage(bucketName);
  return Storage.get(fullPathToFile);
};

export const removeFileFromS3Bucket = async (fullPathToDestFile: string): Promise<DeleteObjectCommandOutput> => {
  return Storage.remove(fullPathToDestFile);
};

export const uploadStellactFilesOnS3 = (stellaPointer: string, bucketId: string, lang: string, pointerContentAsString: string): void => {
  // Build the content of stellactWeb_content.js
  const stellactWebContent: string =
    'window.stellactWebContentByLng=[{"request":{"query":"getPointerByLng","variables":{"pointer":"' +
    stellaPointer +
    '","language":"' +
    lang +
    '"}},"result":{"data":{"getPointerByLng":' +
    pointerContentAsString +
    '}}}];';
  const fileContent =
    '<!doctype html><html lang="en"><head><meta charset="utf-8"/><link rel="icon" href="http://geostella-bundle.s3-website.eu-west-3.amazonaws.com/favicon.ico"/><meta name="viewport" content="width=device-width,initial-scale=1"/><meta name="theme-color" content="#000000"/><meta name="description" content="Stella"/><title>Stella</title><link href="http://geostella-bundle.s3-website.eu-west-3.amazonaws.com/static/css/main.48b4ad62.css" rel="stylesheet"></head><body><noscript>You need to enable JavaScript to run this app.</noscript><div id="root"></div><script src="stellactWeb_content.js"></script><script src="http://geostella-bundle.s3-website.eu-west-3.amazonaws.com/static/js/main.dce2a99b.js"></script></body></html>';
  configureS3Storage(bucketId);
  uploadFileFromStringToS3Bucket(stellactWebContent, 'stellactWeb_content.js', 'text/javascript')
    .then(() => {
      const indexFileContent = fileContent;
      uploadFileFromStringToS3Bucket(indexFileContent, 'index.html', 'text/html');
    })
    .then(() => {
      const okFileContent = fileContent;
      uploadFileFromStringToS3Bucket(okFileContent, '200.html', 'text/html');
    });
};
