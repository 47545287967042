import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@geo/media/styles/index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { getLang } from '@geo/utils/dist/AppProps';
import { StaticAuth } from '@geo/utils/dist/StaticAuth';
import { LangEnum } from '@geo/utils/dist/TypeUtils';
import AppAuth from './AppAuth';
import registerServiceWorker from './registerServiceWorker';

StaticAuth.refreshAuthentication();
const urlLang: LangEnum | null = getLang(window.location.search);
const lang: LangEnum = urlLang ? urlLang : LangEnum.fr;
ReactDOM.render(
  <AppAuth country="fr" lang={lang} authenticationData={StaticAuth.getUserAuthenticationData()} />,
  document.getElementById('root'),
);
registerServiceWorker();
