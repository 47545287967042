import { StaticApplication } from './StaticApplication';
import { LangEnum } from './TypeUtils';

export const getLngData = (data: { [index in keyof typeof LangEnum]: string }) => {
  return (lang?: LangEnum): string => {
    if (lang && lang in data) return data[lang];
    if (StaticApplication.getNavLang() in data) return data[StaticApplication.getNavLang()];
    if (StaticApplication.defaultLang in data) return data[StaticApplication.defaultLang];
    return 'Lang error';
  };
};

export enum TextLabelsEnum {
  nameLabel = 'nameLabel',
  pageLabel = 'pageLabel',
  phoneNumberLabel = 'phoneNumberLabel',
  reachableByEmaiQuestion = 'reachableByEmaiQuestion',
  reachableByPhoneQuestion = 'reachableByPhoneQuestion',
  userTitleLabel = 'userTitleLabel',
}
