/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ZCognitoUser = {
  __typename: "ZCognitoUser",
  id?: string | null,
  registrationId?: string | null,
  group?: string | null,
  power?: string | null,
  email?: string | null,
  federation?: string | null,
  gender?: string | null,
  nickname?: string | null,
};

export type CreateBucketInput = {
  id?: string | null,
  web?: ZBucket_WebInput | null,
  stellaFolders: Array< ZSortIdMapInput | null >,
};

export type ZBucket_WebInput = {
  shortUrl: string,
  isStellactWeb: boolean,
  cloudfrontUrl?: string | null,
  ipAddress?: string | null,
  sslCertificateId?: string | null,
};

export type ZSortIdMapInput = {
  id?: string | null,
  sortKey?: string | null,
};

export type ModelBucketConditionInput = {
  and?: Array< ModelBucketConditionInput | null > | null,
  or?: Array< ModelBucketConditionInput | null > | null,
  not?: ModelBucketConditionInput | null,
};

export type Bucket = {
  __typename: "Bucket",
  id: string,
  web?: ZBucket_Web | null,
  stellaFolders:  Array<ZSortIdMap | null >,
  createdAt: string,
  updatedAt: string,
  version: number,
};

export type ZBucket_Web = {
  __typename: "ZBucket_Web",
  shortUrl: string,
  isStellactWeb: boolean,
  cloudfrontUrl?: string | null,
  ipAddress?: string | null,
  sslCertificateId?: string | null,
};

export type ZSortIdMap = {
  __typename: "ZSortIdMap",
  id?: string | null,
  sortKey?: string | null,
};

export type UpdateBucketInput = {
  id: string,
  web?: ZBucket_WebInput | null,
  stellaFolders?: Array< ZSortIdMapInput | null > | null,
  expectedVersion: number,
};

export type DeleteBucketInput = {
  id: string,
  expectedVersion: number,
};

export type CreateDataStructInput = {
  id?: string | null,
  locationId?: string | null,
  language?: string | null,
  name?: string | null,
  audience?: string | null,
  geonewsExpiryDateTime?: string | null,
  geonewsStartDateTime?: string | null,
  geonewsDuration?: number | null,
  entityId: string,
  sectionLngMap: ZLngMapInput,
  sectionLngId?: string | null,
  refSectionLngMap?: ZLngMapInput | null,
  refSectionLngId?: string | null,
  refInternalLinkLngMap?: ZSectionCompositeMapInput | null,
  refInternalLinkLng?: ZSectionCompositeInput | null,
  fromUserId?: string | null,
  toUserId?: string | null,
  queryLanguage?: string | null,
  parentId: string,
};

export type ZLngMapInput = {
  enId?: string | null,
  frId?: string | null,
};

export type ZSectionCompositeMapInput = {
  en?: ZSectionCompositeInput | null,
  fr?: ZSectionCompositeInput | null,
};

export type ZSectionCompositeInput = {
  formatRanges?: Array< ZFormatRangeInput | null > | null,
  ref?: ZReferenceInput | null,
  size?: string | null,
  sortKey?: string | null,
  text: string,
};

export type ZFormatRangeInput = {
  offset: number,
  length: number,
  style: string,
};

export type ZReferenceInput = {
  linkTarget?: string | null,
  format?: string | null,
  refId?: string | null,
  sizes?: ZSizeInput | null,
  formatRange?: ZFormatRangeInput | null,
  type: string,
};

export type ZSizeInput = {
  ll?: boolean | null,
  mm?: boolean | null,
  ss?: boolean | null,
};

export type ModelDataStructConditionInput = {
  locationId?: ModelIDInput | null,
  language?: ModelStringInput | null,
  name?: ModelStringInput | null,
  audience?: ModelStringInput | null,
  geonewsExpiryDateTime?: ModelStringInput | null,
  geonewsStartDateTime?: ModelStringInput | null,
  geonewsDuration?: ModelIntInput | null,
  entityId?: ModelIDInput | null,
  sectionLngId?: ModelIDInput | null,
  refSectionLngId?: ModelIDInput | null,
  fromUserId?: ModelIDInput | null,
  toUserId?: ModelIDInput | null,
  queryLanguage?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  and?: Array< ModelDataStructConditionInput | null > | null,
  or?: Array< ModelDataStructConditionInput | null > | null,
  not?: ModelDataStructConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type DataStruct = {
  __typename: "DataStruct",
  id: string,
  locationId?: string | null,
  location?: Location | null,
  language?: string | null,
  name?: string | null,
  audience?: string | null,
  geonewsExpiryDateTime?: string | null,
  geonewsStartDateTime?: string | null,
  geonewsDuration?: number | null,
  entityId: string,
  sectionLngMap: ZLngMap,
  sectionLngId?: string | null,
  sectionLng?: Section | null,
  refSectionLngMap?: ZLngMap | null,
  refSectionLngId?: string | null,
  refSectionLng?: Section | null,
  refInternalLinkLngMap?: ZSectionCompositeMap | null,
  refInternalLinkLng?: ZSectionComposite | null,
  fromUserId?: string | null,
  fromUser?: User | null,
  toUserId?: string | null,
  toUser?: User | null,
  queryLanguage?: string | null,
  parentId: string,
  createdAt: string,
  updatedAt: string,
  version: number,
};

export type Location = {
  __typename: "Location",
  id: string,
  address?: ZAddress | null,
  calendar?: ZCalendarMap | null,
  geolocation?: ZGeolocation | null,
  parentId: string,
  municipalityId: string,
  type?: string | null,
  createdAt: string,
  updatedAt: string,
  version: number,
};

export type ZAddress = {
  __typename: "ZAddress",
  municipality?: string | null,
  countryCode?: string | null,
  postCode?: string | null,
  regionCode?: string | null,
  street?: string | null,
};

export type ZCalendarMap = {
  __typename: "ZCalendarMap",
  openingHours?: ZOpeningHoursMap | null,
  weekly?: string | null,
  yearly?: ZYearlyMap | null,
};

export type ZOpeningHoursMap = {
  __typename: "ZOpeningHoursMap",
  end?: string | null,
  start?: string | null,
};

export type ZYearlyMap = {
  __typename: "ZYearlyMap",
  endDate?: string | null,
  startDate?: string | null,
};

export type ZGeolocation = {
  __typename: "ZGeolocation",
  latitude?: number | null,
  longitude?: number | null,
};

export type ZLngMap = {
  __typename: "ZLngMap",
  enId?: string | null,
  frId?: string | null,
};

export type Section = {
  __typename: "Section",
  id: string,
  parentId: string,
  bucketId?: string | null,
  flexbox?: ZFlexbox | null,
  elements?:  Array<ZSectionElement | null > | null,
  title?: ZSectionComposite | null,
  language?: string | null,
  type?: string | null,
  createdAt: string,
  updatedAt: string,
  version: number,
};

export type ZFlexbox = {
  __typename: "ZFlexbox",
  columns?: number | null,
  totalColumns?: number | null,
};

export type ZSectionElement = {
  __typename: "ZSectionElement",
  firstComposite: ZSectionComposite,
  nextComposites?:  Array<ZSectionComposite | null > | null,
  sortKey: string,
  type: string,
};

export type ZSectionComposite = {
  __typename: "ZSectionComposite",
  formatRanges?:  Array<ZFormatRange | null > | null,
  ref?: ZReference | null,
  size?: string | null,
  sortKey?: string | null,
  text: string,
};

export type ZFormatRange = {
  __typename: "ZFormatRange",
  offset: number,
  length: number,
  style: string,
};

export type ZReference = {
  __typename: "ZReference",
  linkTarget?: string | null,
  format?: string | null,
  refId?: string | null,
  sizes?: ZSize | null,
  formatRange?: ZFormatRange | null,
  type: string,
};

export type ZSize = {
  __typename: "ZSize",
  ll?: boolean | null,
  mm?: boolean | null,
  ss?: boolean | null,
};

export type ZSectionCompositeMap = {
  __typename: "ZSectionCompositeMap",
  en?: ZSectionComposite | null,
  fr?: ZSectionComposite | null,
};

export type User = {
  __typename: "User",
  id: string,
  administratedPointers?:  Array<ZAdminPointer | null > | null,
  specialtiesPoc?: Array< string | null > | null,
  email: string,
  federation: string,
  registrationId: string,
  power: USERPOWER,
  gender: string,
  name: ZName,
  phoneNumbers?:  Array<ZPhoneNumber | null > | null,
  preferences: ZUserPreferences,
  titleLng?: ZStringLng | null,
  title?: string | null,
  queryLanguage?: string | null,
  createdAt: string,
  updatedAt: string,
  version: number,
};

export type ZAdminPointer = {
  __typename: "ZAdminPointer",
  pointer?: string | null,
  ownershipLevel?: string | null,
};

export enum USERPOWER {
  Y = "Y",
  N = "N",
  MN = "MN",
  KN = "KN",
  KMN = "KMN",
  GN = "GN",
  GMN = "GMN",
  GKN = "GKN",
  GKMN = "GKMN",
}


export type ZName = {
  __typename: "ZName",
  firstname?: string | null,
  lastname?: string | null,
  nickname: string,
};

export type ZPhoneNumber = {
  __typename: "ZPhoneNumber",
  sortKey?: string | null,
  countryCode: string,
  number: string,
  usage?: string | null,
};

export type ZUserPreferences = {
  __typename: "ZUserPreferences",
  visibleEmail: boolean,
  visiblePhones: boolean,
};

export type ZStringLng = {
  __typename: "ZStringLng",
  en?: string | null,
  fr?: string | null,
};

export type UpdateDataStructInput = {
  id: string,
  locationId?: string | null,
  language?: string | null,
  name?: string | null,
  audience?: string | null,
  geonewsExpiryDateTime?: string | null,
  geonewsStartDateTime?: string | null,
  geonewsDuration?: number | null,
  entityId?: string | null,
  sectionLngMap?: ZLngMapInput | null,
  sectionLngId?: string | null,
  refSectionLngMap?: ZLngMapInput | null,
  refSectionLngId?: string | null,
  refInternalLinkLngMap?: ZSectionCompositeMapInput | null,
  refInternalLinkLng?: ZSectionCompositeInput | null,
  fromUserId?: string | null,
  toUserId?: string | null,
  queryLanguage?: string | null,
  parentId?: string | null,
  expectedVersion: number,
};

export type DeleteDataStructInput = {
  id: string,
  expectedVersion: number,
};

export type CreateItemInput = {
  id?: string | null,
  domainId: string,
  isOrion: boolean,
  nameLng?: ZStringLngInput | null,
  itemLngMap?: ZLngMapInput | null,
  descriptionLng?: ZStringLngInput | null,
  price?: ZPriceMapInput | null,
};

export type ZStringLngInput = {
  en?: string | null,
  fr?: string | null,
};

export type ZPriceMapInput = {
  amount?: number | null,
  currency?: string | null,
};

export type ModelItemConditionInput = {
  domainId?: ModelIDInput | null,
  isOrion?: ModelBooleanInput | null,
  and?: Array< ModelItemConditionInput | null > | null,
  or?: Array< ModelItemConditionInput | null > | null,
  not?: ModelItemConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Item = {
  __typename: "Item",
  id: string,
  domainId: string,
  isOrion: boolean,
  nameLng?: ZStringLng | null,
  itemLngMap?: ZLngMap | null,
  descriptionLng?: ZStringLng | null,
  price?: ZPriceMap | null,
  createdAt: string,
  updatedAt: string,
  version: number,
};

export type ZPriceMap = {
  __typename: "ZPriceMap",
  amount?: number | null,
  currency?: string | null,
};

export type UpdateItemInput = {
  id: string,
  domainId?: string | null,
  isOrion?: boolean | null,
  nameLng?: ZStringLngInput | null,
  itemLngMap?: ZLngMapInput | null,
  descriptionLng?: ZStringLngInput | null,
  price?: ZPriceMapInput | null,
  expectedVersion: number,
};

export type DeleteItemInput = {
  id: string,
  expectedVersion: number,
};

export type CreateItemLngInput = {
  id?: string | null,
  language?: string | null,
  parentId: string,
  columnsMap?: Array< ZColumnMapInput | null > | null,
  columnsContentSortIds?: Array< string | null > | null,
  refSectionId?: string | null,
  refInternalLink?: ZSectionCompositeInput | null,
};

export type ZColumnMapInput = {
  columnKey?: string | null,
  sectionId?: string | null,
};

export type ModelItemLngConditionInput = {
  language?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  columnsContentSortIds?: ModelIDInput | null,
  refSectionId?: ModelIDInput | null,
  and?: Array< ModelItemLngConditionInput | null > | null,
  or?: Array< ModelItemLngConditionInput | null > | null,
  not?: ModelItemLngConditionInput | null,
};

export type ItemLng = {
  __typename: "ItemLng",
  id: string,
  language?: string | null,
  parentId: string,
  item?: Item | null,
  columnsMap?:  Array<ZColumnMap | null > | null,
  columnsContentSortIds?: Array< string | null > | null,
  columnsContent?:  Array<Section | null > | null,
  refSectionId?: string | null,
  refSection?: Section | null,
  refInternalLink?: ZSectionComposite | null,
  createdAt: string,
  updatedAt: string,
  version: number,
};

export type ZColumnMap = {
  __typename: "ZColumnMap",
  columnKey?: string | null,
  sectionId?: string | null,
};

export type UpdateItemLngInput = {
  id: string,
  language?: string | null,
  parentId?: string | null,
  columnsMap?: Array< ZColumnMapInput | null > | null,
  columnsContentSortIds?: Array< string | null > | null,
  refSectionId?: string | null,
  refInternalLink?: ZSectionCompositeInput | null,
  expectedVersion: number,
};

export type DeleteItemLngInput = {
  id: string,
  expectedVersion: number,
};

export type CreateLocationInput = {
  id?: string | null,
  address?: ZAddressInput | null,
  calendar?: ZCalendarMapInput | null,
  geolocation?: ZGeolocationInput | null,
  parentId: string,
  municipalityId: string,
  type?: string | null,
};

export type ZAddressInput = {
  municipality?: string | null,
  countryCode?: string | null,
  postCode?: string | null,
  regionCode?: string | null,
  street?: string | null,
};

export type ZCalendarMapInput = {
  openingHours?: ZOpeningHoursMapInput | null,
  weekly?: string | null,
  yearly?: ZYearlyMapInput | null,
};

export type ZOpeningHoursMapInput = {
  end?: string | null,
  start?: string | null,
};

export type ZYearlyMapInput = {
  endDate?: string | null,
  startDate?: string | null,
};

export type ZGeolocationInput = {
  latitude?: number | null,
  longitude?: number | null,
};

export type ModelLocationConditionInput = {
  parentId?: ModelIDInput | null,
  municipalityId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelLocationConditionInput | null > | null,
  or?: Array< ModelLocationConditionInput | null > | null,
  not?: ModelLocationConditionInput | null,
};

export type UpdateLocationInput = {
  id: string,
  address?: ZAddressInput | null,
  calendar?: ZCalendarMapInput | null,
  geolocation?: ZGeolocationInput | null,
  parentId?: string | null,
  municipalityId?: string | null,
  type?: string | null,
  expectedVersion: number,
};

export type DeleteLocationInput = {
  id: string,
  expectedVersion: number,
};

export type ZBucket_S3 = {
  __typename: "ZBucket_S3",
  id: string,
  url?: string | null,
  bucketId?: string | null,
};

export type CreatePointerInput = {
  pointer: string,
  queryLanguage?: string | null,
  stellaId?: string | null,
  geostructId?: string | null,
};

export type ModelPointerConditionInput = {
  queryLanguage?: ModelStringInput | null,
  stellaId?: ModelIDInput | null,
  geostructId?: ModelIDInput | null,
  and?: Array< ModelPointerConditionInput | null > | null,
  or?: Array< ModelPointerConditionInput | null > | null,
  not?: ModelPointerConditionInput | null,
};

export type Pointer = {
  __typename: "Pointer",
  pointer: string,
  queryLanguage?: string | null,
  stellaId?: string | null,
  stella?: Stella | null,
  geostructId?: string | null,
  geostruct?: DataStruct | null,
  createdAt: string,
  updatedAt: string,
  version: number,
};

export type Stella = {
  __typename: "Stella",
  id: string,
  bucket?: Bucket | null,
  bucketId: string,
  entityId: string,
  interfaceLng?: SpecialtyLng | null,
  interfaceLngId?: string | null,
  interfaceLngMap?: ZLngMap | null,
  logo?: ZSectionComposite | null,
  mediaList:  Array<ZMedia | null >,
  name: string,
  options: ZOptions,
  pointer: string,
  queryLanguage?: string | null,
  social?:  Array<ZSortIdMap | null > | null,
  specialties?:  Array<Specialty | null > | null,
  specialtiesIds?:  Array<ZSortIdMap | null > | null,
  createdAt: string,
  updatedAt: string,
  version: number,
};

export type SpecialtyLng = {
  __typename: "SpecialtyLng",
  id: string,
  gallery?: ZSectionElement | null,
  language?: string | null,
  parentId: string,
  type?: string | null,
  itemsTables?:  Array<ZItemsTableMap | null > | null,
  indexSectionsSortIds?:  Array<ZSortIdMap | null > | null,
  indexSections?:  Array<Section | null > | null,
  indexPage?: ZPage | null,
  geonewsSectionsSortIds?:  Array<ZSortIdMap | null > | null,
  geonewsSections?:  Array<Section | null > | null,
  geonewsPage?: ZPage | null,
  gallerySectionsSortIds?:  Array<ZSortIdMap | null > | null,
  gallerySections?:  Array<Section | null > | null,
  galleryPage?: ZPage | null,
  itemsSectionsSortIds?:  Array<ZSortIdMap | null > | null,
  itemsSections?:  Array<Section | null > | null,
  itemsPage?: ZPage | null,
  contactSectionsSortIds?:  Array<ZSortIdMap | null > | null,
  contactSections?:  Array<Section | null > | null,
  contactPage?: ZPage | null,
  add1SectionsSortIds?:  Array<ZSortIdMap | null > | null,
  add1Sections?:  Array<Section | null > | null,
  add1Page?: ZPage | null,
  add2SectionsSortIds?:  Array<ZSortIdMap | null > | null,
  add2Sections?:  Array<Section | null > | null,
  add2Page?: ZPage | null,
  add3SectionsSortIds?:  Array<ZSortIdMap | null > | null,
  add3Sections?:  Array<Section | null > | null,
  add3Page?: ZPage | null,
  add4SectionsSortIds?:  Array<ZSortIdMap | null > | null,
  add4Sections?:  Array<Section | null > | null,
  add4Page?: ZPage | null,
  createdAt: string,
  updatedAt: string,
  version: number,
};

export type ZItemsTableMap = {
  __typename: "ZItemsTableMap",
  headRow?:  Array<ZItemsHeadRowMap | null > | null,
  itemsIds?:  Array<ZSortIdMap | null > | null,
  sortKey?: string | null,
};

export type ZItemsHeadRowMap = {
  __typename: "ZItemsHeadRowMap",
  sortKey?: string | null,
  string?: string | null,
  type?: string | null,
};

export type ZPage = {
  __typename: "ZPage",
  pageTitle?: ZSectionComposite | null,
  layout?: string | null,
  menu?: ZSectionComposite | null,
};

export type ZMedia = {
  __typename: "ZMedia",
  mediaRef: string,
  sizes: string,
};

export type ZOptions = {
  __typename: "ZOptions",
  hasDraft: boolean,
  status: STELLASTATUS,
};

export enum STELLASTATUS {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
  QUASISTELLA = "QUASISTELLA",
  MODERATED = "MODERATED",
  UNASSOCIATED = "UNASSOCIATED",
}


export type Specialty = {
  __typename: "Specialty",
  id: string,
  pointer: string,
  designationId: string,
  designation?: StaticObject | null,
  levelId: string,
  catchphraseLng?: ZStringLng | null,
  itemsIds?: Array< string | null > | null,
  geonews?:  Array<DataStruct | null > | null,
  geonewsIds?: Array< string | null > | null,
  locationId: string,
  location?: Location | null,
  parentId: string,
  othersPocUsersIds?:  Array<ZSortIdMap | null > | null,
  pocUserId: string,
  pocUser?: User | null,
  queryLanguage?: string | null,
  sortKey?: string | null,
  specialtyLngMap?: ZLngMap | null,
  specialtyLngId?: string | null,
  specialtyLng?: SpecialtyLng | null,
  tagsIds?:  Array<ZSortIdMap | null > | null,
  createdAt: string,
  updatedAt: string,
  version: number,
};

export type StaticObject = {
  __typename: "StaticObject",
  id: string,
  nameLng?: ZStringLng | null,
  name?: string | null,
  descriptionLng?: ZStringLng | null,
  description?: string | null,
  attribute?: ZFieldValue | null,
  type?: string | null,
  queryLanguage?: string | null,
  parentId?: string | null,
  createdAt: string,
  updatedAt: string,
  version: number,
};

export type ZFieldValue = {
  __typename: "ZFieldValue",
  value?: string | null,
  field?: string | null,
};

export type UpdatePointerInput = {
  pointer: string,
  queryLanguage?: string | null,
  stellaId?: string | null,
  geostructId?: string | null,
  expectedVersion: number,
};

export type DeletePointerInput = {
  pointer: string,
  expectedVersion: number,
};

export type CreateSectionInput = {
  id?: string | null,
  parentId: string,
  bucketId?: string | null,
  flexbox?: ZFlexboxInput | null,
  elements?: Array< ZSectionElementInput | null > | null,
  title?: ZSectionCompositeInput | null,
  language?: string | null,
  type?: string | null,
};

export type ZFlexboxInput = {
  columns?: number | null,
  totalColumns?: number | null,
};

export type ZSectionElementInput = {
  firstComposite: ZSectionCompositeInput,
  nextComposites?: Array< ZSectionCompositeInput | null > | null,
  sortKey: string,
  type: string,
};

export type ModelSectionConditionInput = {
  parentId?: ModelIDInput | null,
  bucketId?: ModelIDInput | null,
  language?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelSectionConditionInput | null > | null,
  or?: Array< ModelSectionConditionInput | null > | null,
  not?: ModelSectionConditionInput | null,
};

export type UpdateSectionInput = {
  id: string,
  parentId?: string | null,
  bucketId?: string | null,
  flexbox?: ZFlexboxInput | null,
  elements?: Array< ZSectionElementInput | null > | null,
  title?: ZSectionCompositeInput | null,
  language?: string | null,
  type?: string | null,
  expectedVersion: number,
};

export type DeleteSectionInput = {
  id: string,
  expectedVersion: number,
};

export type CreateSpecialtyInput = {
  id?: string | null,
  pointer: string,
  designationId: string,
  levelId: string,
  catchphraseLng?: ZStringLngInput | null,
  itemsIds?: Array< string | null > | null,
  geonewsIds?: Array< string | null > | null,
  locationId: string,
  parentId: string,
  othersPocUsersIds?: Array< ZSortIdMapInput | null > | null,
  pocUserId: string,
  queryLanguage?: string | null,
  sortKey?: string | null,
  specialtyLngMap?: ZLngMapInput | null,
  specialtyLngId?: string | null,
  tagsIds?: Array< ZSortIdMapInput | null > | null,
};

export type ModelSpecialtyConditionInput = {
  pointer?: ModelStringInput | null,
  designationId?: ModelIDInput | null,
  levelId?: ModelIDInput | null,
  itemsIds?: ModelStringInput | null,
  geonewsIds?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  pocUserId?: ModelIDInput | null,
  queryLanguage?: ModelStringInput | null,
  sortKey?: ModelStringInput | null,
  specialtyLngId?: ModelIDInput | null,
  and?: Array< ModelSpecialtyConditionInput | null > | null,
  or?: Array< ModelSpecialtyConditionInput | null > | null,
  not?: ModelSpecialtyConditionInput | null,
};

export type UpdateSpecialtyInput = {
  id: string,
  pointer?: string | null,
  designationId?: string | null,
  levelId?: string | null,
  catchphraseLng?: ZStringLngInput | null,
  itemsIds?: Array< string | null > | null,
  geonewsIds?: Array< string | null > | null,
  locationId?: string | null,
  parentId?: string | null,
  othersPocUsersIds?: Array< ZSortIdMapInput | null > | null,
  pocUserId?: string | null,
  queryLanguage?: string | null,
  sortKey?: string | null,
  specialtyLngMap?: ZLngMapInput | null,
  specialtyLngId?: string | null,
  tagsIds?: Array< ZSortIdMapInput | null > | null,
  expectedVersion: number,
};

export type DeleteSpecialtyInput = {
  id: string,
  expectedVersion: number,
};

export type CreateSpecialtyLngInput = {
  id?: string | null,
  gallery?: ZSectionElementInput | null,
  language?: string | null,
  parentId: string,
  type?: string | null,
  itemsTables?: Array< ZItemsTableMapInput | null > | null,
  indexSectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  indexPage?: ZPageInput | null,
  geonewsSectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  geonewsPage?: ZPageInput | null,
  gallerySectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  galleryPage?: ZPageInput | null,
  itemsSectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  itemsPage?: ZPageInput | null,
  contactSectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  contactPage?: ZPageInput | null,
  add1SectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  add1Page?: ZPageInput | null,
  add2SectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  add2Page?: ZPageInput | null,
  add3SectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  add3Page?: ZPageInput | null,
  add4SectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  add4Page?: ZPageInput | null,
};

export type ZItemsTableMapInput = {
  headRow?: Array< ZItemsHeadRowMapInput | null > | null,
  itemsIds?: Array< ZSortIdMapInput | null > | null,
  sortKey?: string | null,
};

export type ZItemsHeadRowMapInput = {
  sortKey?: string | null,
  string?: string | null,
  type?: string | null,
};

export type ZPageInput = {
  pageTitle?: ZSectionCompositeInput | null,
  layout?: string | null,
  menu?: ZSectionCompositeInput | null,
};

export type ModelSpecialtyLngConditionInput = {
  language?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelSpecialtyLngConditionInput | null > | null,
  or?: Array< ModelSpecialtyLngConditionInput | null > | null,
  not?: ModelSpecialtyLngConditionInput | null,
};

export type UpdateSpecialtyLngInput = {
  id: string,
  gallery?: ZSectionElementInput | null,
  language?: string | null,
  parentId?: string | null,
  type?: string | null,
  itemsTables?: Array< ZItemsTableMapInput | null > | null,
  indexSectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  indexPage?: ZPageInput | null,
  geonewsSectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  geonewsPage?: ZPageInput | null,
  gallerySectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  galleryPage?: ZPageInput | null,
  itemsSectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  itemsPage?: ZPageInput | null,
  contactSectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  contactPage?: ZPageInput | null,
  add1SectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  add1Page?: ZPageInput | null,
  add2SectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  add2Page?: ZPageInput | null,
  add3SectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  add3Page?: ZPageInput | null,
  add4SectionsSortIds?: Array< ZSortIdMapInput | null > | null,
  add4Page?: ZPageInput | null,
  expectedVersion: number,
};

export type DeleteSpecialtyLngInput = {
  id: string,
  expectedVersion: number,
};

export type CreateStaticObjectInput = {
  id?: string | null,
  nameLng?: ZStringLngInput | null,
  name?: string | null,
  descriptionLng?: ZStringLngInput | null,
  description?: string | null,
  attribute?: ZFieldValueInput | null,
  type?: string | null,
  queryLanguage?: string | null,
  parentId?: string | null,
};

export type ZFieldValueInput = {
  value?: string | null,
  field?: string | null,
};

export type ModelStaticObjectConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  queryLanguage?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  and?: Array< ModelStaticObjectConditionInput | null > | null,
  or?: Array< ModelStaticObjectConditionInput | null > | null,
  not?: ModelStaticObjectConditionInput | null,
};

export type UpdateStaticObjectInput = {
  id: string,
  nameLng?: ZStringLngInput | null,
  name?: string | null,
  descriptionLng?: ZStringLngInput | null,
  description?: string | null,
  attribute?: ZFieldValueInput | null,
  type?: string | null,
  queryLanguage?: string | null,
  parentId?: string | null,
  expectedVersion: number,
};

export type DeleteStaticObjectInput = {
  id: string,
  expectedVersion: number,
};

export type CreateStellaInput = {
  id?: string | null,
  bucketId: string,
  entityId: string,
  interfaceLngId?: string | null,
  interfaceLngMap?: ZLngMapInput | null,
  logo?: ZSectionCompositeInput | null,
  mediaList: Array< ZMediaInput | null >,
  name: string,
  options: ZOptionsInput,
  pointer: string,
  queryLanguage?: string | null,
  social?: Array< ZSortIdMapInput | null > | null,
  specialtiesIds?: Array< ZSortIdMapInput | null > | null,
};

export type ZMediaInput = {
  mediaRef: string,
  sizes: string,
};

export type ZOptionsInput = {
  hasDraft: boolean,
  status: STELLASTATUS,
};

export type ModelStellaConditionInput = {
  bucketId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  interfaceLngId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  pointer?: ModelStringInput | null,
  queryLanguage?: ModelStringInput | null,
  and?: Array< ModelStellaConditionInput | null > | null,
  or?: Array< ModelStellaConditionInput | null > | null,
  not?: ModelStellaConditionInput | null,
};

export type UpdateStellaInput = {
  id: string,
  bucketId?: string | null,
  entityId?: string | null,
  interfaceLngId?: string | null,
  interfaceLngMap?: ZLngMapInput | null,
  logo?: ZSectionCompositeInput | null,
  mediaList?: Array< ZMediaInput | null > | null,
  name?: string | null,
  options?: ZOptionsInput | null,
  pointer?: string | null,
  queryLanguage?: string | null,
  social?: Array< ZSortIdMapInput | null > | null,
  specialtiesIds?: Array< ZSortIdMapInput | null > | null,
  expectedVersion: number,
};

export type DeleteStellaInput = {
  id: string,
  expectedVersion: number,
};

export type CreateUserInput = {
  id?: string | null,
  administratedPointers?: Array< ZAdminPointerInput | null > | null,
  specialtiesPoc?: Array< string | null > | null,
  email: string,
  federation: string,
  registrationId: string,
  power: USERPOWER,
  gender: string,
  name: ZNameInput,
  phoneNumbers?: Array< ZPhoneNumberInput | null > | null,
  preferences: ZUserPreferencesInput,
  titleLng?: ZStringLngInput | null,
  title?: string | null,
  queryLanguage?: string | null,
};

export type ZAdminPointerInput = {
  pointer?: string | null,
  ownershipLevel?: string | null,
};

export type ZNameInput = {
  firstname?: string | null,
  lastname?: string | null,
  nickname: string,
};

export type ZPhoneNumberInput = {
  sortKey?: string | null,
  countryCode: string,
  number: string,
  usage?: string | null,
};

export type ZUserPreferencesInput = {
  visibleEmail: boolean,
  visiblePhones: boolean,
};

export type ModelUserConditionInput = {
  specialtiesPoc?: ModelIDInput | null,
  email?: ModelStringInput | null,
  federation?: ModelStringInput | null,
  registrationId?: ModelStringInput | null,
  power?: ModelUSERPOWERInput | null,
  gender?: ModelStringInput | null,
  title?: ModelStringInput | null,
  queryLanguage?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelUSERPOWERInput = {
  eq?: USERPOWER | null,
  ne?: USERPOWER | null,
};

export type UpdateUserInput = {
  id: string,
  administratedPointers?: Array< ZAdminPointerInput | null > | null,
  specialtiesPoc?: Array< string | null > | null,
  email?: string | null,
  federation?: string | null,
  registrationId?: string | null,
  power?: USERPOWER | null,
  gender?: string | null,
  name?: ZNameInput | null,
  phoneNumbers?: Array< ZPhoneNumberInput | null > | null,
  preferences?: ZUserPreferencesInput | null,
  titleLng?: ZStringLngInput | null,
  title?: string | null,
  queryLanguage?: string | null,
  expectedVersion: number,
};

export type DeleteUserInput = {
  id: string,
  expectedVersion: number,
};

export type UserConnection = {
  __typename: "UserConnection",
  items?:  Array<User | null > | null,
  nextToken?: string | null,
};

export type DataStructConnection = {
  __typename: "DataStructConnection",
  items?:  Array<DataStruct | null > | null,
  nextToken?: string | null,
};

export type PointerConnection = {
  __typename: "PointerConnection",
  items?:  Array<Pointer | null > | null,
  nextToken?: string | null,
};

export type StaticObjectConnection = {
  __typename: "StaticObjectConnection",
  items?:  Array<StaticObject | null > | null,
  nextToken?: string | null,
};

export type ModelBucketFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelBucketFilterInput | null > | null,
  or?: Array< ModelBucketFilterInput | null > | null,
  not?: ModelBucketFilterInput | null,
};

export type ModelBucketConnection = {
  __typename: "ModelBucketConnection",
  items:  Array<Bucket | null >,
  nextToken?: string | null,
};

export type ModelDataStructFilterInput = {
  id?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  language?: ModelStringInput | null,
  name?: ModelStringInput | null,
  audience?: ModelStringInput | null,
  geonewsExpiryDateTime?: ModelStringInput | null,
  geonewsStartDateTime?: ModelStringInput | null,
  geonewsDuration?: ModelIntInput | null,
  entityId?: ModelIDInput | null,
  sectionLngId?: ModelIDInput | null,
  refSectionLngId?: ModelIDInput | null,
  fromUserId?: ModelIDInput | null,
  toUserId?: ModelIDInput | null,
  queryLanguage?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  and?: Array< ModelDataStructFilterInput | null > | null,
  or?: Array< ModelDataStructFilterInput | null > | null,
  not?: ModelDataStructFilterInput | null,
};

export type ModelDataStructConnection = {
  __typename: "ModelDataStructConnection",
  items:  Array<DataStruct | null >,
  nextToken?: string | null,
};

export type ModelItemFilterInput = {
  id?: ModelIDInput | null,
  domainId?: ModelIDInput | null,
  isOrion?: ModelBooleanInput | null,
  and?: Array< ModelItemFilterInput | null > | null,
  or?: Array< ModelItemFilterInput | null > | null,
  not?: ModelItemFilterInput | null,
};

export type ModelItemConnection = {
  __typename: "ModelItemConnection",
  items:  Array<Item | null >,
  nextToken?: string | null,
};

export type ModelItemLngFilterInput = {
  id?: ModelIDInput | null,
  language?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  columnsContentSortIds?: ModelIDInput | null,
  refSectionId?: ModelIDInput | null,
  and?: Array< ModelItemLngFilterInput | null > | null,
  or?: Array< ModelItemLngFilterInput | null > | null,
  not?: ModelItemLngFilterInput | null,
};

export type ModelItemLngConnection = {
  __typename: "ModelItemLngConnection",
  items:  Array<ItemLng | null >,
  nextToken?: string | null,
};

export type ModelLocationFilterInput = {
  id?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  municipalityId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelLocationFilterInput | null > | null,
  or?: Array< ModelLocationFilterInput | null > | null,
  not?: ModelLocationFilterInput | null,
};

export type ModelLocationConnection = {
  __typename: "ModelLocationConnection",
  items:  Array<Location | null >,
  nextToken?: string | null,
};

export type ModelPointerFilterInput = {
  pointer?: ModelIDInput | null,
  queryLanguage?: ModelStringInput | null,
  stellaId?: ModelIDInput | null,
  geostructId?: ModelIDInput | null,
  and?: Array< ModelPointerFilterInput | null > | null,
  or?: Array< ModelPointerFilterInput | null > | null,
  not?: ModelPointerFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelPointerConnection = {
  __typename: "ModelPointerConnection",
  items:  Array<Pointer | null >,
  nextToken?: string | null,
};

export type ModelSectionFilterInput = {
  id?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  bucketId?: ModelIDInput | null,
  language?: ModelStringInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelSectionFilterInput | null > | null,
  or?: Array< ModelSectionFilterInput | null > | null,
  not?: ModelSectionFilterInput | null,
};

export type ModelSectionConnection = {
  __typename: "ModelSectionConnection",
  items:  Array<Section | null >,
  nextToken?: string | null,
};

export type ModelSpecialtyFilterInput = {
  id?: ModelIDInput | null,
  pointer?: ModelStringInput | null,
  designationId?: ModelIDInput | null,
  levelId?: ModelIDInput | null,
  itemsIds?: ModelStringInput | null,
  geonewsIds?: ModelIDInput | null,
  locationId?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  pocUserId?: ModelIDInput | null,
  queryLanguage?: ModelStringInput | null,
  sortKey?: ModelStringInput | null,
  specialtyLngId?: ModelIDInput | null,
  and?: Array< ModelSpecialtyFilterInput | null > | null,
  or?: Array< ModelSpecialtyFilterInput | null > | null,
  not?: ModelSpecialtyFilterInput | null,
};

export type ModelSpecialtyConnection = {
  __typename: "ModelSpecialtyConnection",
  items:  Array<Specialty | null >,
  nextToken?: string | null,
};

export type ModelSpecialtyLngFilterInput = {
  id?: ModelIDInput | null,
  language?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  type?: ModelStringInput | null,
  and?: Array< ModelSpecialtyLngFilterInput | null > | null,
  or?: Array< ModelSpecialtyLngFilterInput | null > | null,
  not?: ModelSpecialtyLngFilterInput | null,
};

export type ModelSpecialtyLngConnection = {
  __typename: "ModelSpecialtyLngConnection",
  items:  Array<SpecialtyLng | null >,
  nextToken?: string | null,
};

export type ModelStaticObjectFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelStringInput | null,
  queryLanguage?: ModelStringInput | null,
  parentId?: ModelIDInput | null,
  and?: Array< ModelStaticObjectFilterInput | null > | null,
  or?: Array< ModelStaticObjectFilterInput | null > | null,
  not?: ModelStaticObjectFilterInput | null,
};

export type ModelStaticObjectConnection = {
  __typename: "ModelStaticObjectConnection",
  items:  Array<StaticObject | null >,
  nextToken?: string | null,
};

export type ModelStellaFilterInput = {
  id?: ModelIDInput | null,
  bucketId?: ModelIDInput | null,
  entityId?: ModelIDInput | null,
  interfaceLngId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  pointer?: ModelStringInput | null,
  queryLanguage?: ModelStringInput | null,
  and?: Array< ModelStellaFilterInput | null > | null,
  or?: Array< ModelStellaFilterInput | null > | null,
  not?: ModelStellaFilterInput | null,
};

export type ModelStellaConnection = {
  __typename: "ModelStellaConnection",
  items:  Array<Stella | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  specialtiesPoc?: ModelIDInput | null,
  email?: ModelStringInput | null,
  federation?: ModelStringInput | null,
  registrationId?: ModelStringInput | null,
  power?: ModelUSERPOWERInput | null,
  gender?: ModelStringInput | null,
  title?: ModelStringInput | null,
  queryLanguage?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type AddDbUserAndCognitoGroupMutationVariables = {
  id: string,
  registrationId: string,
  group: string,
  power: string,
  email: string,
  federation: string,
  gender: string,
  nickname: string,
};

export type AddDbUserAndCognitoGroupMutation = {
  addDbUserAndCognitoGroup?:  {
    __typename: "ZCognitoUser",
    id?: string | null,
    registrationId?: string | null,
    group?: string | null,
    power?: string | null,
    email?: string | null,
    federation?: string | null,
    gender?: string | null,
    nickname?: string | null,
  } | null,
};

export type CreateBucketMutationVariables = {
  input: CreateBucketInput,
  condition?: ModelBucketConditionInput | null,
};

export type CreateBucketMutation = {
  createBucket?:  {
    __typename: "Bucket",
    id: string,
    web?:  {
      __typename: "ZBucket_Web",
      shortUrl: string,
      isStellactWeb: boolean,
      cloudfrontUrl?: string | null,
      ipAddress?: string | null,
      sslCertificateId?: string | null,
    } | null,
    stellaFolders:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateBucketMutationVariables = {
  input: UpdateBucketInput,
  condition?: ModelBucketConditionInput | null,
};

export type UpdateBucketMutation = {
  updateBucket?:  {
    __typename: "Bucket",
    id: string,
    web?:  {
      __typename: "ZBucket_Web",
      shortUrl: string,
      isStellactWeb: boolean,
      cloudfrontUrl?: string | null,
      ipAddress?: string | null,
      sslCertificateId?: string | null,
    } | null,
    stellaFolders:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteBucketMutationVariables = {
  input: DeleteBucketInput,
  condition?: ModelBucketConditionInput | null,
};

export type DeleteBucketMutation = {
  deleteBucket?:  {
    __typename: "Bucket",
    id: string,
    web?:  {
      __typename: "ZBucket_Web",
      shortUrl: string,
      isStellactWeb: boolean,
      cloudfrontUrl?: string | null,
      ipAddress?: string | null,
      sslCertificateId?: string | null,
    } | null,
    stellaFolders:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type CreateDataStructMutationVariables = {
  input: CreateDataStructInput,
  condition?: ModelDataStructConditionInput | null,
};

export type CreateDataStructMutation = {
  createDataStruct?:  {
    __typename: "DataStruct",
    id: string,
    locationId?: string | null,
    location?:  {
      __typename: "Location",
      id: string,
      address?:  {
        __typename: "ZAddress",
        municipality?: string | null,
        countryCode?: string | null,
        postCode?: string | null,
        regionCode?: string | null,
        street?: string | null,
      } | null,
      calendar?:  {
        __typename: "ZCalendarMap",
        openingHours?:  {
          __typename: "ZOpeningHoursMap",
          end?: string | null,
          start?: string | null,
        } | null,
        weekly?: string | null,
        yearly?:  {
          __typename: "ZYearlyMap",
          endDate?: string | null,
          startDate?: string | null,
        } | null,
      } | null,
      geolocation?:  {
        __typename: "ZGeolocation",
        latitude?: number | null,
        longitude?: number | null,
      } | null,
      parentId: string,
      municipalityId: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    language?: string | null,
    name?: string | null,
    audience?: string | null,
    geonewsExpiryDateTime?: string | null,
    geonewsStartDateTime?: string | null,
    geonewsDuration?: number | null,
    entityId: string,
    sectionLngMap:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    },
    sectionLngId?: string | null,
    sectionLng?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refSectionLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    refSectionLngId?: string | null,
    refSectionLng?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refInternalLinkLngMap?:  {
      __typename: "ZSectionCompositeMap",
      en?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fr?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    refInternalLinkLng?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    fromUserId?: string | null,
    fromUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    toUserId?: string | null,
    toUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    queryLanguage?: string | null,
    parentId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateDataStructMutationVariables = {
  input: UpdateDataStructInput,
  condition?: ModelDataStructConditionInput | null,
};

export type UpdateDataStructMutation = {
  updateDataStruct?:  {
    __typename: "DataStruct",
    id: string,
    locationId?: string | null,
    location?:  {
      __typename: "Location",
      id: string,
      address?:  {
        __typename: "ZAddress",
        municipality?: string | null,
        countryCode?: string | null,
        postCode?: string | null,
        regionCode?: string | null,
        street?: string | null,
      } | null,
      calendar?:  {
        __typename: "ZCalendarMap",
        openingHours?:  {
          __typename: "ZOpeningHoursMap",
          end?: string | null,
          start?: string | null,
        } | null,
        weekly?: string | null,
        yearly?:  {
          __typename: "ZYearlyMap",
          endDate?: string | null,
          startDate?: string | null,
        } | null,
      } | null,
      geolocation?:  {
        __typename: "ZGeolocation",
        latitude?: number | null,
        longitude?: number | null,
      } | null,
      parentId: string,
      municipalityId: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    language?: string | null,
    name?: string | null,
    audience?: string | null,
    geonewsExpiryDateTime?: string | null,
    geonewsStartDateTime?: string | null,
    geonewsDuration?: number | null,
    entityId: string,
    sectionLngMap:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    },
    sectionLngId?: string | null,
    sectionLng?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refSectionLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    refSectionLngId?: string | null,
    refSectionLng?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refInternalLinkLngMap?:  {
      __typename: "ZSectionCompositeMap",
      en?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fr?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    refInternalLinkLng?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    fromUserId?: string | null,
    fromUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    toUserId?: string | null,
    toUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    queryLanguage?: string | null,
    parentId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteDataStructMutationVariables = {
  input: DeleteDataStructInput,
  condition?: ModelDataStructConditionInput | null,
};

export type DeleteDataStructMutation = {
  deleteDataStruct?:  {
    __typename: "DataStruct",
    id: string,
    locationId?: string | null,
    location?:  {
      __typename: "Location",
      id: string,
      address?:  {
        __typename: "ZAddress",
        municipality?: string | null,
        countryCode?: string | null,
        postCode?: string | null,
        regionCode?: string | null,
        street?: string | null,
      } | null,
      calendar?:  {
        __typename: "ZCalendarMap",
        openingHours?:  {
          __typename: "ZOpeningHoursMap",
          end?: string | null,
          start?: string | null,
        } | null,
        weekly?: string | null,
        yearly?:  {
          __typename: "ZYearlyMap",
          endDate?: string | null,
          startDate?: string | null,
        } | null,
      } | null,
      geolocation?:  {
        __typename: "ZGeolocation",
        latitude?: number | null,
        longitude?: number | null,
      } | null,
      parentId: string,
      municipalityId: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    language?: string | null,
    name?: string | null,
    audience?: string | null,
    geonewsExpiryDateTime?: string | null,
    geonewsStartDateTime?: string | null,
    geonewsDuration?: number | null,
    entityId: string,
    sectionLngMap:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    },
    sectionLngId?: string | null,
    sectionLng?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refSectionLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    refSectionLngId?: string | null,
    refSectionLng?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refInternalLinkLngMap?:  {
      __typename: "ZSectionCompositeMap",
      en?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fr?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    refInternalLinkLng?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    fromUserId?: string | null,
    fromUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    toUserId?: string | null,
    toUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    queryLanguage?: string | null,
    parentId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type CreateItemMutationVariables = {
  input: CreateItemInput,
  condition?: ModelItemConditionInput | null,
};

export type CreateItemMutation = {
  createItem?:  {
    __typename: "Item",
    id: string,
    domainId: string,
    isOrion: boolean,
    nameLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    itemLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    descriptionLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    price?:  {
      __typename: "ZPriceMap",
      amount?: number | null,
      currency?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateItemMutationVariables = {
  input: UpdateItemInput,
  condition?: ModelItemConditionInput | null,
};

export type UpdateItemMutation = {
  updateItem?:  {
    __typename: "Item",
    id: string,
    domainId: string,
    isOrion: boolean,
    nameLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    itemLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    descriptionLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    price?:  {
      __typename: "ZPriceMap",
      amount?: number | null,
      currency?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteItemMutationVariables = {
  input: DeleteItemInput,
  condition?: ModelItemConditionInput | null,
};

export type DeleteItemMutation = {
  deleteItem?:  {
    __typename: "Item",
    id: string,
    domainId: string,
    isOrion: boolean,
    nameLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    itemLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    descriptionLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    price?:  {
      __typename: "ZPriceMap",
      amount?: number | null,
      currency?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type CreateItemLngMutationVariables = {
  input: CreateItemLngInput,
  condition?: ModelItemLngConditionInput | null,
};

export type CreateItemLngMutation = {
  createItemLng?:  {
    __typename: "ItemLng",
    id: string,
    language?: string | null,
    parentId: string,
    item?:  {
      __typename: "Item",
      id: string,
      domainId: string,
      isOrion: boolean,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      price?:  {
        __typename: "ZPriceMap",
        amount?: number | null,
        currency?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    columnsMap?:  Array< {
      __typename: "ZColumnMap",
      columnKey?: string | null,
      sectionId?: string | null,
    } | null > | null,
    columnsContentSortIds?: Array< string | null > | null,
    columnsContent?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    refSectionId?: string | null,
    refSection?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refInternalLink?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateItemLngMutationVariables = {
  input: UpdateItemLngInput,
  condition?: ModelItemLngConditionInput | null,
};

export type UpdateItemLngMutation = {
  updateItemLng?:  {
    __typename: "ItemLng",
    id: string,
    language?: string | null,
    parentId: string,
    item?:  {
      __typename: "Item",
      id: string,
      domainId: string,
      isOrion: boolean,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      price?:  {
        __typename: "ZPriceMap",
        amount?: number | null,
        currency?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    columnsMap?:  Array< {
      __typename: "ZColumnMap",
      columnKey?: string | null,
      sectionId?: string | null,
    } | null > | null,
    columnsContentSortIds?: Array< string | null > | null,
    columnsContent?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    refSectionId?: string | null,
    refSection?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refInternalLink?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteItemLngMutationVariables = {
  input: DeleteItemLngInput,
  condition?: ModelItemLngConditionInput | null,
};

export type DeleteItemLngMutation = {
  deleteItemLng?:  {
    __typename: "ItemLng",
    id: string,
    language?: string | null,
    parentId: string,
    item?:  {
      __typename: "Item",
      id: string,
      domainId: string,
      isOrion: boolean,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      price?:  {
        __typename: "ZPriceMap",
        amount?: number | null,
        currency?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    columnsMap?:  Array< {
      __typename: "ZColumnMap",
      columnKey?: string | null,
      sectionId?: string | null,
    } | null > | null,
    columnsContentSortIds?: Array< string | null > | null,
    columnsContent?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    refSectionId?: string | null,
    refSection?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refInternalLink?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type CreateLocationMutationVariables = {
  input: CreateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type CreateLocationMutation = {
  createLocation?:  {
    __typename: "Location",
    id: string,
    address?:  {
      __typename: "ZAddress",
      municipality?: string | null,
      countryCode?: string | null,
      postCode?: string | null,
      regionCode?: string | null,
      street?: string | null,
    } | null,
    calendar?:  {
      __typename: "ZCalendarMap",
      openingHours?:  {
        __typename: "ZOpeningHoursMap",
        end?: string | null,
        start?: string | null,
      } | null,
      weekly?: string | null,
      yearly?:  {
        __typename: "ZYearlyMap",
        endDate?: string | null,
        startDate?: string | null,
      } | null,
    } | null,
    geolocation?:  {
      __typename: "ZGeolocation",
      latitude?: number | null,
      longitude?: number | null,
    } | null,
    parentId: string,
    municipalityId: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateLocationMutationVariables = {
  input: UpdateLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type UpdateLocationMutation = {
  updateLocation?:  {
    __typename: "Location",
    id: string,
    address?:  {
      __typename: "ZAddress",
      municipality?: string | null,
      countryCode?: string | null,
      postCode?: string | null,
      regionCode?: string | null,
      street?: string | null,
    } | null,
    calendar?:  {
      __typename: "ZCalendarMap",
      openingHours?:  {
        __typename: "ZOpeningHoursMap",
        end?: string | null,
        start?: string | null,
      } | null,
      weekly?: string | null,
      yearly?:  {
        __typename: "ZYearlyMap",
        endDate?: string | null,
        startDate?: string | null,
      } | null,
    } | null,
    geolocation?:  {
      __typename: "ZGeolocation",
      latitude?: number | null,
      longitude?: number | null,
    } | null,
    parentId: string,
    municipalityId: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteLocationMutationVariables = {
  input: DeleteLocationInput,
  condition?: ModelLocationConditionInput | null,
};

export type DeleteLocationMutation = {
  deleteLocation?:  {
    __typename: "Location",
    id: string,
    address?:  {
      __typename: "ZAddress",
      municipality?: string | null,
      countryCode?: string | null,
      postCode?: string | null,
      regionCode?: string | null,
      street?: string | null,
    } | null,
    calendar?:  {
      __typename: "ZCalendarMap",
      openingHours?:  {
        __typename: "ZOpeningHoursMap",
        end?: string | null,
        start?: string | null,
      } | null,
      weekly?: string | null,
      yearly?:  {
        __typename: "ZYearlyMap",
        endDate?: string | null,
        startDate?: string | null,
      } | null,
    } | null,
    geolocation?:  {
      __typename: "ZGeolocation",
      latitude?: number | null,
      longitude?: number | null,
    } | null,
    parentId: string,
    municipalityId: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type AddCognitoGroupMutationVariables = {
  registrationId: string,
  group: string,
};

export type AddCognitoGroupMutation = {
  addCognitoGroup?:  {
    __typename: "ZCognitoUser",
    id?: string | null,
    registrationId?: string | null,
    group?: string | null,
    power?: string | null,
    email?: string | null,
    federation?: string | null,
    gender?: string | null,
    nickname?: string | null,
  } | null,
};

export type CreateBucketOnS3MutationVariables = {
  id: string,
  stellaId?: string | null,
};

export type CreateBucketOnS3Mutation = {
  createBucketOnS3?:  {
    __typename: "ZBucket_S3",
    id: string,
    url?: string | null,
    bucketId?: string | null,
  } | null,
};

export type CreatePointerMutationVariables = {
  input: CreatePointerInput,
  condition?: ModelPointerConditionInput | null,
};

export type CreatePointerMutation = {
  createPointer?:  {
    __typename: "Pointer",
    pointer: string,
    queryLanguage?: string | null,
    stellaId?: string | null,
    stella?:  {
      __typename: "Stella",
      id: string,
      bucket?:  {
        __typename: "Bucket",
        id: string,
        web?:  {
          __typename: "ZBucket_Web",
          shortUrl: string,
          isStellactWeb: boolean,
          cloudfrontUrl?: string | null,
          ipAddress?: string | null,
          sslCertificateId?: string | null,
        } | null,
        stellaFolders:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null >,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      bucketId: string,
      entityId: string,
      interfaceLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      interfaceLngId?: string | null,
      interfaceLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      logo?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      mediaList:  Array< {
        __typename: "ZMedia",
        mediaRef: string,
        sizes: string,
      } | null >,
      name: string,
      options:  {
        __typename: "ZOptions",
        hasDraft: boolean,
        status: STELLASTATUS,
      },
      pointer: string,
      queryLanguage?: string | null,
      social?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      specialties?:  Array< {
        __typename: "Specialty",
        id: string,
        pointer: string,
        designationId: string,
        designation?:  {
          __typename: "StaticObject",
          id: string,
          nameLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          name?: string | null,
          descriptionLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          description?: string | null,
          attribute?:  {
            __typename: "ZFieldValue",
            value?: string | null,
            field?: string | null,
          } | null,
          type?: string | null,
          queryLanguage?: string | null,
          parentId?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        levelId: string,
        catchphraseLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        itemsIds?: Array< string | null > | null,
        geonews?:  Array< {
          __typename: "DataStruct",
          id: string,
          locationId?: string | null,
          location?:  {
            __typename: "Location",
            id: string,
            address?:  {
              __typename: "ZAddress",
              municipality?: string | null,
              countryCode?: string | null,
              postCode?: string | null,
              regionCode?: string | null,
              street?: string | null,
            } | null,
            calendar?:  {
              __typename: "ZCalendarMap",
              openingHours?:  {
                __typename: "ZOpeningHoursMap",
                end?: string | null,
                start?: string | null,
              } | null,
              weekly?: string | null,
              yearly?:  {
                __typename: "ZYearlyMap",
                endDate?: string | null,
                startDate?: string | null,
              } | null,
            } | null,
            geolocation?:  {
              __typename: "ZGeolocation",
              latitude?: number | null,
              longitude?: number | null,
            } | null,
            parentId: string,
            municipalityId: string,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          language?: string | null,
          name?: string | null,
          audience?: string | null,
          geonewsExpiryDateTime?: string | null,
          geonewsStartDateTime?: string | null,
          geonewsDuration?: number | null,
          entityId: string,
          sectionLngMap:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          },
          sectionLngId?: string | null,
          sectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refSectionLngMap?:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          } | null,
          refSectionLngId?: string | null,
          refSectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refInternalLinkLngMap?:  {
            __typename: "ZSectionCompositeMap",
            en?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            fr?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          refInternalLinkLng?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fromUserId?: string | null,
          fromUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          toUserId?: string | null,
          toUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          queryLanguage?: string | null,
          parentId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsIds?: Array< string | null > | null,
        locationId: string,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        parentId: string,
        othersPocUsersIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        pocUserId: string,
        pocUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        sortKey?: string | null,
        specialtyLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        specialtyLngId?: string | null,
        specialtyLng?:  {
          __typename: "SpecialtyLng",
          id: string,
          gallery?:  {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null,
          language?: string | null,
          parentId: string,
          type?: string | null,
          itemsTables?:  Array< {
            __typename: "ZItemsTableMap",
            headRow?:  Array< {
              __typename: "ZItemsHeadRowMap",
              sortKey?: string | null,
              string?: string | null,
              type?: string | null,
            } | null > | null,
            itemsIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            sortKey?: string | null,
          } | null > | null,
          indexSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          indexSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          indexPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          geonewsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          geonewsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          geonewsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          gallerySectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          gallerySections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          galleryPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          itemsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          itemsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          itemsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          contactSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          contactSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          contactPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add1SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add1Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add1Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add2SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add2Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add2Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add3SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add3Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add3Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add4SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add4Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add4Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        tagsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      specialtiesIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    geostructId?: string | null,
    geostruct?:  {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdatePointerMutationVariables = {
  input: UpdatePointerInput,
  condition?: ModelPointerConditionInput | null,
};

export type UpdatePointerMutation = {
  updatePointer?:  {
    __typename: "Pointer",
    pointer: string,
    queryLanguage?: string | null,
    stellaId?: string | null,
    stella?:  {
      __typename: "Stella",
      id: string,
      bucket?:  {
        __typename: "Bucket",
        id: string,
        web?:  {
          __typename: "ZBucket_Web",
          shortUrl: string,
          isStellactWeb: boolean,
          cloudfrontUrl?: string | null,
          ipAddress?: string | null,
          sslCertificateId?: string | null,
        } | null,
        stellaFolders:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null >,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      bucketId: string,
      entityId: string,
      interfaceLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      interfaceLngId?: string | null,
      interfaceLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      logo?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      mediaList:  Array< {
        __typename: "ZMedia",
        mediaRef: string,
        sizes: string,
      } | null >,
      name: string,
      options:  {
        __typename: "ZOptions",
        hasDraft: boolean,
        status: STELLASTATUS,
      },
      pointer: string,
      queryLanguage?: string | null,
      social?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      specialties?:  Array< {
        __typename: "Specialty",
        id: string,
        pointer: string,
        designationId: string,
        designation?:  {
          __typename: "StaticObject",
          id: string,
          nameLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          name?: string | null,
          descriptionLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          description?: string | null,
          attribute?:  {
            __typename: "ZFieldValue",
            value?: string | null,
            field?: string | null,
          } | null,
          type?: string | null,
          queryLanguage?: string | null,
          parentId?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        levelId: string,
        catchphraseLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        itemsIds?: Array< string | null > | null,
        geonews?:  Array< {
          __typename: "DataStruct",
          id: string,
          locationId?: string | null,
          location?:  {
            __typename: "Location",
            id: string,
            address?:  {
              __typename: "ZAddress",
              municipality?: string | null,
              countryCode?: string | null,
              postCode?: string | null,
              regionCode?: string | null,
              street?: string | null,
            } | null,
            calendar?:  {
              __typename: "ZCalendarMap",
              openingHours?:  {
                __typename: "ZOpeningHoursMap",
                end?: string | null,
                start?: string | null,
              } | null,
              weekly?: string | null,
              yearly?:  {
                __typename: "ZYearlyMap",
                endDate?: string | null,
                startDate?: string | null,
              } | null,
            } | null,
            geolocation?:  {
              __typename: "ZGeolocation",
              latitude?: number | null,
              longitude?: number | null,
            } | null,
            parentId: string,
            municipalityId: string,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          language?: string | null,
          name?: string | null,
          audience?: string | null,
          geonewsExpiryDateTime?: string | null,
          geonewsStartDateTime?: string | null,
          geonewsDuration?: number | null,
          entityId: string,
          sectionLngMap:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          },
          sectionLngId?: string | null,
          sectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refSectionLngMap?:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          } | null,
          refSectionLngId?: string | null,
          refSectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refInternalLinkLngMap?:  {
            __typename: "ZSectionCompositeMap",
            en?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            fr?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          refInternalLinkLng?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fromUserId?: string | null,
          fromUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          toUserId?: string | null,
          toUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          queryLanguage?: string | null,
          parentId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsIds?: Array< string | null > | null,
        locationId: string,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        parentId: string,
        othersPocUsersIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        pocUserId: string,
        pocUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        sortKey?: string | null,
        specialtyLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        specialtyLngId?: string | null,
        specialtyLng?:  {
          __typename: "SpecialtyLng",
          id: string,
          gallery?:  {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null,
          language?: string | null,
          parentId: string,
          type?: string | null,
          itemsTables?:  Array< {
            __typename: "ZItemsTableMap",
            headRow?:  Array< {
              __typename: "ZItemsHeadRowMap",
              sortKey?: string | null,
              string?: string | null,
              type?: string | null,
            } | null > | null,
            itemsIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            sortKey?: string | null,
          } | null > | null,
          indexSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          indexSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          indexPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          geonewsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          geonewsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          geonewsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          gallerySectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          gallerySections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          galleryPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          itemsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          itemsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          itemsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          contactSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          contactSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          contactPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add1SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add1Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add1Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add2SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add2Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add2Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add3SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add3Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add3Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add4SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add4Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add4Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        tagsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      specialtiesIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    geostructId?: string | null,
    geostruct?:  {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeletePointerMutationVariables = {
  input: DeletePointerInput,
  condition?: ModelPointerConditionInput | null,
};

export type DeletePointerMutation = {
  deletePointer?:  {
    __typename: "Pointer",
    pointer: string,
    queryLanguage?: string | null,
    stellaId?: string | null,
    stella?:  {
      __typename: "Stella",
      id: string,
      bucket?:  {
        __typename: "Bucket",
        id: string,
        web?:  {
          __typename: "ZBucket_Web",
          shortUrl: string,
          isStellactWeb: boolean,
          cloudfrontUrl?: string | null,
          ipAddress?: string | null,
          sslCertificateId?: string | null,
        } | null,
        stellaFolders:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null >,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      bucketId: string,
      entityId: string,
      interfaceLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      interfaceLngId?: string | null,
      interfaceLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      logo?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      mediaList:  Array< {
        __typename: "ZMedia",
        mediaRef: string,
        sizes: string,
      } | null >,
      name: string,
      options:  {
        __typename: "ZOptions",
        hasDraft: boolean,
        status: STELLASTATUS,
      },
      pointer: string,
      queryLanguage?: string | null,
      social?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      specialties?:  Array< {
        __typename: "Specialty",
        id: string,
        pointer: string,
        designationId: string,
        designation?:  {
          __typename: "StaticObject",
          id: string,
          nameLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          name?: string | null,
          descriptionLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          description?: string | null,
          attribute?:  {
            __typename: "ZFieldValue",
            value?: string | null,
            field?: string | null,
          } | null,
          type?: string | null,
          queryLanguage?: string | null,
          parentId?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        levelId: string,
        catchphraseLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        itemsIds?: Array< string | null > | null,
        geonews?:  Array< {
          __typename: "DataStruct",
          id: string,
          locationId?: string | null,
          location?:  {
            __typename: "Location",
            id: string,
            address?:  {
              __typename: "ZAddress",
              municipality?: string | null,
              countryCode?: string | null,
              postCode?: string | null,
              regionCode?: string | null,
              street?: string | null,
            } | null,
            calendar?:  {
              __typename: "ZCalendarMap",
              openingHours?:  {
                __typename: "ZOpeningHoursMap",
                end?: string | null,
                start?: string | null,
              } | null,
              weekly?: string | null,
              yearly?:  {
                __typename: "ZYearlyMap",
                endDate?: string | null,
                startDate?: string | null,
              } | null,
            } | null,
            geolocation?:  {
              __typename: "ZGeolocation",
              latitude?: number | null,
              longitude?: number | null,
            } | null,
            parentId: string,
            municipalityId: string,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          language?: string | null,
          name?: string | null,
          audience?: string | null,
          geonewsExpiryDateTime?: string | null,
          geonewsStartDateTime?: string | null,
          geonewsDuration?: number | null,
          entityId: string,
          sectionLngMap:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          },
          sectionLngId?: string | null,
          sectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refSectionLngMap?:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          } | null,
          refSectionLngId?: string | null,
          refSectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refInternalLinkLngMap?:  {
            __typename: "ZSectionCompositeMap",
            en?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            fr?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          refInternalLinkLng?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fromUserId?: string | null,
          fromUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          toUserId?: string | null,
          toUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          queryLanguage?: string | null,
          parentId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsIds?: Array< string | null > | null,
        locationId: string,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        parentId: string,
        othersPocUsersIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        pocUserId: string,
        pocUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        sortKey?: string | null,
        specialtyLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        specialtyLngId?: string | null,
        specialtyLng?:  {
          __typename: "SpecialtyLng",
          id: string,
          gallery?:  {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null,
          language?: string | null,
          parentId: string,
          type?: string | null,
          itemsTables?:  Array< {
            __typename: "ZItemsTableMap",
            headRow?:  Array< {
              __typename: "ZItemsHeadRowMap",
              sortKey?: string | null,
              string?: string | null,
              type?: string | null,
            } | null > | null,
            itemsIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            sortKey?: string | null,
          } | null > | null,
          indexSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          indexSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          indexPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          geonewsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          geonewsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          geonewsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          gallerySectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          gallerySections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          galleryPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          itemsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          itemsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          itemsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          contactSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          contactSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          contactPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add1SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add1Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add1Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add2SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add2Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add2Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add3SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add3Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add3Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add4SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add4Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add4Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        tagsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      specialtiesIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    geostructId?: string | null,
    geostruct?:  {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type CreateSectionMutationVariables = {
  input: CreateSectionInput,
  condition?: ModelSectionConditionInput | null,
};

export type CreateSectionMutation = {
  createSection?:  {
    __typename: "Section",
    id: string,
    parentId: string,
    bucketId?: string | null,
    flexbox?:  {
      __typename: "ZFlexbox",
      columns?: number | null,
      totalColumns?: number | null,
    } | null,
    elements?:  Array< {
      __typename: "ZSectionElement",
      firstComposite:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      },
      nextComposites?:  Array< {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null > | null,
      sortKey: string,
      type: string,
    } | null > | null,
    title?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    language?: string | null,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateSectionMutationVariables = {
  input: UpdateSectionInput,
  condition?: ModelSectionConditionInput | null,
};

export type UpdateSectionMutation = {
  updateSection?:  {
    __typename: "Section",
    id: string,
    parentId: string,
    bucketId?: string | null,
    flexbox?:  {
      __typename: "ZFlexbox",
      columns?: number | null,
      totalColumns?: number | null,
    } | null,
    elements?:  Array< {
      __typename: "ZSectionElement",
      firstComposite:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      },
      nextComposites?:  Array< {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null > | null,
      sortKey: string,
      type: string,
    } | null > | null,
    title?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    language?: string | null,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteSectionMutationVariables = {
  input: DeleteSectionInput,
  condition?: ModelSectionConditionInput | null,
};

export type DeleteSectionMutation = {
  deleteSection?:  {
    __typename: "Section",
    id: string,
    parentId: string,
    bucketId?: string | null,
    flexbox?:  {
      __typename: "ZFlexbox",
      columns?: number | null,
      totalColumns?: number | null,
    } | null,
    elements?:  Array< {
      __typename: "ZSectionElement",
      firstComposite:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      },
      nextComposites?:  Array< {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null > | null,
      sortKey: string,
      type: string,
    } | null > | null,
    title?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    language?: string | null,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type CreateSpecialtyMutationVariables = {
  input: CreateSpecialtyInput,
  condition?: ModelSpecialtyConditionInput | null,
};

export type CreateSpecialtyMutation = {
  createSpecialty?:  {
    __typename: "Specialty",
    id: string,
    pointer: string,
    designationId: string,
    designation?:  {
      __typename: "StaticObject",
      id: string,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      name?: string | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      description?: string | null,
      attribute?:  {
        __typename: "ZFieldValue",
        value?: string | null,
        field?: string | null,
      } | null,
      type?: string | null,
      queryLanguage?: string | null,
      parentId?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    levelId: string,
    catchphraseLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    itemsIds?: Array< string | null > | null,
    geonews?:  Array< {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    geonewsIds?: Array< string | null > | null,
    locationId: string,
    location?:  {
      __typename: "Location",
      id: string,
      address?:  {
        __typename: "ZAddress",
        municipality?: string | null,
        countryCode?: string | null,
        postCode?: string | null,
        regionCode?: string | null,
        street?: string | null,
      } | null,
      calendar?:  {
        __typename: "ZCalendarMap",
        openingHours?:  {
          __typename: "ZOpeningHoursMap",
          end?: string | null,
          start?: string | null,
        } | null,
        weekly?: string | null,
        yearly?:  {
          __typename: "ZYearlyMap",
          endDate?: string | null,
          startDate?: string | null,
        } | null,
      } | null,
      geolocation?:  {
        __typename: "ZGeolocation",
        latitude?: number | null,
        longitude?: number | null,
      } | null,
      parentId: string,
      municipalityId: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    parentId: string,
    othersPocUsersIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    pocUserId: string,
    pocUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    queryLanguage?: string | null,
    sortKey?: string | null,
    specialtyLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    specialtyLngId?: string | null,
    specialtyLng?:  {
      __typename: "SpecialtyLng",
      id: string,
      gallery?:  {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null,
      language?: string | null,
      parentId: string,
      type?: string | null,
      itemsTables?:  Array< {
        __typename: "ZItemsTableMap",
        headRow?:  Array< {
          __typename: "ZItemsHeadRowMap",
          sortKey?: string | null,
          string?: string | null,
          type?: string | null,
        } | null > | null,
        itemsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        sortKey?: string | null,
      } | null > | null,
      indexSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      indexSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      indexPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      geonewsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      geonewsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      gallerySectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      gallerySections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      galleryPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      itemsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      itemsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      itemsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      contactSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      contactSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      contactPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add1SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add1Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add1Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add2SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add2Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add2Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add3SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add3Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add3Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add4SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add4Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add4Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    tagsIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateSpecialtyMutationVariables = {
  input: UpdateSpecialtyInput,
  condition?: ModelSpecialtyConditionInput | null,
};

export type UpdateSpecialtyMutation = {
  updateSpecialty?:  {
    __typename: "Specialty",
    id: string,
    pointer: string,
    designationId: string,
    designation?:  {
      __typename: "StaticObject",
      id: string,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      name?: string | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      description?: string | null,
      attribute?:  {
        __typename: "ZFieldValue",
        value?: string | null,
        field?: string | null,
      } | null,
      type?: string | null,
      queryLanguage?: string | null,
      parentId?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    levelId: string,
    catchphraseLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    itemsIds?: Array< string | null > | null,
    geonews?:  Array< {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    geonewsIds?: Array< string | null > | null,
    locationId: string,
    location?:  {
      __typename: "Location",
      id: string,
      address?:  {
        __typename: "ZAddress",
        municipality?: string | null,
        countryCode?: string | null,
        postCode?: string | null,
        regionCode?: string | null,
        street?: string | null,
      } | null,
      calendar?:  {
        __typename: "ZCalendarMap",
        openingHours?:  {
          __typename: "ZOpeningHoursMap",
          end?: string | null,
          start?: string | null,
        } | null,
        weekly?: string | null,
        yearly?:  {
          __typename: "ZYearlyMap",
          endDate?: string | null,
          startDate?: string | null,
        } | null,
      } | null,
      geolocation?:  {
        __typename: "ZGeolocation",
        latitude?: number | null,
        longitude?: number | null,
      } | null,
      parentId: string,
      municipalityId: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    parentId: string,
    othersPocUsersIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    pocUserId: string,
    pocUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    queryLanguage?: string | null,
    sortKey?: string | null,
    specialtyLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    specialtyLngId?: string | null,
    specialtyLng?:  {
      __typename: "SpecialtyLng",
      id: string,
      gallery?:  {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null,
      language?: string | null,
      parentId: string,
      type?: string | null,
      itemsTables?:  Array< {
        __typename: "ZItemsTableMap",
        headRow?:  Array< {
          __typename: "ZItemsHeadRowMap",
          sortKey?: string | null,
          string?: string | null,
          type?: string | null,
        } | null > | null,
        itemsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        sortKey?: string | null,
      } | null > | null,
      indexSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      indexSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      indexPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      geonewsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      geonewsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      gallerySectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      gallerySections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      galleryPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      itemsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      itemsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      itemsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      contactSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      contactSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      contactPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add1SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add1Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add1Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add2SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add2Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add2Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add3SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add3Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add3Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add4SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add4Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add4Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    tagsIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteSpecialtyMutationVariables = {
  input: DeleteSpecialtyInput,
  condition?: ModelSpecialtyConditionInput | null,
};

export type DeleteSpecialtyMutation = {
  deleteSpecialty?:  {
    __typename: "Specialty",
    id: string,
    pointer: string,
    designationId: string,
    designation?:  {
      __typename: "StaticObject",
      id: string,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      name?: string | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      description?: string | null,
      attribute?:  {
        __typename: "ZFieldValue",
        value?: string | null,
        field?: string | null,
      } | null,
      type?: string | null,
      queryLanguage?: string | null,
      parentId?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    levelId: string,
    catchphraseLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    itemsIds?: Array< string | null > | null,
    geonews?:  Array< {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    geonewsIds?: Array< string | null > | null,
    locationId: string,
    location?:  {
      __typename: "Location",
      id: string,
      address?:  {
        __typename: "ZAddress",
        municipality?: string | null,
        countryCode?: string | null,
        postCode?: string | null,
        regionCode?: string | null,
        street?: string | null,
      } | null,
      calendar?:  {
        __typename: "ZCalendarMap",
        openingHours?:  {
          __typename: "ZOpeningHoursMap",
          end?: string | null,
          start?: string | null,
        } | null,
        weekly?: string | null,
        yearly?:  {
          __typename: "ZYearlyMap",
          endDate?: string | null,
          startDate?: string | null,
        } | null,
      } | null,
      geolocation?:  {
        __typename: "ZGeolocation",
        latitude?: number | null,
        longitude?: number | null,
      } | null,
      parentId: string,
      municipalityId: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    parentId: string,
    othersPocUsersIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    pocUserId: string,
    pocUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    queryLanguage?: string | null,
    sortKey?: string | null,
    specialtyLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    specialtyLngId?: string | null,
    specialtyLng?:  {
      __typename: "SpecialtyLng",
      id: string,
      gallery?:  {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null,
      language?: string | null,
      parentId: string,
      type?: string | null,
      itemsTables?:  Array< {
        __typename: "ZItemsTableMap",
        headRow?:  Array< {
          __typename: "ZItemsHeadRowMap",
          sortKey?: string | null,
          string?: string | null,
          type?: string | null,
        } | null > | null,
        itemsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        sortKey?: string | null,
      } | null > | null,
      indexSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      indexSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      indexPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      geonewsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      geonewsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      gallerySectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      gallerySections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      galleryPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      itemsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      itemsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      itemsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      contactSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      contactSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      contactPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add1SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add1Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add1Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add2SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add2Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add2Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add3SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add3Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add3Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add4SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add4Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add4Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    tagsIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type CreateSpecialtyLngMutationVariables = {
  input: CreateSpecialtyLngInput,
  condition?: ModelSpecialtyLngConditionInput | null,
};

export type CreateSpecialtyLngMutation = {
  createSpecialtyLng?:  {
    __typename: "SpecialtyLng",
    id: string,
    gallery?:  {
      __typename: "ZSectionElement",
      firstComposite:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      },
      nextComposites?:  Array< {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null > | null,
      sortKey: string,
      type: string,
    } | null,
    language?: string | null,
    parentId: string,
    type?: string | null,
    itemsTables?:  Array< {
      __typename: "ZItemsTableMap",
      headRow?:  Array< {
        __typename: "ZItemsHeadRowMap",
        sortKey?: string | null,
        string?: string | null,
        type?: string | null,
      } | null > | null,
      itemsIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      sortKey?: string | null,
    } | null > | null,
    indexSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    indexSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    indexPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    geonewsSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    geonewsSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    geonewsPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    gallerySectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    gallerySections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    galleryPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    itemsSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    itemsSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    itemsPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    contactSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    contactSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    contactPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add1SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add1Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add1Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add2SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add2Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add2Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add3SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add3Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add3Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add4SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add4Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add4Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateSpecialtyLngMutationVariables = {
  input: UpdateSpecialtyLngInput,
  condition?: ModelSpecialtyLngConditionInput | null,
};

export type UpdateSpecialtyLngMutation = {
  updateSpecialtyLng?:  {
    __typename: "SpecialtyLng",
    id: string,
    gallery?:  {
      __typename: "ZSectionElement",
      firstComposite:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      },
      nextComposites?:  Array< {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null > | null,
      sortKey: string,
      type: string,
    } | null,
    language?: string | null,
    parentId: string,
    type?: string | null,
    itemsTables?:  Array< {
      __typename: "ZItemsTableMap",
      headRow?:  Array< {
        __typename: "ZItemsHeadRowMap",
        sortKey?: string | null,
        string?: string | null,
        type?: string | null,
      } | null > | null,
      itemsIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      sortKey?: string | null,
    } | null > | null,
    indexSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    indexSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    indexPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    geonewsSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    geonewsSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    geonewsPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    gallerySectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    gallerySections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    galleryPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    itemsSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    itemsSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    itemsPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    contactSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    contactSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    contactPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add1SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add1Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add1Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add2SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add2Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add2Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add3SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add3Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add3Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add4SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add4Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add4Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteSpecialtyLngMutationVariables = {
  input: DeleteSpecialtyLngInput,
  condition?: ModelSpecialtyLngConditionInput | null,
};

export type DeleteSpecialtyLngMutation = {
  deleteSpecialtyLng?:  {
    __typename: "SpecialtyLng",
    id: string,
    gallery?:  {
      __typename: "ZSectionElement",
      firstComposite:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      },
      nextComposites?:  Array< {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null > | null,
      sortKey: string,
      type: string,
    } | null,
    language?: string | null,
    parentId: string,
    type?: string | null,
    itemsTables?:  Array< {
      __typename: "ZItemsTableMap",
      headRow?:  Array< {
        __typename: "ZItemsHeadRowMap",
        sortKey?: string | null,
        string?: string | null,
        type?: string | null,
      } | null > | null,
      itemsIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      sortKey?: string | null,
    } | null > | null,
    indexSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    indexSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    indexPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    geonewsSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    geonewsSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    geonewsPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    gallerySectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    gallerySections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    galleryPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    itemsSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    itemsSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    itemsPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    contactSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    contactSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    contactPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add1SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add1Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add1Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add2SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add2Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add2Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add3SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add3Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add3Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add4SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add4Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add4Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type CreateStaticObjectMutationVariables = {
  input: CreateStaticObjectInput,
  condition?: ModelStaticObjectConditionInput | null,
};

export type CreateStaticObjectMutation = {
  createStaticObject?:  {
    __typename: "StaticObject",
    id: string,
    nameLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    name?: string | null,
    descriptionLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    description?: string | null,
    attribute?:  {
      __typename: "ZFieldValue",
      value?: string | null,
      field?: string | null,
    } | null,
    type?: string | null,
    queryLanguage?: string | null,
    parentId?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateStaticObjectMutationVariables = {
  input: UpdateStaticObjectInput,
  condition?: ModelStaticObjectConditionInput | null,
};

export type UpdateStaticObjectMutation = {
  updateStaticObject?:  {
    __typename: "StaticObject",
    id: string,
    nameLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    name?: string | null,
    descriptionLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    description?: string | null,
    attribute?:  {
      __typename: "ZFieldValue",
      value?: string | null,
      field?: string | null,
    } | null,
    type?: string | null,
    queryLanguage?: string | null,
    parentId?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteStaticObjectMutationVariables = {
  input: DeleteStaticObjectInput,
  condition?: ModelStaticObjectConditionInput | null,
};

export type DeleteStaticObjectMutation = {
  deleteStaticObject?:  {
    __typename: "StaticObject",
    id: string,
    nameLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    name?: string | null,
    descriptionLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    description?: string | null,
    attribute?:  {
      __typename: "ZFieldValue",
      value?: string | null,
      field?: string | null,
    } | null,
    type?: string | null,
    queryLanguage?: string | null,
    parentId?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type CreateStellaMutationVariables = {
  input: CreateStellaInput,
  condition?: ModelStellaConditionInput | null,
};

export type CreateStellaMutation = {
  createStella?:  {
    __typename: "Stella",
    id: string,
    bucket?:  {
      __typename: "Bucket",
      id: string,
      web?:  {
        __typename: "ZBucket_Web",
        shortUrl: string,
        isStellactWeb: boolean,
        cloudfrontUrl?: string | null,
        ipAddress?: string | null,
        sslCertificateId?: string | null,
      } | null,
      stellaFolders:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null >,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    bucketId: string,
    entityId: string,
    interfaceLng?:  {
      __typename: "SpecialtyLng",
      id: string,
      gallery?:  {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null,
      language?: string | null,
      parentId: string,
      type?: string | null,
      itemsTables?:  Array< {
        __typename: "ZItemsTableMap",
        headRow?:  Array< {
          __typename: "ZItemsHeadRowMap",
          sortKey?: string | null,
          string?: string | null,
          type?: string | null,
        } | null > | null,
        itemsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        sortKey?: string | null,
      } | null > | null,
      indexSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      indexSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      indexPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      geonewsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      geonewsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      gallerySectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      gallerySections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      galleryPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      itemsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      itemsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      itemsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      contactSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      contactSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      contactPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add1SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add1Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add1Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add2SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add2Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add2Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add3SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add3Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add3Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add4SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add4Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add4Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    interfaceLngId?: string | null,
    interfaceLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    logo?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    mediaList:  Array< {
      __typename: "ZMedia",
      mediaRef: string,
      sizes: string,
    } | null >,
    name: string,
    options:  {
      __typename: "ZOptions",
      hasDraft: boolean,
      status: STELLASTATUS,
    },
    pointer: string,
    queryLanguage?: string | null,
    social?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    specialties?:  Array< {
      __typename: "Specialty",
      id: string,
      pointer: string,
      designationId: string,
      designation?:  {
        __typename: "StaticObject",
        id: string,
        nameLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        name?: string | null,
        descriptionLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        description?: string | null,
        attribute?:  {
          __typename: "ZFieldValue",
          value?: string | null,
          field?: string | null,
        } | null,
        type?: string | null,
        queryLanguage?: string | null,
        parentId?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      levelId: string,
      catchphraseLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemsIds?: Array< string | null > | null,
      geonews?:  Array< {
        __typename: "DataStruct",
        id: string,
        locationId?: string | null,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        language?: string | null,
        name?: string | null,
        audience?: string | null,
        geonewsExpiryDateTime?: string | null,
        geonewsStartDateTime?: string | null,
        geonewsDuration?: number | null,
        entityId: string,
        sectionLngMap:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        },
        sectionLngId?: string | null,
        sectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refSectionLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        refSectionLngId?: string | null,
        refSectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refInternalLinkLngMap?:  {
          __typename: "ZSectionCompositeMap",
          en?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fr?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        refInternalLinkLng?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fromUserId?: string | null,
        fromUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        toUserId?: string | null,
        toUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        parentId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsIds?: Array< string | null > | null,
      locationId: string,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      parentId: string,
      othersPocUsersIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      pocUserId: string,
      pocUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      sortKey?: string | null,
      specialtyLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      specialtyLngId?: string | null,
      specialtyLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      tagsIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    specialtiesIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateStellaMutationVariables = {
  input: UpdateStellaInput,
  condition?: ModelStellaConditionInput | null,
};

export type UpdateStellaMutation = {
  updateStella?:  {
    __typename: "Stella",
    id: string,
    bucket?:  {
      __typename: "Bucket",
      id: string,
      web?:  {
        __typename: "ZBucket_Web",
        shortUrl: string,
        isStellactWeb: boolean,
        cloudfrontUrl?: string | null,
        ipAddress?: string | null,
        sslCertificateId?: string | null,
      } | null,
      stellaFolders:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null >,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    bucketId: string,
    entityId: string,
    interfaceLng?:  {
      __typename: "SpecialtyLng",
      id: string,
      gallery?:  {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null,
      language?: string | null,
      parentId: string,
      type?: string | null,
      itemsTables?:  Array< {
        __typename: "ZItemsTableMap",
        headRow?:  Array< {
          __typename: "ZItemsHeadRowMap",
          sortKey?: string | null,
          string?: string | null,
          type?: string | null,
        } | null > | null,
        itemsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        sortKey?: string | null,
      } | null > | null,
      indexSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      indexSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      indexPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      geonewsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      geonewsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      gallerySectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      gallerySections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      galleryPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      itemsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      itemsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      itemsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      contactSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      contactSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      contactPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add1SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add1Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add1Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add2SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add2Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add2Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add3SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add3Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add3Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add4SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add4Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add4Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    interfaceLngId?: string | null,
    interfaceLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    logo?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    mediaList:  Array< {
      __typename: "ZMedia",
      mediaRef: string,
      sizes: string,
    } | null >,
    name: string,
    options:  {
      __typename: "ZOptions",
      hasDraft: boolean,
      status: STELLASTATUS,
    },
    pointer: string,
    queryLanguage?: string | null,
    social?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    specialties?:  Array< {
      __typename: "Specialty",
      id: string,
      pointer: string,
      designationId: string,
      designation?:  {
        __typename: "StaticObject",
        id: string,
        nameLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        name?: string | null,
        descriptionLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        description?: string | null,
        attribute?:  {
          __typename: "ZFieldValue",
          value?: string | null,
          field?: string | null,
        } | null,
        type?: string | null,
        queryLanguage?: string | null,
        parentId?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      levelId: string,
      catchphraseLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemsIds?: Array< string | null > | null,
      geonews?:  Array< {
        __typename: "DataStruct",
        id: string,
        locationId?: string | null,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        language?: string | null,
        name?: string | null,
        audience?: string | null,
        geonewsExpiryDateTime?: string | null,
        geonewsStartDateTime?: string | null,
        geonewsDuration?: number | null,
        entityId: string,
        sectionLngMap:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        },
        sectionLngId?: string | null,
        sectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refSectionLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        refSectionLngId?: string | null,
        refSectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refInternalLinkLngMap?:  {
          __typename: "ZSectionCompositeMap",
          en?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fr?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        refInternalLinkLng?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fromUserId?: string | null,
        fromUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        toUserId?: string | null,
        toUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        parentId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsIds?: Array< string | null > | null,
      locationId: string,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      parentId: string,
      othersPocUsersIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      pocUserId: string,
      pocUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      sortKey?: string | null,
      specialtyLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      specialtyLngId?: string | null,
      specialtyLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      tagsIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    specialtiesIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteStellaMutationVariables = {
  input: DeleteStellaInput,
  condition?: ModelStellaConditionInput | null,
};

export type DeleteStellaMutation = {
  deleteStella?:  {
    __typename: "Stella",
    id: string,
    bucket?:  {
      __typename: "Bucket",
      id: string,
      web?:  {
        __typename: "ZBucket_Web",
        shortUrl: string,
        isStellactWeb: boolean,
        cloudfrontUrl?: string | null,
        ipAddress?: string | null,
        sslCertificateId?: string | null,
      } | null,
      stellaFolders:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null >,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    bucketId: string,
    entityId: string,
    interfaceLng?:  {
      __typename: "SpecialtyLng",
      id: string,
      gallery?:  {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null,
      language?: string | null,
      parentId: string,
      type?: string | null,
      itemsTables?:  Array< {
        __typename: "ZItemsTableMap",
        headRow?:  Array< {
          __typename: "ZItemsHeadRowMap",
          sortKey?: string | null,
          string?: string | null,
          type?: string | null,
        } | null > | null,
        itemsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        sortKey?: string | null,
      } | null > | null,
      indexSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      indexSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      indexPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      geonewsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      geonewsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      gallerySectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      gallerySections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      galleryPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      itemsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      itemsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      itemsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      contactSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      contactSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      contactPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add1SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add1Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add1Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add2SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add2Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add2Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add3SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add3Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add3Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add4SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add4Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add4Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    interfaceLngId?: string | null,
    interfaceLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    logo?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    mediaList:  Array< {
      __typename: "ZMedia",
      mediaRef: string,
      sizes: string,
    } | null >,
    name: string,
    options:  {
      __typename: "ZOptions",
      hasDraft: boolean,
      status: STELLASTATUS,
    },
    pointer: string,
    queryLanguage?: string | null,
    social?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    specialties?:  Array< {
      __typename: "Specialty",
      id: string,
      pointer: string,
      designationId: string,
      designation?:  {
        __typename: "StaticObject",
        id: string,
        nameLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        name?: string | null,
        descriptionLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        description?: string | null,
        attribute?:  {
          __typename: "ZFieldValue",
          value?: string | null,
          field?: string | null,
        } | null,
        type?: string | null,
        queryLanguage?: string | null,
        parentId?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      levelId: string,
      catchphraseLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemsIds?: Array< string | null > | null,
      geonews?:  Array< {
        __typename: "DataStruct",
        id: string,
        locationId?: string | null,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        language?: string | null,
        name?: string | null,
        audience?: string | null,
        geonewsExpiryDateTime?: string | null,
        geonewsStartDateTime?: string | null,
        geonewsDuration?: number | null,
        entityId: string,
        sectionLngMap:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        },
        sectionLngId?: string | null,
        sectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refSectionLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        refSectionLngId?: string | null,
        refSectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refInternalLinkLngMap?:  {
          __typename: "ZSectionCompositeMap",
          en?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fr?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        refInternalLinkLng?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fromUserId?: string | null,
        fromUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        toUserId?: string | null,
        toUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        parentId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsIds?: Array< string | null > | null,
      locationId: string,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      parentId: string,
      othersPocUsersIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      pocUserId: string,
      pocUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      sortKey?: string | null,
      specialtyLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      specialtyLngId?: string | null,
      specialtyLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      tagsIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    specialtiesIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    administratedPointers?:  Array< {
      __typename: "ZAdminPointer",
      pointer?: string | null,
      ownershipLevel?: string | null,
    } | null > | null,
    specialtiesPoc?: Array< string | null > | null,
    email: string,
    federation: string,
    registrationId: string,
    power: USERPOWER,
    gender: string,
    name:  {
      __typename: "ZName",
      firstname?: string | null,
      lastname?: string | null,
      nickname: string,
    },
    phoneNumbers?:  Array< {
      __typename: "ZPhoneNumber",
      sortKey?: string | null,
      countryCode: string,
      number: string,
      usage?: string | null,
    } | null > | null,
    preferences:  {
      __typename: "ZUserPreferences",
      visibleEmail: boolean,
      visiblePhones: boolean,
    },
    titleLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    title?: string | null,
    queryLanguage?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    administratedPointers?:  Array< {
      __typename: "ZAdminPointer",
      pointer?: string | null,
      ownershipLevel?: string | null,
    } | null > | null,
    specialtiesPoc?: Array< string | null > | null,
    email: string,
    federation: string,
    registrationId: string,
    power: USERPOWER,
    gender: string,
    name:  {
      __typename: "ZName",
      firstname?: string | null,
      lastname?: string | null,
      nickname: string,
    },
    phoneNumbers?:  Array< {
      __typename: "ZPhoneNumber",
      sortKey?: string | null,
      countryCode: string,
      number: string,
      usage?: string | null,
    } | null > | null,
    preferences:  {
      __typename: "ZUserPreferences",
      visibleEmail: boolean,
      visiblePhones: boolean,
    },
    titleLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    title?: string | null,
    queryLanguage?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    administratedPointers?:  Array< {
      __typename: "ZAdminPointer",
      pointer?: string | null,
      ownershipLevel?: string | null,
    } | null > | null,
    specialtiesPoc?: Array< string | null > | null,
    email: string,
    federation: string,
    registrationId: string,
    power: USERPOWER,
    gender: string,
    name:  {
      __typename: "ZName",
      firstname?: string | null,
      lastname?: string | null,
      nickname: string,
    },
    phoneNumbers?:  Array< {
      __typename: "ZPhoneNumber",
      sortKey?: string | null,
      countryCode: string,
      number: string,
      usage?: string | null,
    } | null > | null,
    preferences:  {
      __typename: "ZUserPreferences",
      visibleEmail: boolean,
      visiblePhones: boolean,
    },
    titleLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    title?: string | null,
    queryLanguage?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type GetPointerByLngQueryVariables = {
  pointer: string,
  language: string,
};

export type GetPointerByLngQuery = {
  getPointerByLng?:  {
    __typename: "Pointer",
    pointer: string,
    queryLanguage?: string | null,
    stellaId?: string | null,
    stella?:  {
      __typename: "Stella",
      id: string,
      bucket?:  {
        __typename: "Bucket",
        id: string,
        web?:  {
          __typename: "ZBucket_Web",
          shortUrl: string,
          isStellactWeb: boolean,
          cloudfrontUrl?: string | null,
          ipAddress?: string | null,
          sslCertificateId?: string | null,
        } | null,
        stellaFolders:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null >,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      bucketId: string,
      entityId: string,
      interfaceLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      interfaceLngId?: string | null,
      interfaceLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      logo?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      mediaList:  Array< {
        __typename: "ZMedia",
        mediaRef: string,
        sizes: string,
      } | null >,
      name: string,
      options:  {
        __typename: "ZOptions",
        hasDraft: boolean,
        status: STELLASTATUS,
      },
      pointer: string,
      queryLanguage?: string | null,
      social?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      specialties?:  Array< {
        __typename: "Specialty",
        id: string,
        pointer: string,
        designationId: string,
        designation?:  {
          __typename: "StaticObject",
          id: string,
          nameLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          name?: string | null,
          descriptionLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          description?: string | null,
          attribute?:  {
            __typename: "ZFieldValue",
            value?: string | null,
            field?: string | null,
          } | null,
          type?: string | null,
          queryLanguage?: string | null,
          parentId?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        levelId: string,
        catchphraseLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        itemsIds?: Array< string | null > | null,
        geonews?:  Array< {
          __typename: "DataStruct",
          id: string,
          locationId?: string | null,
          location?:  {
            __typename: "Location",
            id: string,
            address?:  {
              __typename: "ZAddress",
              municipality?: string | null,
              countryCode?: string | null,
              postCode?: string | null,
              regionCode?: string | null,
              street?: string | null,
            } | null,
            calendar?:  {
              __typename: "ZCalendarMap",
              openingHours?:  {
                __typename: "ZOpeningHoursMap",
                end?: string | null,
                start?: string | null,
              } | null,
              weekly?: string | null,
              yearly?:  {
                __typename: "ZYearlyMap",
                endDate?: string | null,
                startDate?: string | null,
              } | null,
            } | null,
            geolocation?:  {
              __typename: "ZGeolocation",
              latitude?: number | null,
              longitude?: number | null,
            } | null,
            parentId: string,
            municipalityId: string,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          language?: string | null,
          name?: string | null,
          audience?: string | null,
          geonewsExpiryDateTime?: string | null,
          geonewsStartDateTime?: string | null,
          geonewsDuration?: number | null,
          entityId: string,
          sectionLngMap:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          },
          sectionLngId?: string | null,
          sectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refSectionLngMap?:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          } | null,
          refSectionLngId?: string | null,
          refSectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refInternalLinkLngMap?:  {
            __typename: "ZSectionCompositeMap",
            en?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            fr?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          refInternalLinkLng?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fromUserId?: string | null,
          fromUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          toUserId?: string | null,
          toUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          queryLanguage?: string | null,
          parentId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsIds?: Array< string | null > | null,
        locationId: string,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        parentId: string,
        othersPocUsersIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        pocUserId: string,
        pocUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        sortKey?: string | null,
        specialtyLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        specialtyLngId?: string | null,
        specialtyLng?:  {
          __typename: "SpecialtyLng",
          id: string,
          gallery?:  {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null,
          language?: string | null,
          parentId: string,
          type?: string | null,
          itemsTables?:  Array< {
            __typename: "ZItemsTableMap",
            headRow?:  Array< {
              __typename: "ZItemsHeadRowMap",
              sortKey?: string | null,
              string?: string | null,
              type?: string | null,
            } | null > | null,
            itemsIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            sortKey?: string | null,
          } | null > | null,
          indexSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          indexSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          indexPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          geonewsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          geonewsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          geonewsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          gallerySectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          gallerySections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          galleryPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          itemsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          itemsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          itemsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          contactSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          contactSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          contactPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add1SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add1Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add1Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add2SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add2Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add2Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add3SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add3Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add3Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add4SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add4Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add4Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        tagsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      specialtiesIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    geostructId?: string | null,
    geostruct?:  {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type GetUserByEmailQueryVariables = {
  email: string,
};

export type GetUserByEmailQuery = {
  getUserByEmail?:  {
    __typename: "UserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListDataStructsByLngQueryVariables = {
  language: string,
};

export type ListDataStructsByLngQuery = {
  listDataStructsByLng?:  {
    __typename: "DataStructConnection",
    items?:  Array< {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPointersByLngQueryVariables = {
  language: string,
};

export type ListPointersByLngQuery = {
  listPointersByLng?:  {
    __typename: "PointerConnection",
    items?:  Array< {
      __typename: "Pointer",
      pointer: string,
      queryLanguage?: string | null,
      stellaId?: string | null,
      stella?:  {
        __typename: "Stella",
        id: string,
        bucket?:  {
          __typename: "Bucket",
          id: string,
          web?:  {
            __typename: "ZBucket_Web",
            shortUrl: string,
            isStellactWeb: boolean,
            cloudfrontUrl?: string | null,
            ipAddress?: string | null,
            sslCertificateId?: string | null,
          } | null,
          stellaFolders:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null >,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        bucketId: string,
        entityId: string,
        interfaceLng?:  {
          __typename: "SpecialtyLng",
          id: string,
          gallery?:  {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null,
          language?: string | null,
          parentId: string,
          type?: string | null,
          itemsTables?:  Array< {
            __typename: "ZItemsTableMap",
            headRow?:  Array< {
              __typename: "ZItemsHeadRowMap",
              sortKey?: string | null,
              string?: string | null,
              type?: string | null,
            } | null > | null,
            itemsIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            sortKey?: string | null,
          } | null > | null,
          indexSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          indexSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          indexPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          geonewsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          geonewsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          geonewsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          gallerySectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          gallerySections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          galleryPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          itemsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          itemsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          itemsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          contactSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          contactSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          contactPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add1SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add1Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add1Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add2SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add2Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add2Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add3SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add3Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add3Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add4SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add4Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add4Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        interfaceLngId?: string | null,
        interfaceLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        logo?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        mediaList:  Array< {
          __typename: "ZMedia",
          mediaRef: string,
          sizes: string,
        } | null >,
        name: string,
        options:  {
          __typename: "ZOptions",
          hasDraft: boolean,
          status: STELLASTATUS,
        },
        pointer: string,
        queryLanguage?: string | null,
        social?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        specialties?:  Array< {
          __typename: "Specialty",
          id: string,
          pointer: string,
          designationId: string,
          designation?:  {
            __typename: "StaticObject",
            id: string,
            nameLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            name?: string | null,
            descriptionLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            description?: string | null,
            attribute?:  {
              __typename: "ZFieldValue",
              value?: string | null,
              field?: string | null,
            } | null,
            type?: string | null,
            queryLanguage?: string | null,
            parentId?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          levelId: string,
          catchphraseLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          itemsIds?: Array< string | null > | null,
          geonews?:  Array< {
            __typename: "DataStruct",
            id: string,
            locationId?: string | null,
            location?:  {
              __typename: "Location",
              id: string,
              address?:  {
                __typename: "ZAddress",
                municipality?: string | null,
                countryCode?: string | null,
                postCode?: string | null,
                regionCode?: string | null,
                street?: string | null,
              } | null,
              calendar?:  {
                __typename: "ZCalendarMap",
                openingHours?:  {
                  __typename: "ZOpeningHoursMap",
                  end?: string | null,
                  start?: string | null,
                } | null,
                weekly?: string | null,
                yearly?:  {
                  __typename: "ZYearlyMap",
                  endDate?: string | null,
                  startDate?: string | null,
                } | null,
              } | null,
              geolocation?:  {
                __typename: "ZGeolocation",
                latitude?: number | null,
                longitude?: number | null,
              } | null,
              parentId: string,
              municipalityId: string,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            language?: string | null,
            name?: string | null,
            audience?: string | null,
            geonewsExpiryDateTime?: string | null,
            geonewsStartDateTime?: string | null,
            geonewsDuration?: number | null,
            entityId: string,
            sectionLngMap:  {
              __typename: "ZLngMap",
              enId?: string | null,
              frId?: string | null,
            },
            sectionLngId?: string | null,
            sectionLng?:  {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            refSectionLngMap?:  {
              __typename: "ZLngMap",
              enId?: string | null,
              frId?: string | null,
            } | null,
            refSectionLngId?: string | null,
            refSectionLng?:  {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            refInternalLinkLngMap?:  {
              __typename: "ZSectionCompositeMap",
              en?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              fr?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            refInternalLinkLng?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            fromUserId?: string | null,
            fromUser?:  {
              __typename: "User",
              id: string,
              administratedPointers?:  Array< {
                __typename: "ZAdminPointer",
                pointer?: string | null,
                ownershipLevel?: string | null,
              } | null > | null,
              specialtiesPoc?: Array< string | null > | null,
              email: string,
              federation: string,
              registrationId: string,
              power: USERPOWER,
              gender: string,
              name:  {
                __typename: "ZName",
                firstname?: string | null,
                lastname?: string | null,
                nickname: string,
              },
              phoneNumbers?:  Array< {
                __typename: "ZPhoneNumber",
                sortKey?: string | null,
                countryCode: string,
                number: string,
                usage?: string | null,
              } | null > | null,
              preferences:  {
                __typename: "ZUserPreferences",
                visibleEmail: boolean,
                visiblePhones: boolean,
              },
              titleLng?:  {
                __typename: "ZStringLng",
                en?: string | null,
                fr?: string | null,
              } | null,
              title?: string | null,
              queryLanguage?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            toUserId?: string | null,
            toUser?:  {
              __typename: "User",
              id: string,
              administratedPointers?:  Array< {
                __typename: "ZAdminPointer",
                pointer?: string | null,
                ownershipLevel?: string | null,
              } | null > | null,
              specialtiesPoc?: Array< string | null > | null,
              email: string,
              federation: string,
              registrationId: string,
              power: USERPOWER,
              gender: string,
              name:  {
                __typename: "ZName",
                firstname?: string | null,
                lastname?: string | null,
                nickname: string,
              },
              phoneNumbers?:  Array< {
                __typename: "ZPhoneNumber",
                sortKey?: string | null,
                countryCode: string,
                number: string,
                usage?: string | null,
              } | null > | null,
              preferences:  {
                __typename: "ZUserPreferences",
                visibleEmail: boolean,
                visiblePhones: boolean,
              },
              titleLng?:  {
                __typename: "ZStringLng",
                en?: string | null,
                fr?: string | null,
              } | null,
              title?: string | null,
              queryLanguage?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            queryLanguage?: string | null,
            parentId: string,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          geonewsIds?: Array< string | null > | null,
          locationId: string,
          location?:  {
            __typename: "Location",
            id: string,
            address?:  {
              __typename: "ZAddress",
              municipality?: string | null,
              countryCode?: string | null,
              postCode?: string | null,
              regionCode?: string | null,
              street?: string | null,
            } | null,
            calendar?:  {
              __typename: "ZCalendarMap",
              openingHours?:  {
                __typename: "ZOpeningHoursMap",
                end?: string | null,
                start?: string | null,
              } | null,
              weekly?: string | null,
              yearly?:  {
                __typename: "ZYearlyMap",
                endDate?: string | null,
                startDate?: string | null,
              } | null,
            } | null,
            geolocation?:  {
              __typename: "ZGeolocation",
              latitude?: number | null,
              longitude?: number | null,
            } | null,
            parentId: string,
            municipalityId: string,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          parentId: string,
          othersPocUsersIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          pocUserId: string,
          pocUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          queryLanguage?: string | null,
          sortKey?: string | null,
          specialtyLngMap?:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          } | null,
          specialtyLngId?: string | null,
          specialtyLng?:  {
            __typename: "SpecialtyLng",
            id: string,
            gallery?:  {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null,
            language?: string | null,
            parentId: string,
            type?: string | null,
            itemsTables?:  Array< {
              __typename: "ZItemsTableMap",
              headRow?:  Array< {
                __typename: "ZItemsHeadRowMap",
                sortKey?: string | null,
                string?: string | null,
                type?: string | null,
              } | null > | null,
              itemsIds?:  Array< {
                __typename: "ZSortIdMap",
                id?: string | null,
                sortKey?: string | null,
              } | null > | null,
              sortKey?: string | null,
            } | null > | null,
            indexSectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            indexSections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            indexPage?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            geonewsSectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            geonewsSections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            geonewsPage?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            gallerySectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            gallerySections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            galleryPage?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            itemsSectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            itemsSections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            itemsPage?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            contactSectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            contactSections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            contactPage?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            add1SectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            add1Sections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            add1Page?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            add2SectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            add2Sections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            add2Page?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            add3SectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            add3Sections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            add3Page?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            add4SectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            add4Sections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            add4Page?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          tagsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        specialtiesIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      geostructId?: string | null,
      geostruct?:  {
        __typename: "DataStruct",
        id: string,
        locationId?: string | null,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        language?: string | null,
        name?: string | null,
        audience?: string | null,
        geonewsExpiryDateTime?: string | null,
        geonewsStartDateTime?: string | null,
        geonewsDuration?: number | null,
        entityId: string,
        sectionLngMap:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        },
        sectionLngId?: string | null,
        sectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refSectionLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        refSectionLngId?: string | null,
        refSectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refInternalLinkLngMap?:  {
          __typename: "ZSectionCompositeMap",
          en?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fr?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        refInternalLinkLng?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fromUserId?: string | null,
        fromUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        toUserId?: string | null,
        toUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        parentId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListStaticObjectsByTypeByPrefixByLngQueryVariables = {
  type: string,
  prefix?: string | null,
  language?: string | null,
};

export type ListStaticObjectsByTypeByPrefixByLngQuery = {
  listStaticObjectsByTypeByPrefixByLng?:  {
    __typename: "StaticObjectConnection",
    items?:  Array< {
      __typename: "StaticObject",
      id: string,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      name?: string | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      description?: string | null,
      attribute?:  {
        __typename: "ZFieldValue",
        value?: string | null,
        field?: string | null,
      } | null,
      type?: string | null,
      queryLanguage?: string | null,
      parentId?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetBucketQueryVariables = {
  id: string,
};

export type GetBucketQuery = {
  getBucket?:  {
    __typename: "Bucket",
    id: string,
    web?:  {
      __typename: "ZBucket_Web",
      shortUrl: string,
      isStellactWeb: boolean,
      cloudfrontUrl?: string | null,
      ipAddress?: string | null,
      sslCertificateId?: string | null,
    } | null,
    stellaFolders:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListBucketsQueryVariables = {
  filter?: ModelBucketFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBucketsQuery = {
  listBuckets?:  {
    __typename: "ModelBucketConnection",
    items:  Array< {
      __typename: "Bucket",
      id: string,
      web?:  {
        __typename: "ZBucket_Web",
        shortUrl: string,
        isStellactWeb: boolean,
        cloudfrontUrl?: string | null,
        ipAddress?: string | null,
        sslCertificateId?: string | null,
      } | null,
      stellaFolders:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null >,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDataStructQueryVariables = {
  id: string,
};

export type GetDataStructQuery = {
  getDataStruct?:  {
    __typename: "DataStruct",
    id: string,
    locationId?: string | null,
    location?:  {
      __typename: "Location",
      id: string,
      address?:  {
        __typename: "ZAddress",
        municipality?: string | null,
        countryCode?: string | null,
        postCode?: string | null,
        regionCode?: string | null,
        street?: string | null,
      } | null,
      calendar?:  {
        __typename: "ZCalendarMap",
        openingHours?:  {
          __typename: "ZOpeningHoursMap",
          end?: string | null,
          start?: string | null,
        } | null,
        weekly?: string | null,
        yearly?:  {
          __typename: "ZYearlyMap",
          endDate?: string | null,
          startDate?: string | null,
        } | null,
      } | null,
      geolocation?:  {
        __typename: "ZGeolocation",
        latitude?: number | null,
        longitude?: number | null,
      } | null,
      parentId: string,
      municipalityId: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    language?: string | null,
    name?: string | null,
    audience?: string | null,
    geonewsExpiryDateTime?: string | null,
    geonewsStartDateTime?: string | null,
    geonewsDuration?: number | null,
    entityId: string,
    sectionLngMap:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    },
    sectionLngId?: string | null,
    sectionLng?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refSectionLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    refSectionLngId?: string | null,
    refSectionLng?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refInternalLinkLngMap?:  {
      __typename: "ZSectionCompositeMap",
      en?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fr?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    refInternalLinkLng?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    fromUserId?: string | null,
    fromUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    toUserId?: string | null,
    toUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    queryLanguage?: string | null,
    parentId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListDataStructsQueryVariables = {
  filter?: ModelDataStructFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDataStructsQuery = {
  listDataStructs?:  {
    __typename: "ModelDataStructConnection",
    items:  Array< {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemQueryVariables = {
  id: string,
};

export type GetItemQuery = {
  getItem?:  {
    __typename: "Item",
    id: string,
    domainId: string,
    isOrion: boolean,
    nameLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    itemLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    descriptionLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    price?:  {
      __typename: "ZPriceMap",
      amount?: number | null,
      currency?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListItemsQueryVariables = {
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListItemsQuery = {
  listItems?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      domainId: string,
      isOrion: boolean,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      price?:  {
        __typename: "ZPriceMap",
        amount?: number | null,
        currency?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemLngQueryVariables = {
  id: string,
};

export type GetItemLngQuery = {
  getItemLng?:  {
    __typename: "ItemLng",
    id: string,
    language?: string | null,
    parentId: string,
    item?:  {
      __typename: "Item",
      id: string,
      domainId: string,
      isOrion: boolean,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      price?:  {
        __typename: "ZPriceMap",
        amount?: number | null,
        currency?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    columnsMap?:  Array< {
      __typename: "ZColumnMap",
      columnKey?: string | null,
      sectionId?: string | null,
    } | null > | null,
    columnsContentSortIds?: Array< string | null > | null,
    columnsContent?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    refSectionId?: string | null,
    refSection?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refInternalLink?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListItemLngsQueryVariables = {
  filter?: ModelItemLngFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListItemLngsQuery = {
  listItemLngs?:  {
    __typename: "ModelItemLngConnection",
    items:  Array< {
      __typename: "ItemLng",
      id: string,
      language?: string | null,
      parentId: string,
      item?:  {
        __typename: "Item",
        id: string,
        domainId: string,
        isOrion: boolean,
        nameLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        itemLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        descriptionLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        price?:  {
          __typename: "ZPriceMap",
          amount?: number | null,
          currency?: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      columnsMap?:  Array< {
        __typename: "ZColumnMap",
        columnKey?: string | null,
        sectionId?: string | null,
      } | null > | null,
      columnsContentSortIds?: Array< string | null > | null,
      columnsContent?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      refSectionId?: string | null,
      refSection?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLink?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLocationQueryVariables = {
  id: string,
};

export type GetLocationQuery = {
  getLocation?:  {
    __typename: "Location",
    id: string,
    address?:  {
      __typename: "ZAddress",
      municipality?: string | null,
      countryCode?: string | null,
      postCode?: string | null,
      regionCode?: string | null,
      street?: string | null,
    } | null,
    calendar?:  {
      __typename: "ZCalendarMap",
      openingHours?:  {
        __typename: "ZOpeningHoursMap",
        end?: string | null,
        start?: string | null,
      } | null,
      weekly?: string | null,
      yearly?:  {
        __typename: "ZYearlyMap",
        endDate?: string | null,
        startDate?: string | null,
      } | null,
    } | null,
    geolocation?:  {
      __typename: "ZGeolocation",
      latitude?: number | null,
      longitude?: number | null,
    } | null,
    parentId: string,
    municipalityId: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListLocationsQueryVariables = {
  filter?: ModelLocationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLocationsQuery = {
  listLocations?:  {
    __typename: "ModelLocationConnection",
    items:  Array< {
      __typename: "Location",
      id: string,
      address?:  {
        __typename: "ZAddress",
        municipality?: string | null,
        countryCode?: string | null,
        postCode?: string | null,
        regionCode?: string | null,
        street?: string | null,
      } | null,
      calendar?:  {
        __typename: "ZCalendarMap",
        openingHours?:  {
          __typename: "ZOpeningHoursMap",
          end?: string | null,
          start?: string | null,
        } | null,
        weekly?: string | null,
        yearly?:  {
          __typename: "ZYearlyMap",
          endDate?: string | null,
          startDate?: string | null,
        } | null,
      } | null,
      geolocation?:  {
        __typename: "ZGeolocation",
        latitude?: number | null,
        longitude?: number | null,
      } | null,
      parentId: string,
      municipalityId: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPointerQueryVariables = {
  pointer: string,
};

export type GetPointerQuery = {
  getPointer?:  {
    __typename: "Pointer",
    pointer: string,
    queryLanguage?: string | null,
    stellaId?: string | null,
    stella?:  {
      __typename: "Stella",
      id: string,
      bucket?:  {
        __typename: "Bucket",
        id: string,
        web?:  {
          __typename: "ZBucket_Web",
          shortUrl: string,
          isStellactWeb: boolean,
          cloudfrontUrl?: string | null,
          ipAddress?: string | null,
          sslCertificateId?: string | null,
        } | null,
        stellaFolders:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null >,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      bucketId: string,
      entityId: string,
      interfaceLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      interfaceLngId?: string | null,
      interfaceLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      logo?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      mediaList:  Array< {
        __typename: "ZMedia",
        mediaRef: string,
        sizes: string,
      } | null >,
      name: string,
      options:  {
        __typename: "ZOptions",
        hasDraft: boolean,
        status: STELLASTATUS,
      },
      pointer: string,
      queryLanguage?: string | null,
      social?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      specialties?:  Array< {
        __typename: "Specialty",
        id: string,
        pointer: string,
        designationId: string,
        designation?:  {
          __typename: "StaticObject",
          id: string,
          nameLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          name?: string | null,
          descriptionLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          description?: string | null,
          attribute?:  {
            __typename: "ZFieldValue",
            value?: string | null,
            field?: string | null,
          } | null,
          type?: string | null,
          queryLanguage?: string | null,
          parentId?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        levelId: string,
        catchphraseLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        itemsIds?: Array< string | null > | null,
        geonews?:  Array< {
          __typename: "DataStruct",
          id: string,
          locationId?: string | null,
          location?:  {
            __typename: "Location",
            id: string,
            address?:  {
              __typename: "ZAddress",
              municipality?: string | null,
              countryCode?: string | null,
              postCode?: string | null,
              regionCode?: string | null,
              street?: string | null,
            } | null,
            calendar?:  {
              __typename: "ZCalendarMap",
              openingHours?:  {
                __typename: "ZOpeningHoursMap",
                end?: string | null,
                start?: string | null,
              } | null,
              weekly?: string | null,
              yearly?:  {
                __typename: "ZYearlyMap",
                endDate?: string | null,
                startDate?: string | null,
              } | null,
            } | null,
            geolocation?:  {
              __typename: "ZGeolocation",
              latitude?: number | null,
              longitude?: number | null,
            } | null,
            parentId: string,
            municipalityId: string,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          language?: string | null,
          name?: string | null,
          audience?: string | null,
          geonewsExpiryDateTime?: string | null,
          geonewsStartDateTime?: string | null,
          geonewsDuration?: number | null,
          entityId: string,
          sectionLngMap:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          },
          sectionLngId?: string | null,
          sectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refSectionLngMap?:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          } | null,
          refSectionLngId?: string | null,
          refSectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refInternalLinkLngMap?:  {
            __typename: "ZSectionCompositeMap",
            en?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            fr?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          refInternalLinkLng?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fromUserId?: string | null,
          fromUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          toUserId?: string | null,
          toUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          queryLanguage?: string | null,
          parentId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsIds?: Array< string | null > | null,
        locationId: string,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        parentId: string,
        othersPocUsersIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        pocUserId: string,
        pocUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        sortKey?: string | null,
        specialtyLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        specialtyLngId?: string | null,
        specialtyLng?:  {
          __typename: "SpecialtyLng",
          id: string,
          gallery?:  {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null,
          language?: string | null,
          parentId: string,
          type?: string | null,
          itemsTables?:  Array< {
            __typename: "ZItemsTableMap",
            headRow?:  Array< {
              __typename: "ZItemsHeadRowMap",
              sortKey?: string | null,
              string?: string | null,
              type?: string | null,
            } | null > | null,
            itemsIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            sortKey?: string | null,
          } | null > | null,
          indexSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          indexSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          indexPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          geonewsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          geonewsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          geonewsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          gallerySectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          gallerySections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          galleryPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          itemsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          itemsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          itemsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          contactSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          contactSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          contactPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add1SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add1Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add1Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add2SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add2Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add2Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add3SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add3Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add3Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add4SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add4Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add4Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        tagsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      specialtiesIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    geostructId?: string | null,
    geostruct?:  {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListPointersQueryVariables = {
  pointer?: string | null,
  filter?: ModelPointerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPointersQuery = {
  listPointers?:  {
    __typename: "ModelPointerConnection",
    items:  Array< {
      __typename: "Pointer",
      pointer: string,
      queryLanguage?: string | null,
      stellaId?: string | null,
      stella?:  {
        __typename: "Stella",
        id: string,
        bucket?:  {
          __typename: "Bucket",
          id: string,
          web?:  {
            __typename: "ZBucket_Web",
            shortUrl: string,
            isStellactWeb: boolean,
            cloudfrontUrl?: string | null,
            ipAddress?: string | null,
            sslCertificateId?: string | null,
          } | null,
          stellaFolders:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null >,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        bucketId: string,
        entityId: string,
        interfaceLng?:  {
          __typename: "SpecialtyLng",
          id: string,
          gallery?:  {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null,
          language?: string | null,
          parentId: string,
          type?: string | null,
          itemsTables?:  Array< {
            __typename: "ZItemsTableMap",
            headRow?:  Array< {
              __typename: "ZItemsHeadRowMap",
              sortKey?: string | null,
              string?: string | null,
              type?: string | null,
            } | null > | null,
            itemsIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            sortKey?: string | null,
          } | null > | null,
          indexSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          indexSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          indexPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          geonewsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          geonewsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          geonewsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          gallerySectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          gallerySections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          galleryPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          itemsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          itemsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          itemsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          contactSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          contactSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          contactPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add1SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add1Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add1Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add2SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add2Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add2Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add3SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add3Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add3Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add4SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add4Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add4Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        interfaceLngId?: string | null,
        interfaceLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        logo?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        mediaList:  Array< {
          __typename: "ZMedia",
          mediaRef: string,
          sizes: string,
        } | null >,
        name: string,
        options:  {
          __typename: "ZOptions",
          hasDraft: boolean,
          status: STELLASTATUS,
        },
        pointer: string,
        queryLanguage?: string | null,
        social?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        specialties?:  Array< {
          __typename: "Specialty",
          id: string,
          pointer: string,
          designationId: string,
          designation?:  {
            __typename: "StaticObject",
            id: string,
            nameLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            name?: string | null,
            descriptionLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            description?: string | null,
            attribute?:  {
              __typename: "ZFieldValue",
              value?: string | null,
              field?: string | null,
            } | null,
            type?: string | null,
            queryLanguage?: string | null,
            parentId?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          levelId: string,
          catchphraseLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          itemsIds?: Array< string | null > | null,
          geonews?:  Array< {
            __typename: "DataStruct",
            id: string,
            locationId?: string | null,
            location?:  {
              __typename: "Location",
              id: string,
              address?:  {
                __typename: "ZAddress",
                municipality?: string | null,
                countryCode?: string | null,
                postCode?: string | null,
                regionCode?: string | null,
                street?: string | null,
              } | null,
              calendar?:  {
                __typename: "ZCalendarMap",
                openingHours?:  {
                  __typename: "ZOpeningHoursMap",
                  end?: string | null,
                  start?: string | null,
                } | null,
                weekly?: string | null,
                yearly?:  {
                  __typename: "ZYearlyMap",
                  endDate?: string | null,
                  startDate?: string | null,
                } | null,
              } | null,
              geolocation?:  {
                __typename: "ZGeolocation",
                latitude?: number | null,
                longitude?: number | null,
              } | null,
              parentId: string,
              municipalityId: string,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            language?: string | null,
            name?: string | null,
            audience?: string | null,
            geonewsExpiryDateTime?: string | null,
            geonewsStartDateTime?: string | null,
            geonewsDuration?: number | null,
            entityId: string,
            sectionLngMap:  {
              __typename: "ZLngMap",
              enId?: string | null,
              frId?: string | null,
            },
            sectionLngId?: string | null,
            sectionLng?:  {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            refSectionLngMap?:  {
              __typename: "ZLngMap",
              enId?: string | null,
              frId?: string | null,
            } | null,
            refSectionLngId?: string | null,
            refSectionLng?:  {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            refInternalLinkLngMap?:  {
              __typename: "ZSectionCompositeMap",
              en?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              fr?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            refInternalLinkLng?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            fromUserId?: string | null,
            fromUser?:  {
              __typename: "User",
              id: string,
              administratedPointers?:  Array< {
                __typename: "ZAdminPointer",
                pointer?: string | null,
                ownershipLevel?: string | null,
              } | null > | null,
              specialtiesPoc?: Array< string | null > | null,
              email: string,
              federation: string,
              registrationId: string,
              power: USERPOWER,
              gender: string,
              name:  {
                __typename: "ZName",
                firstname?: string | null,
                lastname?: string | null,
                nickname: string,
              },
              phoneNumbers?:  Array< {
                __typename: "ZPhoneNumber",
                sortKey?: string | null,
                countryCode: string,
                number: string,
                usage?: string | null,
              } | null > | null,
              preferences:  {
                __typename: "ZUserPreferences",
                visibleEmail: boolean,
                visiblePhones: boolean,
              },
              titleLng?:  {
                __typename: "ZStringLng",
                en?: string | null,
                fr?: string | null,
              } | null,
              title?: string | null,
              queryLanguage?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            toUserId?: string | null,
            toUser?:  {
              __typename: "User",
              id: string,
              administratedPointers?:  Array< {
                __typename: "ZAdminPointer",
                pointer?: string | null,
                ownershipLevel?: string | null,
              } | null > | null,
              specialtiesPoc?: Array< string | null > | null,
              email: string,
              federation: string,
              registrationId: string,
              power: USERPOWER,
              gender: string,
              name:  {
                __typename: "ZName",
                firstname?: string | null,
                lastname?: string | null,
                nickname: string,
              },
              phoneNumbers?:  Array< {
                __typename: "ZPhoneNumber",
                sortKey?: string | null,
                countryCode: string,
                number: string,
                usage?: string | null,
              } | null > | null,
              preferences:  {
                __typename: "ZUserPreferences",
                visibleEmail: boolean,
                visiblePhones: boolean,
              },
              titleLng?:  {
                __typename: "ZStringLng",
                en?: string | null,
                fr?: string | null,
              } | null,
              title?: string | null,
              queryLanguage?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null,
            queryLanguage?: string | null,
            parentId: string,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          geonewsIds?: Array< string | null > | null,
          locationId: string,
          location?:  {
            __typename: "Location",
            id: string,
            address?:  {
              __typename: "ZAddress",
              municipality?: string | null,
              countryCode?: string | null,
              postCode?: string | null,
              regionCode?: string | null,
              street?: string | null,
            } | null,
            calendar?:  {
              __typename: "ZCalendarMap",
              openingHours?:  {
                __typename: "ZOpeningHoursMap",
                end?: string | null,
                start?: string | null,
              } | null,
              weekly?: string | null,
              yearly?:  {
                __typename: "ZYearlyMap",
                endDate?: string | null,
                startDate?: string | null,
              } | null,
            } | null,
            geolocation?:  {
              __typename: "ZGeolocation",
              latitude?: number | null,
              longitude?: number | null,
            } | null,
            parentId: string,
            municipalityId: string,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          parentId: string,
          othersPocUsersIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          pocUserId: string,
          pocUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          queryLanguage?: string | null,
          sortKey?: string | null,
          specialtyLngMap?:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          } | null,
          specialtyLngId?: string | null,
          specialtyLng?:  {
            __typename: "SpecialtyLng",
            id: string,
            gallery?:  {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null,
            language?: string | null,
            parentId: string,
            type?: string | null,
            itemsTables?:  Array< {
              __typename: "ZItemsTableMap",
              headRow?:  Array< {
                __typename: "ZItemsHeadRowMap",
                sortKey?: string | null,
                string?: string | null,
                type?: string | null,
              } | null > | null,
              itemsIds?:  Array< {
                __typename: "ZSortIdMap",
                id?: string | null,
                sortKey?: string | null,
              } | null > | null,
              sortKey?: string | null,
            } | null > | null,
            indexSectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            indexSections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            indexPage?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            geonewsSectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            geonewsSections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            geonewsPage?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            gallerySectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            gallerySections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            galleryPage?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            itemsSectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            itemsSections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            itemsPage?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            contactSectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            contactSections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            contactPage?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            add1SectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            add1Sections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            add1Page?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            add2SectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            add2Sections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            add2Page?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            add3SectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            add3Sections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            add3Page?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            add4SectionsSortIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            add4Sections?:  Array< {
              __typename: "Section",
              id: string,
              parentId: string,
              bucketId?: string | null,
              flexbox?:  {
                __typename: "ZFlexbox",
                columns?: number | null,
                totalColumns?: number | null,
              } | null,
              elements?:  Array< {
                __typename: "ZSectionElement",
                firstComposite:  {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                },
                nextComposites?:  Array< {
                  __typename: "ZSectionComposite",
                  formatRanges?:  Array< {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null > | null,
                  ref?:  {
                    __typename: "ZReference",
                    linkTarget?: string | null,
                    format?: string | null,
                    refId?: string | null,
                    sizes?:  {
                      __typename: "ZSize",
                      ll?: boolean | null,
                      mm?: boolean | null,
                      ss?: boolean | null,
                    } | null,
                    formatRange?:  {
                      __typename: "ZFormatRange",
                      offset: number,
                      length: number,
                      style: string,
                    } | null,
                    type: string,
                  } | null,
                  size?: string | null,
                  sortKey?: string | null,
                  text: string,
                } | null > | null,
                sortKey: string,
                type: string,
              } | null > | null,
              title?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              language?: string | null,
              type?: string | null,
              createdAt: string,
              updatedAt: string,
              version: number,
            } | null > | null,
            add4Page?:  {
              __typename: "ZPage",
              pageTitle?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
              layout?: string | null,
              menu?:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null,
            } | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          tagsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        specialtiesIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      geostructId?: string | null,
      geostruct?:  {
        __typename: "DataStruct",
        id: string,
        locationId?: string | null,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        language?: string | null,
        name?: string | null,
        audience?: string | null,
        geonewsExpiryDateTime?: string | null,
        geonewsStartDateTime?: string | null,
        geonewsDuration?: number | null,
        entityId: string,
        sectionLngMap:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        },
        sectionLngId?: string | null,
        sectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refSectionLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        refSectionLngId?: string | null,
        refSectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refInternalLinkLngMap?:  {
          __typename: "ZSectionCompositeMap",
          en?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fr?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        refInternalLinkLng?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fromUserId?: string | null,
        fromUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        toUserId?: string | null,
        toUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        parentId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSectionQueryVariables = {
  id: string,
};

export type GetSectionQuery = {
  getSection?:  {
    __typename: "Section",
    id: string,
    parentId: string,
    bucketId?: string | null,
    flexbox?:  {
      __typename: "ZFlexbox",
      columns?: number | null,
      totalColumns?: number | null,
    } | null,
    elements?:  Array< {
      __typename: "ZSectionElement",
      firstComposite:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      },
      nextComposites?:  Array< {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null > | null,
      sortKey: string,
      type: string,
    } | null > | null,
    title?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    language?: string | null,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListSectionsQueryVariables = {
  filter?: ModelSectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSectionsQuery = {
  listSections?:  {
    __typename: "ModelSectionConnection",
    items:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSpecialtyQueryVariables = {
  id: string,
};

export type GetSpecialtyQuery = {
  getSpecialty?:  {
    __typename: "Specialty",
    id: string,
    pointer: string,
    designationId: string,
    designation?:  {
      __typename: "StaticObject",
      id: string,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      name?: string | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      description?: string | null,
      attribute?:  {
        __typename: "ZFieldValue",
        value?: string | null,
        field?: string | null,
      } | null,
      type?: string | null,
      queryLanguage?: string | null,
      parentId?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    levelId: string,
    catchphraseLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    itemsIds?: Array< string | null > | null,
    geonews?:  Array< {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    geonewsIds?: Array< string | null > | null,
    locationId: string,
    location?:  {
      __typename: "Location",
      id: string,
      address?:  {
        __typename: "ZAddress",
        municipality?: string | null,
        countryCode?: string | null,
        postCode?: string | null,
        regionCode?: string | null,
        street?: string | null,
      } | null,
      calendar?:  {
        __typename: "ZCalendarMap",
        openingHours?:  {
          __typename: "ZOpeningHoursMap",
          end?: string | null,
          start?: string | null,
        } | null,
        weekly?: string | null,
        yearly?:  {
          __typename: "ZYearlyMap",
          endDate?: string | null,
          startDate?: string | null,
        } | null,
      } | null,
      geolocation?:  {
        __typename: "ZGeolocation",
        latitude?: number | null,
        longitude?: number | null,
      } | null,
      parentId: string,
      municipalityId: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    parentId: string,
    othersPocUsersIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    pocUserId: string,
    pocUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    queryLanguage?: string | null,
    sortKey?: string | null,
    specialtyLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    specialtyLngId?: string | null,
    specialtyLng?:  {
      __typename: "SpecialtyLng",
      id: string,
      gallery?:  {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null,
      language?: string | null,
      parentId: string,
      type?: string | null,
      itemsTables?:  Array< {
        __typename: "ZItemsTableMap",
        headRow?:  Array< {
          __typename: "ZItemsHeadRowMap",
          sortKey?: string | null,
          string?: string | null,
          type?: string | null,
        } | null > | null,
        itemsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        sortKey?: string | null,
      } | null > | null,
      indexSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      indexSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      indexPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      geonewsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      geonewsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      gallerySectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      gallerySections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      galleryPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      itemsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      itemsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      itemsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      contactSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      contactSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      contactPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add1SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add1Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add1Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add2SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add2Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add2Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add3SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add3Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add3Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add4SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add4Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add4Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    tagsIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListSpecialtysQueryVariables = {
  filter?: ModelSpecialtyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpecialtysQuery = {
  listSpecialtys?:  {
    __typename: "ModelSpecialtyConnection",
    items:  Array< {
      __typename: "Specialty",
      id: string,
      pointer: string,
      designationId: string,
      designation?:  {
        __typename: "StaticObject",
        id: string,
        nameLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        name?: string | null,
        descriptionLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        description?: string | null,
        attribute?:  {
          __typename: "ZFieldValue",
          value?: string | null,
          field?: string | null,
        } | null,
        type?: string | null,
        queryLanguage?: string | null,
        parentId?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      levelId: string,
      catchphraseLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemsIds?: Array< string | null > | null,
      geonews?:  Array< {
        __typename: "DataStruct",
        id: string,
        locationId?: string | null,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        language?: string | null,
        name?: string | null,
        audience?: string | null,
        geonewsExpiryDateTime?: string | null,
        geonewsStartDateTime?: string | null,
        geonewsDuration?: number | null,
        entityId: string,
        sectionLngMap:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        },
        sectionLngId?: string | null,
        sectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refSectionLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        refSectionLngId?: string | null,
        refSectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refInternalLinkLngMap?:  {
          __typename: "ZSectionCompositeMap",
          en?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fr?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        refInternalLinkLng?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fromUserId?: string | null,
        fromUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        toUserId?: string | null,
        toUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        parentId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsIds?: Array< string | null > | null,
      locationId: string,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      parentId: string,
      othersPocUsersIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      pocUserId: string,
      pocUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      sortKey?: string | null,
      specialtyLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      specialtyLngId?: string | null,
      specialtyLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      tagsIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSpecialtyLngQueryVariables = {
  id: string,
};

export type GetSpecialtyLngQuery = {
  getSpecialtyLng?:  {
    __typename: "SpecialtyLng",
    id: string,
    gallery?:  {
      __typename: "ZSectionElement",
      firstComposite:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      },
      nextComposites?:  Array< {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null > | null,
      sortKey: string,
      type: string,
    } | null,
    language?: string | null,
    parentId: string,
    type?: string | null,
    itemsTables?:  Array< {
      __typename: "ZItemsTableMap",
      headRow?:  Array< {
        __typename: "ZItemsHeadRowMap",
        sortKey?: string | null,
        string?: string | null,
        type?: string | null,
      } | null > | null,
      itemsIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      sortKey?: string | null,
    } | null > | null,
    indexSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    indexSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    indexPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    geonewsSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    geonewsSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    geonewsPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    gallerySectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    gallerySections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    galleryPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    itemsSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    itemsSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    itemsPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    contactSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    contactSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    contactPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add1SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add1Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add1Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add2SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add2Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add2Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add3SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add3Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add3Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add4SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add4Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add4Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListSpecialtyLngsQueryVariables = {
  filter?: ModelSpecialtyLngFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpecialtyLngsQuery = {
  listSpecialtyLngs?:  {
    __typename: "ModelSpecialtyLngConnection",
    items:  Array< {
      __typename: "SpecialtyLng",
      id: string,
      gallery?:  {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null,
      language?: string | null,
      parentId: string,
      type?: string | null,
      itemsTables?:  Array< {
        __typename: "ZItemsTableMap",
        headRow?:  Array< {
          __typename: "ZItemsHeadRowMap",
          sortKey?: string | null,
          string?: string | null,
          type?: string | null,
        } | null > | null,
        itemsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        sortKey?: string | null,
      } | null > | null,
      indexSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      indexSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      indexPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      geonewsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      geonewsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      gallerySectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      gallerySections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      galleryPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      itemsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      itemsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      itemsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      contactSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      contactSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      contactPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add1SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add1Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add1Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add2SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add2Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add2Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add3SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add3Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add3Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add4SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add4Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add4Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStaticObjectQueryVariables = {
  id: string,
};

export type GetStaticObjectQuery = {
  getStaticObject?:  {
    __typename: "StaticObject",
    id: string,
    nameLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    name?: string | null,
    descriptionLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    description?: string | null,
    attribute?:  {
      __typename: "ZFieldValue",
      value?: string | null,
      field?: string | null,
    } | null,
    type?: string | null,
    queryLanguage?: string | null,
    parentId?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListStaticObjectsQueryVariables = {
  filter?: ModelStaticObjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStaticObjectsQuery = {
  listStaticObjects?:  {
    __typename: "ModelStaticObjectConnection",
    items:  Array< {
      __typename: "StaticObject",
      id: string,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      name?: string | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      description?: string | null,
      attribute?:  {
        __typename: "ZFieldValue",
        value?: string | null,
        field?: string | null,
      } | null,
      type?: string | null,
      queryLanguage?: string | null,
      parentId?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStellaQueryVariables = {
  id: string,
};

export type GetStellaQuery = {
  getStella?:  {
    __typename: "Stella",
    id: string,
    bucket?:  {
      __typename: "Bucket",
      id: string,
      web?:  {
        __typename: "ZBucket_Web",
        shortUrl: string,
        isStellactWeb: boolean,
        cloudfrontUrl?: string | null,
        ipAddress?: string | null,
        sslCertificateId?: string | null,
      } | null,
      stellaFolders:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null >,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    bucketId: string,
    entityId: string,
    interfaceLng?:  {
      __typename: "SpecialtyLng",
      id: string,
      gallery?:  {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null,
      language?: string | null,
      parentId: string,
      type?: string | null,
      itemsTables?:  Array< {
        __typename: "ZItemsTableMap",
        headRow?:  Array< {
          __typename: "ZItemsHeadRowMap",
          sortKey?: string | null,
          string?: string | null,
          type?: string | null,
        } | null > | null,
        itemsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        sortKey?: string | null,
      } | null > | null,
      indexSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      indexSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      indexPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      geonewsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      geonewsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      gallerySectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      gallerySections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      galleryPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      itemsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      itemsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      itemsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      contactSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      contactSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      contactPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add1SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add1Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add1Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add2SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add2Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add2Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add3SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add3Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add3Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add4SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add4Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add4Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    interfaceLngId?: string | null,
    interfaceLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    logo?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    mediaList:  Array< {
      __typename: "ZMedia",
      mediaRef: string,
      sizes: string,
    } | null >,
    name: string,
    options:  {
      __typename: "ZOptions",
      hasDraft: boolean,
      status: STELLASTATUS,
    },
    pointer: string,
    queryLanguage?: string | null,
    social?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    specialties?:  Array< {
      __typename: "Specialty",
      id: string,
      pointer: string,
      designationId: string,
      designation?:  {
        __typename: "StaticObject",
        id: string,
        nameLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        name?: string | null,
        descriptionLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        description?: string | null,
        attribute?:  {
          __typename: "ZFieldValue",
          value?: string | null,
          field?: string | null,
        } | null,
        type?: string | null,
        queryLanguage?: string | null,
        parentId?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      levelId: string,
      catchphraseLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemsIds?: Array< string | null > | null,
      geonews?:  Array< {
        __typename: "DataStruct",
        id: string,
        locationId?: string | null,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        language?: string | null,
        name?: string | null,
        audience?: string | null,
        geonewsExpiryDateTime?: string | null,
        geonewsStartDateTime?: string | null,
        geonewsDuration?: number | null,
        entityId: string,
        sectionLngMap:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        },
        sectionLngId?: string | null,
        sectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refSectionLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        refSectionLngId?: string | null,
        refSectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refInternalLinkLngMap?:  {
          __typename: "ZSectionCompositeMap",
          en?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fr?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        refInternalLinkLng?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fromUserId?: string | null,
        fromUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        toUserId?: string | null,
        toUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        parentId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsIds?: Array< string | null > | null,
      locationId: string,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      parentId: string,
      othersPocUsersIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      pocUserId: string,
      pocUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      sortKey?: string | null,
      specialtyLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      specialtyLngId?: string | null,
      specialtyLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      tagsIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    specialtiesIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListStellasQueryVariables = {
  filter?: ModelStellaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStellasQuery = {
  listStellas?:  {
    __typename: "ModelStellaConnection",
    items:  Array< {
      __typename: "Stella",
      id: string,
      bucket?:  {
        __typename: "Bucket",
        id: string,
        web?:  {
          __typename: "ZBucket_Web",
          shortUrl: string,
          isStellactWeb: boolean,
          cloudfrontUrl?: string | null,
          ipAddress?: string | null,
          sslCertificateId?: string | null,
        } | null,
        stellaFolders:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null >,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      bucketId: string,
      entityId: string,
      interfaceLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      interfaceLngId?: string | null,
      interfaceLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      logo?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      mediaList:  Array< {
        __typename: "ZMedia",
        mediaRef: string,
        sizes: string,
      } | null >,
      name: string,
      options:  {
        __typename: "ZOptions",
        hasDraft: boolean,
        status: STELLASTATUS,
      },
      pointer: string,
      queryLanguage?: string | null,
      social?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      specialties?:  Array< {
        __typename: "Specialty",
        id: string,
        pointer: string,
        designationId: string,
        designation?:  {
          __typename: "StaticObject",
          id: string,
          nameLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          name?: string | null,
          descriptionLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          description?: string | null,
          attribute?:  {
            __typename: "ZFieldValue",
            value?: string | null,
            field?: string | null,
          } | null,
          type?: string | null,
          queryLanguage?: string | null,
          parentId?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        levelId: string,
        catchphraseLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        itemsIds?: Array< string | null > | null,
        geonews?:  Array< {
          __typename: "DataStruct",
          id: string,
          locationId?: string | null,
          location?:  {
            __typename: "Location",
            id: string,
            address?:  {
              __typename: "ZAddress",
              municipality?: string | null,
              countryCode?: string | null,
              postCode?: string | null,
              regionCode?: string | null,
              street?: string | null,
            } | null,
            calendar?:  {
              __typename: "ZCalendarMap",
              openingHours?:  {
                __typename: "ZOpeningHoursMap",
                end?: string | null,
                start?: string | null,
              } | null,
              weekly?: string | null,
              yearly?:  {
                __typename: "ZYearlyMap",
                endDate?: string | null,
                startDate?: string | null,
              } | null,
            } | null,
            geolocation?:  {
              __typename: "ZGeolocation",
              latitude?: number | null,
              longitude?: number | null,
            } | null,
            parentId: string,
            municipalityId: string,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          language?: string | null,
          name?: string | null,
          audience?: string | null,
          geonewsExpiryDateTime?: string | null,
          geonewsStartDateTime?: string | null,
          geonewsDuration?: number | null,
          entityId: string,
          sectionLngMap:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          },
          sectionLngId?: string | null,
          sectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refSectionLngMap?:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          } | null,
          refSectionLngId?: string | null,
          refSectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refInternalLinkLngMap?:  {
            __typename: "ZSectionCompositeMap",
            en?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            fr?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          refInternalLinkLng?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fromUserId?: string | null,
          fromUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          toUserId?: string | null,
          toUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          queryLanguage?: string | null,
          parentId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsIds?: Array< string | null > | null,
        locationId: string,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        parentId: string,
        othersPocUsersIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        pocUserId: string,
        pocUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        sortKey?: string | null,
        specialtyLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        specialtyLngId?: string | null,
        specialtyLng?:  {
          __typename: "SpecialtyLng",
          id: string,
          gallery?:  {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null,
          language?: string | null,
          parentId: string,
          type?: string | null,
          itemsTables?:  Array< {
            __typename: "ZItemsTableMap",
            headRow?:  Array< {
              __typename: "ZItemsHeadRowMap",
              sortKey?: string | null,
              string?: string | null,
              type?: string | null,
            } | null > | null,
            itemsIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            sortKey?: string | null,
          } | null > | null,
          indexSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          indexSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          indexPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          geonewsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          geonewsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          geonewsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          gallerySectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          gallerySections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          galleryPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          itemsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          itemsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          itemsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          contactSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          contactSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          contactPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add1SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add1Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add1Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add2SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add2Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add2Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add3SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add3Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add3Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add4SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add4Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add4Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        tagsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      specialtiesIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    administratedPointers?:  Array< {
      __typename: "ZAdminPointer",
      pointer?: string | null,
      ownershipLevel?: string | null,
    } | null > | null,
    specialtiesPoc?: Array< string | null > | null,
    email: string,
    federation: string,
    registrationId: string,
    power: USERPOWER,
    gender: string,
    name:  {
      __typename: "ZName",
      firstname?: string | null,
      lastname?: string | null,
      nickname: string,
    },
    phoneNumbers?:  Array< {
      __typename: "ZPhoneNumber",
      sortKey?: string | null,
      countryCode: string,
      number: string,
      usage?: string | null,
    } | null > | null,
    preferences:  {
      __typename: "ZUserPreferences",
      visibleEmail: boolean,
      visiblePhones: boolean,
    },
    titleLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    title?: string | null,
    queryLanguage?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateBucketSubscription = {
  onCreateBucket?:  {
    __typename: "Bucket",
    id: string,
    web?:  {
      __typename: "ZBucket_Web",
      shortUrl: string,
      isStellactWeb: boolean,
      cloudfrontUrl?: string | null,
      ipAddress?: string | null,
      sslCertificateId?: string | null,
    } | null,
    stellaFolders:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateBucketSubscription = {
  onUpdateBucket?:  {
    __typename: "Bucket",
    id: string,
    web?:  {
      __typename: "ZBucket_Web",
      shortUrl: string,
      isStellactWeb: boolean,
      cloudfrontUrl?: string | null,
      ipAddress?: string | null,
      sslCertificateId?: string | null,
    } | null,
    stellaFolders:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteBucketSubscription = {
  onDeleteBucket?:  {
    __typename: "Bucket",
    id: string,
    web?:  {
      __typename: "ZBucket_Web",
      shortUrl: string,
      isStellactWeb: boolean,
      cloudfrontUrl?: string | null,
      ipAddress?: string | null,
      sslCertificateId?: string | null,
    } | null,
    stellaFolders:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreateDataStructSubscription = {
  onCreateDataStruct?:  {
    __typename: "DataStruct",
    id: string,
    locationId?: string | null,
    location?:  {
      __typename: "Location",
      id: string,
      address?:  {
        __typename: "ZAddress",
        municipality?: string | null,
        countryCode?: string | null,
        postCode?: string | null,
        regionCode?: string | null,
        street?: string | null,
      } | null,
      calendar?:  {
        __typename: "ZCalendarMap",
        openingHours?:  {
          __typename: "ZOpeningHoursMap",
          end?: string | null,
          start?: string | null,
        } | null,
        weekly?: string | null,
        yearly?:  {
          __typename: "ZYearlyMap",
          endDate?: string | null,
          startDate?: string | null,
        } | null,
      } | null,
      geolocation?:  {
        __typename: "ZGeolocation",
        latitude?: number | null,
        longitude?: number | null,
      } | null,
      parentId: string,
      municipalityId: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    language?: string | null,
    name?: string | null,
    audience?: string | null,
    geonewsExpiryDateTime?: string | null,
    geonewsStartDateTime?: string | null,
    geonewsDuration?: number | null,
    entityId: string,
    sectionLngMap:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    },
    sectionLngId?: string | null,
    sectionLng?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refSectionLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    refSectionLngId?: string | null,
    refSectionLng?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refInternalLinkLngMap?:  {
      __typename: "ZSectionCompositeMap",
      en?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fr?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    refInternalLinkLng?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    fromUserId?: string | null,
    fromUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    toUserId?: string | null,
    toUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    queryLanguage?: string | null,
    parentId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateDataStructSubscription = {
  onUpdateDataStruct?:  {
    __typename: "DataStruct",
    id: string,
    locationId?: string | null,
    location?:  {
      __typename: "Location",
      id: string,
      address?:  {
        __typename: "ZAddress",
        municipality?: string | null,
        countryCode?: string | null,
        postCode?: string | null,
        regionCode?: string | null,
        street?: string | null,
      } | null,
      calendar?:  {
        __typename: "ZCalendarMap",
        openingHours?:  {
          __typename: "ZOpeningHoursMap",
          end?: string | null,
          start?: string | null,
        } | null,
        weekly?: string | null,
        yearly?:  {
          __typename: "ZYearlyMap",
          endDate?: string | null,
          startDate?: string | null,
        } | null,
      } | null,
      geolocation?:  {
        __typename: "ZGeolocation",
        latitude?: number | null,
        longitude?: number | null,
      } | null,
      parentId: string,
      municipalityId: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    language?: string | null,
    name?: string | null,
    audience?: string | null,
    geonewsExpiryDateTime?: string | null,
    geonewsStartDateTime?: string | null,
    geonewsDuration?: number | null,
    entityId: string,
    sectionLngMap:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    },
    sectionLngId?: string | null,
    sectionLng?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refSectionLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    refSectionLngId?: string | null,
    refSectionLng?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refInternalLinkLngMap?:  {
      __typename: "ZSectionCompositeMap",
      en?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fr?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    refInternalLinkLng?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    fromUserId?: string | null,
    fromUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    toUserId?: string | null,
    toUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    queryLanguage?: string | null,
    parentId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteDataStructSubscription = {
  onDeleteDataStruct?:  {
    __typename: "DataStruct",
    id: string,
    locationId?: string | null,
    location?:  {
      __typename: "Location",
      id: string,
      address?:  {
        __typename: "ZAddress",
        municipality?: string | null,
        countryCode?: string | null,
        postCode?: string | null,
        regionCode?: string | null,
        street?: string | null,
      } | null,
      calendar?:  {
        __typename: "ZCalendarMap",
        openingHours?:  {
          __typename: "ZOpeningHoursMap",
          end?: string | null,
          start?: string | null,
        } | null,
        weekly?: string | null,
        yearly?:  {
          __typename: "ZYearlyMap",
          endDate?: string | null,
          startDate?: string | null,
        } | null,
      } | null,
      geolocation?:  {
        __typename: "ZGeolocation",
        latitude?: number | null,
        longitude?: number | null,
      } | null,
      parentId: string,
      municipalityId: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    language?: string | null,
    name?: string | null,
    audience?: string | null,
    geonewsExpiryDateTime?: string | null,
    geonewsStartDateTime?: string | null,
    geonewsDuration?: number | null,
    entityId: string,
    sectionLngMap:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    },
    sectionLngId?: string | null,
    sectionLng?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refSectionLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    refSectionLngId?: string | null,
    refSectionLng?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refInternalLinkLngMap?:  {
      __typename: "ZSectionCompositeMap",
      en?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fr?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    refInternalLinkLng?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    fromUserId?: string | null,
    fromUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    toUserId?: string | null,
    toUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    queryLanguage?: string | null,
    parentId: string,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreateItemSubscription = {
  onCreateItem?:  {
    __typename: "Item",
    id: string,
    domainId: string,
    isOrion: boolean,
    nameLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    itemLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    descriptionLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    price?:  {
      __typename: "ZPriceMap",
      amount?: number | null,
      currency?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateItemSubscription = {
  onUpdateItem?:  {
    __typename: "Item",
    id: string,
    domainId: string,
    isOrion: boolean,
    nameLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    itemLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    descriptionLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    price?:  {
      __typename: "ZPriceMap",
      amount?: number | null,
      currency?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteItemSubscription = {
  onDeleteItem?:  {
    __typename: "Item",
    id: string,
    domainId: string,
    isOrion: boolean,
    nameLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    itemLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    descriptionLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    price?:  {
      __typename: "ZPriceMap",
      amount?: number | null,
      currency?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreateItemLngSubscription = {
  onCreateItemLng?:  {
    __typename: "ItemLng",
    id: string,
    language?: string | null,
    parentId: string,
    item?:  {
      __typename: "Item",
      id: string,
      domainId: string,
      isOrion: boolean,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      price?:  {
        __typename: "ZPriceMap",
        amount?: number | null,
        currency?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    columnsMap?:  Array< {
      __typename: "ZColumnMap",
      columnKey?: string | null,
      sectionId?: string | null,
    } | null > | null,
    columnsContentSortIds?: Array< string | null > | null,
    columnsContent?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    refSectionId?: string | null,
    refSection?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refInternalLink?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateItemLngSubscription = {
  onUpdateItemLng?:  {
    __typename: "ItemLng",
    id: string,
    language?: string | null,
    parentId: string,
    item?:  {
      __typename: "Item",
      id: string,
      domainId: string,
      isOrion: boolean,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      price?:  {
        __typename: "ZPriceMap",
        amount?: number | null,
        currency?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    columnsMap?:  Array< {
      __typename: "ZColumnMap",
      columnKey?: string | null,
      sectionId?: string | null,
    } | null > | null,
    columnsContentSortIds?: Array< string | null > | null,
    columnsContent?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    refSectionId?: string | null,
    refSection?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refInternalLink?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteItemLngSubscription = {
  onDeleteItemLng?:  {
    __typename: "ItemLng",
    id: string,
    language?: string | null,
    parentId: string,
    item?:  {
      __typename: "Item",
      id: string,
      domainId: string,
      isOrion: boolean,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      price?:  {
        __typename: "ZPriceMap",
        amount?: number | null,
        currency?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    columnsMap?:  Array< {
      __typename: "ZColumnMap",
      columnKey?: string | null,
      sectionId?: string | null,
    } | null > | null,
    columnsContentSortIds?: Array< string | null > | null,
    columnsContent?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    refSectionId?: string | null,
    refSection?:  {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    refInternalLink?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreateLocationSubscription = {
  onCreateLocation?:  {
    __typename: "Location",
    id: string,
    address?:  {
      __typename: "ZAddress",
      municipality?: string | null,
      countryCode?: string | null,
      postCode?: string | null,
      regionCode?: string | null,
      street?: string | null,
    } | null,
    calendar?:  {
      __typename: "ZCalendarMap",
      openingHours?:  {
        __typename: "ZOpeningHoursMap",
        end?: string | null,
        start?: string | null,
      } | null,
      weekly?: string | null,
      yearly?:  {
        __typename: "ZYearlyMap",
        endDate?: string | null,
        startDate?: string | null,
      } | null,
    } | null,
    geolocation?:  {
      __typename: "ZGeolocation",
      latitude?: number | null,
      longitude?: number | null,
    } | null,
    parentId: string,
    municipalityId: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateLocationSubscription = {
  onUpdateLocation?:  {
    __typename: "Location",
    id: string,
    address?:  {
      __typename: "ZAddress",
      municipality?: string | null,
      countryCode?: string | null,
      postCode?: string | null,
      regionCode?: string | null,
      street?: string | null,
    } | null,
    calendar?:  {
      __typename: "ZCalendarMap",
      openingHours?:  {
        __typename: "ZOpeningHoursMap",
        end?: string | null,
        start?: string | null,
      } | null,
      weekly?: string | null,
      yearly?:  {
        __typename: "ZYearlyMap",
        endDate?: string | null,
        startDate?: string | null,
      } | null,
    } | null,
    geolocation?:  {
      __typename: "ZGeolocation",
      latitude?: number | null,
      longitude?: number | null,
    } | null,
    parentId: string,
    municipalityId: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteLocationSubscription = {
  onDeleteLocation?:  {
    __typename: "Location",
    id: string,
    address?:  {
      __typename: "ZAddress",
      municipality?: string | null,
      countryCode?: string | null,
      postCode?: string | null,
      regionCode?: string | null,
      street?: string | null,
    } | null,
    calendar?:  {
      __typename: "ZCalendarMap",
      openingHours?:  {
        __typename: "ZOpeningHoursMap",
        end?: string | null,
        start?: string | null,
      } | null,
      weekly?: string | null,
      yearly?:  {
        __typename: "ZYearlyMap",
        endDate?: string | null,
        startDate?: string | null,
      } | null,
    } | null,
    geolocation?:  {
      __typename: "ZGeolocation",
      latitude?: number | null,
      longitude?: number | null,
    } | null,
    parentId: string,
    municipalityId: string,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreatePointerSubscription = {
  onCreatePointer?:  {
    __typename: "Pointer",
    pointer: string,
    queryLanguage?: string | null,
    stellaId?: string | null,
    stella?:  {
      __typename: "Stella",
      id: string,
      bucket?:  {
        __typename: "Bucket",
        id: string,
        web?:  {
          __typename: "ZBucket_Web",
          shortUrl: string,
          isStellactWeb: boolean,
          cloudfrontUrl?: string | null,
          ipAddress?: string | null,
          sslCertificateId?: string | null,
        } | null,
        stellaFolders:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null >,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      bucketId: string,
      entityId: string,
      interfaceLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      interfaceLngId?: string | null,
      interfaceLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      logo?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      mediaList:  Array< {
        __typename: "ZMedia",
        mediaRef: string,
        sizes: string,
      } | null >,
      name: string,
      options:  {
        __typename: "ZOptions",
        hasDraft: boolean,
        status: STELLASTATUS,
      },
      pointer: string,
      queryLanguage?: string | null,
      social?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      specialties?:  Array< {
        __typename: "Specialty",
        id: string,
        pointer: string,
        designationId: string,
        designation?:  {
          __typename: "StaticObject",
          id: string,
          nameLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          name?: string | null,
          descriptionLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          description?: string | null,
          attribute?:  {
            __typename: "ZFieldValue",
            value?: string | null,
            field?: string | null,
          } | null,
          type?: string | null,
          queryLanguage?: string | null,
          parentId?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        levelId: string,
        catchphraseLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        itemsIds?: Array< string | null > | null,
        geonews?:  Array< {
          __typename: "DataStruct",
          id: string,
          locationId?: string | null,
          location?:  {
            __typename: "Location",
            id: string,
            address?:  {
              __typename: "ZAddress",
              municipality?: string | null,
              countryCode?: string | null,
              postCode?: string | null,
              regionCode?: string | null,
              street?: string | null,
            } | null,
            calendar?:  {
              __typename: "ZCalendarMap",
              openingHours?:  {
                __typename: "ZOpeningHoursMap",
                end?: string | null,
                start?: string | null,
              } | null,
              weekly?: string | null,
              yearly?:  {
                __typename: "ZYearlyMap",
                endDate?: string | null,
                startDate?: string | null,
              } | null,
            } | null,
            geolocation?:  {
              __typename: "ZGeolocation",
              latitude?: number | null,
              longitude?: number | null,
            } | null,
            parentId: string,
            municipalityId: string,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          language?: string | null,
          name?: string | null,
          audience?: string | null,
          geonewsExpiryDateTime?: string | null,
          geonewsStartDateTime?: string | null,
          geonewsDuration?: number | null,
          entityId: string,
          sectionLngMap:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          },
          sectionLngId?: string | null,
          sectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refSectionLngMap?:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          } | null,
          refSectionLngId?: string | null,
          refSectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refInternalLinkLngMap?:  {
            __typename: "ZSectionCompositeMap",
            en?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            fr?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          refInternalLinkLng?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fromUserId?: string | null,
          fromUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          toUserId?: string | null,
          toUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          queryLanguage?: string | null,
          parentId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsIds?: Array< string | null > | null,
        locationId: string,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        parentId: string,
        othersPocUsersIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        pocUserId: string,
        pocUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        sortKey?: string | null,
        specialtyLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        specialtyLngId?: string | null,
        specialtyLng?:  {
          __typename: "SpecialtyLng",
          id: string,
          gallery?:  {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null,
          language?: string | null,
          parentId: string,
          type?: string | null,
          itemsTables?:  Array< {
            __typename: "ZItemsTableMap",
            headRow?:  Array< {
              __typename: "ZItemsHeadRowMap",
              sortKey?: string | null,
              string?: string | null,
              type?: string | null,
            } | null > | null,
            itemsIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            sortKey?: string | null,
          } | null > | null,
          indexSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          indexSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          indexPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          geonewsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          geonewsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          geonewsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          gallerySectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          gallerySections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          galleryPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          itemsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          itemsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          itemsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          contactSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          contactSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          contactPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add1SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add1Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add1Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add2SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add2Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add2Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add3SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add3Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add3Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add4SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add4Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add4Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        tagsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      specialtiesIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    geostructId?: string | null,
    geostruct?:  {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdatePointerSubscription = {
  onUpdatePointer?:  {
    __typename: "Pointer",
    pointer: string,
    queryLanguage?: string | null,
    stellaId?: string | null,
    stella?:  {
      __typename: "Stella",
      id: string,
      bucket?:  {
        __typename: "Bucket",
        id: string,
        web?:  {
          __typename: "ZBucket_Web",
          shortUrl: string,
          isStellactWeb: boolean,
          cloudfrontUrl?: string | null,
          ipAddress?: string | null,
          sslCertificateId?: string | null,
        } | null,
        stellaFolders:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null >,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      bucketId: string,
      entityId: string,
      interfaceLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      interfaceLngId?: string | null,
      interfaceLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      logo?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      mediaList:  Array< {
        __typename: "ZMedia",
        mediaRef: string,
        sizes: string,
      } | null >,
      name: string,
      options:  {
        __typename: "ZOptions",
        hasDraft: boolean,
        status: STELLASTATUS,
      },
      pointer: string,
      queryLanguage?: string | null,
      social?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      specialties?:  Array< {
        __typename: "Specialty",
        id: string,
        pointer: string,
        designationId: string,
        designation?:  {
          __typename: "StaticObject",
          id: string,
          nameLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          name?: string | null,
          descriptionLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          description?: string | null,
          attribute?:  {
            __typename: "ZFieldValue",
            value?: string | null,
            field?: string | null,
          } | null,
          type?: string | null,
          queryLanguage?: string | null,
          parentId?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        levelId: string,
        catchphraseLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        itemsIds?: Array< string | null > | null,
        geonews?:  Array< {
          __typename: "DataStruct",
          id: string,
          locationId?: string | null,
          location?:  {
            __typename: "Location",
            id: string,
            address?:  {
              __typename: "ZAddress",
              municipality?: string | null,
              countryCode?: string | null,
              postCode?: string | null,
              regionCode?: string | null,
              street?: string | null,
            } | null,
            calendar?:  {
              __typename: "ZCalendarMap",
              openingHours?:  {
                __typename: "ZOpeningHoursMap",
                end?: string | null,
                start?: string | null,
              } | null,
              weekly?: string | null,
              yearly?:  {
                __typename: "ZYearlyMap",
                endDate?: string | null,
                startDate?: string | null,
              } | null,
            } | null,
            geolocation?:  {
              __typename: "ZGeolocation",
              latitude?: number | null,
              longitude?: number | null,
            } | null,
            parentId: string,
            municipalityId: string,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          language?: string | null,
          name?: string | null,
          audience?: string | null,
          geonewsExpiryDateTime?: string | null,
          geonewsStartDateTime?: string | null,
          geonewsDuration?: number | null,
          entityId: string,
          sectionLngMap:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          },
          sectionLngId?: string | null,
          sectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refSectionLngMap?:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          } | null,
          refSectionLngId?: string | null,
          refSectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refInternalLinkLngMap?:  {
            __typename: "ZSectionCompositeMap",
            en?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            fr?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          refInternalLinkLng?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fromUserId?: string | null,
          fromUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          toUserId?: string | null,
          toUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          queryLanguage?: string | null,
          parentId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsIds?: Array< string | null > | null,
        locationId: string,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        parentId: string,
        othersPocUsersIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        pocUserId: string,
        pocUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        sortKey?: string | null,
        specialtyLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        specialtyLngId?: string | null,
        specialtyLng?:  {
          __typename: "SpecialtyLng",
          id: string,
          gallery?:  {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null,
          language?: string | null,
          parentId: string,
          type?: string | null,
          itemsTables?:  Array< {
            __typename: "ZItemsTableMap",
            headRow?:  Array< {
              __typename: "ZItemsHeadRowMap",
              sortKey?: string | null,
              string?: string | null,
              type?: string | null,
            } | null > | null,
            itemsIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            sortKey?: string | null,
          } | null > | null,
          indexSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          indexSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          indexPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          geonewsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          geonewsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          geonewsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          gallerySectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          gallerySections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          galleryPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          itemsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          itemsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          itemsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          contactSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          contactSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          contactPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add1SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add1Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add1Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add2SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add2Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add2Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add3SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add3Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add3Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add4SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add4Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add4Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        tagsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      specialtiesIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    geostructId?: string | null,
    geostruct?:  {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeletePointerSubscription = {
  onDeletePointer?:  {
    __typename: "Pointer",
    pointer: string,
    queryLanguage?: string | null,
    stellaId?: string | null,
    stella?:  {
      __typename: "Stella",
      id: string,
      bucket?:  {
        __typename: "Bucket",
        id: string,
        web?:  {
          __typename: "ZBucket_Web",
          shortUrl: string,
          isStellactWeb: boolean,
          cloudfrontUrl?: string | null,
          ipAddress?: string | null,
          sslCertificateId?: string | null,
        } | null,
        stellaFolders:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null >,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      bucketId: string,
      entityId: string,
      interfaceLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      interfaceLngId?: string | null,
      interfaceLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      logo?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      mediaList:  Array< {
        __typename: "ZMedia",
        mediaRef: string,
        sizes: string,
      } | null >,
      name: string,
      options:  {
        __typename: "ZOptions",
        hasDraft: boolean,
        status: STELLASTATUS,
      },
      pointer: string,
      queryLanguage?: string | null,
      social?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      specialties?:  Array< {
        __typename: "Specialty",
        id: string,
        pointer: string,
        designationId: string,
        designation?:  {
          __typename: "StaticObject",
          id: string,
          nameLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          name?: string | null,
          descriptionLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          description?: string | null,
          attribute?:  {
            __typename: "ZFieldValue",
            value?: string | null,
            field?: string | null,
          } | null,
          type?: string | null,
          queryLanguage?: string | null,
          parentId?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        levelId: string,
        catchphraseLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        itemsIds?: Array< string | null > | null,
        geonews?:  Array< {
          __typename: "DataStruct",
          id: string,
          locationId?: string | null,
          location?:  {
            __typename: "Location",
            id: string,
            address?:  {
              __typename: "ZAddress",
              municipality?: string | null,
              countryCode?: string | null,
              postCode?: string | null,
              regionCode?: string | null,
              street?: string | null,
            } | null,
            calendar?:  {
              __typename: "ZCalendarMap",
              openingHours?:  {
                __typename: "ZOpeningHoursMap",
                end?: string | null,
                start?: string | null,
              } | null,
              weekly?: string | null,
              yearly?:  {
                __typename: "ZYearlyMap",
                endDate?: string | null,
                startDate?: string | null,
              } | null,
            } | null,
            geolocation?:  {
              __typename: "ZGeolocation",
              latitude?: number | null,
              longitude?: number | null,
            } | null,
            parentId: string,
            municipalityId: string,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          language?: string | null,
          name?: string | null,
          audience?: string | null,
          geonewsExpiryDateTime?: string | null,
          geonewsStartDateTime?: string | null,
          geonewsDuration?: number | null,
          entityId: string,
          sectionLngMap:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          },
          sectionLngId?: string | null,
          sectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refSectionLngMap?:  {
            __typename: "ZLngMap",
            enId?: string | null,
            frId?: string | null,
          } | null,
          refSectionLngId?: string | null,
          refSectionLng?:  {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          refInternalLinkLngMap?:  {
            __typename: "ZSectionCompositeMap",
            en?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            fr?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          refInternalLinkLng?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fromUserId?: string | null,
          fromUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          toUserId?: string | null,
          toUser?:  {
            __typename: "User",
            id: string,
            administratedPointers?:  Array< {
              __typename: "ZAdminPointer",
              pointer?: string | null,
              ownershipLevel?: string | null,
            } | null > | null,
            specialtiesPoc?: Array< string | null > | null,
            email: string,
            federation: string,
            registrationId: string,
            power: USERPOWER,
            gender: string,
            name:  {
              __typename: "ZName",
              firstname?: string | null,
              lastname?: string | null,
              nickname: string,
            },
            phoneNumbers?:  Array< {
              __typename: "ZPhoneNumber",
              sortKey?: string | null,
              countryCode: string,
              number: string,
              usage?: string | null,
            } | null > | null,
            preferences:  {
              __typename: "ZUserPreferences",
              visibleEmail: boolean,
              visiblePhones: boolean,
            },
            titleLng?:  {
              __typename: "ZStringLng",
              en?: string | null,
              fr?: string | null,
            } | null,
            title?: string | null,
            queryLanguage?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null,
          queryLanguage?: string | null,
          parentId: string,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsIds?: Array< string | null > | null,
        locationId: string,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        parentId: string,
        othersPocUsersIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        pocUserId: string,
        pocUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        sortKey?: string | null,
        specialtyLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        specialtyLngId?: string | null,
        specialtyLng?:  {
          __typename: "SpecialtyLng",
          id: string,
          gallery?:  {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null,
          language?: string | null,
          parentId: string,
          type?: string | null,
          itemsTables?:  Array< {
            __typename: "ZItemsTableMap",
            headRow?:  Array< {
              __typename: "ZItemsHeadRowMap",
              sortKey?: string | null,
              string?: string | null,
              type?: string | null,
            } | null > | null,
            itemsIds?:  Array< {
              __typename: "ZSortIdMap",
              id?: string | null,
              sortKey?: string | null,
            } | null > | null,
            sortKey?: string | null,
          } | null > | null,
          indexSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          indexSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          indexPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          geonewsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          geonewsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          geonewsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          gallerySectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          gallerySections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          galleryPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          itemsSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          itemsSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          itemsPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          contactSectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          contactSections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          contactPage?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add1SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add1Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add1Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add2SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add2Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add2Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add3SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add3Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add3Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          add4SectionsSortIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          add4Sections?:  Array< {
            __typename: "Section",
            id: string,
            parentId: string,
            bucketId?: string | null,
            flexbox?:  {
              __typename: "ZFlexbox",
              columns?: number | null,
              totalColumns?: number | null,
            } | null,
            elements?:  Array< {
              __typename: "ZSectionElement",
              firstComposite:  {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              },
              nextComposites?:  Array< {
                __typename: "ZSectionComposite",
                formatRanges?:  Array< {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null > | null,
                ref?:  {
                  __typename: "ZReference",
                  linkTarget?: string | null,
                  format?: string | null,
                  refId?: string | null,
                  sizes?:  {
                    __typename: "ZSize",
                    ll?: boolean | null,
                    mm?: boolean | null,
                    ss?: boolean | null,
                  } | null,
                  formatRange?:  {
                    __typename: "ZFormatRange",
                    offset: number,
                    length: number,
                    style: string,
                  } | null,
                  type: string,
                } | null,
                size?: string | null,
                sortKey?: string | null,
                text: string,
              } | null > | null,
              sortKey: string,
              type: string,
            } | null > | null,
            title?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            language?: string | null,
            type?: string | null,
            createdAt: string,
            updatedAt: string,
            version: number,
          } | null > | null,
          add4Page?:  {
            __typename: "ZPage",
            pageTitle?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
            layout?: string | null,
            menu?:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null,
          } | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        tagsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      specialtiesIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    geostructId?: string | null,
    geostruct?:  {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreateSectionSubscription = {
  onCreateSection?:  {
    __typename: "Section",
    id: string,
    parentId: string,
    bucketId?: string | null,
    flexbox?:  {
      __typename: "ZFlexbox",
      columns?: number | null,
      totalColumns?: number | null,
    } | null,
    elements?:  Array< {
      __typename: "ZSectionElement",
      firstComposite:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      },
      nextComposites?:  Array< {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null > | null,
      sortKey: string,
      type: string,
    } | null > | null,
    title?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    language?: string | null,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateSectionSubscription = {
  onUpdateSection?:  {
    __typename: "Section",
    id: string,
    parentId: string,
    bucketId?: string | null,
    flexbox?:  {
      __typename: "ZFlexbox",
      columns?: number | null,
      totalColumns?: number | null,
    } | null,
    elements?:  Array< {
      __typename: "ZSectionElement",
      firstComposite:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      },
      nextComposites?:  Array< {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null > | null,
      sortKey: string,
      type: string,
    } | null > | null,
    title?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    language?: string | null,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteSectionSubscription = {
  onDeleteSection?:  {
    __typename: "Section",
    id: string,
    parentId: string,
    bucketId?: string | null,
    flexbox?:  {
      __typename: "ZFlexbox",
      columns?: number | null,
      totalColumns?: number | null,
    } | null,
    elements?:  Array< {
      __typename: "ZSectionElement",
      firstComposite:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      },
      nextComposites?:  Array< {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null > | null,
      sortKey: string,
      type: string,
    } | null > | null,
    title?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    language?: string | null,
    type?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreateSpecialtySubscription = {
  onCreateSpecialty?:  {
    __typename: "Specialty",
    id: string,
    pointer: string,
    designationId: string,
    designation?:  {
      __typename: "StaticObject",
      id: string,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      name?: string | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      description?: string | null,
      attribute?:  {
        __typename: "ZFieldValue",
        value?: string | null,
        field?: string | null,
      } | null,
      type?: string | null,
      queryLanguage?: string | null,
      parentId?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    levelId: string,
    catchphraseLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    itemsIds?: Array< string | null > | null,
    geonews?:  Array< {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    geonewsIds?: Array< string | null > | null,
    locationId: string,
    location?:  {
      __typename: "Location",
      id: string,
      address?:  {
        __typename: "ZAddress",
        municipality?: string | null,
        countryCode?: string | null,
        postCode?: string | null,
        regionCode?: string | null,
        street?: string | null,
      } | null,
      calendar?:  {
        __typename: "ZCalendarMap",
        openingHours?:  {
          __typename: "ZOpeningHoursMap",
          end?: string | null,
          start?: string | null,
        } | null,
        weekly?: string | null,
        yearly?:  {
          __typename: "ZYearlyMap",
          endDate?: string | null,
          startDate?: string | null,
        } | null,
      } | null,
      geolocation?:  {
        __typename: "ZGeolocation",
        latitude?: number | null,
        longitude?: number | null,
      } | null,
      parentId: string,
      municipalityId: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    parentId: string,
    othersPocUsersIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    pocUserId: string,
    pocUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    queryLanguage?: string | null,
    sortKey?: string | null,
    specialtyLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    specialtyLngId?: string | null,
    specialtyLng?:  {
      __typename: "SpecialtyLng",
      id: string,
      gallery?:  {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null,
      language?: string | null,
      parentId: string,
      type?: string | null,
      itemsTables?:  Array< {
        __typename: "ZItemsTableMap",
        headRow?:  Array< {
          __typename: "ZItemsHeadRowMap",
          sortKey?: string | null,
          string?: string | null,
          type?: string | null,
        } | null > | null,
        itemsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        sortKey?: string | null,
      } | null > | null,
      indexSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      indexSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      indexPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      geonewsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      geonewsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      gallerySectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      gallerySections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      galleryPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      itemsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      itemsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      itemsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      contactSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      contactSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      contactPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add1SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add1Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add1Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add2SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add2Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add2Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add3SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add3Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add3Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add4SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add4Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add4Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    tagsIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateSpecialtySubscription = {
  onUpdateSpecialty?:  {
    __typename: "Specialty",
    id: string,
    pointer: string,
    designationId: string,
    designation?:  {
      __typename: "StaticObject",
      id: string,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      name?: string | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      description?: string | null,
      attribute?:  {
        __typename: "ZFieldValue",
        value?: string | null,
        field?: string | null,
      } | null,
      type?: string | null,
      queryLanguage?: string | null,
      parentId?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    levelId: string,
    catchphraseLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    itemsIds?: Array< string | null > | null,
    geonews?:  Array< {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    geonewsIds?: Array< string | null > | null,
    locationId: string,
    location?:  {
      __typename: "Location",
      id: string,
      address?:  {
        __typename: "ZAddress",
        municipality?: string | null,
        countryCode?: string | null,
        postCode?: string | null,
        regionCode?: string | null,
        street?: string | null,
      } | null,
      calendar?:  {
        __typename: "ZCalendarMap",
        openingHours?:  {
          __typename: "ZOpeningHoursMap",
          end?: string | null,
          start?: string | null,
        } | null,
        weekly?: string | null,
        yearly?:  {
          __typename: "ZYearlyMap",
          endDate?: string | null,
          startDate?: string | null,
        } | null,
      } | null,
      geolocation?:  {
        __typename: "ZGeolocation",
        latitude?: number | null,
        longitude?: number | null,
      } | null,
      parentId: string,
      municipalityId: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    parentId: string,
    othersPocUsersIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    pocUserId: string,
    pocUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    queryLanguage?: string | null,
    sortKey?: string | null,
    specialtyLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    specialtyLngId?: string | null,
    specialtyLng?:  {
      __typename: "SpecialtyLng",
      id: string,
      gallery?:  {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null,
      language?: string | null,
      parentId: string,
      type?: string | null,
      itemsTables?:  Array< {
        __typename: "ZItemsTableMap",
        headRow?:  Array< {
          __typename: "ZItemsHeadRowMap",
          sortKey?: string | null,
          string?: string | null,
          type?: string | null,
        } | null > | null,
        itemsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        sortKey?: string | null,
      } | null > | null,
      indexSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      indexSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      indexPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      geonewsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      geonewsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      gallerySectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      gallerySections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      galleryPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      itemsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      itemsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      itemsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      contactSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      contactSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      contactPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add1SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add1Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add1Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add2SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add2Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add2Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add3SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add3Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add3Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add4SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add4Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add4Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    tagsIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteSpecialtySubscription = {
  onDeleteSpecialty?:  {
    __typename: "Specialty",
    id: string,
    pointer: string,
    designationId: string,
    designation?:  {
      __typename: "StaticObject",
      id: string,
      nameLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      name?: string | null,
      descriptionLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      description?: string | null,
      attribute?:  {
        __typename: "ZFieldValue",
        value?: string | null,
        field?: string | null,
      } | null,
      type?: string | null,
      queryLanguage?: string | null,
      parentId?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    levelId: string,
    catchphraseLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    itemsIds?: Array< string | null > | null,
    geonews?:  Array< {
      __typename: "DataStruct",
      id: string,
      locationId?: string | null,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      language?: string | null,
      name?: string | null,
      audience?: string | null,
      geonewsExpiryDateTime?: string | null,
      geonewsStartDateTime?: string | null,
      geonewsDuration?: number | null,
      entityId: string,
      sectionLngMap:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      },
      sectionLngId?: string | null,
      sectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refSectionLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      refSectionLngId?: string | null,
      refSectionLng?:  {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      refInternalLinkLngMap?:  {
        __typename: "ZSectionCompositeMap",
        en?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fr?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      refInternalLinkLng?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      fromUserId?: string | null,
      fromUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      toUserId?: string | null,
      toUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      parentId: string,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    geonewsIds?: Array< string | null > | null,
    locationId: string,
    location?:  {
      __typename: "Location",
      id: string,
      address?:  {
        __typename: "ZAddress",
        municipality?: string | null,
        countryCode?: string | null,
        postCode?: string | null,
        regionCode?: string | null,
        street?: string | null,
      } | null,
      calendar?:  {
        __typename: "ZCalendarMap",
        openingHours?:  {
          __typename: "ZOpeningHoursMap",
          end?: string | null,
          start?: string | null,
        } | null,
        weekly?: string | null,
        yearly?:  {
          __typename: "ZYearlyMap",
          endDate?: string | null,
          startDate?: string | null,
        } | null,
      } | null,
      geolocation?:  {
        __typename: "ZGeolocation",
        latitude?: number | null,
        longitude?: number | null,
      } | null,
      parentId: string,
      municipalityId: string,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    parentId: string,
    othersPocUsersIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    pocUserId: string,
    pocUser?:  {
      __typename: "User",
      id: string,
      administratedPointers?:  Array< {
        __typename: "ZAdminPointer",
        pointer?: string | null,
        ownershipLevel?: string | null,
      } | null > | null,
      specialtiesPoc?: Array< string | null > | null,
      email: string,
      federation: string,
      registrationId: string,
      power: USERPOWER,
      gender: string,
      name:  {
        __typename: "ZName",
        firstname?: string | null,
        lastname?: string | null,
        nickname: string,
      },
      phoneNumbers?:  Array< {
        __typename: "ZPhoneNumber",
        sortKey?: string | null,
        countryCode: string,
        number: string,
        usage?: string | null,
      } | null > | null,
      preferences:  {
        __typename: "ZUserPreferences",
        visibleEmail: boolean,
        visiblePhones: boolean,
      },
      titleLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      title?: string | null,
      queryLanguage?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    queryLanguage?: string | null,
    sortKey?: string | null,
    specialtyLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    specialtyLngId?: string | null,
    specialtyLng?:  {
      __typename: "SpecialtyLng",
      id: string,
      gallery?:  {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null,
      language?: string | null,
      parentId: string,
      type?: string | null,
      itemsTables?:  Array< {
        __typename: "ZItemsTableMap",
        headRow?:  Array< {
          __typename: "ZItemsHeadRowMap",
          sortKey?: string | null,
          string?: string | null,
          type?: string | null,
        } | null > | null,
        itemsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        sortKey?: string | null,
      } | null > | null,
      indexSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      indexSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      indexPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      geonewsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      geonewsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      gallerySectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      gallerySections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      galleryPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      itemsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      itemsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      itemsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      contactSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      contactSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      contactPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add1SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add1Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add1Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add2SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add2Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add2Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add3SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add3Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add3Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add4SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add4Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add4Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    tagsIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreateSpecialtyLngSubscription = {
  onCreateSpecialtyLng?:  {
    __typename: "SpecialtyLng",
    id: string,
    gallery?:  {
      __typename: "ZSectionElement",
      firstComposite:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      },
      nextComposites?:  Array< {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null > | null,
      sortKey: string,
      type: string,
    } | null,
    language?: string | null,
    parentId: string,
    type?: string | null,
    itemsTables?:  Array< {
      __typename: "ZItemsTableMap",
      headRow?:  Array< {
        __typename: "ZItemsHeadRowMap",
        sortKey?: string | null,
        string?: string | null,
        type?: string | null,
      } | null > | null,
      itemsIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      sortKey?: string | null,
    } | null > | null,
    indexSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    indexSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    indexPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    geonewsSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    geonewsSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    geonewsPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    gallerySectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    gallerySections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    galleryPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    itemsSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    itemsSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    itemsPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    contactSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    contactSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    contactPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add1SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add1Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add1Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add2SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add2Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add2Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add3SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add3Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add3Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add4SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add4Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add4Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateSpecialtyLngSubscription = {
  onUpdateSpecialtyLng?:  {
    __typename: "SpecialtyLng",
    id: string,
    gallery?:  {
      __typename: "ZSectionElement",
      firstComposite:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      },
      nextComposites?:  Array< {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null > | null,
      sortKey: string,
      type: string,
    } | null,
    language?: string | null,
    parentId: string,
    type?: string | null,
    itemsTables?:  Array< {
      __typename: "ZItemsTableMap",
      headRow?:  Array< {
        __typename: "ZItemsHeadRowMap",
        sortKey?: string | null,
        string?: string | null,
        type?: string | null,
      } | null > | null,
      itemsIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      sortKey?: string | null,
    } | null > | null,
    indexSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    indexSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    indexPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    geonewsSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    geonewsSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    geonewsPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    gallerySectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    gallerySections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    galleryPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    itemsSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    itemsSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    itemsPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    contactSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    contactSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    contactPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add1SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add1Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add1Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add2SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add2Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add2Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add3SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add3Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add3Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add4SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add4Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add4Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteSpecialtyLngSubscription = {
  onDeleteSpecialtyLng?:  {
    __typename: "SpecialtyLng",
    id: string,
    gallery?:  {
      __typename: "ZSectionElement",
      firstComposite:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      },
      nextComposites?:  Array< {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null > | null,
      sortKey: string,
      type: string,
    } | null,
    language?: string | null,
    parentId: string,
    type?: string | null,
    itemsTables?:  Array< {
      __typename: "ZItemsTableMap",
      headRow?:  Array< {
        __typename: "ZItemsHeadRowMap",
        sortKey?: string | null,
        string?: string | null,
        type?: string | null,
      } | null > | null,
      itemsIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      sortKey?: string | null,
    } | null > | null,
    indexSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    indexSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    indexPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    geonewsSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    geonewsSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    geonewsPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    gallerySectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    gallerySections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    galleryPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    itemsSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    itemsSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    itemsPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    contactSectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    contactSections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    contactPage?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add1SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add1Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add1Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add2SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add2Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add2Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add3SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add3Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add3Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    add4SectionsSortIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    add4Sections?:  Array< {
      __typename: "Section",
      id: string,
      parentId: string,
      bucketId?: string | null,
      flexbox?:  {
        __typename: "ZFlexbox",
        columns?: number | null,
        totalColumns?: number | null,
      } | null,
      elements?:  Array< {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null > | null,
      title?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      language?: string | null,
      type?: string | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    add4Page?:  {
      __typename: "ZPage",
      pageTitle?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
      layout?: string | null,
      menu?:  {
        __typename: "ZSectionComposite",
        formatRanges?:  Array< {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null > | null,
        ref?:  {
          __typename: "ZReference",
          linkTarget?: string | null,
          format?: string | null,
          refId?: string | null,
          sizes?:  {
            __typename: "ZSize",
            ll?: boolean | null,
            mm?: boolean | null,
            ss?: boolean | null,
          } | null,
          formatRange?:  {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null,
          type: string,
        } | null,
        size?: string | null,
        sortKey?: string | null,
        text: string,
      } | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreateStaticObjectSubscription = {
  onCreateStaticObject?:  {
    __typename: "StaticObject",
    id: string,
    nameLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    name?: string | null,
    descriptionLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    description?: string | null,
    attribute?:  {
      __typename: "ZFieldValue",
      value?: string | null,
      field?: string | null,
    } | null,
    type?: string | null,
    queryLanguage?: string | null,
    parentId?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateStaticObjectSubscription = {
  onUpdateStaticObject?:  {
    __typename: "StaticObject",
    id: string,
    nameLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    name?: string | null,
    descriptionLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    description?: string | null,
    attribute?:  {
      __typename: "ZFieldValue",
      value?: string | null,
      field?: string | null,
    } | null,
    type?: string | null,
    queryLanguage?: string | null,
    parentId?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteStaticObjectSubscription = {
  onDeleteStaticObject?:  {
    __typename: "StaticObject",
    id: string,
    nameLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    name?: string | null,
    descriptionLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    description?: string | null,
    attribute?:  {
      __typename: "ZFieldValue",
      value?: string | null,
      field?: string | null,
    } | null,
    type?: string | null,
    queryLanguage?: string | null,
    parentId?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreateStellaSubscription = {
  onCreateStella?:  {
    __typename: "Stella",
    id: string,
    bucket?:  {
      __typename: "Bucket",
      id: string,
      web?:  {
        __typename: "ZBucket_Web",
        shortUrl: string,
        isStellactWeb: boolean,
        cloudfrontUrl?: string | null,
        ipAddress?: string | null,
        sslCertificateId?: string | null,
      } | null,
      stellaFolders:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null >,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    bucketId: string,
    entityId: string,
    interfaceLng?:  {
      __typename: "SpecialtyLng",
      id: string,
      gallery?:  {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null,
      language?: string | null,
      parentId: string,
      type?: string | null,
      itemsTables?:  Array< {
        __typename: "ZItemsTableMap",
        headRow?:  Array< {
          __typename: "ZItemsHeadRowMap",
          sortKey?: string | null,
          string?: string | null,
          type?: string | null,
        } | null > | null,
        itemsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        sortKey?: string | null,
      } | null > | null,
      indexSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      indexSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      indexPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      geonewsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      geonewsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      gallerySectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      gallerySections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      galleryPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      itemsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      itemsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      itemsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      contactSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      contactSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      contactPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add1SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add1Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add1Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add2SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add2Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add2Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add3SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add3Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add3Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add4SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add4Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add4Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    interfaceLngId?: string | null,
    interfaceLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    logo?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    mediaList:  Array< {
      __typename: "ZMedia",
      mediaRef: string,
      sizes: string,
    } | null >,
    name: string,
    options:  {
      __typename: "ZOptions",
      hasDraft: boolean,
      status: STELLASTATUS,
    },
    pointer: string,
    queryLanguage?: string | null,
    social?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    specialties?:  Array< {
      __typename: "Specialty",
      id: string,
      pointer: string,
      designationId: string,
      designation?:  {
        __typename: "StaticObject",
        id: string,
        nameLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        name?: string | null,
        descriptionLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        description?: string | null,
        attribute?:  {
          __typename: "ZFieldValue",
          value?: string | null,
          field?: string | null,
        } | null,
        type?: string | null,
        queryLanguage?: string | null,
        parentId?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      levelId: string,
      catchphraseLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemsIds?: Array< string | null > | null,
      geonews?:  Array< {
        __typename: "DataStruct",
        id: string,
        locationId?: string | null,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        language?: string | null,
        name?: string | null,
        audience?: string | null,
        geonewsExpiryDateTime?: string | null,
        geonewsStartDateTime?: string | null,
        geonewsDuration?: number | null,
        entityId: string,
        sectionLngMap:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        },
        sectionLngId?: string | null,
        sectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refSectionLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        refSectionLngId?: string | null,
        refSectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refInternalLinkLngMap?:  {
          __typename: "ZSectionCompositeMap",
          en?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fr?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        refInternalLinkLng?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fromUserId?: string | null,
        fromUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        toUserId?: string | null,
        toUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        parentId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsIds?: Array< string | null > | null,
      locationId: string,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      parentId: string,
      othersPocUsersIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      pocUserId: string,
      pocUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      sortKey?: string | null,
      specialtyLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      specialtyLngId?: string | null,
      specialtyLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      tagsIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    specialtiesIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateStellaSubscription = {
  onUpdateStella?:  {
    __typename: "Stella",
    id: string,
    bucket?:  {
      __typename: "Bucket",
      id: string,
      web?:  {
        __typename: "ZBucket_Web",
        shortUrl: string,
        isStellactWeb: boolean,
        cloudfrontUrl?: string | null,
        ipAddress?: string | null,
        sslCertificateId?: string | null,
      } | null,
      stellaFolders:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null >,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    bucketId: string,
    entityId: string,
    interfaceLng?:  {
      __typename: "SpecialtyLng",
      id: string,
      gallery?:  {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null,
      language?: string | null,
      parentId: string,
      type?: string | null,
      itemsTables?:  Array< {
        __typename: "ZItemsTableMap",
        headRow?:  Array< {
          __typename: "ZItemsHeadRowMap",
          sortKey?: string | null,
          string?: string | null,
          type?: string | null,
        } | null > | null,
        itemsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        sortKey?: string | null,
      } | null > | null,
      indexSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      indexSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      indexPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      geonewsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      geonewsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      gallerySectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      gallerySections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      galleryPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      itemsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      itemsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      itemsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      contactSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      contactSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      contactPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add1SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add1Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add1Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add2SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add2Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add2Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add3SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add3Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add3Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add4SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add4Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add4Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    interfaceLngId?: string | null,
    interfaceLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    logo?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    mediaList:  Array< {
      __typename: "ZMedia",
      mediaRef: string,
      sizes: string,
    } | null >,
    name: string,
    options:  {
      __typename: "ZOptions",
      hasDraft: boolean,
      status: STELLASTATUS,
    },
    pointer: string,
    queryLanguage?: string | null,
    social?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    specialties?:  Array< {
      __typename: "Specialty",
      id: string,
      pointer: string,
      designationId: string,
      designation?:  {
        __typename: "StaticObject",
        id: string,
        nameLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        name?: string | null,
        descriptionLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        description?: string | null,
        attribute?:  {
          __typename: "ZFieldValue",
          value?: string | null,
          field?: string | null,
        } | null,
        type?: string | null,
        queryLanguage?: string | null,
        parentId?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      levelId: string,
      catchphraseLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemsIds?: Array< string | null > | null,
      geonews?:  Array< {
        __typename: "DataStruct",
        id: string,
        locationId?: string | null,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        language?: string | null,
        name?: string | null,
        audience?: string | null,
        geonewsExpiryDateTime?: string | null,
        geonewsStartDateTime?: string | null,
        geonewsDuration?: number | null,
        entityId: string,
        sectionLngMap:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        },
        sectionLngId?: string | null,
        sectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refSectionLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        refSectionLngId?: string | null,
        refSectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refInternalLinkLngMap?:  {
          __typename: "ZSectionCompositeMap",
          en?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fr?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        refInternalLinkLng?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fromUserId?: string | null,
        fromUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        toUserId?: string | null,
        toUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        parentId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsIds?: Array< string | null > | null,
      locationId: string,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      parentId: string,
      othersPocUsersIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      pocUserId: string,
      pocUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      sortKey?: string | null,
      specialtyLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      specialtyLngId?: string | null,
      specialtyLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      tagsIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    specialtiesIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteStellaSubscription = {
  onDeleteStella?:  {
    __typename: "Stella",
    id: string,
    bucket?:  {
      __typename: "Bucket",
      id: string,
      web?:  {
        __typename: "ZBucket_Web",
        shortUrl: string,
        isStellactWeb: boolean,
        cloudfrontUrl?: string | null,
        ipAddress?: string | null,
        sslCertificateId?: string | null,
      } | null,
      stellaFolders:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null >,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    bucketId: string,
    entityId: string,
    interfaceLng?:  {
      __typename: "SpecialtyLng",
      id: string,
      gallery?:  {
        __typename: "ZSectionElement",
        firstComposite:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        },
        nextComposites?:  Array< {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null > | null,
        sortKey: string,
        type: string,
      } | null,
      language?: string | null,
      parentId: string,
      type?: string | null,
      itemsTables?:  Array< {
        __typename: "ZItemsTableMap",
        headRow?:  Array< {
          __typename: "ZItemsHeadRowMap",
          sortKey?: string | null,
          string?: string | null,
          type?: string | null,
        } | null > | null,
        itemsIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        sortKey?: string | null,
      } | null > | null,
      indexSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      indexSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      indexPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      geonewsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      geonewsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      gallerySectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      gallerySections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      galleryPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      itemsSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      itemsSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      itemsPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      contactSectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      contactSections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      contactPage?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add1SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add1Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add1Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add2SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add2Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add2Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add3SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add3Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add3Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      add4SectionsSortIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      add4Sections?:  Array< {
        __typename: "Section",
        id: string,
        parentId: string,
        bucketId?: string | null,
        flexbox?:  {
          __typename: "ZFlexbox",
          columns?: number | null,
          totalColumns?: number | null,
        } | null,
        elements?:  Array< {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null > | null,
        title?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        language?: string | null,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      add4Page?:  {
        __typename: "ZPage",
        pageTitle?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        layout?: string | null,
        menu?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null,
    interfaceLngId?: string | null,
    interfaceLngMap?:  {
      __typename: "ZLngMap",
      enId?: string | null,
      frId?: string | null,
    } | null,
    logo?:  {
      __typename: "ZSectionComposite",
      formatRanges?:  Array< {
        __typename: "ZFormatRange",
        offset: number,
        length: number,
        style: string,
      } | null > | null,
      ref?:  {
        __typename: "ZReference",
        linkTarget?: string | null,
        format?: string | null,
        refId?: string | null,
        sizes?:  {
          __typename: "ZSize",
          ll?: boolean | null,
          mm?: boolean | null,
          ss?: boolean | null,
        } | null,
        formatRange?:  {
          __typename: "ZFormatRange",
          offset: number,
          length: number,
          style: string,
        } | null,
        type: string,
      } | null,
      size?: string | null,
      sortKey?: string | null,
      text: string,
    } | null,
    mediaList:  Array< {
      __typename: "ZMedia",
      mediaRef: string,
      sizes: string,
    } | null >,
    name: string,
    options:  {
      __typename: "ZOptions",
      hasDraft: boolean,
      status: STELLASTATUS,
    },
    pointer: string,
    queryLanguage?: string | null,
    social?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    specialties?:  Array< {
      __typename: "Specialty",
      id: string,
      pointer: string,
      designationId: string,
      designation?:  {
        __typename: "StaticObject",
        id: string,
        nameLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        name?: string | null,
        descriptionLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        description?: string | null,
        attribute?:  {
          __typename: "ZFieldValue",
          value?: string | null,
          field?: string | null,
        } | null,
        type?: string | null,
        queryLanguage?: string | null,
        parentId?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      levelId: string,
      catchphraseLng?:  {
        __typename: "ZStringLng",
        en?: string | null,
        fr?: string | null,
      } | null,
      itemsIds?: Array< string | null > | null,
      geonews?:  Array< {
        __typename: "DataStruct",
        id: string,
        locationId?: string | null,
        location?:  {
          __typename: "Location",
          id: string,
          address?:  {
            __typename: "ZAddress",
            municipality?: string | null,
            countryCode?: string | null,
            postCode?: string | null,
            regionCode?: string | null,
            street?: string | null,
          } | null,
          calendar?:  {
            __typename: "ZCalendarMap",
            openingHours?:  {
              __typename: "ZOpeningHoursMap",
              end?: string | null,
              start?: string | null,
            } | null,
            weekly?: string | null,
            yearly?:  {
              __typename: "ZYearlyMap",
              endDate?: string | null,
              startDate?: string | null,
            } | null,
          } | null,
          geolocation?:  {
            __typename: "ZGeolocation",
            latitude?: number | null,
            longitude?: number | null,
          } | null,
          parentId: string,
          municipalityId: string,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        language?: string | null,
        name?: string | null,
        audience?: string | null,
        geonewsExpiryDateTime?: string | null,
        geonewsStartDateTime?: string | null,
        geonewsDuration?: number | null,
        entityId: string,
        sectionLngMap:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        },
        sectionLngId?: string | null,
        sectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refSectionLngMap?:  {
          __typename: "ZLngMap",
          enId?: string | null,
          frId?: string | null,
        } | null,
        refSectionLngId?: string | null,
        refSectionLng?:  {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        refInternalLinkLngMap?:  {
          __typename: "ZSectionCompositeMap",
          en?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          fr?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        refInternalLinkLng?:  {
          __typename: "ZSectionComposite",
          formatRanges?:  Array< {
            __typename: "ZFormatRange",
            offset: number,
            length: number,
            style: string,
          } | null > | null,
          ref?:  {
            __typename: "ZReference",
            linkTarget?: string | null,
            format?: string | null,
            refId?: string | null,
            sizes?:  {
              __typename: "ZSize",
              ll?: boolean | null,
              mm?: boolean | null,
              ss?: boolean | null,
            } | null,
            formatRange?:  {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null,
            type: string,
          } | null,
          size?: string | null,
          sortKey?: string | null,
          text: string,
        } | null,
        fromUserId?: string | null,
        fromUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        toUserId?: string | null,
        toUser?:  {
          __typename: "User",
          id: string,
          administratedPointers?:  Array< {
            __typename: "ZAdminPointer",
            pointer?: string | null,
            ownershipLevel?: string | null,
          } | null > | null,
          specialtiesPoc?: Array< string | null > | null,
          email: string,
          federation: string,
          registrationId: string,
          power: USERPOWER,
          gender: string,
          name:  {
            __typename: "ZName",
            firstname?: string | null,
            lastname?: string | null,
            nickname: string,
          },
          phoneNumbers?:  Array< {
            __typename: "ZPhoneNumber",
            sortKey?: string | null,
            countryCode: string,
            number: string,
            usage?: string | null,
          } | null > | null,
          preferences:  {
            __typename: "ZUserPreferences",
            visibleEmail: boolean,
            visiblePhones: boolean,
          },
          titleLng?:  {
            __typename: "ZStringLng",
            en?: string | null,
            fr?: string | null,
          } | null,
          title?: string | null,
          queryLanguage?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null,
        queryLanguage?: string | null,
        parentId: string,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null > | null,
      geonewsIds?: Array< string | null > | null,
      locationId: string,
      location?:  {
        __typename: "Location",
        id: string,
        address?:  {
          __typename: "ZAddress",
          municipality?: string | null,
          countryCode?: string | null,
          postCode?: string | null,
          regionCode?: string | null,
          street?: string | null,
        } | null,
        calendar?:  {
          __typename: "ZCalendarMap",
          openingHours?:  {
            __typename: "ZOpeningHoursMap",
            end?: string | null,
            start?: string | null,
          } | null,
          weekly?: string | null,
          yearly?:  {
            __typename: "ZYearlyMap",
            endDate?: string | null,
            startDate?: string | null,
          } | null,
        } | null,
        geolocation?:  {
          __typename: "ZGeolocation",
          latitude?: number | null,
          longitude?: number | null,
        } | null,
        parentId: string,
        municipalityId: string,
        type?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      parentId: string,
      othersPocUsersIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      pocUserId: string,
      pocUser?:  {
        __typename: "User",
        id: string,
        administratedPointers?:  Array< {
          __typename: "ZAdminPointer",
          pointer?: string | null,
          ownershipLevel?: string | null,
        } | null > | null,
        specialtiesPoc?: Array< string | null > | null,
        email: string,
        federation: string,
        registrationId: string,
        power: USERPOWER,
        gender: string,
        name:  {
          __typename: "ZName",
          firstname?: string | null,
          lastname?: string | null,
          nickname: string,
        },
        phoneNumbers?:  Array< {
          __typename: "ZPhoneNumber",
          sortKey?: string | null,
          countryCode: string,
          number: string,
          usage?: string | null,
        } | null > | null,
        preferences:  {
          __typename: "ZUserPreferences",
          visibleEmail: boolean,
          visiblePhones: boolean,
        },
        titleLng?:  {
          __typename: "ZStringLng",
          en?: string | null,
          fr?: string | null,
        } | null,
        title?: string | null,
        queryLanguage?: string | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      queryLanguage?: string | null,
      sortKey?: string | null,
      specialtyLngMap?:  {
        __typename: "ZLngMap",
        enId?: string | null,
        frId?: string | null,
      } | null,
      specialtyLngId?: string | null,
      specialtyLng?:  {
        __typename: "SpecialtyLng",
        id: string,
        gallery?:  {
          __typename: "ZSectionElement",
          firstComposite:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          },
          nextComposites?:  Array< {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null > | null,
          sortKey: string,
          type: string,
        } | null,
        language?: string | null,
        parentId: string,
        type?: string | null,
        itemsTables?:  Array< {
          __typename: "ZItemsTableMap",
          headRow?:  Array< {
            __typename: "ZItemsHeadRowMap",
            sortKey?: string | null,
            string?: string | null,
            type?: string | null,
          } | null > | null,
          itemsIds?:  Array< {
            __typename: "ZSortIdMap",
            id?: string | null,
            sortKey?: string | null,
          } | null > | null,
          sortKey?: string | null,
        } | null > | null,
        indexSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        indexSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        indexPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        geonewsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        geonewsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        geonewsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        gallerySectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        gallerySections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        galleryPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        itemsSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        itemsSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        itemsPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        contactSectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        contactSections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        contactPage?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add1SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add1Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add1Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add2SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add2Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add2Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add3SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add3Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add3Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        add4SectionsSortIds?:  Array< {
          __typename: "ZSortIdMap",
          id?: string | null,
          sortKey?: string | null,
        } | null > | null,
        add4Sections?:  Array< {
          __typename: "Section",
          id: string,
          parentId: string,
          bucketId?: string | null,
          flexbox?:  {
            __typename: "ZFlexbox",
            columns?: number | null,
            totalColumns?: number | null,
          } | null,
          elements?:  Array< {
            __typename: "ZSectionElement",
            firstComposite:  {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            },
            nextComposites?:  Array< {
              __typename: "ZSectionComposite",
              formatRanges?:  Array< {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null > | null,
              ref?:  {
                __typename: "ZReference",
                linkTarget?: string | null,
                format?: string | null,
                refId?: string | null,
                sizes?:  {
                  __typename: "ZSize",
                  ll?: boolean | null,
                  mm?: boolean | null,
                  ss?: boolean | null,
                } | null,
                formatRange?:  {
                  __typename: "ZFormatRange",
                  offset: number,
                  length: number,
                  style: string,
                } | null,
                type: string,
              } | null,
              size?: string | null,
              sortKey?: string | null,
              text: string,
            } | null > | null,
            sortKey: string,
            type: string,
          } | null > | null,
          title?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          language?: string | null,
          type?: string | null,
          createdAt: string,
          updatedAt: string,
          version: number,
        } | null > | null,
        add4Page?:  {
          __typename: "ZPage",
          pageTitle?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
          layout?: string | null,
          menu?:  {
            __typename: "ZSectionComposite",
            formatRanges?:  Array< {
              __typename: "ZFormatRange",
              offset: number,
              length: number,
              style: string,
            } | null > | null,
            ref?:  {
              __typename: "ZReference",
              linkTarget?: string | null,
              format?: string | null,
              refId?: string | null,
              sizes?:  {
                __typename: "ZSize",
                ll?: boolean | null,
                mm?: boolean | null,
                ss?: boolean | null,
              } | null,
              formatRange?:  {
                __typename: "ZFormatRange",
                offset: number,
                length: number,
                style: string,
              } | null,
              type: string,
            } | null,
            size?: string | null,
            sortKey?: string | null,
            text: string,
          } | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        version: number,
      } | null,
      tagsIds?:  Array< {
        __typename: "ZSortIdMap",
        id?: string | null,
        sortKey?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      version: number,
    } | null > | null,
    specialtiesIds?:  Array< {
      __typename: "ZSortIdMap",
      id?: string | null,
      sortKey?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    administratedPointers?:  Array< {
      __typename: "ZAdminPointer",
      pointer?: string | null,
      ownershipLevel?: string | null,
    } | null > | null,
    specialtiesPoc?: Array< string | null > | null,
    email: string,
    federation: string,
    registrationId: string,
    power: USERPOWER,
    gender: string,
    name:  {
      __typename: "ZName",
      firstname?: string | null,
      lastname?: string | null,
      nickname: string,
    },
    phoneNumbers?:  Array< {
      __typename: "ZPhoneNumber",
      sortKey?: string | null,
      countryCode: string,
      number: string,
      usage?: string | null,
    } | null > | null,
    preferences:  {
      __typename: "ZUserPreferences",
      visibleEmail: boolean,
      visiblePhones: boolean,
    },
    titleLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    title?: string | null,
    queryLanguage?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    administratedPointers?:  Array< {
      __typename: "ZAdminPointer",
      pointer?: string | null,
      ownershipLevel?: string | null,
    } | null > | null,
    specialtiesPoc?: Array< string | null > | null,
    email: string,
    federation: string,
    registrationId: string,
    power: USERPOWER,
    gender: string,
    name:  {
      __typename: "ZName",
      firstname?: string | null,
      lastname?: string | null,
      nickname: string,
    },
    phoneNumbers?:  Array< {
      __typename: "ZPhoneNumber",
      sortKey?: string | null,
      countryCode: string,
      number: string,
      usage?: string | null,
    } | null > | null,
    preferences:  {
      __typename: "ZUserPreferences",
      visibleEmail: boolean,
      visiblePhones: boolean,
    },
    titleLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    title?: string | null,
    queryLanguage?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    administratedPointers?:  Array< {
      __typename: "ZAdminPointer",
      pointer?: string | null,
      ownershipLevel?: string | null,
    } | null > | null,
    specialtiesPoc?: Array< string | null > | null,
    email: string,
    federation: string,
    registrationId: string,
    power: USERPOWER,
    gender: string,
    name:  {
      __typename: "ZName",
      firstname?: string | null,
      lastname?: string | null,
      nickname: string,
    },
    phoneNumbers?:  Array< {
      __typename: "ZPhoneNumber",
      sortKey?: string | null,
      countryCode: string,
      number: string,
      usage?: string | null,
    } | null > | null,
    preferences:  {
      __typename: "ZUserPreferences",
      visibleEmail: boolean,
      visiblePhones: boolean,
    },
    titleLng?:  {
      __typename: "ZStringLng",
      en?: string | null,
      fr?: string | null,
    } | null,
    title?: string | null,
    queryLanguage?: string | null,
    createdAt: string,
    updatedAt: string,
    version: number,
  } | null,
};
