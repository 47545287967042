import React, { FC, useEffect, useState, Suspense } from 'react';
import { RouteComponentProps } from 'react-router';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ApolloProvider, gql, useLazyQuery } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import { Box, CircularProgress, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import '@geo/media/styles/app.css';
import { getLocation, getSpecialty, getStella, getUser } from '@geo/gql/dist/queries';
import { GetLocationQuery, GetSpecialtyQuery, GetStellaQuery, GetUserQuery } from '@geo/gql/dist/schema';
import { AppProps, StellaInterfaceForUser } from '@geo/utils/dist/AppProps';
import { isMockServer } from '@geo/utils/dist/Config';
import {
  LocationFlatInput,
  SpecialtyNestedInput,
  StellaFlatInput,
  stripTableForCustomInput,
  TableTypeEnum,
  UserFlatInput,
} from '@geo/utils/dist/GqlInputUtils';
import { LangDataMessageMilky } from '@geo/utils/dist/LangData';
import { StaticAuth } from '@geo/utils/dist/StaticAuth';
import { StaticApplication, ApplicationInfo, AppListener } from '@geo/utils/dist/StaticApplication';
import theme from '@geo/utils/dist/Theme';
import { LangEnum } from '@geo/utils/dist/TypeUtils';
import Footer from './components/Footer';
import StaticMenu from './components/StaticMenu';
import { getClient } from './AppClient';
import { getRoutes } from './AppRoute';

const App: FC<AppProps> = (props: AppProps) => {
  const [lang, setLang] = useState<LangEnum>(props.lang);
  const [href, setHref] = useState<string>('');

  const [getUserDataFromDb] = useLazyQuery<GetUserQuery>(gql(getUser), {
    fetchPolicy: 'no-cache',
  });
  const [getStellaDataFromDb] = useLazyQuery<GetStellaQuery>(gql(getStella), {
    fetchPolicy: 'no-cache',
  });
  const [getSpecialtyDataFromDb] = useLazyQuery<GetSpecialtyQuery>(gql(getSpecialty), {
    fetchPolicy: 'no-cache',
  });
  const [getLocationDataFromDb] = useLazyQuery<GetLocationQuery>(gql(getLocation), {
    fetchPolicy: 'no-cache',
  });

  const refreshAuthenticationDataFromDb = async (): Promise<void> => {
    let inputUser: UserFlatInput | null = null;
    let stellaInterface: StellaInterfaceForUser | null = null;
    let inputStella: StellaFlatInput | null = null;
    let inputSpecialty: SpecialtyNestedInput | null = null;
    let inputLocation: LocationFlatInput | null = null;
    if (props.authenticationData.userId !== null) {
      const userReply = (await getUserDataFromDb({ variables: { id: props.authenticationData.userId } })).data;
      if (userReply?.getUser) {
        inputUser = stripTableForCustomInput(TableTypeEnum.User, userReply.getUser) as UserFlatInput;
      }
    }
    if (props.authenticationData.stellaId !== null) {
      const stellaReply = (await getStellaDataFromDb({ variables: { id: props.authenticationData.stellaId } })).data;
      if (
        stellaReply?.getStella &&
        stellaReply?.getStella.specialtiesIds &&
        stellaReply?.getStella.specialtiesIds[0] &&
        stellaReply?.getStella.specialtiesIds[0].id
      ) {
        inputStella = stripTableForCustomInput(TableTypeEnum.Stella, stellaReply.getStella) as StellaFlatInput;
        const specialtyReply = (await getSpecialtyDataFromDb({ variables: { id: stellaReply?.getStella.specialtiesIds[0].id } })).data;
        if (specialtyReply?.getSpecialty) {
          inputSpecialty = stripTableForCustomInput(TableTypeEnum.Specialty, specialtyReply.getSpecialty) as SpecialtyNestedInput;
          const locationReply = (await getLocationDataFromDb({ variables: { id: inputSpecialty.locationId } })).data;
          if (locationReply?.getLocation)
            inputLocation = stripTableForCustomInput(TableTypeEnum.Location, locationReply.getLocation) as LocationFlatInput;
        }
      }
      if (inputStella && inputSpecialty && inputLocation) stellaInterface = { inputStella, inputSpecialty, inputLocation };
    }
    StaticAuth.setAuthenticationFromProps({ ...props.authenticationData, inputUser, stellaInterface });
    props.setAuthenticationData({ ...props.authenticationData, inputUser, stellaInterface });
  };

  useEffect(() => {
    if (
      (props.authenticationData.inputUser === null && props.authenticationData.userId !== null) ||
      (props.authenticationData.stellaInterface === null && props.authenticationData.stellaId !== null)
    )
      refreshAuthenticationDataFromDb();
  }, [props.authenticationData]);

  const applicationListener: AppListener = (info: ApplicationInfo) => {
    // Put href in the state to ensure it is refreshed when url changes
    if (info.href) setHref(href);
    setLang(info.lang);
  };

  useEffect(() => {
    StaticApplication.addApplicationRefreshListener(applicationListener);
    return () => {
      StaticApplication.removeApplicationRefreshListener(applicationListener);
    };
  });

  const Body: FC<AppProps> = (props: AppProps) => {
    return (
      <>
        <Switch>
          {getRoutes(props).map(({ param, component: C, props: p }) => (
            <Route
              key={`route_${param.path}`}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...param}
              render={(routeProps: RouteComponentProps) => {
                routeProps.history.listen(() => {
                  StaticApplication.refreshApplication();
                });
                // eslint-disable-next-line react/jsx-props-no-spreading
                return (
                  <>
                    <StaticMenu {...routeProps} {...{ ...props, lang }} />
                    <C {...routeProps} {...p} />
                  </>
                );
              }}
            />
          ))}
        </Switch>
        <Footer />
      </>
    );
  };

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <>
            <CircularProgress />
            <>{LangDataMessageMilky.loadingData()}</>
          </>
        }
      >
        <Body {...props} />
      </Suspense>
      {isMockServer() ? (
        <Box sx={{ backgroundColor: theme.palette.tertiary.main, color: theme.palette.tertiary.contrastText }}>
          <Typography sx={{ textAlign: 'center' }} variant="body2">
            !!! You are using local amplify environment !!!
          </Typography>
        </Box>
      ) : null}
    </BrowserRouter>
  );
};

export const AppWithProvider: FC<AppProps> = (props: AppProps) => {
  const AppWithClient = withApollo<AppProps>(App);
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={getClient(props.authenticationData.userPower)}>
        <AppWithClient {...props} />
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default AppWithProvider;
