import { Auth } from 'aws-amplify';
import { createAuthLink, AuthOptions, AUTH_TYPE } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { USERPOWER } from '@geo/gql/dist/schema';
import { getSessionToken, gqlUnauthApi, gqlAuthApi, isStellanaut } from '@geo/utils/dist/AwsUtils';
import { traceLog } from '@geo/utils/dist/Trace';

// Link to handle error during query
const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      traceLog(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  } else if (networkError) {
    traceLog(`[Network error]: ${networkError}`);
  } else {
    return forward(operation);
  }
});

// Mock link
const mockLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    if (process.env.REACT_APP_RECORD_MOCK === 'Y') {
      traceLog('Graphql request mock', {
        request: { query: operation.operationName, variables: operation.variables },
        result: data,
      });
    }
    return data;
  });
});

// Add custom links to the given links
const getApolloLinks = (links: Array<ApolloLink>) => {
  if (process.env.NODE_ENV === 'development') {
    return [errorLink, mockLink, ...links];
  }
  return [errorLink, ...links];
};

// Get unauthenticated link
const getUnAuthenticatedLink = (): ApolloLink => {
  const url = gqlUnauthApi.graphqlEndpoint;
  const httpLink = new HttpLink({ uri: url, fetch: fetch });
  const region = gqlUnauthApi.region;
  const auth: AuthOptions = {
    type: AUTH_TYPE.AWS_IAM,
    credentials: () => Auth.currentCredentials().then((data) => data),
  };
  return ApolloLink.from([createAuthLink({ url, region, auth }), createSubscriptionHandshakeLink(url, httpLink)]);
};

// Get authenticated link
const getAuthenticatedLink = (): ApolloLink => {
  const url = gqlAuthApi.graphqlEndpoint;
  const httpLink = new HttpLink({ uri: url, fetch: fetch });
  const region = gqlAuthApi.region;
  const auth: AuthOptions = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
      const token = await Auth.currentSession()
        .then((data) => {
          return data.getIdToken().getJwtToken();
        })
        .catch((err) => err);

      return token;
    },
  };
  return ApolloLink.from([createAuthLink({ url, region, auth }), createSubscriptionHandshakeLink(url, httpLink)]);
};

// Get an apollo client from given links
const getApolloClient = (links: Array<ApolloLink>): ApolloClient<NormalizedCacheObject> => {
  const link = ApolloLink.from(getApolloLinks(links));
  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
};

// Get client to use in local with local graphql server
export const getClientLocal = (): ApolloClient<NormalizedCacheObject> => {
  const url = `http://localhost:${process.env.REACT_APP_GRAPHQL_SERVER_PORT}/`;
  const httpLink = new HttpLink({ uri: url, fetch: fetch });
  return getApolloClient([httpLink]);
};

// Get apollo client
export const getClient = (userPower: USERPOWER): ApolloClient<NormalizedCacheObject> => {
  // Authenticated link context
  const authenticatedLinkContext = setContext((previousContext) => {
    const tokenValue = getSessionToken();
    return {
      ...previousContext,
      props: {
        token: tokenValue,
      },
    };
  });

  // Split link
  const splittedLink = ApolloLink.split(() => isStellanaut(userPower), getAuthenticatedLink(), getUnAuthenticatedLink());

  const links = [authenticatedLinkContext, splittedLink];

  return getApolloClient(links);
};
