import { lazy } from 'react';
import { RouteProps } from 'react-router';
import { AppProps } from '@geo/utils/dist/AppProps';
import { AppPathEnum } from '@geo/utils/dist/TypeUtils';

// To be checked if main component like Home or Map should not be lazy as most of the time loaded
const Cube = lazy(() => import('./components/cube/Cube'));
const CubeCreate = lazy(() => import('./components/cubecreate/CubeCreate'));
const Hello = lazy(() => import('./components/hello/Hello'));
const Hexagon = lazy(() => import('./components/hexagon/Hexagon'));
const Milky = lazy(() => import('./components/milky/Milky'));
const Stellanaut = lazy(() => import('./components/stellanaut/Stellanaut'));
const StellaViewer = lazy(() => import('./components/stella/Stella'));
const NotFound404 = lazy(() => import('./components/NotFound404'));

export const getRoutes = (
  routeProps: AppProps,
): Array<{
  param: RouteProps;
  component:
    | typeof Cube
    | typeof CubeCreate
    | typeof Hello
    | typeof Hexagon
    | typeof Milky
    | typeof Stellanaut
    | typeof StellaViewer
    | typeof NotFound404;
  props: AppProps;
}> => {
  return [
    { param: { exact: true, path: AppPathEnum.root.path }, component: Milky, props: routeProps },
    { param: { exact: true, path: AppPathEnum.hello.path }, component: Hello, props: routeProps },
    { param: { exact: true, path: AppPathEnum.hexagon.path }, component: Hexagon, props: routeProps },
    { param: { exact: true, path: AppPathEnum.cube.path }, component: CubeCreate, props: routeProps },
    { param: { exact: true, path: `${AppPathEnum.cube.element}:id` }, component: Cube, props: routeProps },
    { param: { exact: true, path: `${AppPathEnum.stella.element}:id` }, component: StellaViewer, props: routeProps },
    { param: { exact: true, path: AppPathEnum.stellanaut.path }, component: Stellanaut, props: routeProps },
    { param: { exact: false, path: AppPathEnum.root.path }, component: NotFound404, props: routeProps },
  ];
};
