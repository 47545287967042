const graphqlEndpoint = 'https://kgpmho5etrbhbj7ifbmxjskz7u.appsync-api.eu-west-3.amazonaws.com/graphql';

export const geostellaProdAPI = {
  graphqlEndpoint: graphqlEndpoint,
  region: 'eu-west-3',
  authenticationType: 'AWS_IAM',
};

export const geostellaProdAuthAPI = {
  graphqlEndpoint: graphqlEndpoint,
  region: 'eu-west-3',
  authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};
