export enum AppEnum {
  cube = 'cube',
  hello = 'hello',
  hexagon = 'hexagon',
  milky = 'milky',
  root = 'root',
  stella = 'stella',
  stellanaut = 'stellanaut',
}

export enum DomainsKeysEnum {
  cata = 'cata', // Activity
  catc = 'catc', // Creation
  catt = 'catt', // Tourism
  // cite, // Not activated yet - Event & Culture
  // cits, // Not activated yet - Service
  // comc, // Not activated yet - Community
  // conc, // Not activated yet - Concept
}

export enum EntityKeyEnum {
  a = 'a-e-v2', // Private Stella - dynamic color
  c = 'c-e-v2', // Comment from a user
  e = 'e-e-v2', // Localized event - dynamic color / grey
  g = 'g-e-v2', // Geostella event - green
  h = 'h-e-v2', // Hearticle - red
  i = 'i-e-v2', // General news in Inbox - dynamic color / grey
  m = 'm-e-v2', // Municipality - grey
  n = 'n-e-v2', // Geonote - green
  o = 'o-e-v2', // Comet event - red
  r = 'r-e-v2', // Marca - grey
  t = 't-e-v2', // Spot - grey
  u = 'u-e-v2', // Public Stella - dynamic color
  v = 'v-e-v2', // Spot event - grey
  z = 'z-e-v2', // Milkizz - dynamic color / grey
}

export enum UserGroup {
  cybernaut = 'Y',
  stellanaut = 'N',
  stelladmin = 'M',
  stelladuke = 'K',
  stellaguru = 'G',
}

export interface GeoJsonPointerProperties {
  id: string;
  index: number;
  name: string;
  designation: string;
  designationId: string;
  entityId: string;
  linkedIds?: [
    {
      id: string;
      index: number;
    },
  ];
}

export type Geolocation = {
  latitude: number;
  longitude: number;
};

export enum LangEnum {
  en = 'en',
  fr = 'fr',
}

export type LangMap = Record<LangEnum, string>;

export type LangMapGeneric<T> = Record<LangEnum, T>;

export type DataStructIdsSet = {
  idsSetDataStructId: string;
  idsSetSectionLngMapId: LangMap;
};

export type StellaIdsSet = {
  idsSetStellaId: string;
  idsSetSpecialtyId: string;
  idsSetSpecialtyLngMapId: LangMap;
  idsSetLocationId: string;
  idsSetSectionLngMapId: LangMap;
};

export type MenuPageArray = Record<AppEnum, string>;

export type AppPath = Record<AppEnum, { path: string; element: string }>;
export const AppPathEnum: AppPath = {
  cube: { path: '/cube', element: '/cube/' },
  hello: { path: '/hello', element: '/hello/' },
  hexagon: { path: '/hexagon', element: '/hexagon/' },
  milky: { path: '/milky', element: '/milky/' },
  root: { path: '/', element: '/' },
  stella: { path: '/stella', element: '/stella/' },
  stellanaut: { path: '/stellanaut', element: '/stellanaut/' },
};

export enum MessagePurposeEnum {
  error,
  success,
  info,
}

export type Municipality = {
  name: string;
  geolocation: Geolocation;
  id?: string;
};

export interface MunicipalitiesMap {
  [key: string]: Municipality;
}

export interface MunicipalitiesPerPostCode {
  [key: string]: Array<{ municipalityId: string }>;
}

export enum StaticObjectEnum {
  entity = 0,
  level = 1,
  domain = 2,
  subdomain = 3,
  designation = 4,
}

export enum SuffixEnum {
  none = 'none',
  status = 'status',
  nickname = 'nickname',
}

export type StaticObjectStrings = {
  [key in keyof typeof StaticObjectEnum]: string;
};

export interface StatusContent {
  message: string;
  error: boolean;
  suffix: SuffixEnum;
}

export enum StatusEnum {
  editTextPart1 = 'editTextPart1',
  editImagePart2 = 'editImagePart2',
  noStatus = 'noStatus',
  successfulConnection = 'successfulConnection',
  successfulPasswordChange = 'successfulPasswordChange',
  successfulSignUp = 'successfulSignUp',
  successfulStellaCreation = 'successfulStellaCreation',
  welcome = 'welcome',
}

export const StatusMode: Record<StatusEnum, { isPersistent: boolean }> = {
  editTextPart1: { isPersistent: true },
  editImagePart2: { isPersistent: true },
  noStatus: { isPersistent: false },
  successfulConnection: { isPersistent: false },
  successfulPasswordChange: { isPersistent: false },
  successfulSignUp: { isPersistent: false },
  successfulStellaCreation: { isPersistent: false },
  welcome: { isPersistent: false },
};

export interface DialogContent {
  title: string;
  prompt: string;
  action: string;
}

export enum DialogEnum {
  deletionConfirmation = 'deletionConfirmation',
  stellaAssociation = 'stellaAssociation',
}

export enum StellaUseCaseEnum { // Use cases for the results of getUserByEmail()
  Unset,
  Error, // any non-possible combination -> an error should be displayed
  // 3 use cases of create:
  StellanautCreate, // Cube flow of Stella creation with user is just a Stellanaut (Stelladmin-to-be)
  StelladukeCreateForOtherUser, // creation use case by a Stelladuke (or Stellaguru)
  // 3 use cases of update:
  StelladminUpdate, // Cube flow of Stella update
  StelladukeUpdateAssociatedStella, // Update the associated Stella by Stelladuke or Stellaguru
  StelladukeUpdateUnassociatedStella, // There is a temporary user, plus a real user or not -> Update the corresponding Unassociated Stella
  SectionUpdate, // Feature add-on just for convenience
}

export interface UrlParamsType {
  email: string;
  code: string;
}

export type UseCaseContext = {
  useCaseIndex: StellaUseCaseEnum;
  stelladminId: string | null;
  stelladminEmail: string | null;
  stellaPointer: string | null;
};

export const initializeUseCaseContext = (useCaseIndex = StellaUseCaseEnum.Unset): UseCaseContext => {
  return {
    useCaseIndex,
    stelladminId: null,
    stelladminEmail: null,
    stellaPointer: null,
  };
};

export enum AnchorEnum {
  index = 'index',
  geonews = 'geonews',
  contact = 'contact',
  designation = 'designation',
  mylogo = 'mylogo',
}

export enum HexagonDrawerEnum {
  cloud = 'cloud',
  index = 'index',
  stella = 'stella',
  unassociatedStella = 'unassociatedStella',
}

export enum ZoomLevelEnum {
  world = 0,
  country = 1,
  region = 2,
  department = 3,
}

export enum SectionElementType {
  carousel = 'c',
  header = 'h',
  media = 'm',
  paragraph = 'p',
  table = 't',
}

interface ImageData {
  base64code: string;
  mimeType: string;
}

export interface DecoratedImageData extends ImageData {
  fileName?: string;
  base64string?: string;
}

export const supportedImages: Array<ImageData> = [
  { base64code: '/', mimeType: 'jpg' },
  { base64code: 'i', mimeType: 'png' },
];
