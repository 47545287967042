import { createTheme } from '@mui/material/styles';
import { DomainsKeysEnum } from './TypeUtils';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    tertiary: Palette['primary'];
    neutral: PaletteOptions['primary'];
  }

  interface PaletteColor {
    dim?: string;
    pale?: string;
  }

  interface SimplePaletteColorOptions {
    dim?: string;
    pale?: string;
  }
}

type MainAndDark = {
  main: ColorsEnum;
  dark: ColorsEnum;
};

export enum ColorsEnum {
  c01_dark_green = '#68952d',
  c02_green = '#8ac33d',
  c03_light_green = '#b1d77e',
  c04_pale_green = '#d0e7b2',
  c05_dark_red = '#a9083c',
  c06_red = '#f53371',
  c07_dark_blue = '#2c6c7f',
  c08_blue = '#4fa8c2',
  c09_light_black = '#212121',
  c10_dim_grey = '#5d5d5d',
  c11_dark_grey = '#757575',
  c12_grey = '#888888',
  c13_light_grey = '#cccccc',
  c14_pale_grey = '#e0e0e0',
  c15_dark_cream = '#eeeeee',
  c16_cream = '#fafafa',
  c17_white = '#ffffff',
  hover_cata_domain = '#567b25',
  hover_catc_domain = '#890630',
  hover_catt_domain = '#235766',
}

export const DomainsColors: { [key in keyof typeof DomainsKeysEnum]: MainAndDark } = {
  cata: { main: ColorsEnum.c01_dark_green, dark: ColorsEnum.hover_cata_domain },
  catc: { main: ColorsEnum.c05_dark_red, dark: ColorsEnum.hover_catc_domain },
  catt: { main: ColorsEnum.c07_dark_blue, dark: ColorsEnum.hover_catt_domain },
};

const theme = createTheme({
  palette: {
    primary: {
      dark: ColorsEnum.c01_dark_green,
      main: ColorsEnum.c02_green,
      light: ColorsEnum.c03_light_green,
      pale: ColorsEnum.c04_pale_green,
      contrastText: ColorsEnum.c17_white,
    },
    secondary: {
      dark: ColorsEnum.c05_dark_red,
      main: ColorsEnum.c06_red,
      contrastText: ColorsEnum.c17_white,
    },
    tertiary: {
      dark: ColorsEnum.c07_dark_blue,
      main: ColorsEnum.c08_blue,
      light: '',
      contrastText: ColorsEnum.c17_white,
    },
    neutral: {
      dim: ColorsEnum.c10_dim_grey,
      dark: ColorsEnum.c11_dark_grey,
      main: ColorsEnum.c12_grey,
      light: ColorsEnum.c13_light_grey,
      pale: ColorsEnum.c14_pale_grey,
      contrastText: ColorsEnum.c17_white,
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Roboto"', 'sans-serif'].join(','),
  },
  mixins: {
    toolbar: {
      height: '64px',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});
export default theme;
