import queryString from 'query-string';
import { isBrowser } from 'react-device-detect';
import { isProductionUI, isLocalhost, setIsDebugFlow } from './Config';
import { traceError, traceDebug, traceFlow, DebugFlow } from './Trace';
import { LangEnum, StatusEnum } from './TypeUtils';

export interface ApplicationInfo {
  app: string;
  country: string;
  lang: LangEnum;
  protocol?: string;
  hostname?: string;
  port?: string;
  pathname?: string;
  href?: string;
  debugFlow?: boolean;
  useMock?: string | boolean;
  localMap?: boolean;
  useRedirect: boolean;
}

interface ApplicationInfoOptional {
  app?: string;
  country?: string;
  lang?: LangEnum;
  protocol?: string;
  hostname?: string;
  port?: string;
  pathname?: string;
  href?: string;
  debugFlow?: boolean;
  useMock?: string | boolean;
  localMap?: boolean;
  useRedirect?: boolean;
}

interface UrlData {
  protocol?: string;
  hostname?: string;
  port?: string;
  app?: string;
  country?: string;
  lang?: LangEnum;
  search?: { debugFlow?: boolean; useMock?: string | boolean; localMap?: boolean; useRedirect?: boolean; id?: string };
  hash?: string | queryString.ParsedQuery<string>;
}

export type AppListener = (info: ApplicationInfo, status: StatusEnum, designation: string | null) => void;
export class StaticApplication {
  applicationInfo: ApplicationInfo;
  status: StatusEnum;
  designation: string | null;
  refreshAppListener: Array<AppListener>;

  static Apps = { admin: 'admin', geostella: 'geostella' };
  static Countries = { france: 'fr', italy: 'it' };
  static supportedApp = Object.values(StaticApplication.Apps);
  static supportedCountry = Object.values(StaticApplication.Countries);
  static supportedLanguage = ['fr', 'en'];
  static countryLanguage: { [index: string]: string } = {
    fr: 'fr',
  };

  static supportedCountries = [
    { key: 'fr', text: 'France', value: 'fr' },
    { key: 'it', text: 'Italy', value: 'it' },
  ];

  static defaultLang: LangEnum = LangEnum.en;
  static defaultCountry = StaticApplication.Countries.france;
  static defaultApp = StaticApplication.Apps.geostella;
  static defaultLocalHostname = 'localhost';
  static defaultHostname = 'geostella.com';

  static isProductionUI(): boolean {
    return isProductionUI();
  }

  static isLocalhost(hostname = StaticApplication.internalWindowLocation().hostname): boolean {
    return isLocalhost(hostname);
  }

  static isBrowser(): boolean {
    return isBrowser;
  }

  static publicUrl(): string | undefined {
    return process.env.PUBLIC_URL;
  }

  static getNextMock(): false | 'mock' | 'local_server' {
    if (StaticApplication.getApplicationInfo().useMock === false) return 'mock';
    if (StaticApplication.getApplicationInfo().useMock === 'mock') return 'local_server';
    return false;
  }

  /** Get the current host full url */
  static getCurrentHostUrl(): string {
    const location = StaticApplication.internalWindowLocation();
    return `${location.protocol}//${location.host}`;
  }

  /** Build the current full url based on current information */
  static buildCurrentUrl(): string {
    return StaticApplication.internalBuildHostUrl() + StaticApplication.internalBuildUrl(null, {}, StaticApplication.getApplicationInfo());
  }

  static getUrlSearch(search = StaticApplication.internalWindowLocation().search): queryString.ParsedQuery<string> {
    return queryString.parse(search);
  }

  /** Change the current url by pushing it in the history */
  static changeCurrentUrl(url: string, { data = null, title = '' } = {}): void {
    window.history.pushState(data, title, url);
  }

  /** Replace the current url replacing it in the history */
  static replaceCurrentUrl(url: string, { data = null, title = '' }: { data: string | null; title: string }): void {
    window.history.replaceState(data, title, url);
  }

  /**
   * It builds the hostname based on the input
   * data: can contain the the app, country, protocol, hostname, port
   */
  static buildHostname(data = {}): string {
    return StaticApplication.internalBuildHostData(data).hostname;
  }

  /** return information of the current application (app, country, lang) */
  static getApplicationInfo(): ApplicationInfo {
    return StaticApplication.initInstance().applicationInfo;
  }

  /** return only current navigation language selected */
  static getNavLang(): LangEnum {
    return StaticApplication.getApplicationInfo().lang;
  }

  /** Add a listener to call when the application configuration changes */
  static addApplicationRefreshListener(listener: AppListener): void {
    StaticApplication.initInstance().refreshAppListener.push(listener);
  }

  /** Remove a listener */
  static removeApplicationRefreshListener(listener: AppListener): void {
    const index = StaticApplication.initInstance().refreshAppListener.indexOf(listener);
    if (index > -1) {
      StaticApplication.initInstance().refreshAppListener.splice(index, 1);
    }
  }

  /** To be called when the configuration of the application has changed to refresh it */
  static refreshApplication(): void {
    const info = StaticApplication.internalGetApplicationInfo();
    // Check that href is different from current one, otherwise no need to refresh
    // I have seen that it is triggered several times with the same href
    if (info.href !== StaticApplication.initInstance().applicationInfo.href) {
      StaticApplication.initInstance().applicationInfo = info;
      traceDebug('Refresh application:', info);
      StaticApplication.initInstance().refreshAppListener.forEach((func) => func(info, StaticApplication.initInstance().status, null));
    }
  }

  static isHostIpAddress(hostname = StaticApplication.internalWindowLocation().hostname): boolean {
    return (hostname[0] >= '0' && hostname[0] <= '9') || hostname[0] === '[';
  }

  static filterDictKeys(data: Record<string, unknown>, keys: Array<string>, keep = false): Record<string, unknown> {
    return Object.entries(data)
      .filter(([key]) => {
        const index = keys.indexOf(key);
        return (keep && index !== -1) || (!keep && index === -1);
      })
      .map(([k, v]) => ({ [k]: v }))
      .reduce((a, b) => ({ ...a, ...b }), {});
  }

  static changeApplicationStatus(newStatus: StatusEnum): void {
    const app = StaticApplication.initInstance();
    if (newStatus !== app.status) {
      app.status = newStatus;
      traceDebug('Change application status:', StatusEnum[newStatus]);
      app.refreshAppListener.forEach((func) => func(app.applicationInfo, newStatus, app.designation));
    }
  }

  static getStatus(): StatusEnum {
    return StaticApplication.initInstance().status;
  }

  static changeDesignation(newDesignation: string | null): void {
    const app = StaticApplication.initInstance();
    if (newDesignation !== app.designation) {
      app.designation = newDesignation;
      traceDebug('Change browsing designation:', newDesignation);
      app.refreshAppListener.forEach((func) => func(app.applicationInfo, app.status, newDesignation));
    }
  }

  static getDesignation(): string | null {
    return StaticApplication.initInstance().designation;
  }

  /* ALL BELOW IS FOR INTERNAL USE ONLY */
  static instance: StaticApplication | undefined = undefined;

  constructor() {
    if (StaticApplication.instance !== undefined) {
      traceError('Never instantiate Application directly');
    }

    this.applicationInfo = StaticApplication.internalGetApplicationInfo();
    this.status = StatusEnum.noStatus;
    this.designation = null;
    // List of functions to call whenever the application configuration changes
    this.refreshAppListener = [];
    traceFlow(this, DebugFlow.INIT);
  }

  static initInstance(): StaticApplication {
    if (StaticApplication.instance === undefined) {
      StaticApplication.instance = new StaticApplication();
    }
    return StaticApplication.instance;
  }

  /** Return information retrieved from current location */
  static internalGetApplicationInfo(): ApplicationInfo {
    const location = StaticApplication.internalWindowLocation();
    const { app, country, hostname } = StaticApplication.internalGethostInfo(location.hostname);
    const lang = StaticApplication.internalGetLang(country, { urlLang: location.search });
    const { protocol, pathname, port, href } = location;
    const parsedSearch = queryString.parse(location.search);
    const debugFlow = parsedSearch.debugFlow === 'true';
    const useRedirect = parsedSearch.useRedirect === 'true';
    const localMap = !StaticApplication.isProductionUI() && parsedSearch.localMap === 'true';
    const useMock =
      StaticApplication.isProductionUI() ||
      parsedSearch.useMock === null ||
      parsedSearch.useMock === undefined ||
      typeof parsedSearch.useMock === 'object' ||
      ['mock', 'local_server'].indexOf(parsedSearch.useMock) === -1
        ? false
        : parsedSearch.useMock;

    setIsDebugFlow(debugFlow);

    return { app, country, lang, protocol, hostname, port, pathname, href, debugFlow, useMock, localMap, useRedirect };
  }

  /** Simply return the current location from window object if available */
  static internalWindowLocation(): {
    protocol?: string;
    host?: string;
    hostname: string;
    port?: string;
    pathname?: string;
    search: string;
    hash: string;
    href: string;
  } {
    if (window) return window.location;
    return { hostname: 'localhost', search: '', hash: '', href: '' };
  }

  /** Simply return { protocol, hostname, port } from current location
   * It is used only in internalBuildHostData but if using directly internalWindowLocation
   * it does not work, no idea why
   */
  static internalGetCurrentHost(): {
    protocol: string | undefined;
    hostname: string;
    port: string | undefined;
  } {
    const { protocol, hostname, port } = StaticApplication.internalWindowLocation();
    return { protocol, hostname, port };
  }

  /** Simply return the default hostname based on the givent ip hostname */
  static internalGetDefaultHostnameFromIp(hostname: string): string {
    if (StaticApplication.isLocalhost(hostname)) return StaticApplication.defaultLocalHostname;
    return StaticApplication.defaultHostname;
  }

  static internalUseRedirect(): boolean {
    const { useRedirect } = StaticApplication.getApplicationInfo();
    return useRedirect;
  }

  /**
   * It builds the host data part of the url based on the input
   * data: can contain the app, country, protocol, hostname, port
   * It returns { protocol, hostname, port }
   */
  static internalBuildHostData(data = {}): {
    protocol: string | undefined;
    hostname: string;
    port: string | undefined;
  } {
    const { protocol, hostname: fullHost, port } = { ...StaticApplication.internalGetCurrentHost(), ...data };
    const {
      app,
      country,
      hostname: shortHost,
    } = {
      ...StaticApplication.internalGethostInfo(fullHost),
      // do not take hostname from data into account again
      ...StaticApplication.filterDictKeys(data, ['hostname'], false),
    };
    const useRedirect = StaticApplication.internalUseRedirect();
    let hostname = useRedirect ? shortHost : fullHost;

    if (StaticApplication.isHostIpAddress(hostname)) {
      // if ip address and default app and country, stay with the ip address
      if (app === StaticApplication.defaultApp && country === StaticApplication.defaultCountry) {
        return { protocol, hostname, port };
      }
      // else get the default hostname for this ip address
      hostname = StaticApplication.internalGetDefaultHostnameFromIp(hostname);
    }

    // If the hostname without country and application still has many domains
    // it probably does not support the subdomains as expected, so just return it
    if (hostname.split('.').length > 3) return { protocol, hostname, port };

    if (!useRedirect) return { protocol, hostname, port };

    // only add app domain if not the default app
    if (app !== StaticApplication.defaultApp) hostname = `${app}.${hostname}`;
    hostname = `${country}.${hostname}`;

    return { protocol, hostname, port };
  }

  /**
   * It builds the host part of the url based on the input
   * data: can contain the app, country, protocol, hostname, port
   */
  static internalBuildHostUrl(data = {}): string {
    const { protocol, hostname, port } = StaticApplication.internalBuildHostData(data);
    if (!port) return `${protocol}//${hostname}`;
    return `${protocol}//${hostname}:${port}`;
  }

  /**
   * It builds the path part of the url based on the input path, lang, search, hash
   */
  static internalBuildUrlPath({
    path,
    lang,
    search,
    hash,
  }: {
    path?: string;
    lang?: string;
    search?: string | object;
    hash?: string | queryString.ParsedQuery<string>;
  }): string {
    let url = path;
    if (!url) url = '/';
    if (url[url.length - 1] !== '/') url += '/';
    let param: string | undefined = queryString.stringify({
      ...(typeof search === 'string' ? queryString.parse(search) : search),
      ...{ lang },
    });
    if (param) url += `?${param}`;
    param = typeof hash === 'object' ? queryString.stringify(hash) : hash;
    if (param) url += `#${param}`;
    return url;
  }

  /** path: the url path
   * data: can contain protocol, hostname, port, app, country, lang, search param and hash param
   * applicationInfo: info from the application (app, country, lang)
   */
  static internalBuildUrl(path: string | null, data: UrlData = {}, applicationInfo: ApplicationInfoOptional = {}): string {
    const { app, country, lang, search = {}, hash } = { ...applicationInfo, ...data };
    const { protocol, hostname, port } = data;

    // Add option flags if needed
    if (search.debugFlow === undefined && applicationInfo.debugFlow) search.debugFlow = applicationInfo.debugFlow;
    if (search.useMock === undefined && applicationInfo.useMock) search.useMock = applicationInfo.useMock;
    if (search.localMap === undefined && applicationInfo.localMap) search.localMap = applicationInfo.localMap;
    if (search.useRedirect === undefined && applicationInfo.useRedirect) search.useRedirect = applicationInfo.useRedirect;

    // In case any part of the host changes, we need to build a full url
    if (protocol || hostname || port) {
      return (
        StaticApplication.internalBuildHostUrl(data) +
        StaticApplication.internalBuildUrlPath({
          path: path ? path : StaticApplication.internalWindowLocation().pathname,
          lang,
          search,
          hash,
        })
      );
    }

    // In case the app or country change, return the root of the app
    if (Object.keys(applicationInfo).length && (app !== applicationInfo.app || country !== applicationInfo.country)) {
      return StaticApplication.internalBuildUrlPath({ path: StaticApplication.internalBuildHostUrl({ app, country }), lang });
    }

    // First, if there is no path provided, use the data from window.location
    if (!path) {
      const location = StaticApplication.internalWindowLocation();
      if (location.pathname) {
        const currentData: UrlData = {};
        if (location.search) currentData.search = { ...queryString.parse(location.search), ...data.search };
        if (location.hash) currentData.hash = { ...queryString.parse(location.hash), ...(typeof data.hash === 'object' ? data.hash : {}) };
        return StaticApplication.internalBuildUrl(location.pathname, { ...data, ...currentData }, applicationInfo);
      }
    }

    return StaticApplication.internalBuildUrlPath({
      path: path ? path : undefined,
      lang,
      search,
      hash,
    });
  }

  /** urlLang: language passed in the url through "lang" param
   *   urlLang can be the full url search param
   * preferredLang: language stored in user preference or cookie
   */
  static internalGetLang(
    country: string,
    { urlLang, preferredLang = undefined }: { urlLang?: string; preferredLang?: string } = {},
  ): LangEnum {
    if (urlLang !== undefined) {
      if (urlLang.length === 2) {
        if (StaticApplication.supportedLanguage.indexOf(urlLang as LangEnum) !== -1) return urlLang as LangEnum;
      } else {
        const { lang } = queryString.parse(urlLang);
        if (lang !== undefined && typeof lang === 'string' && StaticApplication.supportedLanguage.indexOf(lang as LangEnum) !== -1)
          return lang as LangEnum;
      }
    }
    if (preferredLang !== undefined && StaticApplication.supportedLanguage.indexOf(preferredLang as LangEnum) !== -1)
      return preferredLang as LangEnum;
    if (StaticApplication.countryLanguage[country]) return StaticApplication.countryLanguage[country] as LangEnum;
    return StaticApplication.defaultLang;
  }

  /** Return country and application based on given hostname, and the hostname without those information */
  static internalGethostInfo(hostname: string): {
    country: string;
    app: string;
    hostname: string;
  } {
    const defaultInfo = { country: StaticApplication.defaultCountry, app: StaticApplication.defaultApp, hostname };
    const info = { ...defaultInfo };

    // if empty hostname or is IPv4 or IPv6
    if (!hostname || !hostname.length || StaticApplication.isHostIpAddress(hostname)) return defaultInfo;

    let domains = hostname.split('.');
    // Remove www if there
    if (domains.length && domains[0] === 'www') {
      domains = domains.slice(1);
    }
    // Get the country first
    if (domains.length && StaticApplication.supportedCountry.indexOf(domains[0]) !== -1) {
      [info.country] = domains;
      domains = domains.slice(1);

      // Then get the application
      if (domains.length && domains[0] !== StaticApplication.defaultApp && StaticApplication.supportedApp.indexOf(domains[0]) !== -1) {
        [info.app] = domains;
        domains = domains.slice(1);
      }
    }

    info.hostname = domains.join('.');
    return info;
  }
}

interface ApplicationInfoMock extends ApplicationInfo {
  hurl: string;
}
/** This should be used only in unit test to mock Application
 * location can have protocol, hostname, port and pathname
 * if location has href, all the other parameters will be discarded and calculated based on the href
 */
export const mockApplication = ({
  app = StaticApplication.defaultApp,
  country = StaticApplication.defaultCountry,
  lang = StaticApplication.countryLanguage[country],
  location = {
    protocol: 'http:',
    hostname: 'localhost',
    pathname: '/',
  },
  useRedirect = false,
}: {
  app: string;
  country: string;
  lang: string;
  location: { protocol: string; hostname: string; pathname: string; port?: string; href?: string; search?: string; hash?: string };
  useRedirect: boolean;
}): ApplicationInfoMock => {
  const windowLocation = { ...location };
  if (!windowLocation.href) {
    StaticApplication.internalUseRedirect = () => useRedirect;
    if (!windowLocation.protocol) windowLocation.protocol = 'http:';
    if (!windowLocation.hostname) windowLocation.hostname = 'localhost';
    if (!windowLocation.pathname) windowLocation.pathname = '/';
    windowLocation.href = StaticApplication.internalBuildUrlPath({
      path:
        StaticApplication.internalBuildHostUrl({
          app,
          country,
          protocol: windowLocation.protocol,
          hostname: windowLocation.hostname,
          port: windowLocation.port,
        }) + windowLocation.pathname,
      lang,
      search: windowLocation.search,
      hash: windowLocation.hash,
    });
    if (useRedirect && windowLocation.href.indexOf('useRedirect=') === -1) {
      windowLocation.href += '&useRedirect=true';
    }
  } else {
    StaticApplication.internalUseRedirect = () =>
      useRedirect || (windowLocation.href !== undefined && windowLocation.href.indexOf('useRedirect=true') !== -1);
  }
  const mockedWindowLocation = new URL(windowLocation.href);
  StaticApplication.internalWindowLocation = () => mockedWindowLocation;
  delete StaticApplication.instance;
  return { ...StaticApplication.getApplicationInfo(), hurl: StaticApplication.buildCurrentUrl() };
};
