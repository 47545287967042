import awsExports from './aws-exports';

export const isLocalhost = (hostname = window.location.hostname): boolean => {
  if (['127.0.0.1', '::1'].indexOf(hostname) !== -1) return true;
  const domains = hostname.split('.');
  if (domains[domains.length - 1] === 'localhost') return true;
  if (domains.length === 4 && domains[0] === '192' && domains[1] === '168') return true;
  return false;
};

let RunningTestMode = false;
let DebugFlow = false;

export const isRunningTestMode = (): boolean => {
  return RunningTestMode;
};

export const setIsRunningTestMode = (value: boolean): void => {
  RunningTestMode = value;
};

export const isDebugFlow = (): boolean => {
  return !isProductionUI() && DebugFlow;
};

export const setIsDebugFlow = (value: boolean): void => {
  DebugFlow = value;
};

export const isProductionUI = (): boolean => {
  return process.env.NODE_ENV === 'production' || window.location.hostname === 'www.localgeostella.com';
};

export const isMockServer = (): boolean => {
  return awsExports.aws_appsync_graphqlEndpoint.indexOf('20002') !== -1;
};
