import { alpha } from '@mui/material';
import { SxProps } from '@mui/system';
import theme, { ColorsEnum, DomainsColors } from './Theme';
import { DomainsKeysEnum } from './TypeUtils';

export enum PaletteEnum {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  neutral = 'neutral',
}

interface sxColorsParameters {
  palette: string;
  hover: boolean;
  bg: boolean;
}

interface sxParameters {
  size?: string;
  colors?: sxColorsParameters;
}

const getColorScheme = (colors: sxColorsParameters): SxProps => {
  if (!colors.bg)
    return {
      color: colors.palette + '.main',
      ':hover': colors.hover
        ? {
            color: colors.palette + '.dark',
          }
        : null,
    };
  else
    return {
      color: ColorsEnum.c17_white,
      backgroundColor: colors.palette + '.main',
      ':hover': colors.hover
        ? {
            backgroundColor: colors.palette + '.dark',
          }
        : null,
    };
};

const getSx = (params: sxParameters): SxProps => {
  if (!params.colors && params.size) return { height: [params.size + 'em'], width: [params.size + 'em'] };
  else if (params.colors && !params.size) return getColorScheme(params.colors);
  else if (params.colors && params.size)
    return {
      ...getColorScheme(params.colors),
      height: [params.size + 'em'],
      width: [params.size + 'em'],
    };
  return { color: 'primary.contrastText' };
};

// Those styles are invariant, not changeable with palette hue
interface sxLayoutStylesType {
  iconBigAndNoBgStyle: SxProps;
  iconGreyStyle: SxProps;
  iconNoBgStyle: SxProps;
  iconSizeStyle: SxProps;
  paddingStyle: SxProps;
  paleStyle: SxProps;
  paperStyle: SxProps;
  paperStyleForCube: SxProps;
}

export const sxLayout: sxLayoutStylesType = {
  iconBigAndNoBgStyle: { ...getSx({ size: '4' }), color: ColorsEnum.c17_white },
  iconGreyStyle: { ...getSx({ size: '1.3' }), color: ColorsEnum.c11_dark_grey },
  iconNoBgStyle: { ...getSx({ size: '1.5' }), color: ColorsEnum.c17_white },
  iconSizeStyle: getSx({ size: '2' }),
  paddingStyle: { px: ['0em', '1em', '2em'], py: '1em' },
  paleStyle: { backgroundColor: alpha(`${theme.palette.primary.pale}`, 0.5) },
  paperStyle: { gap: 2, width: ['90%', '80%', '80%', '60%'] },
  paperStyleForCube: { width: ['100%', '100%', '95%'] },
};

// Those styles are called with sx function with palette hue variable (primary/secondary/tertiary/neutral)
export interface sxColoredStylesType {
  activeIconStyle: SxProps;
  activeMenuItemStyle: SxProps;
  activeSmallIconStyle: SxProps;
  activeWideIconStyle: SxProps;
  bgColorStyle: SxProps;
  colorStyle: SxProps;
  expandStyle: SxProps;
  hoverIconStyle: SxProps;
  hoverStyle: SxProps;
  hoverTextStyle: SxProps;
  hoverSmallIconStyle: SxProps;
  hoverWideIconStyle: SxProps;
  hoverXWideIconStyle: SxProps;
  menuItemStyle: SxProps;
  textInBannerStyle: SxProps;
}

export const sx = (palette: PaletteEnum): sxColoredStylesType => {
  return {
    activeIconStyle: {
      ...getSx({ size: '1.5' }),
      color: palette + '.dark',
      backgroundColor: 'transparent',
    },
    activeMenuItemStyle: {
      color: ColorsEnum.c17_white,
      backgroundColor: palette + '.dark',
      ':hover': {
        backgroundColor: palette + '.dark',
      },
    },
    activeSmallIconStyle: {
      ...getSx({ size: '1.0' }),
      color: palette + '.dark',
      backgroundColor: 'transparent',
    },
    activeWideIconStyle: {
      ...getSx({ size: '2.0' }),
      color: palette + '.dark',
      backgroundColor: 'transparent',
    },
    bgColorStyle: getSx({ colors: { palette, hover: false, bg: true } }),
    colorStyle: getSx({ colors: { palette, hover: false, bg: false } }),
    expandStyle: getSx({ colors: { palette, hover: false, bg: true }, size: '1' }),
    hoverIconStyle: getSx({ colors: { palette, hover: true, bg: false }, size: '1.5' }),
    hoverStyle: getSx({ colors: { palette, hover: true, bg: true } }),
    hoverTextStyle: getSx({ colors: { palette, hover: true, bg: false } }),
    hoverSmallIconStyle: getSx({ colors: { palette, hover: true, bg: false }, size: '1.0' }),
    hoverWideIconStyle: getSx({ colors: { palette, hover: true, bg: false }, size: '2.0' }),
    hoverXWideIconStyle: getSx({ colors: { palette, hover: true, bg: false }, size: '4.0' }),
    menuItemStyle: {
      color: ColorsEnum.c17_white,
      backgroundColor: palette + '.main',
      ':hover': {
        backgroundColor: palette + '.dark',
      },
    },
    textInBannerStyle: getSx({ colors: { palette, hover: true, bg: true }, size: '1.8' }),
  };
};

export const sxDomain = (domain: DomainsKeysEnum): SxProps => {
  return {
    height: ['4em', '4em', '5em', '5em', '6em'],
    width: ['4em', '4em', '5em', '5em', '6em'],
    color: ColorsEnum.c17_white,
    backgroundColor: DomainsColors[domain].main,
    ':hover': {
      backgroundColor: DomainsColors[domain].dark,
    },
  };
};
