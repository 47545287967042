import {
  Bucket,
  CreateBucketInput,
  CreateDataStructInput,
  CreateLocationInput,
  CreateSectionInput,
  CreateSpecialtyInput,
  CreateSpecialtyLngInput,
  CreateStellaInput,
  CreateUserInput,
  DataStruct,
  Location,
  Section,
  Specialty,
  SpecialtyLng,
  Stella,
  UpdateBucketInput,
  UpdateDataStructInput,
  UpdateLocationInput,
  UpdateSectionInput,
  UpdateSpecialtyInput,
  UpdateSpecialtyLngInput,
  UpdateStellaInput,
  UpdateUserInput,
  User,
  ZAddress,
  ZAddressInput,
  ZAdminPointer,
  ZAdminPointerInput,
  ZBucket_Web,
  ZBucket_WebInput,
  ZFormatRange,
  ZFormatRangeInput,
  ZGeolocation,
  ZGeolocationInput,
  ZLngMap,
  ZLngMapInput,
  ZMedia,
  ZMediaInput,
  ZName,
  ZNameInput,
  ZOptions,
  ZOptionsInput,
  ZPage,
  ZPageInput,
  ZPhoneNumber,
  ZPhoneNumberInput,
  ZReference,
  ZReferenceInput,
  ZSectionComposite,
  ZSectionCompositeInput,
  ZSectionElement,
  ZSectionElementInput,
  ZSortIdMap,
  ZSortIdMapInput,
  ZStringLng,
  ZStringLngInput,
  ZUserPreferences,
  ZUserPreferencesInput,
} from '@geo/gql/dist/schema';

export type PickOptional<T, K extends keyof T> = {
  [P in K]?: T[P];
};

type ZType =
  | ZAddress
  | ZAdminPointer
  | ZBucket_Web
  | ZFormatRange
  | ZGeolocation
  | ZLngMap
  | ZMedia
  | ZName
  | ZOptions
  | ZPage
  | ZPhoneNumber
  | ZReference
  | ZSectionComposite
  | ZSectionElement
  | ZSortIdMap
  | ZStringLng
  | ZUserPreferences;
type ZInput =
  | ZAddressInput
  | ZAdminPointerInput
  | ZBucket_WebInput
  | ZFormatRangeInput
  | ZGeolocationInput
  | ZLngMapInput
  | ZMediaInput
  | ZNameInput
  | ZOptionsInput
  | ZPageInput
  | ZPhoneNumberInput
  | ZReferenceInput
  | ZSectionCompositeInput
  | ZSectionElementInput
  | ZSortIdMapInput
  | ZStringLng
  | ZUserPreferencesInput;
// ZType and ZInputExtended are almost the same type except that __typename is optional in ZInputExtended, which enables its deletion
type ZInputExtended = PickOptional<ZType, '__typename'>;

type TableType = Bucket | DataStruct | Location | Section | Specialty | SpecialtyLng | Stella | User;
type TableCreateInput =
  | CreateBucketInput
  | CreateDataStructInput
  | CreateLocationInput
  | CreateSectionInput
  | CreateSpecialtyInput
  | CreateSpecialtyLngInput
  | CreateStellaInput
  | CreateUserInput;
type TableUpdateInput =
  | UpdateBucketInput
  | UpdateDataStructInput
  | UpdateLocationInput
  | UpdateSectionInput
  | UpdateSpecialtyInput
  | UpdateSpecialtyLngInput
  | UpdateStellaInput
  | UpdateUserInput;
export enum TableTypeEnum {
  Bucket = 'Bucket',
  DataStruct = 'DataStruct',
  Location = 'Location',
  Section = 'Section',
  Specialty = 'Specialty',
  SpecialtyLng = 'SpecialtyLng',
  Stella = 'Stella',
  User = 'User',
}
// TableInputExtended is similar to TableInput except that __typename, createdAt, updatedAt and version are optional
type TableInputExtended = PickOptional<TableType, '__typename' | 'createdAt' | 'updatedAt' | 'version'>;
export interface BucketFlatInput extends CreateBucketInput {
  expectedVersion: number;
  id: string;
}
export interface DataStructNestedInput extends CreateDataStructInput {
  expectedVersion: number;
  id: string;
  sectionLng: SectionFlatInput | null;
}
export interface LocationFlatInput extends CreateLocationInput {
  expectedVersion: number;
  id: string;
}
export interface SectionFlatInput extends CreateSectionInput {
  expectedVersion: number;
  id: string;
}
export interface SpecialtyNestedInput extends CreateSpecialtyInput {
  expectedVersion: number;
  id: string;
  geonews: (DataStructNestedInput | null)[] | null | undefined;
}
export interface SpecialtyFlatInput extends CreateSpecialtyInput {
  expectedVersion: number;
  id: string;
}
export interface SpecialtyLngFlatInput extends CreateSpecialtyLngInput {
  expectedVersion: number;
  id: string;
}
export interface StellaFlatInput extends CreateStellaInput {
  expectedVersion: number;
  id: string;
}
export interface StellaNestedInput extends CreateStellaInput {
  expectedVersion: number;
  id: string;
  bucket: BucketFlatInput;
}
export interface UserFlatInput extends CreateUserInput {
  expectedVersion: number;
  id: string;
}
type TableNestedInput =
  | CreateBucketInput
  | DataStructNestedInput
  | CreateLocationInput
  | CreateSectionInput
  | SpecialtyNestedInput
  | CreateSpecialtyLngInput
  | CreateStellaInput
  | CreateUserInput;

export const fillExpectedVersion = (tableType: TableType): { expectedVersion: number } => {
  return {
    expectedVersion: tableType.version !== null && tableType.version !== undefined ? tableType.version : 1,
  };
};

const stripToZInput = (schemaType: ZInputExtended): ZInput => {
  delete schemaType.__typename;
  return schemaType as ZInput;
};

const stripToCreateInput = (schemaType: TableInputExtended): TableCreateInput => {
  const copy: TableInputExtended = { ...schemaType };
  delete copy.__typename;
  delete copy.createdAt;
  delete copy.updatedAt;
  delete copy.version;
  return copy as TableCreateInput;
};

const stripZAdminPointers = (zAdminPointers: (ZAdminPointer | null)[] | null | undefined): ZAdminPointerInput[] => {
  const zAdminPointersInput: ZAdminPointerInput[] = [];
  if (zAdminPointers !== null && zAdminPointers !== undefined) {
    zAdminPointers.forEach((zAdminPointer) => {
      if (zAdminPointer != null) {
        zAdminPointersInput.push(stripToZInput({ ...zAdminPointer }) as ZAdminPointerInput);
      }
    });
  }
  return zAdminPointersInput;
};

const stripZSectionComposite = (zSectionComposite: ZSectionComposite): ZSectionCompositeInput => {
  if (zSectionComposite !== undefined) {
    const formatRangesInput: ZFormatRangeInput[] = [];
    if (zSectionComposite.formatRanges !== null && zSectionComposite.formatRanges !== undefined) {
      zSectionComposite.formatRanges.forEach((formatRange) => {
        if (formatRange != null) {
          formatRangesInput.push(stripToZInput({ ...formatRange }) as ZFormatRangeInput);
        }
      });
    }
    return {
      ...(stripToZInput({ ...zSectionComposite }) as ZSectionCompositeInput),
      formatRanges: formatRangesInput,
      ref: zSectionComposite.ref ? (stripToZInput({ ...zSectionComposite.ref }) as ZReferenceInput) : null,
    };
  }
  return zSectionComposite;
};

const stripZSectionElement = (zSectionElement: ZSectionElement): ZSectionElementInput => {
  if (
    zSectionElement !== null &&
    zSectionElement !== undefined &&
    zSectionElement.firstComposite !== null &&
    zSectionElement.firstComposite !== undefined &&
    zSectionElement.sortKey !== undefined &&
    zSectionElement.type !== undefined
  ) {
    const nextComposites: ZSectionCompositeInput[] = [];
    if (zSectionElement.nextComposites !== null && zSectionElement.nextComposites !== undefined) {
      zSectionElement.nextComposites.forEach((composite) => {
        if (composite !== null && composite !== undefined) {
          nextComposites.push(stripZSectionComposite(composite));
        }
      });
    }
    return {
      ...(stripToZInput({ ...zSectionElement }) as ZSectionElementInput),
      firstComposite: stripZSectionComposite(zSectionElement.firstComposite),
      nextComposites: nextComposites,
    };
  }
  return zSectionElement;
};

const stripZTypeList = (zTypeList: (ZType | null)[] | null | undefined): ZInput[] => {
  const zInput: ZInput[] = [];
  if (zTypeList !== null && zTypeList !== undefined) {
    zTypeList.forEach((zType) => {
      if (zType != null) {
        zInput.push(stripToZInput({ ...zType }) as ZInput);
      }
    });
  }
  return zInput;
};

const stripZPhoneNumberList = (zPhoneNumbers: (ZPhoneNumber | null)[] | null | undefined): ZPhoneNumberInput[] => {
  const zPhoneNumbersInput: ZPhoneNumberInput[] = [];
  if (zPhoneNumbers !== null && zPhoneNumbers !== undefined) {
    zPhoneNumbers.forEach((zPhoneNumber) => {
      if (zPhoneNumber != null) {
        zPhoneNumbersInput.push(stripToZInput({ ...zPhoneNumber }) as ZPhoneNumberInput);
      }
    });
  }
  return zPhoneNumbersInput;
};

const stripZPage = (zPage: ZPage): ZPageInput => {
  return {
    ...(stripToZInput({ ...zPage }) as ZPageInput),
    pageTitle: zPage.pageTitle ? stripZSectionComposite(zPage.pageTitle) : null,
    menu: zPage.menu ? stripZSectionComposite(zPage.menu) : null,
  };
};
const stripBucket = (data: TableType): CreateBucketInput => {
  const bucket = data as Bucket;
  const stellaFoldersInput: ZSortIdMapInput[] =
    bucket.stellaFolders !== null && bucket.stellaFolders !== undefined ? (stripZTypeList(bucket.stellaFolders) as ZSortIdMapInput[]) : [];
  return {
    ...(stripToCreateInput(bucket) as CreateBucketInput),
    web: bucket.web ? (stripToZInput({ ...bucket.web }) as ZBucket_WebInput) : null,
    stellaFolders: stellaFoldersInput,
  };
};
const stripDataStruct = (data: TableType): DataStructNestedInput => {
  const dataStruct = data as DataStruct;
  return {
    ...(stripToCreateInput(dataStruct) as CreateDataStructInput),
    sectionLngMap: stripToZInput({ ...dataStruct.sectionLngMap }) as ZLngMapInput,
    sectionLng: dataStruct.sectionLng
      ? { ...stripSection(dataStruct.sectionLng), ...fillExpectedVersion(dataStruct.sectionLng), id: dataStruct.sectionLng.id }
      : null,
    ...fillExpectedVersion(dataStruct),
    id: dataStruct.id,
  };
};
const stripLocation = (data: TableType): CreateLocationInput => {
  const location = data as Location;
  return {
    ...(stripToCreateInput(location) as CreateLocationInput),
    address: location.address ? (stripToZInput({ ...location.address }) as ZAddressInput) : null,
    geolocation: location.geolocation ? (stripToZInput({ ...location.geolocation }) as ZGeolocationInput) : null,
  };
};
const stripSection = (data: TableType): CreateSectionInput => {
  const section = data as Section;
  const elementsInput: ZSectionElementInput[] = [];
  if (section.elements !== null && section.elements !== undefined) {
    section.elements.forEach((element) => {
      if (element !== null && element !== undefined) {
        elementsInput.push(stripZSectionElement(element));
      }
    });
  }
  return {
    ...(stripToCreateInput(section) as CreateSectionInput),
    elements: elementsInput,
    title: section.title ? stripZSectionComposite(section.title) : null,
  };
};
export const stripSpecialtyToFlatInput = (data: Specialty): SpecialtyFlatInput => {
  const specialty = Object.assign({}, data);
  delete specialty.designation;
  delete specialty.location;
  delete specialty.pocUser;
  delete specialty.specialtyLng;
  delete specialty.geonews;
  return {
    ...(stripToCreateInput(specialty) as CreateSpecialtyInput),
    catchphraseLng: specialty.catchphraseLng ? (stripToZInput({ ...specialty.catchphraseLng }) as ZStringLngInput) : null,
    specialtyLngMap: specialty.specialtyLngMap ? (stripToZInput({ ...specialty.specialtyLngMap }) as ZLngMapInput) : null,
    ...fillExpectedVersion(specialty),
    id: specialty.id,
  };
};
const stripSpecialty = (data: TableType): SpecialtyNestedInput => {
  const specialty = data as Specialty;
  delete specialty.designation;
  delete specialty.location;
  delete specialty.pocUser;
  delete specialty.specialtyLng;
  return {
    ...(stripToCreateInput(specialty) as CreateSpecialtyInput),
    catchphraseLng: specialty.catchphraseLng ? (stripToZInput({ ...specialty.catchphraseLng }) as ZStringLngInput) : null,
    specialtyLngMap: specialty.specialtyLngMap ? (stripToZInput({ ...specialty.specialtyLngMap }) as ZLngMapInput) : null,
    geonews:
      specialty.geonews && specialty.geonews[0] && specialty.geonews[0] !== null
        ? specialty.geonews.map((item: DataStruct | null) => (item !== null ? stripDataStruct(item) : null))
        : null,
    ...fillExpectedVersion(specialty),
    id: specialty.id,
  };
};
const stripSpecialtyLng = (data: TableType): CreateSpecialtyLngInput => {
  const specialtyLng = data as SpecialtyLng;
  delete specialtyLng.add1Sections;
  delete specialtyLng.add2Sections;
  delete specialtyLng.add3Sections;
  delete specialtyLng.add4Sections;
  delete specialtyLng.contactSections;
  delete specialtyLng.gallerySections;
  delete specialtyLng.geonewsSections;
  delete specialtyLng.indexSections;
  delete specialtyLng.itemsSections;
  return {
    ...(stripToCreateInput(specialtyLng) as CreateSpecialtyLngInput),
    indexSectionsSortIds: specialtyLng?.indexSectionsSortIds,
    indexPage: specialtyLng.indexPage ? stripZPage(specialtyLng.indexPage) : null,
  };
};
const stripStella = (data: TableType): CreateStellaInput => {
  const stella = data as Stella;
  delete stella.bucket;
  delete stella.interfaceLng;
  delete stella.specialties;
  return {
    ...(stripToCreateInput(stella) as CreateStellaInput),
    logo: stella.logo ? stripZSectionComposite(stella.logo) : null,
    options: stripToZInput(stella.options) as ZOptionsInput,
    mediaList: stripZTypeList(stella.mediaList) as ZMediaInput[],
    specialtiesIds: stella.specialtiesIds ? (stripZTypeList(stella.specialtiesIds) as ZSortIdMapInput[]) : null,
    social: stella.social ? (stripZTypeList(stella.social) as ZSortIdMapInput[]) : null,
  };
};
const stripUser = (data: TableType): CreateUserInput => {
  const user = data as User;
  // for an unknown reason, name: stripZType(user.name) as ZNameInput, doesn't work: __typename is still there
  return {
    ...(stripToCreateInput(user) as CreateUserInput),
    administratedPointers: user.administratedPointers ? stripZAdminPointers(user.administratedPointers) : null,
    name: {
      nickname: user.name.nickname,
      firstname: user.name.firstname,
      lastname: user.name.lastname,
    },
    phoneNumbers: user.phoneNumbers ? stripZPhoneNumberList(user.phoneNumbers) : null,
    titleLng: user.titleLng ? (stripToZInput({ ...user.titleLng }) as ZStringLngInput) : null,
    preferences: stripToZInput({ ...user.preferences }) as ZUserPreferencesInput,
  };
};

const stripArray: Record<TableTypeEnum, (data: TableType) => TableNestedInput> = {
  [TableTypeEnum.Bucket]: stripBucket,
  [TableTypeEnum.DataStruct]: stripDataStruct,
  [TableTypeEnum.Location]: stripLocation,
  [TableTypeEnum.Section]: stripSection,
  [TableTypeEnum.Specialty]: stripSpecialty,
  [TableTypeEnum.SpecialtyLng]: stripSpecialtyLng,
  [TableTypeEnum.Stella]: stripStella,
  [TableTypeEnum.User]: stripUser,
};

type typeArray = {
  [TableTypeEnum.Bucket]: Bucket;
  [TableTypeEnum.DataStruct]: DataStruct;
  [TableTypeEnum.Location]: Location;
  [TableTypeEnum.Section]: Section;
  [TableTypeEnum.Specialty]: Specialty;
  [TableTypeEnum.SpecialtyLng]: SpecialtyLng;
  [TableTypeEnum.Stella]: Stella;
  [TableTypeEnum.User]: User;
};

export const stripTableForUpdate = (tableType: TableTypeEnum, tableItem: TableType): TableUpdateInput => {
  const deepCopy = Object.assign({}, tableItem);
  return { ...fillExpectedVersion(tableItem), ...stripArray[tableType](deepCopy as typeArray[typeof tableType]), id: tableItem.id };
};

export const stripTableForCustomInput = (
  tableType: TableTypeEnum,
  tableItem: TableType,
):
  | BucketFlatInput
  | DataStructNestedInput
  | LocationFlatInput
  | SectionFlatInput
  | SpecialtyLngFlatInput
  | SpecialtyNestedInput
  | StellaFlatInput
  | UserFlatInput => {
  const deepCopy = Object.assign({}, tableItem);
  return {
    ...fillExpectedVersion(tableItem),
    ...stripArray[tableType](deepCopy as typeArray[typeof tableType]),
    id: tableItem.id,
  };
};
