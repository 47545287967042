/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPointerByLng = /* GraphQL */ `
  query GetPointerByLng($pointer: ID!, $language: String!) {
    getPointerByLng(pointer: $pointer, language: $language) {
      pointer
      queryLanguage
      stellaId
      stella {
        id
        bucket {
          id
          web {
            shortUrl
            isStellactWeb
            cloudfrontUrl
            ipAddress
            sslCertificateId
          }
          stellaFolders {
            id
            sortKey
          }
          createdAt
          updatedAt
          version
        }
        bucketId
        entityId
        interfaceLng {
          id
          gallery {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          language
          parentId
          type
          itemsTables {
            headRow {
              sortKey
              string
              type
            }
            itemsIds {
              id
              sortKey
            }
            sortKey
          }
          indexSectionsSortIds {
            id
            sortKey
          }
          indexSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          indexPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          geonewsSectionsSortIds {
            id
            sortKey
          }
          geonewsSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          geonewsPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          gallerySectionsSortIds {
            id
            sortKey
          }
          gallerySections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          galleryPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          itemsSectionsSortIds {
            id
            sortKey
          }
          itemsSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          itemsPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          contactSectionsSortIds {
            id
            sortKey
          }
          contactSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          contactPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add1SectionsSortIds {
            id
            sortKey
          }
          add1Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add1Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add2SectionsSortIds {
            id
            sortKey
          }
          add2Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add2Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add3SectionsSortIds {
            id
            sortKey
          }
          add3Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add3Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add4SectionsSortIds {
            id
            sortKey
          }
          add4Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add4Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          createdAt
          updatedAt
          version
        }
        interfaceLngId
        interfaceLngMap {
          enId
          frId
        }
        logo {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        mediaList {
          mediaRef
          sizes
        }
        name
        options {
          hasDraft
          status
        }
        pointer
        queryLanguage
        social {
          id
          sortKey
        }
        specialties {
          id
          pointer
          designationId
          designation {
            id
            nameLng {
              en
              fr
            }
            name
            descriptionLng {
              en
              fr
            }
            description
            attribute {
              value
              field
            }
            type
            queryLanguage
            parentId
            createdAt
            updatedAt
            version
          }
          levelId
          catchphraseLng {
            en
            fr
          }
          itemsIds
          geonews {
            id
            locationId
            location {
              id
              address {
                municipality
                countryCode
                postCode
                regionCode
                street
              }
              calendar {
                openingHours {
                  end
                  start
                }
                weekly
                yearly {
                  endDate
                  startDate
                }
              }
              geolocation {
                latitude
                longitude
              }
              parentId
              municipalityId
              type
              createdAt
              updatedAt
              version
            }
            language
            name
            audience
            geonewsExpiryDateTime
            geonewsStartDateTime
            geonewsDuration
            entityId
            sectionLngMap {
              enId
              frId
            }
            sectionLngId
            sectionLng {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            refSectionLngMap {
              enId
              frId
            }
            refSectionLngId
            refSectionLng {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            refInternalLinkLngMap {
              en {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              fr {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            refInternalLinkLng {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            fromUserId
            fromUser {
              id
              administratedPointers {
                pointer
                ownershipLevel
              }
              specialtiesPoc
              email
              federation
              registrationId
              power
              gender
              name {
                firstname
                lastname
                nickname
              }
              phoneNumbers {
                sortKey
                countryCode
                number
                usage
              }
              preferences {
                visibleEmail
                visiblePhones
              }
              titleLng {
                en
                fr
              }
              title
              queryLanguage
              createdAt
              updatedAt
              version
            }
            toUserId
            toUser {
              id
              administratedPointers {
                pointer
                ownershipLevel
              }
              specialtiesPoc
              email
              federation
              registrationId
              power
              gender
              name {
                firstname
                lastname
                nickname
              }
              phoneNumbers {
                sortKey
                countryCode
                number
                usage
              }
              preferences {
                visibleEmail
                visiblePhones
              }
              titleLng {
                en
                fr
              }
              title
              queryLanguage
              createdAt
              updatedAt
              version
            }
            queryLanguage
            parentId
            createdAt
            updatedAt
            version
          }
          geonewsIds
          locationId
          location {
            id
            address {
              municipality
              countryCode
              postCode
              regionCode
              street
            }
            calendar {
              openingHours {
                end
                start
              }
              weekly
              yearly {
                endDate
                startDate
              }
            }
            geolocation {
              latitude
              longitude
            }
            parentId
            municipalityId
            type
            createdAt
            updatedAt
            version
          }
          parentId
          othersPocUsersIds {
            id
            sortKey
          }
          pocUserId
          pocUser {
            id
            administratedPointers {
              pointer
              ownershipLevel
            }
            specialtiesPoc
            email
            federation
            registrationId
            power
            gender
            name {
              firstname
              lastname
              nickname
            }
            phoneNumbers {
              sortKey
              countryCode
              number
              usage
            }
            preferences {
              visibleEmail
              visiblePhones
            }
            titleLng {
              en
              fr
            }
            title
            queryLanguage
            createdAt
            updatedAt
            version
          }
          queryLanguage
          sortKey
          specialtyLngMap {
            enId
            frId
          }
          specialtyLngId
          specialtyLng {
            id
            gallery {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            language
            parentId
            type
            itemsTables {
              headRow {
                sortKey
                string
                type
              }
              itemsIds {
                id
                sortKey
              }
              sortKey
            }
            indexSectionsSortIds {
              id
              sortKey
            }
            indexSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            indexPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            geonewsSectionsSortIds {
              id
              sortKey
            }
            geonewsSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            geonewsPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            gallerySectionsSortIds {
              id
              sortKey
            }
            gallerySections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            galleryPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            itemsSectionsSortIds {
              id
              sortKey
            }
            itemsSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            itemsPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            contactSectionsSortIds {
              id
              sortKey
            }
            contactSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            contactPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add1SectionsSortIds {
              id
              sortKey
            }
            add1Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add1Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add2SectionsSortIds {
              id
              sortKey
            }
            add2Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add2Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add3SectionsSortIds {
              id
              sortKey
            }
            add3Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add3Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add4SectionsSortIds {
              id
              sortKey
            }
            add4Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add4Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            createdAt
            updatedAt
            version
          }
          tagsIds {
            id
            sortKey
          }
          createdAt
          updatedAt
          version
        }
        specialtiesIds {
          id
          sortKey
        }
        createdAt
        updatedAt
        version
      }
      geostructId
      geostruct {
        id
        locationId
        location {
          id
          address {
            municipality
            countryCode
            postCode
            regionCode
            street
          }
          calendar {
            openingHours {
              end
              start
            }
            weekly
            yearly {
              endDate
              startDate
            }
          }
          geolocation {
            latitude
            longitude
          }
          parentId
          municipalityId
          type
          createdAt
          updatedAt
          version
        }
        language
        name
        audience
        geonewsExpiryDateTime
        geonewsStartDateTime
        geonewsDuration
        entityId
        sectionLngMap {
          enId
          frId
        }
        sectionLngId
        sectionLng {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        refSectionLngMap {
          enId
          frId
        }
        refSectionLngId
        refSectionLng {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        refInternalLinkLngMap {
          en {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          fr {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        refInternalLinkLng {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        fromUserId
        fromUser {
          id
          administratedPointers {
            pointer
            ownershipLevel
          }
          specialtiesPoc
          email
          federation
          registrationId
          power
          gender
          name {
            firstname
            lastname
            nickname
          }
          phoneNumbers {
            sortKey
            countryCode
            number
            usage
          }
          preferences {
            visibleEmail
            visiblePhones
          }
          titleLng {
            en
            fr
          }
          title
          queryLanguage
          createdAt
          updatedAt
          version
        }
        toUserId
        toUser {
          id
          administratedPointers {
            pointer
            ownershipLevel
          }
          specialtiesPoc
          email
          federation
          registrationId
          power
          gender
          name {
            firstname
            lastname
            nickname
          }
          phoneNumbers {
            sortKey
            countryCode
            number
            usage
          }
          preferences {
            visibleEmail
            visiblePhones
          }
          titleLng {
            en
            fr
          }
          title
          queryLanguage
          createdAt
          updatedAt
          version
        }
        queryLanguage
        parentId
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail($email: String!) {
    getUserByEmail(email: $email) {
      items {
        id
        administratedPointers {
          pointer
          ownershipLevel
        }
        specialtiesPoc
        email
        federation
        registrationId
        power
        gender
        name {
          firstname
          lastname
          nickname
        }
        phoneNumbers {
          sortKey
          countryCode
          number
          usage
        }
        preferences {
          visibleEmail
          visiblePhones
        }
        titleLng {
          en
          fr
        }
        title
        queryLanguage
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const listDataStructsByLng = /* GraphQL */ `
  query ListDataStructsByLng($language: String!) {
    listDataStructsByLng(language: $language) {
      items {
        id
        locationId
        location {
          id
          address {
            municipality
            countryCode
            postCode
            regionCode
            street
          }
          calendar {
            openingHours {
              end
              start
            }
            weekly
            yearly {
              endDate
              startDate
            }
          }
          geolocation {
            latitude
            longitude
          }
          parentId
          municipalityId
          type
          createdAt
          updatedAt
          version
        }
        language
        name
        audience
        geonewsExpiryDateTime
        geonewsStartDateTime
        geonewsDuration
        entityId
        sectionLngMap {
          enId
          frId
        }
        sectionLngId
        sectionLng {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        refSectionLngMap {
          enId
          frId
        }
        refSectionLngId
        refSectionLng {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        refInternalLinkLngMap {
          en {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          fr {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        refInternalLinkLng {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        fromUserId
        fromUser {
          id
          administratedPointers {
            pointer
            ownershipLevel
          }
          specialtiesPoc
          email
          federation
          registrationId
          power
          gender
          name {
            firstname
            lastname
            nickname
          }
          phoneNumbers {
            sortKey
            countryCode
            number
            usage
          }
          preferences {
            visibleEmail
            visiblePhones
          }
          titleLng {
            en
            fr
          }
          title
          queryLanguage
          createdAt
          updatedAt
          version
        }
        toUserId
        toUser {
          id
          administratedPointers {
            pointer
            ownershipLevel
          }
          specialtiesPoc
          email
          federation
          registrationId
          power
          gender
          name {
            firstname
            lastname
            nickname
          }
          phoneNumbers {
            sortKey
            countryCode
            number
            usage
          }
          preferences {
            visibleEmail
            visiblePhones
          }
          titleLng {
            en
            fr
          }
          title
          queryLanguage
          createdAt
          updatedAt
          version
        }
        queryLanguage
        parentId
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const listPointersByLng = /* GraphQL */ `
  query ListPointersByLng($language: String!) {
    listPointersByLng(language: $language) {
      items {
        pointer
        queryLanguage
        stellaId
        stella {
          id
          bucket {
            id
            web {
              shortUrl
              isStellactWeb
              cloudfrontUrl
              ipAddress
              sslCertificateId
            }
            stellaFolders {
              id
              sortKey
            }
            createdAt
            updatedAt
            version
          }
          bucketId
          entityId
          interfaceLng {
            id
            gallery {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            language
            parentId
            type
            itemsTables {
              headRow {
                sortKey
                string
                type
              }
              itemsIds {
                id
                sortKey
              }
              sortKey
            }
            indexSectionsSortIds {
              id
              sortKey
            }
            indexSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            indexPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            geonewsSectionsSortIds {
              id
              sortKey
            }
            geonewsSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            geonewsPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            gallerySectionsSortIds {
              id
              sortKey
            }
            gallerySections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            galleryPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            itemsSectionsSortIds {
              id
              sortKey
            }
            itemsSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            itemsPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            contactSectionsSortIds {
              id
              sortKey
            }
            contactSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            contactPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add1SectionsSortIds {
              id
              sortKey
            }
            add1Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add1Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add2SectionsSortIds {
              id
              sortKey
            }
            add2Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add2Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add3SectionsSortIds {
              id
              sortKey
            }
            add3Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add3Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add4SectionsSortIds {
              id
              sortKey
            }
            add4Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add4Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            createdAt
            updatedAt
            version
          }
          interfaceLngId
          interfaceLngMap {
            enId
            frId
          }
          logo {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          mediaList {
            mediaRef
            sizes
          }
          name
          options {
            hasDraft
            status
          }
          pointer
          queryLanguage
          social {
            id
            sortKey
          }
          specialties {
            id
            pointer
            designationId
            designation {
              id
              nameLng {
                en
                fr
              }
              name
              descriptionLng {
                en
                fr
              }
              description
              attribute {
                value
                field
              }
              type
              queryLanguage
              parentId
              createdAt
              updatedAt
              version
            }
            levelId
            catchphraseLng {
              en
              fr
            }
            itemsIds
            geonews {
              id
              locationId
              location {
                id
                address {
                  municipality
                  countryCode
                  postCode
                  regionCode
                  street
                }
                calendar {
                  openingHours {
                    end
                    start
                  }
                  weekly
                  yearly {
                    endDate
                    startDate
                  }
                }
                geolocation {
                  latitude
                  longitude
                }
                parentId
                municipalityId
                type
                createdAt
                updatedAt
                version
              }
              language
              name
              audience
              geonewsExpiryDateTime
              geonewsStartDateTime
              geonewsDuration
              entityId
              sectionLngMap {
                enId
                frId
              }
              sectionLngId
              sectionLng {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              refSectionLngMap {
                enId
                frId
              }
              refSectionLngId
              refSectionLng {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              refInternalLinkLngMap {
                en {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                fr {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              refInternalLinkLng {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              fromUserId
              fromUser {
                id
                administratedPointers {
                  pointer
                  ownershipLevel
                }
                specialtiesPoc
                email
                federation
                registrationId
                power
                gender
                name {
                  firstname
                  lastname
                  nickname
                }
                phoneNumbers {
                  sortKey
                  countryCode
                  number
                  usage
                }
                preferences {
                  visibleEmail
                  visiblePhones
                }
                titleLng {
                  en
                  fr
                }
                title
                queryLanguage
                createdAt
                updatedAt
                version
              }
              toUserId
              toUser {
                id
                administratedPointers {
                  pointer
                  ownershipLevel
                }
                specialtiesPoc
                email
                federation
                registrationId
                power
                gender
                name {
                  firstname
                  lastname
                  nickname
                }
                phoneNumbers {
                  sortKey
                  countryCode
                  number
                  usage
                }
                preferences {
                  visibleEmail
                  visiblePhones
                }
                titleLng {
                  en
                  fr
                }
                title
                queryLanguage
                createdAt
                updatedAt
                version
              }
              queryLanguage
              parentId
              createdAt
              updatedAt
              version
            }
            geonewsIds
            locationId
            location {
              id
              address {
                municipality
                countryCode
                postCode
                regionCode
                street
              }
              calendar {
                openingHours {
                  end
                  start
                }
                weekly
                yearly {
                  endDate
                  startDate
                }
              }
              geolocation {
                latitude
                longitude
              }
              parentId
              municipalityId
              type
              createdAt
              updatedAt
              version
            }
            parentId
            othersPocUsersIds {
              id
              sortKey
            }
            pocUserId
            pocUser {
              id
              administratedPointers {
                pointer
                ownershipLevel
              }
              specialtiesPoc
              email
              federation
              registrationId
              power
              gender
              name {
                firstname
                lastname
                nickname
              }
              phoneNumbers {
                sortKey
                countryCode
                number
                usage
              }
              preferences {
                visibleEmail
                visiblePhones
              }
              titleLng {
                en
                fr
              }
              title
              queryLanguage
              createdAt
              updatedAt
              version
            }
            queryLanguage
            sortKey
            specialtyLngMap {
              enId
              frId
            }
            specialtyLngId
            specialtyLng {
              id
              gallery {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              language
              parentId
              type
              itemsTables {
                headRow {
                  sortKey
                  string
                  type
                }
                itemsIds {
                  id
                  sortKey
                }
                sortKey
              }
              indexSectionsSortIds {
                id
                sortKey
              }
              indexSections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              indexPage {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              geonewsSectionsSortIds {
                id
                sortKey
              }
              geonewsSections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              geonewsPage {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              gallerySectionsSortIds {
                id
                sortKey
              }
              gallerySections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              galleryPage {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              itemsSectionsSortIds {
                id
                sortKey
              }
              itemsSections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              itemsPage {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              contactSectionsSortIds {
                id
                sortKey
              }
              contactSections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              contactPage {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              add1SectionsSortIds {
                id
                sortKey
              }
              add1Sections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              add1Page {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              add2SectionsSortIds {
                id
                sortKey
              }
              add2Sections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              add2Page {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              add3SectionsSortIds {
                id
                sortKey
              }
              add3Sections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              add3Page {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              add4SectionsSortIds {
                id
                sortKey
              }
              add4Sections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              add4Page {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              createdAt
              updatedAt
              version
            }
            tagsIds {
              id
              sortKey
            }
            createdAt
            updatedAt
            version
          }
          specialtiesIds {
            id
            sortKey
          }
          createdAt
          updatedAt
          version
        }
        geostructId
        geostruct {
          id
          locationId
          location {
            id
            address {
              municipality
              countryCode
              postCode
              regionCode
              street
            }
            calendar {
              openingHours {
                end
                start
              }
              weekly
              yearly {
                endDate
                startDate
              }
            }
            geolocation {
              latitude
              longitude
            }
            parentId
            municipalityId
            type
            createdAt
            updatedAt
            version
          }
          language
          name
          audience
          geonewsExpiryDateTime
          geonewsStartDateTime
          geonewsDuration
          entityId
          sectionLngMap {
            enId
            frId
          }
          sectionLngId
          sectionLng {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          refSectionLngMap {
            enId
            frId
          }
          refSectionLngId
          refSectionLng {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          refInternalLinkLngMap {
            en {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            fr {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          refInternalLinkLng {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          fromUserId
          fromUser {
            id
            administratedPointers {
              pointer
              ownershipLevel
            }
            specialtiesPoc
            email
            federation
            registrationId
            power
            gender
            name {
              firstname
              lastname
              nickname
            }
            phoneNumbers {
              sortKey
              countryCode
              number
              usage
            }
            preferences {
              visibleEmail
              visiblePhones
            }
            titleLng {
              en
              fr
            }
            title
            queryLanguage
            createdAt
            updatedAt
            version
          }
          toUserId
          toUser {
            id
            administratedPointers {
              pointer
              ownershipLevel
            }
            specialtiesPoc
            email
            federation
            registrationId
            power
            gender
            name {
              firstname
              lastname
              nickname
            }
            phoneNumbers {
              sortKey
              countryCode
              number
              usage
            }
            preferences {
              visibleEmail
              visiblePhones
            }
            titleLng {
              en
              fr
            }
            title
            queryLanguage
            createdAt
            updatedAt
            version
          }
          queryLanguage
          parentId
          createdAt
          updatedAt
          version
        }
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const listStaticObjectsByTypeByPrefixByLng = /* GraphQL */ `
  query ListStaticObjectsByTypeByPrefixByLng(
    $type: String!
    $prefix: ID
    $language: String
  ) {
    listStaticObjectsByTypeByPrefixByLng(
      type: $type
      prefix: $prefix
      language: $language
    ) {
      items {
        id
        nameLng {
          en
          fr
        }
        name
        descriptionLng {
          en
          fr
        }
        description
        attribute {
          value
          field
        }
        type
        queryLanguage
        parentId
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getBucket = /* GraphQL */ `
  query GetBucket($id: ID!) {
    getBucket(id: $id) {
      id
      web {
        shortUrl
        isStellactWeb
        cloudfrontUrl
        ipAddress
        sslCertificateId
      }
      stellaFolders {
        id
        sortKey
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const listBuckets = /* GraphQL */ `
  query ListBuckets(
    $filter: ModelBucketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuckets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        web {
          shortUrl
          isStellactWeb
          cloudfrontUrl
          ipAddress
          sslCertificateId
        }
        stellaFolders {
          id
          sortKey
        }
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getDataStruct = /* GraphQL */ `
  query GetDataStruct($id: ID!) {
    getDataStruct(id: $id) {
      id
      locationId
      location {
        id
        address {
          municipality
          countryCode
          postCode
          regionCode
          street
        }
        calendar {
          openingHours {
            end
            start
          }
          weekly
          yearly {
            endDate
            startDate
          }
        }
        geolocation {
          latitude
          longitude
        }
        parentId
        municipalityId
        type
        createdAt
        updatedAt
        version
      }
      language
      name
      audience
      geonewsExpiryDateTime
      geonewsStartDateTime
      geonewsDuration
      entityId
      sectionLngMap {
        enId
        frId
      }
      sectionLngId
      sectionLng {
        id
        parentId
        bucketId
        flexbox {
          columns
          totalColumns
        }
        elements {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        title {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        language
        type
        createdAt
        updatedAt
        version
      }
      refSectionLngMap {
        enId
        frId
      }
      refSectionLngId
      refSectionLng {
        id
        parentId
        bucketId
        flexbox {
          columns
          totalColumns
        }
        elements {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        title {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        language
        type
        createdAt
        updatedAt
        version
      }
      refInternalLinkLngMap {
        en {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        fr {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
      }
      refInternalLinkLng {
        formatRanges {
          offset
          length
          style
        }
        ref {
          linkTarget
          format
          refId
          sizes {
            ll
            mm
            ss
          }
          formatRange {
            offset
            length
            style
          }
          type
        }
        size
        sortKey
        text
      }
      fromUserId
      fromUser {
        id
        administratedPointers {
          pointer
          ownershipLevel
        }
        specialtiesPoc
        email
        federation
        registrationId
        power
        gender
        name {
          firstname
          lastname
          nickname
        }
        phoneNumbers {
          sortKey
          countryCode
          number
          usage
        }
        preferences {
          visibleEmail
          visiblePhones
        }
        titleLng {
          en
          fr
        }
        title
        queryLanguage
        createdAt
        updatedAt
        version
      }
      toUserId
      toUser {
        id
        administratedPointers {
          pointer
          ownershipLevel
        }
        specialtiesPoc
        email
        federation
        registrationId
        power
        gender
        name {
          firstname
          lastname
          nickname
        }
        phoneNumbers {
          sortKey
          countryCode
          number
          usage
        }
        preferences {
          visibleEmail
          visiblePhones
        }
        titleLng {
          en
          fr
        }
        title
        queryLanguage
        createdAt
        updatedAt
        version
      }
      queryLanguage
      parentId
      createdAt
      updatedAt
      version
    }
  }
`;
export const listDataStructs = /* GraphQL */ `
  query ListDataStructs(
    $filter: ModelDataStructFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataStructs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        locationId
        location {
          id
          address {
            municipality
            countryCode
            postCode
            regionCode
            street
          }
          calendar {
            openingHours {
              end
              start
            }
            weekly
            yearly {
              endDate
              startDate
            }
          }
          geolocation {
            latitude
            longitude
          }
          parentId
          municipalityId
          type
          createdAt
          updatedAt
          version
        }
        language
        name
        audience
        geonewsExpiryDateTime
        geonewsStartDateTime
        geonewsDuration
        entityId
        sectionLngMap {
          enId
          frId
        }
        sectionLngId
        sectionLng {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        refSectionLngMap {
          enId
          frId
        }
        refSectionLngId
        refSectionLng {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        refInternalLinkLngMap {
          en {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          fr {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        refInternalLinkLng {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        fromUserId
        fromUser {
          id
          administratedPointers {
            pointer
            ownershipLevel
          }
          specialtiesPoc
          email
          federation
          registrationId
          power
          gender
          name {
            firstname
            lastname
            nickname
          }
          phoneNumbers {
            sortKey
            countryCode
            number
            usage
          }
          preferences {
            visibleEmail
            visiblePhones
          }
          titleLng {
            en
            fr
          }
          title
          queryLanguage
          createdAt
          updatedAt
          version
        }
        toUserId
        toUser {
          id
          administratedPointers {
            pointer
            ownershipLevel
          }
          specialtiesPoc
          email
          federation
          registrationId
          power
          gender
          name {
            firstname
            lastname
            nickname
          }
          phoneNumbers {
            sortKey
            countryCode
            number
            usage
          }
          preferences {
            visibleEmail
            visiblePhones
          }
          titleLng {
            en
            fr
          }
          title
          queryLanguage
          createdAt
          updatedAt
          version
        }
        queryLanguage
        parentId
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      domainId
      isOrion
      nameLng {
        en
        fr
      }
      itemLngMap {
        enId
        frId
      }
      descriptionLng {
        en
        fr
      }
      price {
        amount
        currency
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        domainId
        isOrion
        nameLng {
          en
          fr
        }
        itemLngMap {
          enId
          frId
        }
        descriptionLng {
          en
          fr
        }
        price {
          amount
          currency
        }
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getItemLng = /* GraphQL */ `
  query GetItemLng($id: ID!) {
    getItemLng(id: $id) {
      id
      language
      parentId
      item {
        id
        domainId
        isOrion
        nameLng {
          en
          fr
        }
        itemLngMap {
          enId
          frId
        }
        descriptionLng {
          en
          fr
        }
        price {
          amount
          currency
        }
        createdAt
        updatedAt
        version
      }
      columnsMap {
        columnKey
        sectionId
      }
      columnsContentSortIds
      columnsContent {
        id
        parentId
        bucketId
        flexbox {
          columns
          totalColumns
        }
        elements {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        title {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        language
        type
        createdAt
        updatedAt
        version
      }
      refSectionId
      refSection {
        id
        parentId
        bucketId
        flexbox {
          columns
          totalColumns
        }
        elements {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        title {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        language
        type
        createdAt
        updatedAt
        version
      }
      refInternalLink {
        formatRanges {
          offset
          length
          style
        }
        ref {
          linkTarget
          format
          refId
          sizes {
            ll
            mm
            ss
          }
          formatRange {
            offset
            length
            style
          }
          type
        }
        size
        sortKey
        text
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const listItemLngs = /* GraphQL */ `
  query ListItemLngs(
    $filter: ModelItemLngFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemLngs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        language
        parentId
        item {
          id
          domainId
          isOrion
          nameLng {
            en
            fr
          }
          itemLngMap {
            enId
            frId
          }
          descriptionLng {
            en
            fr
          }
          price {
            amount
            currency
          }
          createdAt
          updatedAt
          version
        }
        columnsMap {
          columnKey
          sectionId
        }
        columnsContentSortIds
        columnsContent {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        refSectionId
        refSection {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        refInternalLink {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      address {
        municipality
        countryCode
        postCode
        regionCode
        street
      }
      calendar {
        openingHours {
          end
          start
        }
        weekly
        yearly {
          endDate
          startDate
        }
      }
      geolocation {
        latitude
        longitude
      }
      parentId
      municipalityId
      type
      createdAt
      updatedAt
      version
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address {
          municipality
          countryCode
          postCode
          regionCode
          street
        }
        calendar {
          openingHours {
            end
            start
          }
          weekly
          yearly {
            endDate
            startDate
          }
        }
        geolocation {
          latitude
          longitude
        }
        parentId
        municipalityId
        type
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getPointer = /* GraphQL */ `
  query GetPointer($pointer: ID!) {
    getPointer(pointer: $pointer) {
      pointer
      queryLanguage
      stellaId
      stella {
        id
        bucket {
          id
          web {
            shortUrl
            isStellactWeb
            cloudfrontUrl
            ipAddress
            sslCertificateId
          }
          stellaFolders {
            id
            sortKey
          }
          createdAt
          updatedAt
          version
        }
        bucketId
        entityId
        interfaceLng {
          id
          gallery {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          language
          parentId
          type
          itemsTables {
            headRow {
              sortKey
              string
              type
            }
            itemsIds {
              id
              sortKey
            }
            sortKey
          }
          indexSectionsSortIds {
            id
            sortKey
          }
          indexSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          indexPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          geonewsSectionsSortIds {
            id
            sortKey
          }
          geonewsSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          geonewsPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          gallerySectionsSortIds {
            id
            sortKey
          }
          gallerySections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          galleryPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          itemsSectionsSortIds {
            id
            sortKey
          }
          itemsSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          itemsPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          contactSectionsSortIds {
            id
            sortKey
          }
          contactSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          contactPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add1SectionsSortIds {
            id
            sortKey
          }
          add1Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add1Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add2SectionsSortIds {
            id
            sortKey
          }
          add2Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add2Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add3SectionsSortIds {
            id
            sortKey
          }
          add3Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add3Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add4SectionsSortIds {
            id
            sortKey
          }
          add4Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add4Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          createdAt
          updatedAt
          version
        }
        interfaceLngId
        interfaceLngMap {
          enId
          frId
        }
        logo {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        mediaList {
          mediaRef
          sizes
        }
        name
        options {
          hasDraft
          status
        }
        pointer
        queryLanguage
        social {
          id
          sortKey
        }
        specialties {
          id
          pointer
          designationId
          designation {
            id
            nameLng {
              en
              fr
            }
            name
            descriptionLng {
              en
              fr
            }
            description
            attribute {
              value
              field
            }
            type
            queryLanguage
            parentId
            createdAt
            updatedAt
            version
          }
          levelId
          catchphraseLng {
            en
            fr
          }
          itemsIds
          geonews {
            id
            locationId
            location {
              id
              address {
                municipality
                countryCode
                postCode
                regionCode
                street
              }
              calendar {
                openingHours {
                  end
                  start
                }
                weekly
                yearly {
                  endDate
                  startDate
                }
              }
              geolocation {
                latitude
                longitude
              }
              parentId
              municipalityId
              type
              createdAt
              updatedAt
              version
            }
            language
            name
            audience
            geonewsExpiryDateTime
            geonewsStartDateTime
            geonewsDuration
            entityId
            sectionLngMap {
              enId
              frId
            }
            sectionLngId
            sectionLng {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            refSectionLngMap {
              enId
              frId
            }
            refSectionLngId
            refSectionLng {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            refInternalLinkLngMap {
              en {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              fr {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            refInternalLinkLng {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            fromUserId
            fromUser {
              id
              administratedPointers {
                pointer
                ownershipLevel
              }
              specialtiesPoc
              email
              federation
              registrationId
              power
              gender
              name {
                firstname
                lastname
                nickname
              }
              phoneNumbers {
                sortKey
                countryCode
                number
                usage
              }
              preferences {
                visibleEmail
                visiblePhones
              }
              titleLng {
                en
                fr
              }
              title
              queryLanguage
              createdAt
              updatedAt
              version
            }
            toUserId
            toUser {
              id
              administratedPointers {
                pointer
                ownershipLevel
              }
              specialtiesPoc
              email
              federation
              registrationId
              power
              gender
              name {
                firstname
                lastname
                nickname
              }
              phoneNumbers {
                sortKey
                countryCode
                number
                usage
              }
              preferences {
                visibleEmail
                visiblePhones
              }
              titleLng {
                en
                fr
              }
              title
              queryLanguage
              createdAt
              updatedAt
              version
            }
            queryLanguage
            parentId
            createdAt
            updatedAt
            version
          }
          geonewsIds
          locationId
          location {
            id
            address {
              municipality
              countryCode
              postCode
              regionCode
              street
            }
            calendar {
              openingHours {
                end
                start
              }
              weekly
              yearly {
                endDate
                startDate
              }
            }
            geolocation {
              latitude
              longitude
            }
            parentId
            municipalityId
            type
            createdAt
            updatedAt
            version
          }
          parentId
          othersPocUsersIds {
            id
            sortKey
          }
          pocUserId
          pocUser {
            id
            administratedPointers {
              pointer
              ownershipLevel
            }
            specialtiesPoc
            email
            federation
            registrationId
            power
            gender
            name {
              firstname
              lastname
              nickname
            }
            phoneNumbers {
              sortKey
              countryCode
              number
              usage
            }
            preferences {
              visibleEmail
              visiblePhones
            }
            titleLng {
              en
              fr
            }
            title
            queryLanguage
            createdAt
            updatedAt
            version
          }
          queryLanguage
          sortKey
          specialtyLngMap {
            enId
            frId
          }
          specialtyLngId
          specialtyLng {
            id
            gallery {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            language
            parentId
            type
            itemsTables {
              headRow {
                sortKey
                string
                type
              }
              itemsIds {
                id
                sortKey
              }
              sortKey
            }
            indexSectionsSortIds {
              id
              sortKey
            }
            indexSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            indexPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            geonewsSectionsSortIds {
              id
              sortKey
            }
            geonewsSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            geonewsPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            gallerySectionsSortIds {
              id
              sortKey
            }
            gallerySections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            galleryPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            itemsSectionsSortIds {
              id
              sortKey
            }
            itemsSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            itemsPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            contactSectionsSortIds {
              id
              sortKey
            }
            contactSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            contactPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add1SectionsSortIds {
              id
              sortKey
            }
            add1Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add1Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add2SectionsSortIds {
              id
              sortKey
            }
            add2Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add2Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add3SectionsSortIds {
              id
              sortKey
            }
            add3Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add3Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add4SectionsSortIds {
              id
              sortKey
            }
            add4Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add4Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            createdAt
            updatedAt
            version
          }
          tagsIds {
            id
            sortKey
          }
          createdAt
          updatedAt
          version
        }
        specialtiesIds {
          id
          sortKey
        }
        createdAt
        updatedAt
        version
      }
      geostructId
      geostruct {
        id
        locationId
        location {
          id
          address {
            municipality
            countryCode
            postCode
            regionCode
            street
          }
          calendar {
            openingHours {
              end
              start
            }
            weekly
            yearly {
              endDate
              startDate
            }
          }
          geolocation {
            latitude
            longitude
          }
          parentId
          municipalityId
          type
          createdAt
          updatedAt
          version
        }
        language
        name
        audience
        geonewsExpiryDateTime
        geonewsStartDateTime
        geonewsDuration
        entityId
        sectionLngMap {
          enId
          frId
        }
        sectionLngId
        sectionLng {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        refSectionLngMap {
          enId
          frId
        }
        refSectionLngId
        refSectionLng {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        refInternalLinkLngMap {
          en {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          fr {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        refInternalLinkLng {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        fromUserId
        fromUser {
          id
          administratedPointers {
            pointer
            ownershipLevel
          }
          specialtiesPoc
          email
          federation
          registrationId
          power
          gender
          name {
            firstname
            lastname
            nickname
          }
          phoneNumbers {
            sortKey
            countryCode
            number
            usage
          }
          preferences {
            visibleEmail
            visiblePhones
          }
          titleLng {
            en
            fr
          }
          title
          queryLanguage
          createdAt
          updatedAt
          version
        }
        toUserId
        toUser {
          id
          administratedPointers {
            pointer
            ownershipLevel
          }
          specialtiesPoc
          email
          federation
          registrationId
          power
          gender
          name {
            firstname
            lastname
            nickname
          }
          phoneNumbers {
            sortKey
            countryCode
            number
            usage
          }
          preferences {
            visibleEmail
            visiblePhones
          }
          titleLng {
            en
            fr
          }
          title
          queryLanguage
          createdAt
          updatedAt
          version
        }
        queryLanguage
        parentId
        createdAt
        updatedAt
        version
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const listPointers = /* GraphQL */ `
  query ListPointers(
    $pointer: ID
    $filter: ModelPointerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPointers(
      pointer: $pointer
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pointer
        queryLanguage
        stellaId
        stella {
          id
          bucket {
            id
            web {
              shortUrl
              isStellactWeb
              cloudfrontUrl
              ipAddress
              sslCertificateId
            }
            stellaFolders {
              id
              sortKey
            }
            createdAt
            updatedAt
            version
          }
          bucketId
          entityId
          interfaceLng {
            id
            gallery {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            language
            parentId
            type
            itemsTables {
              headRow {
                sortKey
                string
                type
              }
              itemsIds {
                id
                sortKey
              }
              sortKey
            }
            indexSectionsSortIds {
              id
              sortKey
            }
            indexSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            indexPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            geonewsSectionsSortIds {
              id
              sortKey
            }
            geonewsSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            geonewsPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            gallerySectionsSortIds {
              id
              sortKey
            }
            gallerySections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            galleryPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            itemsSectionsSortIds {
              id
              sortKey
            }
            itemsSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            itemsPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            contactSectionsSortIds {
              id
              sortKey
            }
            contactSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            contactPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add1SectionsSortIds {
              id
              sortKey
            }
            add1Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add1Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add2SectionsSortIds {
              id
              sortKey
            }
            add2Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add2Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add3SectionsSortIds {
              id
              sortKey
            }
            add3Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add3Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add4SectionsSortIds {
              id
              sortKey
            }
            add4Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add4Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            createdAt
            updatedAt
            version
          }
          interfaceLngId
          interfaceLngMap {
            enId
            frId
          }
          logo {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          mediaList {
            mediaRef
            sizes
          }
          name
          options {
            hasDraft
            status
          }
          pointer
          queryLanguage
          social {
            id
            sortKey
          }
          specialties {
            id
            pointer
            designationId
            designation {
              id
              nameLng {
                en
                fr
              }
              name
              descriptionLng {
                en
                fr
              }
              description
              attribute {
                value
                field
              }
              type
              queryLanguage
              parentId
              createdAt
              updatedAt
              version
            }
            levelId
            catchphraseLng {
              en
              fr
            }
            itemsIds
            geonews {
              id
              locationId
              location {
                id
                address {
                  municipality
                  countryCode
                  postCode
                  regionCode
                  street
                }
                calendar {
                  openingHours {
                    end
                    start
                  }
                  weekly
                  yearly {
                    endDate
                    startDate
                  }
                }
                geolocation {
                  latitude
                  longitude
                }
                parentId
                municipalityId
                type
                createdAt
                updatedAt
                version
              }
              language
              name
              audience
              geonewsExpiryDateTime
              geonewsStartDateTime
              geonewsDuration
              entityId
              sectionLngMap {
                enId
                frId
              }
              sectionLngId
              sectionLng {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              refSectionLngMap {
                enId
                frId
              }
              refSectionLngId
              refSectionLng {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              refInternalLinkLngMap {
                en {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                fr {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              refInternalLinkLng {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              fromUserId
              fromUser {
                id
                administratedPointers {
                  pointer
                  ownershipLevel
                }
                specialtiesPoc
                email
                federation
                registrationId
                power
                gender
                name {
                  firstname
                  lastname
                  nickname
                }
                phoneNumbers {
                  sortKey
                  countryCode
                  number
                  usage
                }
                preferences {
                  visibleEmail
                  visiblePhones
                }
                titleLng {
                  en
                  fr
                }
                title
                queryLanguage
                createdAt
                updatedAt
                version
              }
              toUserId
              toUser {
                id
                administratedPointers {
                  pointer
                  ownershipLevel
                }
                specialtiesPoc
                email
                federation
                registrationId
                power
                gender
                name {
                  firstname
                  lastname
                  nickname
                }
                phoneNumbers {
                  sortKey
                  countryCode
                  number
                  usage
                }
                preferences {
                  visibleEmail
                  visiblePhones
                }
                titleLng {
                  en
                  fr
                }
                title
                queryLanguage
                createdAt
                updatedAt
                version
              }
              queryLanguage
              parentId
              createdAt
              updatedAt
              version
            }
            geonewsIds
            locationId
            location {
              id
              address {
                municipality
                countryCode
                postCode
                regionCode
                street
              }
              calendar {
                openingHours {
                  end
                  start
                }
                weekly
                yearly {
                  endDate
                  startDate
                }
              }
              geolocation {
                latitude
                longitude
              }
              parentId
              municipalityId
              type
              createdAt
              updatedAt
              version
            }
            parentId
            othersPocUsersIds {
              id
              sortKey
            }
            pocUserId
            pocUser {
              id
              administratedPointers {
                pointer
                ownershipLevel
              }
              specialtiesPoc
              email
              federation
              registrationId
              power
              gender
              name {
                firstname
                lastname
                nickname
              }
              phoneNumbers {
                sortKey
                countryCode
                number
                usage
              }
              preferences {
                visibleEmail
                visiblePhones
              }
              titleLng {
                en
                fr
              }
              title
              queryLanguage
              createdAt
              updatedAt
              version
            }
            queryLanguage
            sortKey
            specialtyLngMap {
              enId
              frId
            }
            specialtyLngId
            specialtyLng {
              id
              gallery {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              language
              parentId
              type
              itemsTables {
                headRow {
                  sortKey
                  string
                  type
                }
                itemsIds {
                  id
                  sortKey
                }
                sortKey
              }
              indexSectionsSortIds {
                id
                sortKey
              }
              indexSections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              indexPage {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              geonewsSectionsSortIds {
                id
                sortKey
              }
              geonewsSections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              geonewsPage {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              gallerySectionsSortIds {
                id
                sortKey
              }
              gallerySections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              galleryPage {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              itemsSectionsSortIds {
                id
                sortKey
              }
              itemsSections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              itemsPage {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              contactSectionsSortIds {
                id
                sortKey
              }
              contactSections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              contactPage {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              add1SectionsSortIds {
                id
                sortKey
              }
              add1Sections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              add1Page {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              add2SectionsSortIds {
                id
                sortKey
              }
              add2Sections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              add2Page {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              add3SectionsSortIds {
                id
                sortKey
              }
              add3Sections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              add3Page {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              add4SectionsSortIds {
                id
                sortKey
              }
              add4Sections {
                id
                parentId
                bucketId
                flexbox {
                  columns
                  totalColumns
                }
                elements {
                  firstComposite {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  nextComposites {
                    formatRanges {
                      offset
                      length
                      style
                    }
                    ref {
                      linkTarget
                      format
                      refId
                      sizes {
                        ll
                        mm
                        ss
                      }
                      formatRange {
                        offset
                        length
                        style
                      }
                      type
                    }
                    size
                    sortKey
                    text
                  }
                  sortKey
                  type
                }
                title {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                language
                type
                createdAt
                updatedAt
                version
              }
              add4Page {
                pageTitle {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                layout
                menu {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
              }
              createdAt
              updatedAt
              version
            }
            tagsIds {
              id
              sortKey
            }
            createdAt
            updatedAt
            version
          }
          specialtiesIds {
            id
            sortKey
          }
          createdAt
          updatedAt
          version
        }
        geostructId
        geostruct {
          id
          locationId
          location {
            id
            address {
              municipality
              countryCode
              postCode
              regionCode
              street
            }
            calendar {
              openingHours {
                end
                start
              }
              weekly
              yearly {
                endDate
                startDate
              }
            }
            geolocation {
              latitude
              longitude
            }
            parentId
            municipalityId
            type
            createdAt
            updatedAt
            version
          }
          language
          name
          audience
          geonewsExpiryDateTime
          geonewsStartDateTime
          geonewsDuration
          entityId
          sectionLngMap {
            enId
            frId
          }
          sectionLngId
          sectionLng {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          refSectionLngMap {
            enId
            frId
          }
          refSectionLngId
          refSectionLng {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          refInternalLinkLngMap {
            en {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            fr {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          refInternalLinkLng {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          fromUserId
          fromUser {
            id
            administratedPointers {
              pointer
              ownershipLevel
            }
            specialtiesPoc
            email
            federation
            registrationId
            power
            gender
            name {
              firstname
              lastname
              nickname
            }
            phoneNumbers {
              sortKey
              countryCode
              number
              usage
            }
            preferences {
              visibleEmail
              visiblePhones
            }
            titleLng {
              en
              fr
            }
            title
            queryLanguage
            createdAt
            updatedAt
            version
          }
          toUserId
          toUser {
            id
            administratedPointers {
              pointer
              ownershipLevel
            }
            specialtiesPoc
            email
            federation
            registrationId
            power
            gender
            name {
              firstname
              lastname
              nickname
            }
            phoneNumbers {
              sortKey
              countryCode
              number
              usage
            }
            preferences {
              visibleEmail
              visiblePhones
            }
            titleLng {
              en
              fr
            }
            title
            queryLanguage
            createdAt
            updatedAt
            version
          }
          queryLanguage
          parentId
          createdAt
          updatedAt
          version
        }
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getSection = /* GraphQL */ `
  query GetSection($id: ID!) {
    getSection(id: $id) {
      id
      parentId
      bucketId
      flexbox {
        columns
        totalColumns
      }
      elements {
        firstComposite {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        nextComposites {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        sortKey
        type
      }
      title {
        formatRanges {
          offset
          length
          style
        }
        ref {
          linkTarget
          format
          refId
          sizes {
            ll
            mm
            ss
          }
          formatRange {
            offset
            length
            style
          }
          type
        }
        size
        sortKey
        text
      }
      language
      type
      createdAt
      updatedAt
      version
    }
  }
`;
export const listSections = /* GraphQL */ `
  query ListSections(
    $filter: ModelSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentId
        bucketId
        flexbox {
          columns
          totalColumns
        }
        elements {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        title {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        language
        type
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getSpecialty = /* GraphQL */ `
  query GetSpecialty($id: ID!) {
    getSpecialty(id: $id) {
      id
      pointer
      designationId
      designation {
        id
        nameLng {
          en
          fr
        }
        name
        descriptionLng {
          en
          fr
        }
        description
        attribute {
          value
          field
        }
        type
        queryLanguage
        parentId
        createdAt
        updatedAt
        version
      }
      levelId
      catchphraseLng {
        en
        fr
      }
      itemsIds
      geonews {
        id
        locationId
        location {
          id
          address {
            municipality
            countryCode
            postCode
            regionCode
            street
          }
          calendar {
            openingHours {
              end
              start
            }
            weekly
            yearly {
              endDate
              startDate
            }
          }
          geolocation {
            latitude
            longitude
          }
          parentId
          municipalityId
          type
          createdAt
          updatedAt
          version
        }
        language
        name
        audience
        geonewsExpiryDateTime
        geonewsStartDateTime
        geonewsDuration
        entityId
        sectionLngMap {
          enId
          frId
        }
        sectionLngId
        sectionLng {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        refSectionLngMap {
          enId
          frId
        }
        refSectionLngId
        refSectionLng {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        refInternalLinkLngMap {
          en {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          fr {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        refInternalLinkLng {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        fromUserId
        fromUser {
          id
          administratedPointers {
            pointer
            ownershipLevel
          }
          specialtiesPoc
          email
          federation
          registrationId
          power
          gender
          name {
            firstname
            lastname
            nickname
          }
          phoneNumbers {
            sortKey
            countryCode
            number
            usage
          }
          preferences {
            visibleEmail
            visiblePhones
          }
          titleLng {
            en
            fr
          }
          title
          queryLanguage
          createdAt
          updatedAt
          version
        }
        toUserId
        toUser {
          id
          administratedPointers {
            pointer
            ownershipLevel
          }
          specialtiesPoc
          email
          federation
          registrationId
          power
          gender
          name {
            firstname
            lastname
            nickname
          }
          phoneNumbers {
            sortKey
            countryCode
            number
            usage
          }
          preferences {
            visibleEmail
            visiblePhones
          }
          titleLng {
            en
            fr
          }
          title
          queryLanguage
          createdAt
          updatedAt
          version
        }
        queryLanguage
        parentId
        createdAt
        updatedAt
        version
      }
      geonewsIds
      locationId
      location {
        id
        address {
          municipality
          countryCode
          postCode
          regionCode
          street
        }
        calendar {
          openingHours {
            end
            start
          }
          weekly
          yearly {
            endDate
            startDate
          }
        }
        geolocation {
          latitude
          longitude
        }
        parentId
        municipalityId
        type
        createdAt
        updatedAt
        version
      }
      parentId
      othersPocUsersIds {
        id
        sortKey
      }
      pocUserId
      pocUser {
        id
        administratedPointers {
          pointer
          ownershipLevel
        }
        specialtiesPoc
        email
        federation
        registrationId
        power
        gender
        name {
          firstname
          lastname
          nickname
        }
        phoneNumbers {
          sortKey
          countryCode
          number
          usage
        }
        preferences {
          visibleEmail
          visiblePhones
        }
        titleLng {
          en
          fr
        }
        title
        queryLanguage
        createdAt
        updatedAt
        version
      }
      queryLanguage
      sortKey
      specialtyLngMap {
        enId
        frId
      }
      specialtyLngId
      specialtyLng {
        id
        gallery {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        language
        parentId
        type
        itemsTables {
          headRow {
            sortKey
            string
            type
          }
          itemsIds {
            id
            sortKey
          }
          sortKey
        }
        indexSectionsSortIds {
          id
          sortKey
        }
        indexSections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        indexPage {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        geonewsSectionsSortIds {
          id
          sortKey
        }
        geonewsSections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        geonewsPage {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        gallerySectionsSortIds {
          id
          sortKey
        }
        gallerySections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        galleryPage {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        itemsSectionsSortIds {
          id
          sortKey
        }
        itemsSections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        itemsPage {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        contactSectionsSortIds {
          id
          sortKey
        }
        contactSections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        contactPage {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        add1SectionsSortIds {
          id
          sortKey
        }
        add1Sections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        add1Page {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        add2SectionsSortIds {
          id
          sortKey
        }
        add2Sections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        add2Page {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        add3SectionsSortIds {
          id
          sortKey
        }
        add3Sections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        add3Page {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        add4SectionsSortIds {
          id
          sortKey
        }
        add4Sections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        add4Page {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        createdAt
        updatedAt
        version
      }
      tagsIds {
        id
        sortKey
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const listSpecialtys = /* GraphQL */ `
  query ListSpecialtys(
    $filter: ModelSpecialtyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialtys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        pointer
        designationId
        designation {
          id
          nameLng {
            en
            fr
          }
          name
          descriptionLng {
            en
            fr
          }
          description
          attribute {
            value
            field
          }
          type
          queryLanguage
          parentId
          createdAt
          updatedAt
          version
        }
        levelId
        catchphraseLng {
          en
          fr
        }
        itemsIds
        geonews {
          id
          locationId
          location {
            id
            address {
              municipality
              countryCode
              postCode
              regionCode
              street
            }
            calendar {
              openingHours {
                end
                start
              }
              weekly
              yearly {
                endDate
                startDate
              }
            }
            geolocation {
              latitude
              longitude
            }
            parentId
            municipalityId
            type
            createdAt
            updatedAt
            version
          }
          language
          name
          audience
          geonewsExpiryDateTime
          geonewsStartDateTime
          geonewsDuration
          entityId
          sectionLngMap {
            enId
            frId
          }
          sectionLngId
          sectionLng {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          refSectionLngMap {
            enId
            frId
          }
          refSectionLngId
          refSectionLng {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          refInternalLinkLngMap {
            en {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            fr {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          refInternalLinkLng {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          fromUserId
          fromUser {
            id
            administratedPointers {
              pointer
              ownershipLevel
            }
            specialtiesPoc
            email
            federation
            registrationId
            power
            gender
            name {
              firstname
              lastname
              nickname
            }
            phoneNumbers {
              sortKey
              countryCode
              number
              usage
            }
            preferences {
              visibleEmail
              visiblePhones
            }
            titleLng {
              en
              fr
            }
            title
            queryLanguage
            createdAt
            updatedAt
            version
          }
          toUserId
          toUser {
            id
            administratedPointers {
              pointer
              ownershipLevel
            }
            specialtiesPoc
            email
            federation
            registrationId
            power
            gender
            name {
              firstname
              lastname
              nickname
            }
            phoneNumbers {
              sortKey
              countryCode
              number
              usage
            }
            preferences {
              visibleEmail
              visiblePhones
            }
            titleLng {
              en
              fr
            }
            title
            queryLanguage
            createdAt
            updatedAt
            version
          }
          queryLanguage
          parentId
          createdAt
          updatedAt
          version
        }
        geonewsIds
        locationId
        location {
          id
          address {
            municipality
            countryCode
            postCode
            regionCode
            street
          }
          calendar {
            openingHours {
              end
              start
            }
            weekly
            yearly {
              endDate
              startDate
            }
          }
          geolocation {
            latitude
            longitude
          }
          parentId
          municipalityId
          type
          createdAt
          updatedAt
          version
        }
        parentId
        othersPocUsersIds {
          id
          sortKey
        }
        pocUserId
        pocUser {
          id
          administratedPointers {
            pointer
            ownershipLevel
          }
          specialtiesPoc
          email
          federation
          registrationId
          power
          gender
          name {
            firstname
            lastname
            nickname
          }
          phoneNumbers {
            sortKey
            countryCode
            number
            usage
          }
          preferences {
            visibleEmail
            visiblePhones
          }
          titleLng {
            en
            fr
          }
          title
          queryLanguage
          createdAt
          updatedAt
          version
        }
        queryLanguage
        sortKey
        specialtyLngMap {
          enId
          frId
        }
        specialtyLngId
        specialtyLng {
          id
          gallery {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          language
          parentId
          type
          itemsTables {
            headRow {
              sortKey
              string
              type
            }
            itemsIds {
              id
              sortKey
            }
            sortKey
          }
          indexSectionsSortIds {
            id
            sortKey
          }
          indexSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          indexPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          geonewsSectionsSortIds {
            id
            sortKey
          }
          geonewsSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          geonewsPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          gallerySectionsSortIds {
            id
            sortKey
          }
          gallerySections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          galleryPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          itemsSectionsSortIds {
            id
            sortKey
          }
          itemsSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          itemsPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          contactSectionsSortIds {
            id
            sortKey
          }
          contactSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          contactPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add1SectionsSortIds {
            id
            sortKey
          }
          add1Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add1Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add2SectionsSortIds {
            id
            sortKey
          }
          add2Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add2Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add3SectionsSortIds {
            id
            sortKey
          }
          add3Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add3Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add4SectionsSortIds {
            id
            sortKey
          }
          add4Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add4Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          createdAt
          updatedAt
          version
        }
        tagsIds {
          id
          sortKey
        }
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getSpecialtyLng = /* GraphQL */ `
  query GetSpecialtyLng($id: ID!) {
    getSpecialtyLng(id: $id) {
      id
      gallery {
        firstComposite {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        nextComposites {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        sortKey
        type
      }
      language
      parentId
      type
      itemsTables {
        headRow {
          sortKey
          string
          type
        }
        itemsIds {
          id
          sortKey
        }
        sortKey
      }
      indexSectionsSortIds {
        id
        sortKey
      }
      indexSections {
        id
        parentId
        bucketId
        flexbox {
          columns
          totalColumns
        }
        elements {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        title {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        language
        type
        createdAt
        updatedAt
        version
      }
      indexPage {
        pageTitle {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        layout
        menu {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
      }
      geonewsSectionsSortIds {
        id
        sortKey
      }
      geonewsSections {
        id
        parentId
        bucketId
        flexbox {
          columns
          totalColumns
        }
        elements {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        title {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        language
        type
        createdAt
        updatedAt
        version
      }
      geonewsPage {
        pageTitle {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        layout
        menu {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
      }
      gallerySectionsSortIds {
        id
        sortKey
      }
      gallerySections {
        id
        parentId
        bucketId
        flexbox {
          columns
          totalColumns
        }
        elements {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        title {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        language
        type
        createdAt
        updatedAt
        version
      }
      galleryPage {
        pageTitle {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        layout
        menu {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
      }
      itemsSectionsSortIds {
        id
        sortKey
      }
      itemsSections {
        id
        parentId
        bucketId
        flexbox {
          columns
          totalColumns
        }
        elements {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        title {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        language
        type
        createdAt
        updatedAt
        version
      }
      itemsPage {
        pageTitle {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        layout
        menu {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
      }
      contactSectionsSortIds {
        id
        sortKey
      }
      contactSections {
        id
        parentId
        bucketId
        flexbox {
          columns
          totalColumns
        }
        elements {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        title {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        language
        type
        createdAt
        updatedAt
        version
      }
      contactPage {
        pageTitle {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        layout
        menu {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
      }
      add1SectionsSortIds {
        id
        sortKey
      }
      add1Sections {
        id
        parentId
        bucketId
        flexbox {
          columns
          totalColumns
        }
        elements {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        title {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        language
        type
        createdAt
        updatedAt
        version
      }
      add1Page {
        pageTitle {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        layout
        menu {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
      }
      add2SectionsSortIds {
        id
        sortKey
      }
      add2Sections {
        id
        parentId
        bucketId
        flexbox {
          columns
          totalColumns
        }
        elements {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        title {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        language
        type
        createdAt
        updatedAt
        version
      }
      add2Page {
        pageTitle {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        layout
        menu {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
      }
      add3SectionsSortIds {
        id
        sortKey
      }
      add3Sections {
        id
        parentId
        bucketId
        flexbox {
          columns
          totalColumns
        }
        elements {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        title {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        language
        type
        createdAt
        updatedAt
        version
      }
      add3Page {
        pageTitle {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        layout
        menu {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
      }
      add4SectionsSortIds {
        id
        sortKey
      }
      add4Sections {
        id
        parentId
        bucketId
        flexbox {
          columns
          totalColumns
        }
        elements {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        title {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        language
        type
        createdAt
        updatedAt
        version
      }
      add4Page {
        pageTitle {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        layout
        menu {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const listSpecialtyLngs = /* GraphQL */ `
  query ListSpecialtyLngs(
    $filter: ModelSpecialtyLngFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialtyLngs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        gallery {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        language
        parentId
        type
        itemsTables {
          headRow {
            sortKey
            string
            type
          }
          itemsIds {
            id
            sortKey
          }
          sortKey
        }
        indexSectionsSortIds {
          id
          sortKey
        }
        indexSections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        indexPage {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        geonewsSectionsSortIds {
          id
          sortKey
        }
        geonewsSections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        geonewsPage {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        gallerySectionsSortIds {
          id
          sortKey
        }
        gallerySections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        galleryPage {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        itemsSectionsSortIds {
          id
          sortKey
        }
        itemsSections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        itemsPage {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        contactSectionsSortIds {
          id
          sortKey
        }
        contactSections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        contactPage {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        add1SectionsSortIds {
          id
          sortKey
        }
        add1Sections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        add1Page {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        add2SectionsSortIds {
          id
          sortKey
        }
        add2Sections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        add2Page {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        add3SectionsSortIds {
          id
          sortKey
        }
        add3Sections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        add3Page {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        add4SectionsSortIds {
          id
          sortKey
        }
        add4Sections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        add4Page {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getStaticObject = /* GraphQL */ `
  query GetStaticObject($id: ID!) {
    getStaticObject(id: $id) {
      id
      nameLng {
        en
        fr
      }
      name
      descriptionLng {
        en
        fr
      }
      description
      attribute {
        value
        field
      }
      type
      queryLanguage
      parentId
      createdAt
      updatedAt
      version
    }
  }
`;
export const listStaticObjects = /* GraphQL */ `
  query ListStaticObjects(
    $filter: ModelStaticObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStaticObjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nameLng {
          en
          fr
        }
        name
        descriptionLng {
          en
          fr
        }
        description
        attribute {
          value
          field
        }
        type
        queryLanguage
        parentId
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getStella = /* GraphQL */ `
  query GetStella($id: ID!) {
    getStella(id: $id) {
      id
      bucket {
        id
        web {
          shortUrl
          isStellactWeb
          cloudfrontUrl
          ipAddress
          sslCertificateId
        }
        stellaFolders {
          id
          sortKey
        }
        createdAt
        updatedAt
        version
      }
      bucketId
      entityId
      interfaceLng {
        id
        gallery {
          firstComposite {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          nextComposites {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          sortKey
          type
        }
        language
        parentId
        type
        itemsTables {
          headRow {
            sortKey
            string
            type
          }
          itemsIds {
            id
            sortKey
          }
          sortKey
        }
        indexSectionsSortIds {
          id
          sortKey
        }
        indexSections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        indexPage {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        geonewsSectionsSortIds {
          id
          sortKey
        }
        geonewsSections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        geonewsPage {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        gallerySectionsSortIds {
          id
          sortKey
        }
        gallerySections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        galleryPage {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        itemsSectionsSortIds {
          id
          sortKey
        }
        itemsSections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        itemsPage {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        contactSectionsSortIds {
          id
          sortKey
        }
        contactSections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        contactPage {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        add1SectionsSortIds {
          id
          sortKey
        }
        add1Sections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        add1Page {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        add2SectionsSortIds {
          id
          sortKey
        }
        add2Sections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        add2Page {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        add3SectionsSortIds {
          id
          sortKey
        }
        add3Sections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        add3Page {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        add4SectionsSortIds {
          id
          sortKey
        }
        add4Sections {
          id
          parentId
          bucketId
          flexbox {
            columns
            totalColumns
          }
          elements {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          title {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          language
          type
          createdAt
          updatedAt
          version
        }
        add4Page {
          pageTitle {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          layout
          menu {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
        }
        createdAt
        updatedAt
        version
      }
      interfaceLngId
      interfaceLngMap {
        enId
        frId
      }
      logo {
        formatRanges {
          offset
          length
          style
        }
        ref {
          linkTarget
          format
          refId
          sizes {
            ll
            mm
            ss
          }
          formatRange {
            offset
            length
            style
          }
          type
        }
        size
        sortKey
        text
      }
      mediaList {
        mediaRef
        sizes
      }
      name
      options {
        hasDraft
        status
      }
      pointer
      queryLanguage
      social {
        id
        sortKey
      }
      specialties {
        id
        pointer
        designationId
        designation {
          id
          nameLng {
            en
            fr
          }
          name
          descriptionLng {
            en
            fr
          }
          description
          attribute {
            value
            field
          }
          type
          queryLanguage
          parentId
          createdAt
          updatedAt
          version
        }
        levelId
        catchphraseLng {
          en
          fr
        }
        itemsIds
        geonews {
          id
          locationId
          location {
            id
            address {
              municipality
              countryCode
              postCode
              regionCode
              street
            }
            calendar {
              openingHours {
                end
                start
              }
              weekly
              yearly {
                endDate
                startDate
              }
            }
            geolocation {
              latitude
              longitude
            }
            parentId
            municipalityId
            type
            createdAt
            updatedAt
            version
          }
          language
          name
          audience
          geonewsExpiryDateTime
          geonewsStartDateTime
          geonewsDuration
          entityId
          sectionLngMap {
            enId
            frId
          }
          sectionLngId
          sectionLng {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          refSectionLngMap {
            enId
            frId
          }
          refSectionLngId
          refSectionLng {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          refInternalLinkLngMap {
            en {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            fr {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          refInternalLinkLng {
            formatRanges {
              offset
              length
              style
            }
            ref {
              linkTarget
              format
              refId
              sizes {
                ll
                mm
                ss
              }
              formatRange {
                offset
                length
                style
              }
              type
            }
            size
            sortKey
            text
          }
          fromUserId
          fromUser {
            id
            administratedPointers {
              pointer
              ownershipLevel
            }
            specialtiesPoc
            email
            federation
            registrationId
            power
            gender
            name {
              firstname
              lastname
              nickname
            }
            phoneNumbers {
              sortKey
              countryCode
              number
              usage
            }
            preferences {
              visibleEmail
              visiblePhones
            }
            titleLng {
              en
              fr
            }
            title
            queryLanguage
            createdAt
            updatedAt
            version
          }
          toUserId
          toUser {
            id
            administratedPointers {
              pointer
              ownershipLevel
            }
            specialtiesPoc
            email
            federation
            registrationId
            power
            gender
            name {
              firstname
              lastname
              nickname
            }
            phoneNumbers {
              sortKey
              countryCode
              number
              usage
            }
            preferences {
              visibleEmail
              visiblePhones
            }
            titleLng {
              en
              fr
            }
            title
            queryLanguage
            createdAt
            updatedAt
            version
          }
          queryLanguage
          parentId
          createdAt
          updatedAt
          version
        }
        geonewsIds
        locationId
        location {
          id
          address {
            municipality
            countryCode
            postCode
            regionCode
            street
          }
          calendar {
            openingHours {
              end
              start
            }
            weekly
            yearly {
              endDate
              startDate
            }
          }
          geolocation {
            latitude
            longitude
          }
          parentId
          municipalityId
          type
          createdAt
          updatedAt
          version
        }
        parentId
        othersPocUsersIds {
          id
          sortKey
        }
        pocUserId
        pocUser {
          id
          administratedPointers {
            pointer
            ownershipLevel
          }
          specialtiesPoc
          email
          federation
          registrationId
          power
          gender
          name {
            firstname
            lastname
            nickname
          }
          phoneNumbers {
            sortKey
            countryCode
            number
            usage
          }
          preferences {
            visibleEmail
            visiblePhones
          }
          titleLng {
            en
            fr
          }
          title
          queryLanguage
          createdAt
          updatedAt
          version
        }
        queryLanguage
        sortKey
        specialtyLngMap {
          enId
          frId
        }
        specialtyLngId
        specialtyLng {
          id
          gallery {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          language
          parentId
          type
          itemsTables {
            headRow {
              sortKey
              string
              type
            }
            itemsIds {
              id
              sortKey
            }
            sortKey
          }
          indexSectionsSortIds {
            id
            sortKey
          }
          indexSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          indexPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          geonewsSectionsSortIds {
            id
            sortKey
          }
          geonewsSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          geonewsPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          gallerySectionsSortIds {
            id
            sortKey
          }
          gallerySections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          galleryPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          itemsSectionsSortIds {
            id
            sortKey
          }
          itemsSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          itemsPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          contactSectionsSortIds {
            id
            sortKey
          }
          contactSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          contactPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add1SectionsSortIds {
            id
            sortKey
          }
          add1Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add1Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add2SectionsSortIds {
            id
            sortKey
          }
          add2Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add2Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add3SectionsSortIds {
            id
            sortKey
          }
          add3Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add3Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add4SectionsSortIds {
            id
            sortKey
          }
          add4Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add4Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          createdAt
          updatedAt
          version
        }
        tagsIds {
          id
          sortKey
        }
        createdAt
        updatedAt
        version
      }
      specialtiesIds {
        id
        sortKey
      }
      createdAt
      updatedAt
      version
    }
  }
`;
export const listStellas = /* GraphQL */ `
  query ListStellas(
    $filter: ModelStellaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStellas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bucket {
          id
          web {
            shortUrl
            isStellactWeb
            cloudfrontUrl
            ipAddress
            sslCertificateId
          }
          stellaFolders {
            id
            sortKey
          }
          createdAt
          updatedAt
          version
        }
        bucketId
        entityId
        interfaceLng {
          id
          gallery {
            firstComposite {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            nextComposites {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            sortKey
            type
          }
          language
          parentId
          type
          itemsTables {
            headRow {
              sortKey
              string
              type
            }
            itemsIds {
              id
              sortKey
            }
            sortKey
          }
          indexSectionsSortIds {
            id
            sortKey
          }
          indexSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          indexPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          geonewsSectionsSortIds {
            id
            sortKey
          }
          geonewsSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          geonewsPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          gallerySectionsSortIds {
            id
            sortKey
          }
          gallerySections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          galleryPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          itemsSectionsSortIds {
            id
            sortKey
          }
          itemsSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          itemsPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          contactSectionsSortIds {
            id
            sortKey
          }
          contactSections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          contactPage {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add1SectionsSortIds {
            id
            sortKey
          }
          add1Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add1Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add2SectionsSortIds {
            id
            sortKey
          }
          add2Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add2Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add3SectionsSortIds {
            id
            sortKey
          }
          add3Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add3Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          add4SectionsSortIds {
            id
            sortKey
          }
          add4Sections {
            id
            parentId
            bucketId
            flexbox {
              columns
              totalColumns
            }
            elements {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            title {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            language
            type
            createdAt
            updatedAt
            version
          }
          add4Page {
            pageTitle {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            layout
            menu {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
          }
          createdAt
          updatedAt
          version
        }
        interfaceLngId
        interfaceLngMap {
          enId
          frId
        }
        logo {
          formatRanges {
            offset
            length
            style
          }
          ref {
            linkTarget
            format
            refId
            sizes {
              ll
              mm
              ss
            }
            formatRange {
              offset
              length
              style
            }
            type
          }
          size
          sortKey
          text
        }
        mediaList {
          mediaRef
          sizes
        }
        name
        options {
          hasDraft
          status
        }
        pointer
        queryLanguage
        social {
          id
          sortKey
        }
        specialties {
          id
          pointer
          designationId
          designation {
            id
            nameLng {
              en
              fr
            }
            name
            descriptionLng {
              en
              fr
            }
            description
            attribute {
              value
              field
            }
            type
            queryLanguage
            parentId
            createdAt
            updatedAt
            version
          }
          levelId
          catchphraseLng {
            en
            fr
          }
          itemsIds
          geonews {
            id
            locationId
            location {
              id
              address {
                municipality
                countryCode
                postCode
                regionCode
                street
              }
              calendar {
                openingHours {
                  end
                  start
                }
                weekly
                yearly {
                  endDate
                  startDate
                }
              }
              geolocation {
                latitude
                longitude
              }
              parentId
              municipalityId
              type
              createdAt
              updatedAt
              version
            }
            language
            name
            audience
            geonewsExpiryDateTime
            geonewsStartDateTime
            geonewsDuration
            entityId
            sectionLngMap {
              enId
              frId
            }
            sectionLngId
            sectionLng {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            refSectionLngMap {
              enId
              frId
            }
            refSectionLngId
            refSectionLng {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            refInternalLinkLngMap {
              en {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              fr {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            refInternalLinkLng {
              formatRanges {
                offset
                length
                style
              }
              ref {
                linkTarget
                format
                refId
                sizes {
                  ll
                  mm
                  ss
                }
                formatRange {
                  offset
                  length
                  style
                }
                type
              }
              size
              sortKey
              text
            }
            fromUserId
            fromUser {
              id
              administratedPointers {
                pointer
                ownershipLevel
              }
              specialtiesPoc
              email
              federation
              registrationId
              power
              gender
              name {
                firstname
                lastname
                nickname
              }
              phoneNumbers {
                sortKey
                countryCode
                number
                usage
              }
              preferences {
                visibleEmail
                visiblePhones
              }
              titleLng {
                en
                fr
              }
              title
              queryLanguage
              createdAt
              updatedAt
              version
            }
            toUserId
            toUser {
              id
              administratedPointers {
                pointer
                ownershipLevel
              }
              specialtiesPoc
              email
              federation
              registrationId
              power
              gender
              name {
                firstname
                lastname
                nickname
              }
              phoneNumbers {
                sortKey
                countryCode
                number
                usage
              }
              preferences {
                visibleEmail
                visiblePhones
              }
              titleLng {
                en
                fr
              }
              title
              queryLanguage
              createdAt
              updatedAt
              version
            }
            queryLanguage
            parentId
            createdAt
            updatedAt
            version
          }
          geonewsIds
          locationId
          location {
            id
            address {
              municipality
              countryCode
              postCode
              regionCode
              street
            }
            calendar {
              openingHours {
                end
                start
              }
              weekly
              yearly {
                endDate
                startDate
              }
            }
            geolocation {
              latitude
              longitude
            }
            parentId
            municipalityId
            type
            createdAt
            updatedAt
            version
          }
          parentId
          othersPocUsersIds {
            id
            sortKey
          }
          pocUserId
          pocUser {
            id
            administratedPointers {
              pointer
              ownershipLevel
            }
            specialtiesPoc
            email
            federation
            registrationId
            power
            gender
            name {
              firstname
              lastname
              nickname
            }
            phoneNumbers {
              sortKey
              countryCode
              number
              usage
            }
            preferences {
              visibleEmail
              visiblePhones
            }
            titleLng {
              en
              fr
            }
            title
            queryLanguage
            createdAt
            updatedAt
            version
          }
          queryLanguage
          sortKey
          specialtyLngMap {
            enId
            frId
          }
          specialtyLngId
          specialtyLng {
            id
            gallery {
              firstComposite {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              nextComposites {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              sortKey
              type
            }
            language
            parentId
            type
            itemsTables {
              headRow {
                sortKey
                string
                type
              }
              itemsIds {
                id
                sortKey
              }
              sortKey
            }
            indexSectionsSortIds {
              id
              sortKey
            }
            indexSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            indexPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            geonewsSectionsSortIds {
              id
              sortKey
            }
            geonewsSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            geonewsPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            gallerySectionsSortIds {
              id
              sortKey
            }
            gallerySections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            galleryPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            itemsSectionsSortIds {
              id
              sortKey
            }
            itemsSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            itemsPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            contactSectionsSortIds {
              id
              sortKey
            }
            contactSections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            contactPage {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add1SectionsSortIds {
              id
              sortKey
            }
            add1Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add1Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add2SectionsSortIds {
              id
              sortKey
            }
            add2Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add2Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add3SectionsSortIds {
              id
              sortKey
            }
            add3Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add3Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            add4SectionsSortIds {
              id
              sortKey
            }
            add4Sections {
              id
              parentId
              bucketId
              flexbox {
                columns
                totalColumns
              }
              elements {
                firstComposite {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                nextComposites {
                  formatRanges {
                    offset
                    length
                    style
                  }
                  ref {
                    linkTarget
                    format
                    refId
                    sizes {
                      ll
                      mm
                      ss
                    }
                    formatRange {
                      offset
                      length
                      style
                    }
                    type
                  }
                  size
                  sortKey
                  text
                }
                sortKey
                type
              }
              title {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              language
              type
              createdAt
              updatedAt
              version
            }
            add4Page {
              pageTitle {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
              layout
              menu {
                formatRanges {
                  offset
                  length
                  style
                }
                ref {
                  linkTarget
                  format
                  refId
                  sizes {
                    ll
                    mm
                    ss
                  }
                  formatRange {
                    offset
                    length
                    style
                  }
                  type
                }
                size
                sortKey
                text
              }
            }
            createdAt
            updatedAt
            version
          }
          tagsIds {
            id
            sortKey
          }
          createdAt
          updatedAt
          version
        }
        specialtiesIds {
          id
          sortKey
        }
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      administratedPointers {
        pointer
        ownershipLevel
      }
      specialtiesPoc
      email
      federation
      registrationId
      power
      gender
      name {
        firstname
        lastname
        nickname
      }
      phoneNumbers {
        sortKey
        countryCode
        number
        usage
      }
      preferences {
        visibleEmail
        visiblePhones
      }
      titleLng {
        en
        fr
      }
      title
      queryLanguage
      createdAt
      updatedAt
      version
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        administratedPointers {
          pointer
          ownershipLevel
        }
        specialtiesPoc
        email
        federation
        registrationId
        power
        gender
        name {
          firstname
          lastname
          nickname
        }
        phoneNumbers {
          sortKey
          countryCode
          number
          usage
        }
        preferences {
          visibleEmail
          visiblePhones
        }
        titleLng {
          en
          fr
        }
        title
        queryLanguage
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
