import { Location } from 'history';
import { parse, ParsedQuery, stringify } from 'query-string';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  BucketFlatInput,
  LocationFlatInput,
  SectionFlatInput,
  SpecialtyLngFlatInput,
  SpecialtyNestedInput,
  StellaFlatInput,
  UserFlatInput,
} from './GqlInputUtils';
import { AuthenticationDataFromCognito, StellanautDataFromCognito } from './SchemaUtils';
import { AppEnum, AnchorEnum, HexagonDrawerEnum, LangEnum } from './TypeUtils';

export interface StellaInterfaceForUser {
  inputLocation: LocationFlatInput;
  inputSpecialty: SpecialtyNestedInput;
  inputStella: StellaFlatInput;
}
export interface UserAuthenticationProps extends AuthenticationDataFromCognito {
  inputUser: UserFlatInput | null;
  stellaInterface: StellaInterfaceForUser | null;
  cubeDrawer: boolean;
}
interface StellanautAuthenticationProps extends StellanautDataFromCognito {
  inputUser: UserFlatInput;
  stellaInterface: StellaInterfaceForUser | null;
  cubeDrawer: boolean;
}
interface StelladminAuthenticationProps extends StellanautDataFromCognito {
  inputUser: UserFlatInput;
  stellaInterface: StellaInterfaceForUser;
  cubeDrawer: boolean;
}
interface CountryAndLang {
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  lang: LangEnum;
  setLang: React.Dispatch<React.SetStateAction<LangEnum>>;
}
export interface AppProps extends CountryAndLang {
  authenticationData: UserAuthenticationProps;
  setAuthenticationData: React.Dispatch<React.SetStateAction<UserAuthenticationProps>>;
}
export interface AppRouteProps extends RouteComponentProps, AppProps {}
export interface ViewStellaRouteProps extends RouteComponentProps {
  inputBucket: BucketFlatInput;
  inputLocation: LocationFlatInput;
  inputSection: SectionFlatInput;
  inputSpecialtyLng: SpecialtyLngFlatInput;
  inputSpecialty: SpecialtyNestedInput;
  inputStella: StellaFlatInput;
  inputUser: UserFlatInput;
  lang: LangEnum;
  cubeDrawer?: boolean;
}
export interface StellanautRouteProps extends RouteComponentProps, CountryAndLang {
  authenticationData: StellanautAuthenticationProps;
  setAuthenticationData: React.Dispatch<React.SetStateAction<UserAuthenticationProps>>;
}
export interface StelladminRouteProps extends RouteComponentProps, CountryAndLang {
  authenticationData: StelladminAuthenticationProps;
  setAuthenticationData: React.Dispatch<React.SetStateAction<UserAuthenticationProps>>;
}
export interface CubeStellaRouteProps extends ViewStellaRouteProps, StellanautRouteProps {
  cubeLang: LangEnum;
  setCubeLang: React.Dispatch<React.SetStateAction<LangEnum>>;
}
export interface UpdateStellaRouteProps extends CubeStellaRouteProps {
  setInputBucket: React.Dispatch<React.SetStateAction<BucketFlatInput>>;
  setInputLocation: React.Dispatch<React.SetStateAction<LocationFlatInput>>;
  setInputSection: React.Dispatch<React.SetStateAction<SectionFlatInput>>;
  setInputSpecialtyLng: React.Dispatch<React.SetStateAction<SpecialtyLngFlatInput>>;
  setInputSpecialty: React.Dispatch<React.SetStateAction<SpecialtyNestedInput>>;
  setInputStella: React.Dispatch<React.SetStateAction<StellaFlatInput>>;
  setInputUser: React.Dispatch<React.SetStateAction<UserFlatInput>>;
}

export interface UrlData {
  lang?: LangEnum;
  hash?: string;
  path?: string;
  search?: ParsedQuery<string>;
}

export const getAppFromPath = (path: string): AppEnum => {
  return path.split('/')[1] as AppEnum;
};

export const getResourceId = (data: Location): string | null => {
  const splittedPathname: string[] = data.pathname.split('/');
  if (splittedPathname.length < 2) return null;
  if (splittedPathname.length >= 2 && splittedPathname[2] === '') return null;
  return data.pathname.split('/')[2];
};

export const getLang = (search: string): LangEnum | null => {
  if (!search) return null;
  const { lang } = parse(search);
  if (typeof lang === 'string') {
    const langAsLangEnum = lang as LangEnum;
    return LangEnum[langAsLangEnum] !== undefined ? langAsLangEnum : null;
  }
  return null;
};

export const getId = (search: string): string | null => {
  if (!search) return null;
  const { id } = parse(search);
  return typeof id === 'string' ? id : null;
};

export const getAnchorEnum = (hash: string | undefined, defautValue: AnchorEnum): AnchorEnum => {
  if (!hash) return defautValue;
  const anchorEnum: AnchorEnum = hash.split('#')[1] as AnchorEnum;
  return AnchorEnum[anchorEnum] !== undefined ? AnchorEnum[anchorEnum] : defautValue;
};

// following search is expected: ?menu=index or ?menu=stella or ?stella=stellaname06fr or ?unassociatedStella=stellaname06fr or ?menu=cloud
export const getHexagonMenuAndId = (search: string): { menu: HexagonDrawerEnum; id: string | null } => {
  const parsedQuery: ParsedQuery = parse(search);
  const { menu, cloud, stella, unassociatedStella } = parsedQuery;
  if (menu) {
    const menuCast: HexagonDrawerEnum = menu as HexagonDrawerEnum;
    return HexagonDrawerEnum[menuCast] ? { menu: HexagonDrawerEnum[menuCast], id: null } : { menu: HexagonDrawerEnum.index, id: null };
  } else if (cloud) return { menu: HexagonDrawerEnum.cloud, id: null };
  else if (unassociatedStella) return { menu: HexagonDrawerEnum.stella, id: unassociatedStella as string };
  else if (stella) return { menu: HexagonDrawerEnum.stella, id: stella as string };
  return { menu: HexagonDrawerEnum.stella, id: null };
};

export const buildHostUrl = (): string => {
  const { protocol, hostname, port } = window.location;
  if (!port) return `${protocol}//${hostname}`;
  return `${protocol}//${hostname}:${port}`;
};

export const buildUrl = (localProps: AppRouteProps | null, data?: UrlData): string => {
  // "new is always better" -> data content is taken in priority to localProps content
  const finalLang: string = data?.lang ? data.lang : localProps !== null ? localProps.lang : '';
  const finalHash = data?.hash ? data.hash : '';
  // the lang is part of search -> as it is computed always, we ignore existing location.search
  // remark: currently, there is only the lang in search -> it is easy
  if (data?.search?.lang) delete data.search.lang;
  const finalSearch: string = data?.search ? stringify(data.search) : '';
  const finalPath = data?.path ? data.path : localProps !== null ? localProps.location.pathname : '/';

  let url = finalPath;
  if (!url) url = '/';
  if (url[url.length - 1] !== '/') url += '/';
  if (finalLang !== '') {
    url += `?${stringify({ ...{ lang: finalLang } })}`;
    if (finalSearch !== '') url += `&${finalSearch}`;
  } else {
    if (finalSearch !== '') url += `?${finalSearch}`;
  }
  if (finalHash !== '') url += `#${finalHash}`;
  return url;
};
