import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { USERPOWER } from '@geo/gql/dist/schema';
import { UserAuthenticationProps } from './AppProps';
import {
  amplifyConfigure,
  amplifyConfirmSignUp,
  amplifyForgotPassword,
  amplifyForgotPasswordSubmit,
  amplifySignIn,
  amplifySignOut,
  amplifyUpdateUserRef,
  CognitoApiReturnCode,
  getAuthenticationDataFromCognito,
  getCognitoApiReturnCode,
  isStellanaut,
} from './AwsUtils';
import { AuthenticationDataFromCognito } from './SchemaUtils';
import { DebugFlow, traceDebug, traceError, traceFlow } from './Trace';

export type AuthListener = (userAuthenticationData: UserAuthenticationProps) => void;

export class StaticAuth {
  refreshAuthListener: Array<AuthListener>;

  static signin(email: string, password: string): Promise<unknown> {
    // Ensure to initialize Application instance
    if (isStellanaut(StaticAuth.initInstance().userAuthenticationData.userPower)) {
      traceDebug('Login while already authenticated');
    }
    return new Promise((resolve, reject) => {
      amplifySignIn(email, password)
        .then((data) => {
          const payload = data.signInUserSession?.idToken?.payload;
          const userGroups: Array<string> = payload['cognito:groups'] as Array<string>;
          const authenticatedData: AuthenticationDataFromCognito = getAuthenticationDataFromCognito(
            userGroups,
            data.attributes?.given_name as string,
            email,
          );
          StaticAuth.initInstance().setAuthenticationData({
            ...authenticatedData,
            inputUser: null,
            stellaInterface: null,
            cubeDrawer: true,
          });
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static signout(): Promise<unknown> {
    // Ensure to initialize Application instance
    if (!isStellanaut(StaticAuth.initInstance().userAuthenticationData.userPower)) {
      traceDebug('Logout while not authenticated');
    }
    return new Promise((resolve) => {
      amplifySignOut().then((data) => {
        const authenticatedData: UserAuthenticationProps = {
          userPower: USERPOWER.Y,
          userId: null,
          stellaId: null,
          email: null,
          inputUser: null,
          stellaInterface: null,
          cubeDrawer: true,
        };
        StaticAuth.initInstance().setAuthenticationData(authenticatedData);
        resolve(data);
      });
    });
  }

  static confirmSignUp(email: string, code: string): Promise<CognitoApiReturnCode> {
    StaticAuth.initInstance();
    return amplifyConfirmSignUp(email, code).then((data) => data);
  }

  static fakeConfirmSignUp(email: string): Promise<CognitoApiReturnCode> {
    StaticAuth.initInstance();
    return amplifyConfirmSignUp(email).then((data) => data);
  }

  static forgotPassword(email: string): Promise<unknown> {
    StaticAuth.initInstance();
    return new Promise((resolve) => {
      amplifyForgotPassword(email).then((data) => resolve(data));
    });
  }

  static forgotPasswordSubmit(email: string, verificationCode: string, newPassword: string): Promise<CognitoApiReturnCode> {
    StaticAuth.initInstance();
    return amplifyForgotPasswordSubmit(email, verificationCode, newPassword).then((data) => data);
  }

  static changePassword(oldPassword: string, newPassword: string): Promise<CognitoApiReturnCode> {
    return new Promise((resolve) => {
      Auth.currentAuthenticatedUser().then((data: CognitoUser) => {
        data.changePassword(oldPassword, newPassword, (err) => {
          if (err) {
            const custom_error: { code?: CognitoApiReturnCode; message?: string } = err as {
              code?: CognitoApiReturnCode;
              message?: string;
            };
            resolve(custom_error.code ? getCognitoApiReturnCode(custom_error.code) : CognitoApiReturnCode.InternalErrorException);
          } else {
            resolve(CognitoApiReturnCode.Success);
          }
        });
      });
    });
  }

  static updateCognitoUser(userId: string, stellaId: string): Promise<unknown> {
    return new Promise((resolve, reject) => {
      amplifyUpdateUserRef(`${userId}:${stellaId}`)
        .then((data) => resolve(data))
        .catch((err) => {
          reject(err);
        });
    });
  }

  /** To be called after user signin or signout */
  static refreshAuthentication(): void {
    const userAuthenticationData: UserAuthenticationProps = StaticAuth.getUserAuthenticationData();
    traceDebug('Refresh authentication:', USERPOWER[userAuthenticationData.userPower]);
    StaticAuth.initInstance().refreshAuthListener.forEach((func) => func(userAuthenticationData));
  }

  /** Add a listener to call after user signin or signout */
  static addAuthRefreshListener(listener: AuthListener): void {
    StaticAuth.initInstance().refreshAuthListener.push(listener);
  }

  static changeAuthenticationData(newAuthenticationData: UserAuthenticationProps): void {
    const auth = StaticAuth.initInstance();
    if (newAuthenticationData !== auth.userAuthenticationData) {
      auth.userAuthenticationData = newAuthenticationData;
      traceDebug('Change StaticAuth userAuthenticationData', newAuthenticationData);
      auth.refreshAuthListener.forEach((func) => func(newAuthenticationData));
    }
  }

  /** Remove a listener */
  static removeAuthRefreshListener(listener: AuthListener): void {
    const index = StaticAuth.initInstance().refreshAuthListener.indexOf(listener);
    if (index > -1) {
      StaticAuth.initInstance().refreshAuthListener.splice(index, 1);
    }
  }

  static getUserAuthenticationData(): UserAuthenticationProps {
    return StaticAuth.initInstance().userAuthenticationData;
  }

  static refreshAuthenticationDataFromAmplify(): void {
    Auth.currentAuthenticatedUser()
      .then((data: CognitoUser) => {
        traceDebug('Current user session', data);
        const payload = data.getSignInUserSession()?.getIdToken().decodePayload();
        traceDebug('Current  user session payload', payload);
        const userGroups: Array<string> | null = payload ? (payload['cognito:groups'] as Array<string>) : null;
        const userRef: string | null = payload ? payload['given_name'] : null;
        const email: string | null = payload ? payload['email'] : null;
        if (userGroups && userRef && email) {
          const cognitoAuthenticatedData: AuthenticationDataFromCognito = getAuthenticationDataFromCognito(userGroups, userRef, email);
          StaticAuth.initInstance().setAuthenticationData({
            ...cognitoAuthenticatedData,
            inputUser: null,
            stellaInterface: null,
            cubeDrawer: true,
          });
        } else
          StaticAuth.initInstance().setAuthenticationData({
            userPower: USERPOWER.Y,
            userId: null,
            stellaId: null,
            email: null,
            inputUser: null,
            stellaInterface: null,
            cubeDrawer: true,
          });
      })
      .catch((...data) => traceDebug('Session error', ...data));
  }

  static setAuthenticationFromProps(authenticationData: UserAuthenticationProps): void {
    traceDebug('Set new authentication from props', authenticationData);
    StaticAuth.initInstance().setAuthenticationData(authenticationData);
  }

  /* ALL BELOW IS FOR INTERNAL USE ONLY */
  private static instance: StaticAuth | undefined = undefined;

  userAuthenticationData: UserAuthenticationProps = {
    userPower: USERPOWER.Y,
    userId: null,
    stellaId: null,
    email: null,
    inputUser: null,
    stellaInterface: null,
    cubeDrawer: true,
  };

  setAuthenticationData(value: UserAuthenticationProps): void {
    this.userAuthenticationData = value;
    StaticAuth.refreshAuthentication();
  }

  constructor() {
    if (StaticAuth.instance !== undefined) {
      traceError('Never instantiate Application directly');
    }

    // List of functions to call whenever the application configuration changes
    this.refreshAuthListener = [];
    traceFlow(this, DebugFlow.INIT);

    // Setup amplify
    amplifyConfigure();
  }

  private static initInstance(): StaticAuth {
    if (StaticAuth.instance === undefined) {
      StaticAuth.instance = new StaticAuth();
    }
    return StaticAuth.instance;
  }
}
