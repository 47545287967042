import { LangDataBodyMilky } from './LangData';
import { LangEnum } from './TypeUtils';

export class LangOption {
  key: string;
  value: LangEnum;
  source: string;
  languageName: string | undefined;
  constructor(key: string, value: LangEnum, source: string, languageName: string | undefined) {
    this.key = key;
    this.value = value;
    this.source = source;
    this.languageName = languageName;
  }
}

export type LangOptions = Array<LangOption>;
export interface LangName {
  lang: string;
  name: string;
}

type LangNames = Array<LangName>;

const langNames: LangNames = [
  { lang: LangEnum.fr, name: LangDataBodyMilky.frLanguageName() },
  { lang: LangEnum.en, name: LangDataBodyMilky.enLanguageName() },
];

export const getLangOption = (lang: string): LangOption => {
  return new LangOption(
    `menu_lang_${lang}`,
    LangEnum[lang as keyof typeof LangEnum],
    `${lang}Flag`,
    langNames.find((langName) => {
      return langName.lang === lang;
    })?.name,
  );
};
